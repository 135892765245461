import { green, purple, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#093d5c",
    },
    secondary: {
      main: "#093d5c",
    },
  },
  body: {
  },
  typography: {
    body1: {
      fontFamily: 'inherit',
      color: localStorage.theme === "light" ? '#6f747b' : 'black',
    },
    body2: {
      fontFamily: 'inherit',
      color: localStorage.theme === "light" ? '#6f747b' : 'black',
    },
    h1: {
      fontFamily: 'inherit',
      color: localStorage.theme === "light" ? '#ffffff' : 'black',
    },
    h2: {
      fontFamily: 'inherit',
      color: localStorage.theme === "light" ? '#242d33' : 'black',
      fontWeight: 'bolder',
    },
    h3: {
      fontFamily: 'inherit',
      color: localStorage.theme === "light" ? '#242d33' : 'black',
    },
    h4: {
      fontFamily: 'inherit',
      color: localStorage.theme === "light" ? '#242d33' : 'black',
    },
    h5: {
      fontFamily: 'inherit',
      color: localStorage.theme === "light" ? '#242d33' : 'black',
    },
    h6: {
      fontFamily: 'inherit',
      fontWeight: 'bolder',
      color: localStorage.theme === "light" ? '#242d33' : 'black',
    },
    subtitle1: {
      fontFamily: 'inherit',
      color: localStorage.theme === "light" ? '#8A8A8F' : 'black',
    },
    subtitle2: {
      fontFamily: 'inherit',
      color: localStorage.theme === "light" ? '#8A8A8F' : 'black',
    },
  }
}
)

export default theme