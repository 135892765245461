/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { login } from '../redux/AuthCRUD'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import FacebookLoginButton from './facebooklgoin'
import GoogleLoginButton from './googlelogin'
import { AxiosError } from 'axios'
import Swal from 'sweetalert2'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, Input, InputAdornment } from '@mui/material'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: 'password',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);

    // Set Formik's state
    formik.setFieldValue('password', e.target.value, true);
  };
  const handleEmailValue = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('email', e.target.value, true);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [loading, setLoading] = useState(false)
  const history = useHistory();
  const dispatch = useDispatch()

  const { search } = useLocation();
  useEffect(() => {
    // Extract token from URL
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get("token");
    if (token) {
      dispatch(auth.actions.login(token));
      history.push('/dashboard');
    }
  }, [history]);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data: { token, _id } }) => {
            setLoading(false)
            dispatch(auth.actions.login(token))
            history.push('/dashboard');
          })
          .catch((error: AxiosError) => {
            setLoading(false)
            setSubmitting(false)
            // setStatus('The login detail is incorrect')
            if (error.response && error.response.status === 403) {
              // setStatus('Your account has been suspended');
              Swal.fire({
                title: "Your account has been suspended",
                text: "",
                icon: "error",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: "OK",
                confirmButtonText: "OK",
              })

            } else if (error.response && error.response.status === 406) {
              setStatus('Email is not verified. Please verify your email.');
            } else if (error.response && error.response.status === 401) {
              setStatus('The login details are incorrect');
            } else if (error.response && error.response.status === 404) {
              setStatus("Email doesn't found.");
            }
            else {
              setStatus('The login details are incorrect');
            }
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-95'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-5'>
        <h1 className='text-dark mb-3'>Sign In to Imlaak FSBO</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className=' alert alert-danger'>
          <div className=''>{formik.status}</div>
        </div>
      ) : (
        <div className=''>

        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <Input
          placeholder='example@domain.com'
          // Updated handler
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          onChange={handleEmailValue}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          {/* begin::Label */}
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          {/* end::Label */}
          <div className='d-flex justify-content-between flex-stack mb-2'>
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <Input
          className='form-control form-control-lg form-control-solid'
          name='password'
          type={showPassword ? 'text' : 'password'}
          placeholder='password'
          onChange={handlePasswordChange}  // Updated handler
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={handleTogglePasswordVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          autoComplete='off'
        />


        {/* <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        /> */}
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-3'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        <div className='text-center text-muted text-uppercase fw-bolder mb-2'>or</div>
        {/* end::Separator */}

        {/* begin::Google link */}
        <GoogleLoginButton />
        {/* end::Google link */}

        {/* begin::Google link */}
        <FacebookLoginButton />

        {/* end::Google link */}


      </div>
      {/* end::Action */}
    </form>
  )
}
