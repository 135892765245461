/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { KTSVG } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { getCSS, getCSSVariableValue } from "../../../assets/ts/_utils";

type Props = {
    className: string;
};

const LandlordChart: React.FC<Props> = ({ className }) => {
    const chartRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!chartRef.current) {
            return;
        }

        const height = parseInt(getCSS(chartRef.current, "height"));

        const chart = new ApexCharts(chartRef.current, getChartOptions(height));
        if (chart) {
            chart.render();
        }

        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [chartRef]);

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
                {/* begin::Title */}
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1">
                        Recent Statistics
                    </span>

                    <span className="text-muted fw-bold fs-7">
                        Property Gross Worth
                    </span>
                </h3>
                {/* end::Title */}

                {/* begin::Toolbar */}
                <div className="card-toolbar d-none">
                    {/* begin::Menu */}
                    <button
                        type="button"
                        className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-flip="top-end"
                    >
                        <KTSVG
                            path="/media/icons/duotune/general/gen024.svg"
                            className="svg-icon-2"
                        />
                    </button>
                    <Dropdown1 />
                    {/* end::Menu */}
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className="card-body">
                {/* begin::Chart */}
                <div
                    ref={chartRef}
                    id="kt_charts_widget_1_chart"
                    style={{ height: "240px" }}
                />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    );
};

export { LandlordChart };

function getChartOptions(height: number): ApexOptions {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");

    return {
        series: [
            {
                name: "Property",
                data: [14, 35, 57, 76, 95, 118, 138, 158, 178, 198, 218, 238],
            },
            {
                name: "Property",
                data: [14, 35, 57, 76, 95, 118, 138, 158, 178, 198, 218, 238],
            },
        ],
        chart: {
            fontFamily: "inherit",
            type: "bar",
            height: height,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "30%",
                borderRadius: 5,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec",],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: "12px",
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: "12px",
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: "none",
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: "none",
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: "none",
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: "12px",
            },
            y: {
                formatter: function (val) {
                    return val + " New Evaluation";
                },
            },
        },
        colors: [baseColor, secondaryColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    };
}
