import { KTSVG } from "_metronic/helpers";
import React, { useEffect, useState } from "react";
import Image from "../../../../../_metronic/assets/media/avatars/my.jpg";
import { EyeIcon } from "@heroicons/react/solid";
import { API as apiHost } from "app/utils/config";
import { Box } from "@mui/material";
import Loader from "../../../Loader";
import { getConsultantDetailsById } from "app/components/auth/redux/AuthCRUD";

interface MyComponentProps {
  id: any;
}

const Consultant = (props: MyComponentProps) => {
  const [userData, setUserData] = useState<any>({});

  useEffect(() => {
    async function fetchData() {
      const response = await getConsultantDetailsById(props?.id);
      setUserData(response?.consultant);
    }
    fetchData();
  }, [props.id]);
  const imageUrl = `${apiHost}/uploads/${userData?.userImage}`;
  const isEmpty = !userData?.userImage;
  return (
    <>
      <button
        type="button"
        className="btn btn-lg btn-light-primary me-3"
        data-bs-toggle="modal"
        data-bs-target="#kt_modal_1"
      >
        <EyeIcon className="mx-2" style={{ height: "20px", width: "20px" }} />
        View Profile
      </button>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <>
              {!userData ? (
                <Box
                  sx={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader top='"60%' left='"50%' />
                </Box>
              ) : (
                <>
                  <div className="modal-header">
                    <h5 className="modal-title text-capitalize">{userData?.fname}</h5>
                    <div
                      className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                      />
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-lg-6 pt-2">
                        <div className="justify-content-between">
                          <h5>Specialities: </h5>
                          <p>{userData?.specialties &&
                            userData?.specialties.join(", ")}</p>
                        </div>
                        <div className="justify-content-between">
                          <h5>Languages: </h5>
                          <p>{userData?.languages &&
                            userData?.languages.join(", ")}</p>
                        </div>
                        <div className="justify-content-between">
                          <h5>Experience: </h5>
                          <p> {userData?.experience}</p>
                        </div>
                        <div className="justify-content-between">
                          <h5>No of Properties:</h5>
                          <p className="">{userData?.propertiesCount + 32}</p>
                        </div>
                        <div className="justify-content-between">
                          <h5>No of Landlords:</h5>
                          <p className="">{userData?.landlordCount + 21}</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-lg-6 d-flex justify-content-end">
                        {!isEmpty ? (
                          <img
                            className="rounded border"
                            style={{ height: "100px", width: "100px" }}
                            src={imageUrl}
                            alt=""
                          />
                        ) : (
                          <img
                            className="rounded"
                            style={{ height: "100px", width: "100px" }}
                            src={Image}
                            alt=""
                          />
                        )}
                      </div>

                      <div className="justify-content-between">
                        <h5>Description:</h5>
                        <p>{userData?.description}</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div >
        </div >
      </div >
    </>
  );
};

export default Consultant;
