import { capitalizeFirstLetter, IconRender } from "app/components/Table";
import Table from "app/components/Table";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import { PageTitle, useLayout } from "_metronic/layout/core";
import { useEffect, useState } from "react";
import { RootState } from "setup";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getConsultantDetailsById } from "../auth/redux/AuthCRUD";

const columns = (iconClickHandler: any) => [

    {
        name: "Landlord Name",
        key: "fname",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    }, {
        name: "Email",
        key: "email",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => value,
    }, {
        name: "Contact",
        key: "contact",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => value,
    },
];

interface RouteParams {
    id: string;
}
export function LandlordsOfConsultant() {
    let { id } = useParams<RouteParams>();

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);

    const [consultantLandlords, setConsultantLandlords] = useState([]);
    const [consultantName, setConsultantName] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getConsultantDetailsById(id);
                setConsultantLandlords(response?.consultant?.landlords);
                setCount(response?.consultant?.landlords?.length)
                setConsultantName(response?.consultant?.fname)
                setLoading(false)
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [id]);

    const {
        iconClickHandler,
    } = useFetchData({});

    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    const data = consultantLandlords
    return (
        <>
            <PageTitle>{consultantName} Landlords</PageTitle>
            <div className="pb-5">
                <Table
                    columns={columns(iconClickHandler("/landlordsOfConsultant"))}
                    data={data}
                    loading={loading}
                    onRowClick={(row: any, type: any) =>
                        iconClickHandler("/landlordsOfConsultant")(row, type)
                    }
                    isPaginationRequired
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    total={count}
                />
            </div>
        </>
    );
}
