/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { Link, useLocation, useHistory } from 'react-router-dom';
import { getMyProperties } from 'app/components/ProfilePage'
import NumberFormatter from 'app/shared/Input/NumberFormatter'

type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
  properties?: any
}

const AssetsCarts: React.FC<Props> = ({ className, chartColor, chartHeight, strokeColor, properties }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [currentListing, setCurrentListing]: any = useState('')
  const [loading, setLoading] = useState(false)
  const [detail, setDetail]: any = useState({})
  const history = useHistory()
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
    getMyProperties(accessToken).then(({ data }) => {
      setDetail(data)
    }).catch(() => { }).finally(() => setLoading(false))
  }, [])

  useEffect(() => {

    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor, strokeColor)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
    <div className={`card shadow `}>

      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div className={`mixed-widget-2-chart card-rounded-bottom `}></div>
        {/* end::Chart */}
        {/* begin::Stats */}
        <div className='card-p mt-10 position-relative'>
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            <div className='col shadow bg-white px-6 py-8 rounded-2 me-7 mb-7' style={{ cursor: 'pointer', borderBottom: '2px solid #E63167' }}>
              <KTSVG
                path='/media/icons/duotune/finance/red.svg'
                className='svg-icon-3x svg-icon-danger d-block my-2'
              />
              <div className='text-muted '>
                <span className="text-muted fw-bolder fs-6" ><KTSVG path="/media/icons/duotune/graphs/gra002.svg" className="svg-icon svg-icon-2x svg-icon-danger" />  PKR <NumberFormatter value={detail?.dashboardDetails?.allPropertiesPrice} /></span>
                {/* {detail?.dashboardDetails?.netWorth} */}
                <br></br><br />
                <span className='fw-bolder fs-6'>Net Worth</span>
              </div>
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col shadow bg-white px-6 py-8 rounded-2 me-7 mb-7' style={{ cursor: 'pointer', borderBottom: '2px solid #29CC9E' }}>
              <KTSVG
                path='/media/icons/duotune/finance/green.svg'
                className='svg-icon-3x svg-icon-success d-block my-2'
              />
              <Link to="/property" className=''>
                <span className=" text-muted fw-bolder fs-6" ><KTSVG path="/media/icons/duotune/general/ht.svg" className="svg-icon svg-icon-2x svg-icon-success" /> {detail?.dashboardDetails?.totalProperties}</span>
                <span className="text-muted fw-bolder fs-6" ><KTSVG path="/media/icons/duotune/graphs/gra001.svg" className="svg-icon svg-icon-2x svg-icon-success px-10" /></span>
                <br></br><br />
                <span className='fw-bolder text-muted fs-6'>Total Properties</span>
              </Link>
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div onClick={() => history.push("/listings?filter=listed")} className='col shadow bg-white px-6 py-8   rounded-2 me-7 mb-7' style={{ cursor: 'pointer', borderBottom: '2px solid #3980e5' }}>
              <KTSVG
                path='/media/icons/duotune/finance/blue.svg'
                className='svg-icon-3x svg-icon-primary d-block my-2'
              />
              <p className='text-muted fw-bolder fs-6'>
                <span className=" text-muted fw-bolder fs-6"><KTSVG path="/media/icons/duotune/general/ht.svg" className="svg-icon svg-icon-2x svg-icon-primary" /> {detail?.dashboardDetails?.listedProperties}</span>
                <span className=" text-muted fw-bolder fs-6"><KTSVG path="/media/icons/duotune/graphs/gra001.svg" className="svg-icon svg-icon-2x svg-icon-primary mx-10" /></span>
                <br></br><br />
                <span className='text-muted fw-bolder fs-6'>Listed Properties</span>
              </p>
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            {/* <div onClick={() => history.push("/listings?filter=unListed")} className='col shadow bg-white px-6 py-8  rounded-2 me-7 mb-7' style={{ cursor: 'pointer', borderBottom: '2px solid #FF9776' }}> */}
            <div className='col shadow bg-white px-6 py-8  rounded-2 me-7 mb-7' style={{ cursor: 'pointer', borderBottom: '2px solid #FF9776' }}>
              <KTSVG
                path='/media/icons/duotune/finance/yellow.svg'
                className='svg-icon-3x svg-icon-warning d-block my-2'
              />
              <p className='text-muted'>
                <span className=" text-muted fw-bolder fs-6"><KTSVG path="/media/icons/duotune/general/ht.svg" className="svg-icon svg-icon-2x svg-icon-warning" /> {detail?.dashboardDetails?.unListedProperties} </span>
                <span className=" text-muted fw-bolder fs-6"><KTSVG path="/media/icons/duotune/graphs/gra001.svg" className="svg-icon svg-icon-2x svg-icon-warning px-10" /></span>
                <br></br><br />
                <span className='fw-bolder fs-6'>UnListed Properties</span>
              </p>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const color = getCSSVariableValue('--bs-' + chartColor)

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['transparent'],
    markers: {
      colors: [color],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
  }
}

export { AssetsCarts }
