/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Field, ErrorMessage } from "formik";

const Step1: FC = () => {
  return (
    <div className="w-100">
      <div className="fv-row">
        <div className="pb-10 pb-lg-12">
          <h2 className="fw-bolder text-dark">
            Please provide your business name and address in the designated
            inputs.
          </h2>
        </div>
        <div className="row">
          <div className="fv-row mb-10">
            <label className="form-label">Business Name<small>(Optional)</small></label>
            <Field
              name="businessName"
              className="form-control form-control-lg form-control-solid"
            />
            <div className="text-danger mt-2">
              <ErrorMessage name="businessName" />
            </div>
          </div>
          <div className="fv-row mb-10">
            <label className="form-label">Business Email<small>(Optional)</small></label>
            <Field
              name="businessEmail"
              className="form-control form-control-lg form-control-solid"
            />
            <div className="text-danger mt-2">
              <ErrorMessage name="businessEmail" />
            </div>
          </div>
          <div className="fv-row mb-10">
            <label className="form-label">Business Address<small>(Optional)</small></label>
            <Field
              name="businessAddress"
              className="form-control form-control-lg form-control-solid"
            />
            <div className="text-danger mt-2">
              <ErrorMessage name="businessAddress" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step1 };
