import { toAbsoluteUrl } from '../../../helpers'

export function FallbackView() {
  return (
    <div className='container splash-screen align-middle'>
      <div className="">
        <img src={toAbsoluteUrl('/media/logos/logo-compact.png')} alt='Start logo' />
        <span>Loading ...</span>
      </div>
    </div>
  )
}
