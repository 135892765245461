import clsx from "clsx";
import React from "react";
import { useLocation } from "react-router-dom";
import { KTSVG } from "_metronic/helpers";
import { useLayout } from "../../core/LayoutProvider";
import { DefaultTitle } from "../header/page-title/DefaultTitle";
import { Toolbar1 } from "./Toolbar1";
const Toolbar = ({ toolbarStyle }: any) => {
  const { classes, clickButtonHandler, otherButtonHandler } = useLayout();
  const { config } = useLayout();
  const location = useLocation();

  return (
    <div className="toolbar" id="kt_toolbar">
      {/* begin::Container */}
      <div
        id="kt_toolbar_container"
        className={clsx(
          classes.toolbarContainer.join(" "),
          "d-flex flex-stack rounded py-3"
        )}
        style={{ background: "#fff", width: "95%" }}
      >
        <DefaultTitle />
        {location.pathname.includes("/projects") ||
          location.pathname.includes("/bookings") ||
          location.pathname.includes("/rent") ||
          location.pathname.includes("/amenities") ||
          location.pathname.includes("/building") ||
          location.pathname.includes("/projectDeveloper") ||
          location.pathname.includes("/career") ||
          location.pathname.includes("/table") ||
          location.pathname.includes("/consultants") ||
          // location.pathname.includes("/PropertyLandlords") ||
          location.pathname.includes("/maps") ||
          location.pathname.includes("/PropertyCategory") ||
          location.pathname.includes("/property") ||
          location.pathname.includes("/inventory") ||
          location.pathname.includes("/country") ||
          location.pathname.includes("/landlordUsers") ||
          location.pathname.includes("/projectName") ||
          location.pathname.includes("/myLandlords") ||
          location.pathname.includes("/managers") ||
          location.pathname.includes("/province") ||
          location.pathname.includes("/city") ||
          location.pathname.includes("/community") ||
          location.pathname.includes("/sector") ||
          location.pathname.includes("/block") ||
          location.pathname.includes("/consultantProperty") ||
          location.pathname.includes("/listingProperty") ||
          location.pathname.includes("/blog") ||
          location.pathname.includes("/ProjectNameTesting") ||
          location.pathname.includes("/listings") ||
          location.pathname.includes("reservation") ? (
          <button
            className="btn btn-sm btn-primary cursor-pointer"
            id="create-button"
            onClick={clickButtonHandler}
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_create_app'
          // id='kt_toolbar_primary_button'
          >
            {location.pathname.includes("/amenities") && "Add Amenities"}
            {location.pathname.includes("/building") && "Add building"}
            {location.pathname.includes("/projectDeveloper") && "Add New Developer"}
            {location.pathname.includes("/career") && "Create Job"}
            {/* {location.pathname.includes("/employment") &&"Add  employment"} */}
            {location.pathname.includes("/consultants") && "Add New Consultants"}
            {/* {location.pathname.includes("/PropertyLandlords") &&"Add New Landlord"} */}
            {location.pathname.includes("/landlordUsers") && "Add New Landlord"}
            {location.pathname.includes("/myLandlords") && "Add New Landlord"}
            {location.pathname.includes("/managers") && "Add New Manager"}
            {location.pathname.includes("/maps") && "Add New Map"}
            {location.pathname.includes("/PropertyCategory") && "Add Property Category"}
            {location.pathname.includes("/country") && "Add Country"}
            {location.pathname.includes("/projectName") && "Add Project Name"}
            {location.pathname.includes("/province") && "Add Province"}
            {location.pathname.includes("/city") && "Add City"}
            {location.pathname.includes("/community") && "Add Community"}
            {location.pathname.includes("/sector") && "Add Sector"}
            {location.pathname.includes("/block") && "Add Block"}
            {location.pathname.includes("/property") && "Add Your Property"}
            {location.pathname.includes("/inventory") && "Add Your Inventory "}
            {location.pathname.includes("/blogs") && "Create Blog"}
            {location.pathname.includes("/projects") && "Post Your Project"}
            {location.pathname.includes("/bookings") && "Add Booking"}
            {location.pathname.includes("/rent") && "Add Rent Project"}
            {location.pathname.includes("/table") && "Add New Table"}
            {location.pathname.includes("/reservations") && "Add New Reservations"}
            {location.pathname.includes("/listings") && "List Property"}
            {location.pathname.includes("/ProjectNameTesting") && "Add Project Name"}

            {/* Consultant Pages */}
            {location.pathname.includes("/consultantProperty") && "Add New Property"}
            {location.pathname.includes("/listingProperty") && "List New Property"}

          </button>
        ) : null}
        {location.pathname.includes("/abc") ? (
          <div className="d-flex align-items-center py-1">
            {/* begin::Wrapper */}
            <div className="me-4">
              {/* begin::Menu */}
              <a
                id="filter"
                href="#"
                className="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="top-end"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-5 svg-icon-gray-500 me-1"
                />
                Filter
              </a>

              {/* end::Menu */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Button */}

            <a
              className="btn btn-sm btn-primary"
              id="create-button"
              onClick={clickButtonHandler}

            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_create_app'
            // id='kt_toolbar_primary_button'
            >
              POST YOUR PROJECT
            </a>
            {/* end::Button */}
          </div>
        ) : null}

        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
    // <></>
  );
  // switch (config.toolbar.layout) {
  //   case 'toolbar1':
  //     return <Toolbar1 toolbarStyle={toolbarStyle} />

  //   default:
  //     return <Toolbar1 />
  // }
};

export { Toolbar };
