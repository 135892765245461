/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG } from "../../../helpers";
import { Stack } from "@mui/material";

type Props = {
  className: string;
  color: string;
  svgIcon: string;
  iconColor: string;
  title: string;
  titleColor?: string;
  description: string;
  descriptionColor?: string;
};

const StatisticsWidgetAdmin: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
}) => {
  return (
    <div className={`card bg-${color} hoverable ${className}`}>
      <div className="card-body">
        <div className={`fw-bolder text-${descriptionColor}`}>
          {description}
        </div>
        <Stack direction={"row"} alignItems="center" spacing={2}>
          <div className={``}>
            <KTSVG
              path={svgIcon}
              className={`svg-icon-${iconColor}  svg-icon-3x  ms-n1`}
            />
          </div>

          <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>
            {title}
          </div>
        </Stack>
      </div>
    </div>
  );
};

export { StatisticsWidgetAdmin };
