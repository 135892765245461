import { capitalizeFirstLetter, IconRender } from "app/components/Table";
import Table from "app/components/Table";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import EditAgent from "./CreateUpdate";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Button } from "@mui/material";
import { useLayout } from "_metronic/layout/core";
import { useEffect } from "react";

const columns = (iconClickHandler: any) => [
  {
    name: "Table Name",
    key: "fname",
    numaric: false,
    isSortable: true,
    renderContent: (value: any) => capitalizeFirstLetter(value),
  },
  {
    name: "Date",
    key: "languages",
    numaric: false,
    isSortable: true,
    renderContent: (value: any) =>
      Array.isArray(value)
        ? capitalizeFirstLetter(value.join(", "))
        : capitalizeFirstLetter(value),
  },
  {
    name: "Action",
    key: "action",
    component: (row: any) => (
      <>
        {/* {IconRender(InfoOutlinedIcon, iconClickHandler)} */}
        {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "EDIT")}
        {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, "delete")}
      </>
    ),
  },
];

export function Details() {
  const { getAll, create, update, delete: deleteUrl } = APIS.users;
  const {
    data,
    loading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    open,
    setOpen,
    modalMode,
    updateFunction,
    createFunction,
    current,
    iconClickHandler,
    setCurrent,
  } = useFetchData({ getAll, create, update, deleteUrl });
  const { setClickButtonHandler } = useLayout();

  useEffect(() => {
    setClickButtonHandler(() => () => {
      setOpen(true);
      setCurrent({});
    });
  }, []);

  // Filter data to only show consultants
  const filteredData = data.filter((row: any) => row.role === "consultant" && row.isVerified === true);

  return (
    <>
      <div className="pb-5">
        <Table
          columns={columns(iconClickHandler("/PropertyConsultants/edit"))}
          data={filteredData}
          loading={loading}
          onRowClick={(row: any, type: any) =>
            iconClickHandler("/PropertyConsultants/edit")(row, type)
          }
          isPaginationRequired
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          total={count}
        />
        <EditAgent
          setOpen={setOpen}
          open={open}
          loading={loading}
          update={modalMode === "EDIT" ? updateFunction : createFunction}
          type={modalMode}
          data={current}
        />
      </div>
    </>
  );
}
