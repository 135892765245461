import { useEffect, useState, useMemo, useRef } from "react";
import { Box, Grid, Card, IconButton, } from "@mui/material";
import Modal from "../../Modal";
import CustomInput from "../../Inputs";
import { useFormik } from "formik";
import * as yup from "yup";
import JoditEditor from "jodit-react";
import { makeStyles } from "@mui/styles";
import Loader from "../../Loader";
import useFetchData from "app/hooks/useFetchData ";
import { APIS } from "Constants";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "setup";
import { CloudImage } from "app/shared/Input/CloudImage";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Delete } from "@mui/icons-material";
import { v2 as cloudinary } from "cloudinary";


const validationSchema = yup.object({
  salePrice: yup.number().required("Sale price is required").min(1, "Sale price must be greater than 0"),
});

type EditLandlordListingForm = {
  open: any;
  setOpen: any;
  property?: any;
  AddProperty?: any;
  loading?: boolean;
  updateProperty?: any;
  modalMode?: any;
  error?: any;
  label?: string;
  value?: string;
};

const EditLandlordListingForm = ({
  open,
  setOpen,
  property,
  AddProperty,
  loading,
  modalMode,
  error,
  label,
  updateProperty,
  value,
}: EditLandlordListingForm) => {
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );
  const { getAllNames } = APIS.ProjectName;
  const { projectNameData } = useFetchData({ getAllNames });
  const [projectLookup, setProjectLookup] = useState<any>({});
  const [error2, setError2] = useState('');

  useEffect(() => {
    const lookup: any = {};
    projectNameData.forEach((row: any) => {
      lookup[row?._id] = row?.projectName;
    });
    setProjectLookup(lookup);
  }, [projectNameData]);

  const editor = useRef(null);

  const [imagePreviews, setImagePreviews] = useState<(string | ArrayBuffer)[]>([]);

  const formik = useFormik({
    initialValues: {
      projectName: property?.projectName || "",
      category: property?.category || "",
      subCategory: property?.subCategory || "",
      description: property?.description || "",
      salePrice: property?.salePrice || 0,
      floorNo: property?.floorNo || 0,
      appartmentNo: property?.appartmentNo || 0,
      images: property?.images || [],
      propertyImages: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      const { propertyImages } = values
      let imagesData = [];
      const existingImages = property.propertyImages;
      const currentImages = values.propertyImages || [];

      // Identify deleted images
      const imagesToDelete = existingImages.filter((img: any) => !currentImages.some((curImg: any) => curImg.public_id === img.public_id));
      const previousImagesToSave = existingImages.filter((img: any) => currentImages.some((curImg: any) => curImg.public_id === img.public_id));

      // Delete removed images from Cloudinary
      const deletePromises = imagesToDelete.map((img: any) => cloudinary.uploader.destroy(img.public_id));
      await Promise.all(deletePromises);

      // Identify new images that need to be uploaded
      const imagesToUpload = currentImages
        .filter((img: { public_id?: string, file?: File }) => !img.public_id)
        .map((img: { file: File }) => img.file);
      // Upload new images
      if (imagesToUpload.length > 0) {
        const uploadPromises = imagesToUpload.map(async (imageFile: any) => {
          return CloudImage(imageFile);
        });
        const uploadedImages = await Promise.all(uploadPromises);
        imagesData = [...previousImagesToSave.filter((img: any) => img.url), ...uploadedImages];
      } else {
        imagesData = currentImages;
      }

      const { salePrice, description } = values;
      const newValues = { isListed: true, salePrice, description, propertyImages: imagesData };

      updateProperty(newValues);
      setImagePreviews([])
    },
  });

  useEffect(() => {
    formik.setFieldValue("projectName", property?.projectName);
    formik.setFieldValue("category", property?.category);
    formik.setFieldValue("subCategory", property?.subCategory);
    formik.setFieldValue("description", property?.description);
    formik.setFieldValue("floorNo", property?.floorNo);
    formik.setFieldValue("appartmentNo", property?.appartmentNo);
    formik.setFieldValue("salePrice", property?.salePrice);
    formik.setFieldValue("propertyImages", property?.propertyImages || []);
  }, [property]);


  // Images Function
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };
  useEffect(() => {
    if (formik.values.propertyImages.length > 0) {
      const initialPreviews = formik.values.propertyImages?.map((image: any) => image.url);
      setImagePreviews(initialPreviews);
    }
  }, [formik.values.propertyImages]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      files.forEach((file) => {
        if (file.size > 1024 * 1024) {
          setError2('File size should not exceed 1024 KB.');
        } else {
          const fileObjects = files.map(file => ({
            file,
            url: URL.createObjectURL(file)
          }));

          // Update Formik state and local preview state
          const allImages = [...formik.values.propertyImages, ...fileObjects];
          formik.setFieldValue("propertyImages", allImages);
          setImagePreviews(allImages.map(img => img.url));
        }
      });
    }
  };

  const handleDeleteImage = (index: number) => {
    const updatedImages = formik.values.propertyImages.filter((_: any, i: any) => i !== index);
    formik.setFieldValue("propertyImages", updatedImages);
    setImagePreviews(updatedImages.map((img: any) => img.url));
  };
  return (
    <Modal
      setOpen={() => setOpen(!open)}
      open={open}
      title={
        modalMode === "edit"
          ? ` Update Listed Property`
          : "List Property"
      }
      cancelButtonTitle="Cancel"
      acceptButtonVarient="contained"
      acceptButtonTitle={
        modalMode === "edit"
          ? ` Update Listed Property`
          : "List Property"
      }
      handleAccept={() => formik.handleSubmit()}
      handleClose={() => {
        formik.resetForm()
        setImagePreviews([])
      }}
      loading={loading}
      isActionable={true}
    >
      {loading ? (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "30%",
          }}
        >
          <Loader top='"60%' left='"50%' />
        </Box>
      ) : null}
      <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="projectName"
              label="Building Name *"
              value={projectLookup[property.projectName] || property.projectName}
              type="text"
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="floorNo"
              label="Floor No *"
              value={formik.values.floorNo}
              type="text"
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="appartmentNo"
              label="Apartment No *"
              value={formik.values.appartmentNo}
              type="text"
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="category"
              label="Category *"
              value={formik.values.category}
              type="text"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="subCategory"
              label="Sub-Category *"
              value={formik.values.subCategory}
              type="text"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="salePrice"
              label="Sale Price (PKR):"
              placeholder="Type here..."
              value={formik.values.salePrice}
              type="number"
              onChangeHandler={(event: any) => {
                formik.setFieldValue("salePrice", event.target.value);
              }}
              isError={formik.touched.salePrice && Boolean(formik.errors.salePrice)}
              helperText={formik.touched.salePrice ? formik.errors.salePrice : ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px" }}>Description</label>
            <JoditEditor
              ref={editor}
              value={formik.values.description}
              onChange={(newContent) =>
                formik.setFieldValue("description", newContent)
              }
            />
          </Grid>

          {/* start images upload section */}
          <Grid item xs={12} sm={12} md={12}>
            <h5
              className='text-light p-3 rounded'
              style={{
                background:
                  "linear-gradient(45deg,  #233a65 0%, #f5f8fa 62%, #fff 100%)",
              }}
            >
              Images
            </h5>
            <Grid item xs={12} sm={12} md={12}>
              <div className="bg-white p-3 shadow rounded mt-3">
                <Box
                  className='alert alert-warning d-flex align-items-center'
                  role='alert'
                >
                  <Box>
                    <i className='fonticon-house'></i>
                    You can select multiple images at once
                  </Box>
                </Box>
                <input
                  ref={fileInputRef}
                  id="propertyImages"
                  name="propertyImages"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  multiple
                />
                {error2 && <p style={{ color: 'red' }}>{error2}</p>}
                <Grid container m={2}>
                  {imagePreviews.length > 0 && (
                    imagePreviews.map((src, index) => (
                      <Grid item key={index}>
                        <Card elevation={4} sx={{ position: "relative", mb: 2, mx: 2 }}>
                          <div className='image-item'>
                            <img src={src.toString()} alt={`Preview ${index}`} style={{ maxWidth: '170px', maxHeight: '170px', borderRadius: '5px' }} />
                            <div className='image-item__btn-wrapper'>
                              <IconButton
                                onClick={() => handleDeleteImage(index)}
                                sx={{
                                  border: "1px dashed red",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  m: 1,
                                }}
                              >
                                <Delete sx={{ fontSize: 28, fill: "red" }} />
                              </IconButton>
                            </div>
                          </div>
                        </Card>
                      </Grid>
                    ))
                  )}

                  <Grid item>
                    <div className="py-1 px-5 bg-white shadow rounded" style={{ textAlign: 'center' }}>
                      <IconButton
                        sx={{
                          border: "1px dashed aquamarine",
                          borderRadius: "50%",
                          width: "110px",
                          height: "110px",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          margin: "6px",
                        }}
                        onClick={handleUploadClick}
                      >
                        <AddAPhotoIcon sx={{ fontSize: 100 }} />
                      </IconButton>
                      <br />
                      <b>Select Images</b>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>

          </Grid>
          {/* End images upload section */}
        </Grid>
      </Box>
    </Modal >
  );
};

export default EditLandlordListingForm;
