import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import {
  Chip,
  FilledInput,
  FormHelperText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { AppErrorColor, AppTextColor } from "../theme/Colors";
import { makeStyles } from "@mui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

export const MultipleSelectCheckmarks = ({
  tagName,
  value,
  label,
  onChange,
  options,
  keyToSelect = null,
  isError,
  helperText,
}: any) => {
  return (
    <div>
      <FormControl sx={{ width: "100%", marginTop: '3%' }} className="">
        <InputLabel id={tagName} style={{ fontSize: '16px', fontWeight: '600', background: '#335688', padding: '5px 5px', borderRadius: '5px', color: '#fff', marginTop: '-1%' }}>{tagName}</InputLabel>
        <Select
          // variant="outlined"
          labelId={tagName}
          label={label}
          id={tagName + "checkbox"}
          multiple
          value={value}
          onChange={onChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => {
            return selected.map((item: string) => (
              <Chip label={item} />
            ));
          }}
          MenuProps={MenuProps}
          error={isError}
        >
          {options.map((option: any) => {
            return keyToSelect ? (
              <MenuItem key={option[keyToSelect]} value={option[keyToSelect]}>
                <Checkbox checked={value.indexOf(option[keyToSelect]) > -1} />
                <ListItemText primary={option[keyToSelect]} />
              </MenuItem>
            ) : (
              <MenuItem key={option} value={option}>
                <Checkbox checked={value.indexOf(option) > -1} />
                <ListItemText primary={option} />
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText style={{ color: AppErrorColor }} error={isError}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default MultipleSelectCheckmarks;
