/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, Route, Switch } from "react-router-dom";
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import { verify } from "../redux/AuthCRUD";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useLocation, useHistory } from "react-router-dom";
import { RootState } from "../../../../setup";
import { IconButton, Input, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useLastLocation from "app/routing/LastLocation";


const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

const VerificationComponent = () => {
  const history = useHistory();

  useLastLocation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);

    // Set Formik's state
    formik.setFieldValue('password', e.target.value, true);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const search = useLocation().search;
  const accessToken = new URLSearchParams(search).get('accessToken');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        verify(values.email, values.password, accessToken)
          .then(({ data }) => {
            if (data.sucess) {
              dispatch(auth.actions.login(data.token));
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            setSubmitting(false);
            setStatus('The login detail is incorrect')
          });
      }, 1000);
    },
  });
  return (
    <form
      className="form w-95"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-5">
        <h1 className="text-dark mb-3">Imlaak FSBO Account Verification</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className=" alert alert-danger">
          <div className="">{formik.status}</div>
        </div>
      ) : (
        <div className=""></div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          id="email"
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Password
            </label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* end::Link */}
          </div>
        </div>
        <Input
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
          name='password'
          type={showPassword ? 'text' : 'password'}
          placeholder='password'
          onChange={handlePasswordChange}  // Updated handler
          endAdornment={
            <InputAdornment position='end'>
              <IconButton onClick={handleTogglePasswordVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          autoComplete='off'
        />
        {/* <input
          type="password"
          // autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        /> */}
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-3"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  );
};

export function Verification() {
  // const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
  // const history = useHistory()
  // useEffect(() => {
  //   if (isAuthorized) history.push('/profile')
  // }, [isAuthorized])

  return (
    <Switch>
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div
            className="d-flex flex-column flex-lg-row-auto w-xl-700px  positon-xl-relative"
            style={{
              background:
                "linear-gradient(180deg, rgb(35 58 101 / 87%) 0%, rgb(35 58 101) 90%)",
            }}
          >
            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0  w-xl-700px ">
              <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
                <a href="#" className="mb-12">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-1-dark.png")}
                    className="h-45px"
                  />
                </a>
                <h1
                  className="fw-bolder fs-2qx pb-5 pb-md-10"
                  style={{ color: "#fff" }}
                >
                  Welcome to FSBO Portal
                </h1>
                <p className="fw-bold fs-2" style={{ color: "#fff" }}>
                  Pakistan's No-1 Real Estate
                  <br />
                  Consultancy Company
                </p>
              </div>
              <div
                className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-300px"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/illustrations/55.png"
                  )})`,
                }}
              ></div>
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row-fluid py-10 ">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              {/* begin::Content */}
              <div className="d-flex flex-center flex-column flex-column-fluid p-2  pb-lg-5 h-xl-650px">
                {/* begin::Wrapper */}
                <div className="w-lg-400px bg-white rounded shadow-sm  p-5 p-lg-10 mx-auto">

                  <Route
                    path="/account/verification"
                    component={VerificationComponent}
                  />
                </div>
                {/* end::Wrapper */}
              </div>
            </div>
          </div>
        </div>

        {/* end::Content */}
        {/* begin::Footer */}

        {/* end::Footer */}
      </div>
      {/* <Route path="/auth/forgot-password" component={ForgotPassword} /> */}
    </Switch>
  );
}