import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Modal from "../../../Modal";
import CustomInput from "../../../Inputs";
import { useFormik } from "formik";
import * as yup from "yup";
import Loader from "../../../Loader";
import {
  CityDropdown,
  CountryDropdown,
  ProvinceDropdown,
} from "app/ServerSideDropdowns/LocationsDropdown";
const validationSchema = yup.object({
  communityLocation: yup.string().required("community Location is required"),
  cityId: yup.string().required("city Location is required"),
  countryId: yup.string().required("country Location is required"),
  provinceId: yup.string().required("province Location is required"),
});
type EditProps = {
  open: any;
  setOpen: any;
  data?: any;
  update?: any;
  loading?: boolean;
  type: "ADD" | "EDIT";
};

const Edit = ({
  open,
  setOpen,
  data,
  update,
  loading,
  type = "EDIT",
}: EditProps) => {
  const formik = useFormik({
    initialValues: {
      communityLocation: data?.communityLocation || "",
      cityId: data?.cityId || "",
      countryId: data?.countryId || "",
      provinceId: data?.provinceId || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      update(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (Object.keys(data).length > 0) formik.setValues({ ...data });
  }, [data, open]);

  return (
    <Modal
      setOpen={() => setOpen(!open)}
      open={open}
      title={` ${
        Object.keys(data).length
          ? "Edit community # " + data?._id
          : "Add community"
      } `}
      cancelButtonTitle="Cancel"
      acceptButtonVarient="contained"
      acceptButtonTitle="Add"
      handleAccept={() => formik.handleSubmit()}
      handleClose={() => formik.resetForm()}
      loading={loading}
      isActionable={true}
    >
      {loading ? (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "30%",
          }}
        >
          <Loader top='"60%' left='"50%' />
        </Box>
      ) : null}
      <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
        <Typography variant="h5" fontWeight={700}>
          Community location that you are currently operating
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <CountryDropdown
              onChangeOption={(value: any) => {
                formik.setFieldValue("countryId", value._id);
              }}
              isError={
                formik.touched?.countryId && Boolean(formik.errors.countryId)
              }
              helperText={
                formik.touched.countryId ? formik.errors.countryId : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ProvinceDropdown
              countryId={formik.values.countryId}
              onChangeOption={(value: any) => {
                formik.setFieldValue("provinceId", value._id);
                formik.setFieldValue("cityId", "");
              }}
              isError={
                formik.touched?.provinceId && Boolean(formik.errors.provinceId)
              }
              helperText={
                formik.touched.provinceId ? formik.errors.provinceId : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CityDropdown
              provinceId={formik.values.provinceId}
              onChangeOption={(value: any) => {
                formik.setFieldValue("cityId", value._id);
              }}
              isError={formik.touched?.cityId && Boolean(formik.errors.cityId)}
              helperText={formik.touched.cityId ? formik.errors.cityId : ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="communityLocation"
              label="Community"
              placeholder="Type here..."
              value={formik.values.communityLocation}
              onChangeHandler={formik.handleChange}
              isError={
                formik.touched?.communityLocation &&
                Boolean(formik.errors.communityLocation)
              }
              helperText={
                formik.touched.communityLocation
                  ? formik.errors.communityLocation
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Edit;
