import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
  PageLink,
  PageTitle,
} from "../../../_metronic/layout/core";
import Table, { capitalizeFirstLetter, IconRender } from "../Table";
import * as AuthRedux from "../auth/redux/AuthRedux";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "_metronic/helpers/AssetHelpers";
import useFetchData from "app/hooks/useFetchData ";
import { APIS } from "Constants";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { deleteConsultantProperty, getLandlordProperties, markingPropertySold, updateProperty } from "../property/redux/PropertyCrud";
import ConsultantListProperty from "../property/components/ConsultantListProperty";
import PropertySold from "../property/components/PropertySold";
import LandlordListingForm from "./components/LandlordListingForm";
import NumberFormatter from "app/shared/Input/NumberFormatter";
import EditLandlordListingForm from "./components/EditLandlordListingForm";
interface LandlordProps {
  _id: string;
}

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];
const columns = (iconClickHandler: any, projectLookup: any) => {
  return [
    {
      name: "Building",
      key: "projectName",
      numaric: false,
      isSortable: true,
      renderContent: (value: any) => capitalizeFirstLetter(
        <div className="overflow-ellipsis" title={value}>
          <div className="overflow-ellipsis" title={value}>
            {projectLookup[value] || value}
          </div>
        </div>
      ),
    },
    {
      name: "Floor",
      key: "floorNo",
      numaric: false,
      isSortable: true,
      renderContent: (value: any) => capitalizeFirstLetter(value),
    },
    {
      name: "Apartment No",
      key: "appartmentNo",
      numaric: false,
      isSortable: true,
      renderContent: (value: any) => capitalizeFirstLetter(value),
    },
    {
      name: "Sale Price",
      key: "salePrice",
      numaric: true,
      isSortable: true,
      renderContent: (row: any) => (
        <NumberFormatter value={row || 0} />
      ),
      // renderContent: (value: any) => new Intl.NumberFormat().format(parseInt(value || 0)),
    }, {
      name: "Listed",
      key: "isListed",
      numaric: false,
      isSortable: true,
      renderContent: (value: any) => (value ? "Listed" : "Not Listed"),
    }, {
      name: "Action",
      key: "action",
      component: (row: any) => (
        <>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" style={{ fontSize: '16px', fontWeight: '600' }}>Select</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select"
              onChange={(event) => iconClickHandler(row, event.target.value)}
            >
              <MenuItem value='unlist'>Unlist</MenuItem>
              <MenuItem value='sold'>Mark as Sold</MenuItem>
            </Select>
          </FormControl>
        </>
      ),
    },
    {
      name: "Edit",
      key: "action",
      component: (row: any) => (
        <>
          {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "edit")}
        </>
      ),
    },
  ];
};

const ListingPage: React.FC = () => {
  const dispatch = useDispatch();
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [filter, setFilter] = useState({});

  const { getAllNames } = APIS.ProjectName;
  const { projectNameData } = useFetchData({ getAllNames });
  const [projectLookup, setProjectLookup] = useState<any>({});

  useEffect(() => {
    const lookup: any = {};
    projectNameData.forEach((row: any) => {
      lookup[row?._id] = row?.projectName;
    });
    setProjectLookup(lookup);
  }, [projectNameData]);
  const [currentListing, setCurrentListing]: any = useState(0);
  const listing: any = useSelector<RootState>(
    ({ auth }) => auth.listing,
    shallowEqual
  );
  const [myProperties, setMyProperties] = useState(listing);
  const [accessToken, setAccessToken]: any = useState("");
  const [modalMode, setModalMode]: any = useState("edit");
  const [modalMode2, setModalMode2]: any = useState("sold");

  const getToken = async () => {
    let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
    accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
    setAccessToken(accessToken);
  };

  // Consultant Properties
  const getProperties = (filters: any) => {
    getLandlordProperties(accessToken, filters)
      .then(({ data }) => {
        const filteredData = data?.property?.filter((row: any) => row.isListed === true);
        dispatch(AuthRedux.actions.setMyListings(filteredData));
        setCount(filteredData.length);
      })
      .catch(() => { })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getToken();
    setTimeout(() => {
      getProperties(filter);
    }, 1000);
  }, []);

  useEffect(() => {
    setMyProperties(listing);
  }, [listing]);

  const filtersChanged = (filters: any) => {
    setFilter(filters);
    setLoading(true);
    setTimeout(() => {
      getProperties(filters);
    }, 1000);
  };

  const onClickCreate = () => {
    if (!user?.landlordProfileCompleted || user?.approvalStatus !== 'approved') {
      Swal.fire({
        title: "Profile Completion OR Consultant Approval",
        text: "Please complete your profile OR Check Consultant Approval",
        icon: "info",
        iconColor: '#ff1616',
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      })
    }
    else {
      setOpen(true);
      setCurrentListing([]);
      setModalMode("create");
    }
  };

  useEffect(() => {
    document
      .getElementById("create-button")
      ?.addEventListener("click", onClickCreate);
  }, []);

  const iconClickHandler = (row: any, type: any) => {
    if (type === "edit") {
      setModalMode("edit");
      setCurrentListing(row);
      setOpen3(true);
    } else if (type === "delete") {
      Swal.fire({
        title: "",
        text: "Are you sure you want to delete this Property",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((status) => {
        if (status.isConfirmed) {
          setLoading(true);
          deleteConsultantProperty(row._id, accessToken)
            .then(({ data, status }) => {
              let text = "";
              let icon: any = "error";
              if (status === 200) {
                text = "Successfully Deleted";
                icon = "success";
              } else {
                text = data.message;
                icon = "error";
              }
              Swal.fire({
                title: "Property Deleted",
                text: text,
                icon: icon,
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              });
              const filteredData = data?.property?.filter((row: any) => row.landlordId === user._id && row.isListed === true);
              setMyProperties(filteredData)
              setCount(filteredData.length);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
        }
      });
    } else if (type === "unlist") {
      Swal.fire({
        title: "",
        text: "You are about to unlist the Property!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((status) => {
        if (status.isConfirmed) {
          setLoading(true);
          const unListValues = { isListed: false };
          updateProperty(unListValues, row._id, accessToken)
            .then(({ data, status }) => {
              let text = "";
              let icon: any = "error";
              if (status === 200) {
                text = "Property Unlisted successfully";
                icon = "success";
              } else {
                text = data.message;
                icon = "error";
              }
              Swal.fire({
                title: "Property Unlisted successfully",
                text: text,
                icon: icon,
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.value || result.dismiss) {
                  window.location.reload();
                }
              });
              const filteredData = data?.property?.filter((row: any) => row.landlordId === user._id && row.isListed === true);
              setMyProperties(filteredData)
              setCount(filteredData.length);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
        }
      });
    } else if (type === "sold") {
      Swal.fire({
        title: "Property Sold!",
        text: "Are you sure you want to mark this property as sold?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((status) => {
        if (status.isConfirmed) {
          setModalMode2("sold");
          setCurrentListing(row);
          setOpen2(true);
        }
      });
    }
  };

  const createPropertyFunction = (values: any) => {
    setLoading(true);
    setOpen(false);
    values = { ...values };
    updateProperty(values, values?.id, accessToken)
      .then(({ data }) => {
        if (data?.success) {
          Swal.fire({
            title: "",
            text: "Property Listed Successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value || result.dismiss) {
              window.location.reload();
            }
          });
          const filteredData = data?.property?.filter((row: any) => row?.landlordId === user?._id && row?.isListed === true);
          dispatch(AuthRedux.actions.setMyListings(filteredData));
          setMyProperties(filteredData)
          setCount(filteredData.length);
        }
      })
      .catch(() => { })
      .finally(() => setLoading(false));
  };

  const updatePropertyFunction = (values: any) => {
    setLoading(true);
    setOpen3(false);
    updateProperty(values, currentListing?._id, accessToken)
      .then(({ data }) => {
        if (data?.success) {
          Swal.fire({
            title: "",
            text: "Listing details updated Successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value || result.dismiss) {
              window.location.reload();
            }
          });
          const filteredData = data?.property?.filter((row: any) => row?.landlordId === user?._id && row?.isListed === true);
          dispatch(AuthRedux.actions.setMyListings(filteredData));
          setMyProperties(filteredData)
          setCount(filteredData.length);
        }
      })
      .catch(() => { })
      .finally(() => setLoading(false));
  };

  const soldPropertyFunction = (values: any) => {
    setLoading(true);
    markingPropertySold(values, currentListing?._id, accessToken)
      .then(({ data }) => {
        if (data?.success) {
          Swal.fire({
            title: "Property Marked",
            text: "Property marked as sold Successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value || result.dismiss) {
              window.location.reload();
            }
          });
          const filteredData = data?.property?.filter((row: any) => row?.landlordId === user._id && row?.isListed === true);
          dispatch(AuthRedux.actions.setMyListings(filteredData));
          setMyProperties(filteredData)
          setCount(filteredData.length);
          setOpen2(false);
        }
      })
      .catch(() => { })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <PageTitle>Listings</PageTitle>
      {user?.status === "approved" && user?.landlordProfileCompleted === true && user?.approvalStatus === 'approved' ? (
        <div className="pb-5">
          <Table
            columns={columns(iconClickHandler, projectLookup)}
            data={myProperties}
            loading={loading}
            onRowClick={(row: any, type: any) => iconClickHandler(row, type)}
            isPaginationRequired
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            total={count}
          />
          <LandlordListingForm
            setOpen={setOpen}
            open={open}
            loading={loading}
            updateProperty={
              modalMode === "edit"
                ? ''
                : createPropertyFunction
            }
            modalMode={modalMode}
            property={currentListing}
          />
          <EditLandlordListingForm
            setOpen={setOpen3}
            open={open3}
            loading={loading}
            updateProperty={
              modalMode === "edit"
                ? updatePropertyFunction
                : ''
            }
            modalMode={modalMode}
            property={currentListing}
          />
          <PropertySold
            setOpen={setOpen2}
            open={open2}
            loading={loading}
            updateProperty={
              modalMode2 === "sold"
                ? soldPropertyFunction
                : ''
            }
            modalMode={modalMode2}
            property={currentListing}
          />
        </div>
      ) : (
        <div className="card-body p-0 ">
          {/* begin::Chart */}
          <div className="alert alert-warning p-5 mt-5 mx-10" role="alert">
            This is a warning alert{" "}
            <h3 className="text-danger">
              Please complete your profile OR Check Consultant Approval, till you can't List Property!!.
            </h3>
          </div>
          {/* end::Chart */}
          {/* begin::Stats */}
          <div className="card-p mt-10 position-relative d-flex justify-content-center">
            <img src={toAbsoluteUrl("/media/illustrations/sketchy-1/57.svg")} />

            {/* end::Row */}
          </div>
          <span className="d-flex justify-content-center fw-bold fs-4 mb-1 ">
            No Record Found
          </span>
        </div>
      )}
    </>
  );
};

export default ListingPage;
