// cloudinaryService.ts

import { v2 as cloudinary } from "cloudinary";

// Configure Cloudinary
cloudinary.config({
    cloud_name: process.env.REACT_APP_CLOUD_NAME,
    api_key: process.env.REACT_APP_CLOUD_API_KEY,
    api_secret: process.env.REACT_APP_CLOUD_API_SECRET,
    secure: true,
});

const getCloudSignature = async () => {
    const secret = cloudinary.config().api_secret!;
    const timestamp = Math.round(new Date().getTime() / 1000);
    const signature = cloudinary.utils.api_sign_request({ timestamp }, secret);

    return { timestamp, signature };
};

const getCloudConfig = async () => {
    return {
        name: process.env.REACT_APP_CLOUD_NAME!,
        key: process.env.REACT_APP_CLOUD_API_KEY!,
    };
};

export const CloudImage = async (file: File): Promise<{ url: string, public_id: string }> => {
    const { signature, timestamp } = await getCloudSignature();
    const cloudConfig = await getCloudConfig();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", cloudConfig.key);
    formData.append("signature", signature);
    formData.append("timestamp", timestamp.toString());

    const endpoint = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`;

    try {
        const response = await fetch(endpoint, {
            method: "POST",
            body: formData,
        });

        const data = await response.json();
        if (data.error) {
            throw new Error(data.error.message);
        }
        return { url: data.secure_url, public_id: data.public_id };
    } catch (error) {
        console.error("Upload error: ", error);
        throw error;
    }
};
