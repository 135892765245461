import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  FormControl,
  FormHelperText,
  Chip,
  Paper,
  Skeleton,
  MenuItem,
  Select,
  Typography,
  FormControlLabel,
  useFormControl,
  Switch,
  InputLabel,
} from "@mui/material";
import Modal from "../../Modal";
import { City, State, Country } from "country-state-city"
import CustomInput from "../../Inputs";
import { FieldArray, useFormik, Form, Field, useFormikContext } from "formik";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import MapView from "../../Map/Map";

import DropDown, {
  Rent,
  RentSystem,
  areaTypeOptions,
  propertyCategoryOptions,
  propertyTypeOptions,
  propertyFloorOptions,
  propertyCitiesOptions,
  Purpose,
  CompletedStatus,
  rentFrequency,
  furnishingStatus,
  ProjectSelecting,
} from "../../DropDownOptions/DropDownOptions";
import Loader from "../../Loader";
import {
  ImageUploader,
  ImageViewer,
} from "app/components/Inputs/imageSelector";
import { useImageMethod } from "app/hooks/useImageUpload";
import { API } from "app/utils/config";
import MultipleSelectCheckmarks from "app/components/Inputs/DropdownWithCheckBox";
import { amenitiesCategories } from "app/components/amenities/components/DropDownOptions";
import { projectCitiesOptions } from "app/components/Project/components/DropDownOptions";
import useFetchData from "app/hooks/useFetchData ";
import { uniqBy } from "lodash";
import { APIS } from "Constants";
import JoditEditor from "jodit-react";
import currency from "currency.js";
import axios from "axios";
import { ProjectNameDropdown } from "app/ServerSideDropdowns/ProjectNameDropdown";
import { AppErrorColor, AppTextColor } from "app/components/theme/Colors";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "setup";
import { LandlordsForm } from "./LandlordsForm";
import { ProjectNamePropertyDropdown } from "app/ServerSideDropdowns/ProjectNamePropertyDropdown";
const CustomInput2: React.FC<any> = ({ field, ...props }) => {
  return (
    <input {...field} {...props} />
  );
};
interface InventoryItem {
  projectName: string;
  appartmentNo: string;
  floorNo: string;
  category: string;
  subCategory: string;
  projectType: string;
  title: string;
  _id: string;
  consultantId: string;
  landlordId: string;
  country: string;
  city: string;
  province: string;
  state: string;
  placeId: string;
  areaName: string;
  area: string;
  sector: string;
  street: string;
  address: string;
  description: string;
  completionStatus: string;
}
const ownerSchema = yup.object().shape({
  landlordName: yup.string()
    .required('Landlord name is required'),
  purchasePrice: yup.string()
    .required('Purchase price is required'),
  purchasingDate: yup.date()
    .required('Purchasing date is required')
    .nullable(), // Use nullable if your date can be null/empty initially
});
const validationSchema = yup.object({
  // category: yup.string().required("Category is required"),
  // subCategory: yup.string().required("SubCategory is required"),
  // furnishingStatus: yup.string().required("Furnishing Status is required"),
  // floorNo: yup.string().required("Floor No is required"),
  // apartmentNo: yup.number().required("Apartment No is required"),
  // address: yup.string().required("Address is required"),
  // area: yup.number().required("Property Area is required"),
  // owner: yup.array().of(ownerSchema).required('At least one owner is required'),
  // furnishingStatus: yup.string().required("Province is required"),
  // city: yup.string().required("City is required"),
  // zipCode: yup.string().required("Property zip-code is required"),
  // state: yup.string().required("Property state is required"),
  // community: yup.string().required("community is required"),
  // provinceLocation: yup.string().required("ProvinceLocation is required"),
  // cityLocation: yup.string().required("CityLocation is required"),
  // communityLocation: yup.string().required("CommunityLocation is required"),
  // title: yup.string().required("title is required"),
  // description: yup.string().required("description is required"),
  // areaType: yup.string().required("Property Area Type is required"),
  // projectType: yup.array().required("Project Type is required"),
  // projectNamemanual: yup.array().required("Project Name Type is required"),
  // images: yup.array().notRequired(),
  // location: yup.object({
  //   lat: yup.number().required().label("listing is required"),
  //   lng: yup.number().required().label("listing is required"),
  // }),
  // amenties: yup.object(),
});

const useStyles = makeStyles(() => ({
  heading: {
    background: "#263c67",
    color: "#fff",
    fontWeight: "700 !important",
  },
  headingStyles: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },
  description: {
    padding: "8.5px 14px",
    width: "100%",
    pointerEvents: "none",
    border: "1px solid",
    borderColor: "#093d5c",
    borderRadius: "5px",
    height: "45px",
  },
}));

type CreateConsultantPropertyProps = {
  open: any;
  setOpen: any;
  property?: any;
  AddProperty?: any;
  loading?: boolean;
  updateProperty?: any;
  modalMode?: any;
  error?: any;
  label?: string;
  value?: string;
};

const CreateConsultantProperty = ({
  open,
  setOpen,
  property,
  AddProperty,
  loading,
  modalMode,
  error,
  label,
  updateProperty,
  value,
}: CreateConsultantPropertyProps) => {
  const onClick = (data: any) => {
    formik.setFieldValue("location", data?.lngLat);
  };

  const formik = useFormik({
    initialValues: {
      projectName: property?.projectName || "",
      category: property?.category || "",
      subCategory: property?.subCategory || "",
      furnishingStatus: property?.furnishingStatus || "",
      owner: [
        {
          landlordName: "",
          purchasePrice: "",
          purchasingDate: "",
          landlord: "",
        },
      ],
      landlords: [
        {
          landlordName: "",
          purchasePrice: "",
          purchasingDate: "",
          percentage: "",
          landlord: "",
        },
      ],
      purchasePrice: property?.purchasePrice || "",
      purpose: "for_sale",
      isVerified: property?.isVerified || true,
      country: property?.country || "",
      province: property?.province || "",
      cityName: property?.cityName || "",
      rentType: property?.rentType || "",
      rentSystem: property?.rentSystem || "",
      state: property?.state || "",
      projectname: property?.projectname || "",
      title: property?.title || "",
      rentalIncome: property?.rentalIncome || "",
      description: property?.description || "",
      price: property?.price || 0,
      salePrice: property?.salePrice || 0,
      advanceAmount: property?.advanceAmount || 0,
      noOfInstallments: property?.noOfInstallments || 0,
      monthlyInstallments: property?.monthlyInstallments || 0,
      possessionAmount: property?.possessionAmount || 0,
      rentFrequency: property?.rentFrequency || "",
      bedrooms: property?.bedrooms || 0,
      bathrooms: property?.bathrooms || 0,
      floorNo: property?.floorNo || 0,
      appartmentNo: property?.appartmentNo || 0,
      completionStatus: property?.completionStatus || "",
      projectNamemanual: property?.projectNamemanual || "",
      ProjectSelecting: property?.ProjectSelecting || "",
      areaType: property?.areaType || "",
      area: property?.area || 0,
      location: property?.location || {},
      images: property?.images || [],
      amenities: property?.amenties || {},
      consultantId: property?.consultantId || "",
      landlordId: property?.landlordId || "",
      placeId: property?.placeId || "",
      areaName: property?.areaName || "",
      sector: property?.sector || "",
      street: property?.street || "",
      address: property?.address || "",
      projectType: property?.projectType || [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { ProjectSelecting, owner, purpose, appartmentNo, floorNo, projectName, landlordId, price, completionStatus, amenities, projectType, country, province, state, cityName, street, sector, address, placeId, areaName } = values;
      const newValues = { landlordId, ProjectSelecting, id: apartmentId, purpose, owner, price, isVerified: true, sold: false, completionStatus, amenities, projectType, country, province, state, cityName, street, sector, address, placeId, areaName };

      if (ProjectSelecting === "Registered_project") {
        updateProperty(newValues)
      }
      else {
        updateProperty(values);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("category", property?.category || "");
    formik.setFieldValue("subCategory", property?.subCategory || "");
    formik.setFieldValue("isVerified", property?.isVerified);
    formik.setFieldValue("purpose", "for_sale");
    formik.setFieldValue("rentType", property?.rentType || "")
    formik.setFieldValue("rentsystem", property?.rentSystem || "")
    formik.setFieldValue("title", property?.title || "");
    formik.setFieldValue("description", property?.description || "");
    formik.setFieldValue("projectType", property?.projectType || []);
    formik.setFieldValue("location", property?.location);
    formik.setFieldValue("name", property?.name || "" || "");
    formik.setFieldValue("price", property?.price || "");
    formik.setFieldValue("salePrice", property?.salePrice || "");
    formik.setFieldValue("advanceAmount", property?.advanceAmount || "");
    formik.setFieldValue("possessionAmount", property?.possessionAmount || "");
    formik.setFieldValue("noOfInstallments", property?.noOfInstallments || "");
    formik.setFieldValue("area", property?.area || "");
    formik.setFieldValue("areaType", property?.areaType);
    formik.setFieldValue("state", property?.location?.state || "");
    formik.setFieldValue("bedrooms", property?.bedrooms || "");
    formik.setFieldValue("bathrooms", property?.bathrooms || "");
    formik.setFieldValue("floorNo", property?.floorNo || "");
    formik.setFieldValue("appartmentNo", property?.appartmentNo || "");
  }, [property]);

  //start Image upload files
  const { uploadImage, images, setImages, files, setFiles } = useImageMethod();
  const [id, setId] = useState("");
  const onSubmitImage = async (event: any) => {
    let formData = new FormData(); //formdata object
    for (var i = 0; i < files.length; ++i) {
      formData.append(files[i].name, files[i]);
    }
    // formData.append('images', files)
    const res: any = await uploadImage({ params: formData });
    if (res?.data?.succes) {
      formik.setFieldValue("images", res?.data?.image);
      setImages([]);
      setFiles([]);
    }
  };

  useEffect(() => {
    // console.log(images, "images");
  }, [images]);
  // End images upload files

  // Amenities List
  const { getAllAmenities } = APIS.amenities;
  const { amenitiesData } = useFetchData({ getAllAmenities });
  const AmenitiesList: any = useMemo(() => {
    let oneToOneData: any = [];
    if (amenitiesData.length > 0 && formik?.values?.projectType?.length > 0) {
      amenitiesData.forEach((amenityRow: any) => {
        amenityRow.categories.forEach((category: any) => {
          if (
            Boolean(
              formik.values.projectType.find((type: any) => type === category)
            )
          ) {
            amenityRow.list.forEach((amenity: any) => {
              oneToOneData.push({
                label: amenity.title,
                value: amenity.title,
                description: amenity.description,
                key: amenity.title,
              });
            });
          }
        });
      });
      return uniqBy(oneToOneData, "label");
    }
    return [];
  }, [amenitiesData, formik.values.projectType]);

  const [amenties, setAmenities]: any = useState({});
  const changedAmenity = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, checked } = event.target;
    setAmenities((currentAmenities: any) => {

      const updatedAmenities = {
        ...currentAmenities,
        [name]: checked,
      };

      const toSetAmenities = AmenitiesList.filter((amenity: any) => updatedAmenities[amenity.label]);
      formik.setFieldValue("amenities", toSetAmenities);

      return updatedAmenities;
    });
  };
  // End of AMenities List

  // Getting Landlords
  const { getAll } = APIS.users;
  const { data } = useFetchData({ getAll });
  const [landlord, setLandlord] = useState<any[]>([]);

  useEffect(() => {
    // Filter and map the data within the useEffect
    const filteredLandlords = data?.filter((row: any) => row?.role === "landlord" && row?.consultant?.toString() === consultantId);
    const mappedLandlords = filteredLandlords.map((landlord: any) => ({
      value: landlord?._id,
      label: landlord?.fname,
      consultant: landlord?.consultant,
    }));
    // Update the state with the mapped data
    setLandlord(mappedLandlords);
  }, [data]);
  // End of Getting Landlord

  const submitHandler = (event: any) => {
    event.preventDefault();
  };

  // For Location
  const countryData = Country.getAllCountries();
  const [stateData, setStateData] = useState<any[] | null>();
  const [cityData, setCityData] = useState<any[] | null>();

  const [country, setCountry] = useState<any | null>(countryData[166]);
  const [state, setState] = useState<any | null>();
  const [city, setCity] = useState<any | null>();

  useEffect(() => {
    if (country) {
      const statesOfCountry = State.getStatesOfCountry(country.isoCode);
      setStateData(statesOfCountry);
    }
  }, [country]);

  useEffect(() => {
    if (country && state) {
      const citiesOfState = City.getCitiesOfState(country.isoCode, state.isoCode);
      setCityData(citiesOfState);
    }
  }, [country, state]);

  useEffect(() => {
    if (stateData && stateData.length >= 2) {
      setState(stateData[1]);
    }
  }, [stateData]);

  useEffect(() => {
    if (cityData && cityData.length >= 1) {
      setCity(cityData[0]);
    }
  }, [cityData]);

  // END PROPERTY AMENTIES

  const editor = useRef(null);
  // start FormatPrice function
  function formatPrice(price: number) {
    const suffixes = ["", "K", "M", "B", "T"];
    const suffixNum = Math.floor(("" + price).length / 3);
    let shortValue = parseFloat(
      (suffixNum !== 0 ? price / Math.pow(1000, suffixNum) : price).toPrecision(
        2
      )
    );
    if (shortValue % 1 !== 0) {
      shortValue = parseFloat(shortValue.toFixed(1));
    }
    return shortValue + suffixes[suffixNum];
  }

  const [installmentAvailable, setInstallmentAvailable] = useState(false);
  const [possession, setPossession] = useState(false);

  const handleSwitchChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setInstallmentAvailable(event.target.checked);
  };

  const handleSwitchChange2 = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setPossession(event.target.checked);
  };

  useEffect(() => {
    let title = "";
    if (formik.values.furnishingStatus === "furnished") {
      title += ` Furnished - `;
    } else if (formik.values.furnishingStatus === "semi-furnished") {
      title += `Semi Furnished `;
    } else {
      title += ` `;
    }

    if (formik.values.subCategory) {
      title += ` ${formik.values.subCategory}`;
    }
    if (formik.values.ProjectSelecting === "Registered_project") {
      title += ` `;
    } else {
      title += ` in ${formik.values.projectName}`;
    }
    if (formik.values.bedrooms) {
      title += ` - ${formik.values.bedrooms} bedroom(s)`;
    }

    formik.setFieldValue("title", title);
  }, [
    property,
    modalMode,
    formik.values.furnishingStatus,
    formik.values.subCategory,
    formik.values.bedrooms,
  ]);

  const classes = useStyles();
  function HeadingText() {
    const { focused } = useFormControl() || {};
    const headingColor = useMemo(() => {
      if (error) {
        return AppErrorColor;
      }

      if (focused && !error) {
        return "#007FFF";
      }

      return AppTextColor;
    }, [focused]);
    return (
      <Typography
        className={classes.headingStyles}
        fontSize={18}
        color={headingColor}
      >
        Floor No
      </Typography>
    );
  }
  function HeadingText2() {
    const { focused } = useFormControl() || {};
    const headingColor = useMemo(() => {
      if (error) {
        return AppErrorColor;
      }

      if (focused && !error) {
        return "#007FFF";
      }

      return AppTextColor;
    }, [focused]);
    return (
      <Typography
        className={classes.headingStyles}
        fontSize={18}
        color={headingColor}
      >
        Apartment No
      </Typography>
    );
  }
  function HeadingText3() {
    const { focused } = useFormControl() || {};
    const headingColor = useMemo(() => {
      if (error) {
        return AppErrorColor;
      }

      if (focused && !error) {
        return "#007FFF";
      }

      return AppTextColor;
    }, [focused]);
    return (
      <Typography
        className={classes.headingStyles}
        fontSize={18}
        color={headingColor}
      >
        Select Landlord
      </Typography>
    );
  }
  // For Fetching Values for Selected Project
  const [projectNameValue, setProjectNameValue] = useState({});

  // get Location of selected Registered Building
  const { getAllNames } = APIS.ProjectName;
  const { projectNameData } = useFetchData({ getAllNames });
  const SelectedProjectNameData: InventoryItem[] = projectNameData?.filter((row: InventoryItem) => row?._id === projectNameValue)
  // useEffect(() => {
  //   if (SelectedProjectNameData.length > 0) {
  //     formik.setFieldValue("street", SelectedProjectNameData?.[0]?.street || '');
  //     formik.setFieldValue("sector", SelectedProjectNameData?.[0]?.sector || '');
  //     formik.setFieldValue("areaName", SelectedProjectNameData?.[0]?.area || '');
  //     formik.setFieldValue("address", SelectedProjectNameData?.[0]?.address || '');
  //     formik.setFieldValue("placeId", SelectedProjectNameData?.[0]?.placeId || '');
  //   }
  // }, [SelectedProjectNameData]);

  const { getAllInventory } = APIS.inventory;
  const { inventoryData } = useFetchData({ getAllInventory });

  const selectedProjectData: InventoryItem[] = inventoryData?.filter((row: InventoryItem) => row?.projectName === projectNameValue);

  const uniqueFloorNumbers: string[] = Array.from(new Set(selectedProjectData.map((row: InventoryItem) => row?.floorNo)));

  const selectedFloorNo: string[] = uniqueFloorNumbers.slice().sort((a, b) => {
    const numA = parseInt(a, 10); // Convert string to number
    const numB = parseInt(b, 10); // Convert string to number

    const isANumber = !isNaN(numA);
    const isBNumber = !isNaN(numB);

    if (!isANumber && !isBNumber) {
      // If both are strings, sort them alphabetically
      return a.localeCompare(b);
    } else if (!isANumber && isBNumber) {
      // Place strings before numbers
      return -1;
    } else if (isANumber && !isBNumber) {
      // Place numbers after strings
      return 1;
    } else {
      // Both are numbers, sort numerically
      return numA - numB;
    }
  });

  const [selectedFloorNoValue, setSelectedFloorNoValue] = useState({});

  const selectedApartments: InventoryItem[] = selectedProjectData.filter((row: InventoryItem) => row?.floorNo === selectedFloorNoValue);
  // const selectedDataApartments: string[] = Array.from(new Set(selectedApartments.map((row: InventoryItem) => row?.appartmentNo)));

  const selectedDataApartments = Array.from(new Set(selectedApartments.map((row: InventoryItem) => {
    return {
      appartmentNo: row?.appartmentNo,
      isLandlordIdEmpty: !row?.landlordId, // true if consultantId is empty
    };
  })));
  const FinalApartmentNo = selectedDataApartments
    .sort((a, b) => a.appartmentNo.localeCompare(b.appartmentNo))
    .map(apartment => ({
      ...apartment,
      appartmentNo: apartment.appartmentNo.charAt(0).toUpperCase() + apartment.appartmentNo.slice(1)
    }));

  const [apartmentNumberValue, setApartmentNumberValue] = useState({});
  const selectedPropertyDetails: InventoryItem[] = selectedApartments.filter((row: InventoryItem) => row?.appartmentNo === apartmentNumberValue);

  const [apartmentId, setApartmentId] = useState({});
  useEffect(() => {
    if (selectedPropertyDetails.length > 0) {
      setApartmentId(selectedPropertyDetails?.[0]?._id);
    }
  }, [selectedPropertyDetails]);
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );
  // For Consultant's id to Add in Property
  const consultantId = user?._id;

  // Get Location
  const [address, setAddress] = useState<string>('');
  const [formattedAddress, setFormattedAddress] = useState<string>('');
  const [coordinates, setCoordinates] = useState<string>('');
  const [placeIdValue, setPlaceIdValue] = useState<string>('');
  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
    handleGetCoordinates();
  };

  const handleGetCoordinates = async () => {
    try {
      const apiKey = process?.env?.REACT_APP_GOOGLE_MAPS_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${apiKey}`
      );

      if (response.ok) {
        const data = await response.json();
        if (data.results.length > 0) {
          const location = data.results[0].geometry.location;
          const addressComponents = data.results[0].address_components;
          const placeId = data.results[0].place_id;
          const formattedAddressValue = data.results[0].formatted_address;
          // Extract city and country names from address components
          let streetName = '';
          let areaName = '';
          let sectorColonyArea = '';
          let district = '';
          let city = '';
          let state = '';
          let country = '';

          // Iterate through address components to extract details
          addressComponents.forEach((component: any) => {
            if (component.types.includes('route')) {
              streetName = component.long_name;
            } else if (component.types.includes('sublocality_level_1') || component.types.includes('sublocality') || component.types.includes('neighborhood')) {
              if (!sectorColonyArea) { // Only set if sectorColonyArea is not already set
                sectorColonyArea = component.long_name;
              }
            } else if (component.types.includes('political') && component.types.includes('sublocality') && component.types.includes('sublocality_level_1')) {
              areaName = component.long_name;
            } else if (component.types.includes('administrative_area_level_2')) {
              district = component.long_name;
            } else if (component.types.includes('locality')) {
              city = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name;
            } else if (component.types.includes('country')) {
              country = component.long_name;
            }
          });

          formik.setValues({
            ...formik.values,
            address: formattedAddressValue,
            street: streetName,
            sector: sectorColonyArea,
            areaName: areaName,
            cityName: city,
            province: state,
            country: country,
            placeId: placeId,
          });
          setCoordinates(location);
          setCity(city);
          setState(state);
          setCountry(country);
          setPlaceIdValue(placeId);
          setFormattedAddress(formattedAddressValue)
        } else {
          console.error('No results found for the provided address.');
        }
      } else {
        console.error('Error fetching data from the geocoding API.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Modal
      setOpen={() => setOpen(!open)}
      open={open}
      title={
        modalMode === "edit"
          ? ` Update Property`
          : "Add Property"
      }
      cancelButtonTitle="Cancel"
      acceptButtonVarient="contained"
      acceptButtonTitle={
        modalMode === "edit"
          ? ` Update Property`
          : "Add Property"
      }
      handleAccept={() => formik.handleSubmit()}
      handleClose={() => formik.resetForm()}
      loading={loading}
      isActionable={true}
    >
      {loading ? (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "30%",
          }}
        >
          <Loader top='"60%' left='"50%' />
        </Box>
      ) : null}
      <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
        {/* <Grid container spacing={2}>
          <LandlordsForm />
        </Grid> */}

        <Grid container spacing={2}>

          <Grid item xs={12} sm={12} md={6}>
            <DropDown
              id="ProjectSelecting"
              label="Please choose one of them*"
              value={formik.values.ProjectSelecting}
              onChangeHandler={(event: any) => {
                formik.setFieldValue("ProjectSelecting", event.target.value);
              }}
              isError={
                formik.touched.ProjectSelecting &&
                Boolean(formik.errors.ProjectSelecting)
              }
              helperText={
                formik.touched.ProjectSelecting
                  ? formik.errors.ProjectSelecting
                  : ""
              }
              options={ProjectSelecting}
            />
          </Grid>

          {formik.values.ProjectSelecting === "" ||
            formik.values.ProjectSelecting === "add_building" ? (
            <Grid item xs={12} sm={12} md={6}>
              <CustomInput
                id="projectName"
                label="Building Name *"
                placeholder="Type here..."
                value={formik.values.projectName}
                onChangeHandler={formik.handleChange}
                isError={
                  formik.touched.projectName &&
                  Boolean(formik.errors.projectName)
                }
                helperText={
                  formik.touched.projectName ? formik.errors.projectName : ""
                }
              />
            </Grid>
          ) : null}

          {formik.values.ProjectSelecting === "Registered_project" && (
            <>
              <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>

                <ProjectNamePropertyDropdown
                  onChangeOption={(value: any) => {
                    formik.setFieldValue("projectName", value._id);
                    formik.setFieldValue("projectname", value.projectName);
                    formik.setFieldValue("floorNo", '');
                    formik.setFieldValue("appartmentNo", '');
                    formik.setFieldValue("category", '');
                    formik.setFieldValue("subCategory", '');
                    formik.setFieldValue("country", value.country);
                    formik.setFieldValue("province", value.state);
                    formik.setFieldValue("state", value.state);
                    formik.setFieldValue("cityName", value.city);
                    formik.setFieldValue("placeId", value.placeId);
                    formik.setFieldValue("areaName", value.areaName);
                    formik.setFieldValue("sector", value.sector);
                    formik.setFieldValue("street", value.street);
                    formik.setFieldValue("address", value.address);
                    setProjectNameValue(value._id);
                    setApartmentNumberValue('')
                  }}
                  isError={
                    formik.touched?.projectName &&
                    Boolean(formik.errors.projectName)
                  }
                  helperText={
                    formik.touched.projectName ? formik.errors.projectName : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>
                <FormControl style={{ width: "100%" }}>
                  <HeadingText />
                  <Select
                    style={{ width: "100%" }}
                    sx={{ backgroundColor: "#fcfcfb" }}
                    id="floorNo"
                    value={formik.values.floorNo}
                    label={<HeadingText />}
                    onChange={(event: any) => {
                      formik.setFieldValue("floorNo", event.target.value);
                      // setSelectedFloorNoValue(event.target.value)
                      setSelectedFloorNoValue(event.target.value.toString());
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {selectedFloorNo.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>
                <FormControl style={{ width: "100%" }}>
                  <HeadingText2 />
                  <Select
                    style={{ width: "100%" }}
                    sx={{ backgroundColor: "#fcfcfb" }}
                    id="appartmentNo"
                    value={formik.values.appartmentNo}
                    label={<HeadingText2 />}
                    onChange={(event: any) => {
                      formik.setFieldValue("appartmentNo", event.target.value.toUpperCase());
                      setApartmentNumberValue(event.target.value.toString())
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {/* {FinalApartmentNo.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))} */}
                    {FinalApartmentNo.map((property, index) => (
                      <MenuItem
                        key={index}
                        value={property.appartmentNo}
                        disabled={!property.isLandlordIdEmpty}
                        style={{ color: property.isLandlordIdEmpty ? 'inherit' : '#ff1616', fontWeight: property.isLandlordIdEmpty ? 'normal' : '600' }}
                      >
                        {/* {property.appartmentNo} */}
                        {property.appartmentNo} {!property.isLandlordIdEmpty && "(taken)"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <CustomInput
                  id="category"
                  label="Category *"
                  value={selectedPropertyDetails[0]?.category || formik.values.category}
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomInput
                  id="subCategory"
                  label="Sub-Category *"
                  value={selectedPropertyDetails[0]?.subCategory || formik.values.subCategory}
                  type="text"
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <DropDown
                  id="furnishingStatus"
                  label="Furnishing Status *"
                  value={formik.values.furnishingStatus}
                  onChangeHandler={(event: any) => {
                    formik.setFieldValue(
                      "furnishingStatus",
                      event.target.value
                    );
                  }}
                  type="string"
                  isError={
                    formik.touched.furnishingStatus && Boolean(formik.errors.furnishingStatus)
                  }
                  helperText={
                    formik.touched.furnishingStatus ? formik.errors.furnishingStatus : ""
                  }
                  options={furnishingStatus}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <DropDown
                  id="completionStatus"
                  label="Completion Status *"
                  value={formik.values.completionStatus || selectedPropertyDetails[0]?.completionStatus}
                  onChangeHandler={(event: any) => {
                    formik.setFieldValue("completionStatus", event.target.value);
                  }}
                  isError={
                    formik.touched.completionStatus &&
                    Boolean(formik.errors.completionStatus)
                  }
                  helperText={
                    formik.touched.completionStatus
                      ? formik.errors.completionStatus
                      : ""
                  }
                  options={CompletedStatus}
                />
              </Grid>

              {formik.values.owner.map((owner, index) => (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <CustomInput
                      id={`owner.purchasePrice${index}`}
                      label="Purchase Price *"
                      placeholder=""
                      value={owner?.purchasePrice}
                      type="number"
                      onChangeHandler={(event: any) => {
                        const newOwners = [...formik.values.owner];
                        newOwners[index].purchasePrice = event.target.value;
                        formik.setFieldValue("owner", newOwners);
                        formik.setFieldValue("price", event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <CustomInput
                      id={`owner.purchasingDate${index}`}
                      label="Purchasing Date*"
                      placeholder="Purchasing Date"
                      value={owner?.purchasingDate}
                      type="date"
                      onChangeHandler={(event: any) => {
                        const newOwners = [...formik.values.owner];
                        newOwners[index].purchasingDate = event.target.value;
                        formik.setFieldValue("owner", newOwners);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl style={{ width: "100%" }}>
                      <HeadingText3 />
                      <Select
                        style={{ width: "100%" }}
                        sx={{ backgroundColor: "#fcfcfb", textTransform: "capitalize" }}
                        id={id}
                        // value={owner?.landlordId}
                        label={<HeadingText3 />}
                        onChange={(event: any) => {
                          const newOwners = [...formik.values.owner];
                          formik.values.landlordId = event.target.value;
                          formik.setFieldValue("landlordId", event.target.value);
                          newOwners[index].landlord = event.target.value;
                          formik.setFieldValue("owner", newOwners);
                          const selectedLandlord = landlord?.find((item: any) => item?.value === event.target.value);
                          if (selectedLandlord) {
                            newOwners[index].landlordName = selectedLandlord?.label; // Set landlordName from selected option's label
                            formik.setFieldValue("owner", newOwners);
                          }
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {landlord.map((item: any) => {
                          return (
                            <MenuItem
                              key={item.value}
                              value={item.value}
                              disabled={item.disabled}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {item.label}
                              {item.badge && (
                                <Chip
                                  size="small"
                                  sx={{ marginLeft: "8px", textTransform: "capitalize" }}
                                  label={item.badge}
                                  color="primary"
                                  variant="filled"
                                />
                              )}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ))}

              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  id="title"
                  label="Property Title *"
                  placeholder="Type here..."
                  value={selectedPropertyDetails[0]?.title || formik.values.title}
                  onChangeHandler={formik.handleChange}
                  isError={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title ? formik.errors.title : ""}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px", padding: "10px 0" }}>Select Property Type for Features/Amenities</label>
                <MultipleSelectCheckmarks
                  name="projectType"
                  tagName="Property Type*"
                  value={formik.values.projectType ?? ""}
                  onChange={(event: any) => {
                    formik.setFieldValue("projectType", event.target.value);
                  }}
                  keyToSelect={"label"}
                  options={amenitiesCategories}
                  isError={
                    formik.touched.projectType && Boolean(formik.errors.projectType)
                  }
                  helperText={
                    formik.touched.projectType ? formik.errors.projectType : ""
                  }
                />
              </Grid>


              <Box className="dropdown">
                <label className="required form-label m-4">Property Features</label>
                <button
                  type="button"
                  className="btn text-light mt-2 btn-sm dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  style={{ backgroundColor: "#181c32" }}
                >
                  + Add Features
                </button>
                <form className="dropdown-menu p-4 " onSubmit={submitHandler}>
                  <Box
                    className="row"
                    sx={{
                      width: 350,
                    }}
                  >
                    {AmenitiesList?.map(
                      (
                        amenity: {
                          label: any;
                          key: string | number | undefined;
                        },
                        index: number
                      ) => {
                        return (
                          <Box className="col-md-6 mt-3">
                            <Box className="form-check">
                              <label
                                className="form-check-label"
                                htmlFor={`dropdownCheck${index}`}
                              >
                                {amenity.label}
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`dropdownCheck${index}`}
                                value={amenties[amenity.key as string]}
                                checked={amenties[amenity.key as string]}
                                name={amenity.key as string}
                                onChange={(event) => changedAmenity(event, index)}
                              />
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                </form>
              </Box>

              <Grid item xs={12} sm={12} md={12}>
                <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px" }}>Description</label>
                <JoditEditor
                  ref={editor}
                  value={formik.values.description || selectedPropertyDetails[0]?.description}
                  onChange={(newContent) =>
                    formik.setFieldValue("description", newContent)
                  }
                />
              </Grid>

            </>
          )}

          {formik.values.ProjectSelecting === "add_building" && (
            <>
              <Grid item xs={12} sm={12} md={6}>
                <DropDown
                  id="floorNo"
                  label="Floor No *"
                  type="text"
                  value={formik.values.floorNo}
                  onChangeHandler={(event: any) => {
                    formik.setFieldValue("floorNo", event.target.value);
                  }}
                  isError={formik.touched.floorNo && Boolean(formik.errors.floorNo)}
                  helperText={formik.touched.floorNo ? formik.errors.floorNo : ""}
                  options={propertyFloorOptions}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomInput
                  id="appartmentNo"
                  label="Appartment No *"
                  placeholder="Type here..."
                  value={formik.values.appartmentNo}
                  type="text"
                  onChangeHandler={(event: any) => {
                    formik.setFieldValue("appartmentNo", event.target.value.toUpperCase());
                  }}
                  isError={
                    formik.touched.appartmentNo &&
                    Boolean(formik.errors.appartmentNo)
                  }
                  helperText={
                    formik.touched.appartmentNo ? formik.errors.appartmentNo : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <DropDown
                  id="category"
                  label="Property Category *"
                  value={formik.values.category}
                  onChangeHandler={(event: any) => {
                    formik.setFieldValue("category", event.target.value);
                  }}
                  isError={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                  helperText={formik.touched.category ? formik.errors.category : ""}
                  options={propertyTypeOptions}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <DropDown
                  id="subCategory"
                  label="Property SubCategory *"
                  value={formik.values.subCategory}
                  onChangeHandler={(event: any) => {
                    formik.setFieldValue("subCategory", event.target.value);
                  }}
                  isError={
                    formik.touched.subCategory && Boolean(formik.errors.subCategory)
                  }
                  helperText={
                    formik.touched.subCategory ? formik.errors.subCategory : ""
                  }
                  options={propertyCategoryOptions.filter(
                    (item: any) => item.category === formik.values.category
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <DropDown
                  id="furnishingStatus"
                  label="Furnishing Status *"
                  value={formik.values.furnishingStatus}
                  onChangeHandler={(event: any) => {
                    formik.setFieldValue(
                      "furnishingStatus",
                      event.target.value
                    );
                  }}
                  type="string"
                  isError={
                    formik.touched.furnishingStatus && Boolean(formik.errors.furnishingStatus)
                  }
                  helperText={
                    formik.touched.furnishingStatus ? formik.errors.furnishingStatus : ""
                  }
                  options={furnishingStatus}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomInput
                  id="area"
                  label="Area *"
                  placeholder="Type here..."
                  value={formik.values.area}
                  type="number"
                  onChangeHandler={(event: any) => {
                    formik.setFieldValue("area", event.target.value);
                  }}
                  isError={formik.touched.area && Boolean(formik.errors.area)}
                  helperText={formik.touched.area ? formik.errors.area : ""}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <CustomInput
                  id="rentalIncome"
                  label="Rental Income (Optional)"
                  placeholder="Type here..."
                  value={formik.values.rentalIncome} // set default value to "N/A"
                  onChangeHandler={(event: any) => {
                    formik.setFieldValue("rentalIncome", event.target.value);
                  }}
                  isError={
                    formik.touched.rentalIncome &&
                    Boolean(formik.errors.rentalIncome)
                  }
                  helperText={
                    formik.touched.rentalIncome ? formik.errors.rentalIncome : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <CustomInput
                  id="salePrice"
                  label="Sale Price (PKR) (optional):"
                  placeholder="Type here..."
                  value={formik.values.salePrice}
                  type="number"
                  onChangeHandler={(event: any) => {
                    formik.setFieldValue("salePrice", event.target.value);
                  }}
                  isError={formik.touched.salePrice && Boolean(formik.errors.salePrice)}
                  helperText={formik.touched.salePrice ? formik.errors.salePrice : ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <DropDown
                  id="completionStatus"
                  label="Completion Status *"
                  value={formik.values.completionStatus}
                  onChangeHandler={(event: any) => {
                    formik.setFieldValue("completionStatus", event.target.value);
                  }}
                  isError={
                    formik.touched.completionStatus &&
                    Boolean(formik.errors.completionStatus)
                  }
                  helperText={
                    formik.touched.completionStatus
                      ? formik.errors.completionStatus
                      : ""
                  }
                  options={CompletedStatus}
                />
              </Grid>

              {formik.values.category === "Residential" && (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <CustomInput
                      id="bedrooms"
                      label="Bedrooms *"
                      placeholder="Type here..."
                      value={formik.values.bedrooms}
                      type="number"
                      onChangeHandler={formik.handleChange}
                      isError={
                        formik.touched.bedrooms && Boolean(formik.errors.bedrooms)
                      }
                      helperText={
                        formik.touched.bedrooms ? formik.errors.bedrooms : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <CustomInput
                      id="bathrooms"
                      label="Bathrooms *"
                      placeholder="Type here..."
                      value={formik.values.bathrooms}
                      type="number"
                      onChangeHandler={formik.handleChange}
                      isError={
                        formik.touched.bathrooms && Boolean(formik.errors.bathrooms)
                      }
                      helperText={
                        formik.touched.bathrooms ? formik.errors.bathrooms : ""
                      }
                    />
                  </Grid>
                </>
              )}

              {formik.values.owner.map((owner, index) => (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <CustomInput
                      id={`owner.purchasePrice${index}`}
                      label="Purchase Price *"
                      placeholder=""
                      value={owner?.purchasePrice}
                      type="number"
                      onChangeHandler={(event: any) => {
                        // formik.setFieldValue(`owner.purchasePrice${index}`, event.target.value);
                        const newOwners = [...formik.values.owner];
                        newOwners[index].purchasePrice = event.target.value;
                        formik.setFieldValue("owner", newOwners);
                        formik.setFieldValue("price", event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <CustomInput
                      id={`owner.purchasingDate${index}`}
                      label="Purchasing Date*"
                      placeholder="Purchasing Date"
                      value={owner?.purchasingDate}
                      type="date"
                      onChangeHandler={(event: any) => {
                        // formik.setFieldValue(`owner.date${index}`, event.target.value);
                        const newOwners = [...formik.values.owner];
                        newOwners[index].purchasingDate = event.target.value;
                        formik.setFieldValue("owner", newOwners);
                      }}
                    // isError={formik.touched.owner?.[index]?.purchasingDate && Boolean(formik.errors.owner?.[index]?.purchasingDate)}
                    // helperText={formik.touched.owner?.[index]?.purchasingDate ? formik.errors.owner?.[index]?.purchasingDate : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl style={{ width: "100%" }}>
                      <HeadingText3 />
                      <Select
                        style={{ width: "100%" }}
                        sx={{ backgroundColor: "#fcfcfb" }}
                        id={id}
                        // value={owner?.landlordId}
                        label={<HeadingText3 />}
                        onChange={(event: any) => {
                          const newOwners = [...formik.values.owner];
                          // newOwners[index].landlordName = event.target.label;
                          // formik.setFieldValue("owner", newOwners);
                          formik.values.landlordId = event.target.value;
                          formik.setFieldValue("landlordId", event.target.value)
                          newOwners[index].landlord = event.target.value;
                          formik.setFieldValue("owner", newOwners);
                          const selectedLandlord = landlord?.find((item: any) => item?.value === event.target.value);
                          if (selectedLandlord) {
                            const newOwners = [...formik.values.owner];
                            newOwners[index].landlordName = selectedLandlord.label; // Set landlordName from selected option's label
                            formik.setFieldValue("owner", newOwners);
                          }
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {landlord.map((item: any) => {
                          return (
                            <MenuItem
                              key={item.value}
                              value={item.value}
                              disabled={item.disabled}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {item.label}
                              {item.badge && (
                                <Chip
                                  size="small"
                                  sx={{ marginLeft: "8px", textTransform: "capitalize" }}
                                  label={item.badge}
                                  color="primary"
                                  variant="filled"
                                />
                              )}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ))}

              {/* Location */}
              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  id="address"
                  label="Enter Address/Building Name with City"
                  placeholder="Type here..."
                  value={address}
                  onChangeHandler={handleAddressChange}
                />
                {coordinates && (
                  <>
                    <div className="pt-3">
                      <p className="fw-bolder">(Address: &nbsp;&nbsp;&nbsp; {formattedAddress})</p>
                      {/* <p className="fw-bolder">City: &nbsp;&nbsp;&nbsp; {city}</p>
                      <p className="fw-bolder">State: &nbsp;&nbsp;&nbsp; {state}</p>
                      <p className="fw-bolder">Country: &nbsp;&nbsp;&nbsp; {country}</p> */}
                    </div>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomInput
                  id="country"
                  label="Country *"
                  value={country}
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomInput
                  id="province"
                  label="Province*"
                  value={state}
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomInput
                  id="cityName"
                  label="City*"
                  value={city}
                  type="text"
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomInput
                    id="title"
                    label="Property Title *"
                    placeholder="Type here..."
                    value={selectedPropertyDetails[0]?.title || formik.values.title}
                    onChangeHandler={formik.handleChange}
                    isError={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title ? formik.errors.title : ""}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px" }}>Description</label>
                <JoditEditor
                  ref={editor}
                  value={formik.values.description}
                  onChange={(newContent) =>
                    formik.setFieldValue("description", newContent)
                  }
                />
              </Grid>

              {/* Amenities */}
              <Grid item xs={12} sm={12} md={12}>
                <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px", padding: "10px 0" }}>Select Property Type for Features/Amenities</label>
                <MultipleSelectCheckmarks
                  name="projectType"
                  tagName="Property Type*"
                  value={formik.values.projectType ?? ""}
                  onChange={(event: any) => {
                    formik.setFieldValue("projectType", event.target.value);
                  }}
                  keyToSelect={"label"}
                  options={amenitiesCategories}
                  isError={
                    formik.touched.projectType && Boolean(formik.errors.projectType)
                  }
                  helperText={
                    formik.touched.projectType ? formik.errors.projectType : ""
                  }
                />
              </Grid>


              <Box className="dropdown">
                <label className="required form-label m-4">Property Features</label>
                <button
                  type="button"
                  className="btn text-light mt-2 btn-sm dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  style={{ backgroundColor: "#181c32" }}
                >
                  + Add Features
                </button>
                <form className="dropdown-menu p-4 " onSubmit={submitHandler}>
                  <Box
                    className="row"
                    sx={{
                      width: 350,
                    }}
                  >
                    {AmenitiesList?.map(
                      (
                        amenity: {
                          label: any;
                          key: string | number | undefined;
                        },
                        index: number
                      ) => {
                        return (
                          <Box className="col-md-6 mt-3">
                            <Box className="form-check">
                              <label
                                className="form-check-label"
                                htmlFor={`dropdownCheck${index}`}
                              >
                                {amenity.label}
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`dropdownCheck${index}`}
                                value={amenties[amenity.key as string]}
                                checked={amenties[amenity.key as string]}
                                name={amenity.key as string}
                                onChange={(event) => changedAmenity(event, index)}
                              />
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                </form>
              </Box>
              {/* End Amenities */}

              {/* start images upload section */}
              <Box className="col-xl-12 d-none">
                <h5
                  className="text-light p-3 mt-2 rounded"
                  style={{
                    background:
                      "linear-gradient(45deg,  #233a65 0%, #f5f8fa 62%, #fff 100%)",
                  }}
                >
                  ADD IMAGES
                </h5>
                {/* START ALERT */}
                <Paper elevation={6} m-2>
                  <Box
                    className="alert alert-warning d-flex align-items-center"
                    role="alert"
                  >
                    <Box>
                      <i className="fonticon-house"></i>
                      Press CTRL key while selecting images to upload multiple images
                      at once
                    </Box>
                  </Box>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container m={2}>
                      {formik?.values?.images?.map((image: any, index: number) => (
                        <ImageViewer
                          key={image}
                          index={image}
                          image={`${API}/uploads/${image}`}
                          onImageRemove={() => {
                            let prevImages: any = [];
                            formik?.values?.images?.map((_: any, i: number) =>
                              index === i ? null : _
                            );
                            setFiles(prevImages);
                          }}
                        />
                      ))}
                      <Grid item>
                        <ImageUploader
                          multiple={true}
                          onSubmit={onSubmitImage}
                          images={images}
                          setImages={setImages}
                          files={files}
                          setFiles={setFiles}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    alignItems="center"
                    sx={{
                      position: "sticky",
                      bottom: "0",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row-reverse",
                      m: 1,
                    }}
                  >
                    {files.length > 0 && (
                      <Button
                        sx={{ backgroundColor: " #233a65", margin: "30px" }}
                        variant="contained"
                        // color="success"
                        size="large"
                        onClick={onSubmitImage}
                      >
                        Upload Images
                      </Button>
                    )}
                  </Grid>
                </Paper>
              </Box>
              {/* End images upload section */}
            </>
          )}
        </Grid>

      </Box>
    </Modal>
  );
};

export default CreateConsultantProperty;
