import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toAbsoluteUrl } from '_metronic/helpers';
import Swal from 'sweetalert2';

const loginSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Minimum 8 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
});



const ResetPassword = () => {
    const history = useHistory();
    const [kaka, setKaka]: any = useState(useParams())
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (password && confirmPassword) {
            if (password === confirmPassword) {
                const data = { password: password };
                const res = await axios.post(`https://fsbo.apiimlaak.com/api/auth/passwordReset/${kaka.token}/${kaka.id}`, data);
                if (res.status === 200) {
                    Swal.fire({
                        title: "Password updated successfully.",
                        text: "",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "OK",
                    }).then((result) => {
                        if (result.value) {
                            history.push('/auth/login');
                        }
                    });
                }

            } else {
                alert('Passwords do not match.');
            }
        } else {
            alert('Please enter both password and confirm password.');
        }
    };

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <div
                        className="d-flex flex-column flex-lg-row-auto w-xl-700px  positon-xl-relative"
                        style={{
                            background:
                                "linear-gradient(180deg, rgb(35 58 101 / 87%) 0%, rgb(35 58 101) 90%)",
                        }}
                    >
                        <div className="d-flex flex-column position-xl-fixed top-0 bottom-0  w-xl-700px ">
                            <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
                                <a href="#" className="mb-12">
                                    <img
                                        alt="Logo"
                                        src={toAbsoluteUrl("/media/logos/logo-1-dark.png")}
                                        className="h-45px"
                                    />
                                </a>
                                <h1
                                    className="fw-bolder fs-2qx pb-5 pb-md-10"
                                    style={{ color: "#fff" }}
                                >
                                    Welcome to FSBO Portal
                                </h1>
                                <p className="fw-bold fs-2" style={{ color: "#fff" }}>
                                    Pakistan's No-1 Real Estate
                                    <br />
                                    Consultancy Company
                                </p>
                            </div>
                            <div
                                className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-300px"
                                style={{
                                    backgroundImage: `url(${toAbsoluteUrl("/media/illustrations/55.png")})`,
                                }}
                            ></div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-lg-row-fluid py-10 ">
                        <div className="d-flex flex-center flex-column flex-column-fluid">
                            {/* begin::Content */}
                            <div className="d-flex flex-center flex-column flex-column-fluid p-2 pb-lg-5 h-xl-650px">
                                {/* begin::Wrapper */}
                                <div className="w-lg-400px bg-white rounded shadow-sm  p-5 p-lg-10 mx-auto">
                                    <form className='form w-95' noValidate id='kt_login_signin_form' onSubmit={handleSubmit}>
                                        <div className='text-center mb-5'>
                                            <h1 className='text-dark mb-3'>New Password | FSBO Imlaak</h1>
                                        </div>

                                        <div className='fv-row mb-10 pt-5'>
                                            <div className='d-flex justify-content-between mt-n5'>
                                                <div className='d-flex flex-stack mb-2'>
                                                    <label className='form-label fw-bolder text-dark fs-6 mb-0'>Enter New Password</label>
                                                </div>
                                            </div>
                                            <input
                                                name='password'
                                                type='password'
                                                autoComplete='off'
                                                className='form-control'
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>

                                        <div className='fv-row mb-10'>
                                            <div className='d-flex justify-content-between mt-n5'>
                                                <div className='d-flex flex-stack mb-2'>
                                                    <label className='form-label fw-bolder text-dark fs-6 mb-0'>Confirm Password</label>
                                                </div>
                                            </div>
                                            <input
                                                name='confirmPassword'
                                                type='password'
                                                autoComplete='off'
                                                className='form-control'
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </div>

                                        <div className='text-center'>
                                            <button type='submit' className='btn btn-lg btn-primary w-100 mb-3'>
                                                Reset Password Now
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                {/* end::Wrapper */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
