import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageLink, PageTitle, } from "../../../_metronic/layout/core";
import Table, { capitalizeFirstLetter, IconRender } from "../Table";
import * as AuthRedux from "../auth/redux/AuthRedux";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "_metronic/helpers/AssetHelpers";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import { numberToNotation } from "app/utils/numberToNotation";
import { Button } from "@mui/material";
import { deletePropertyProperly } from "../inventory/redux/PropertyCrud";
import { UpdateEvaluationValue, getRequestedEvaluations, updateProperty } from "../property/redux/PropertyCrud";
import { RootState } from "setup";
import NumberFormatter from "app/shared/Input/NumberFormatter";
import UpdateEvaluation from "./components/UpdateEvaluation";

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
    },
];

const columns = (iconClickHandler: any, projectLookup: any, truncateToTenWords: any) => [
    {
        name: "Building Name",
        key: "inventory",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => {
            const projectName = projectLookup[value?.projectName] || value?.projectName;
            const formattedText = capitalizeFirstLetter(truncateToTenWords(projectName, 15));

            return (
                <div className="overflow-ellipsis" title={formattedText}>
                    <div className="overflow-ellipsis text-capitalize">
                        {formattedText}
                    </div>
                </div>
            );
        },
    }, {
        name: "Apartment & Floor No",
        key: "inventory",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => `${value?.appartmentNo} on ${value?.floorNo} floor`,
    }, {
        name: "User",
        key: "user",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value?.fname),
    }, {
        name: "Purchase Price",
        key: "inventory",
        numaric: false,
        isSortable: true,
        renderContent: (row: any) => (
            <NumberFormatter value={row?.price} />
        ),

    }, {
        name: "Current Evaluation",
        key: "currentEvaluation",
        numaric: false,
        isSortable: true,
        renderContent: (row: any) => (
            <NumberFormatter value={row || 0} />
        ),

    }, {
        name: "Status",
        key: "status",
        numaric: false,
        isSortable: true,
        renderContent: (value: string) => {
            if (value === 'requested') {
                return <button className="btn btn-warning">{capitalizeFirstLetter(value)}</button>;
            } else if (value === 'completed') {
                return <button className="btn btn-success">{capitalizeFirstLetter(value)}</button>;
            }
            return <span>{value}</span>;
        }
    }, {
        name: "Profit/loss %",
        key: "netGain",
        numaric: false,
        isSortable: true,
        renderContent: (value: string) => `${value} %`,
        // renderContent: (value: string) => value ? numberToNotation(parseFloat(value), 10) : 0 + " PKR", Ye shayad million bilion mein aesy hee kr deta ha will check
    }, {
        name: "Action",
        key: "action",
        component: (row: any) => (
            <>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => iconClickHandler(row, 'edit')}
                >
                    {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, 'edit')}
                    Evaluate
                </Button>
                {/* {IconRender(InfoOutlinedIcon, iconClickHandler)} */}
                {/* {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, 'delete')} */}
            </>
        ),
    },
];

const EvaluationPage: React.FC = () => {
    const dispatch = useDispatch();
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [modalMode, setModalMode]: any = useState("edit");

    const { getAll } = APIS.ProjectName;
    const { data } = useFetchData({ getAll });
    const [projectLookup, setProjectLookup] = useState<any>({});

    useEffect(() => {
        // Create a lookup object for projectName id's to project Names
        const lookup: any = {};
        data.forEach((row: any) => {
            lookup[row?._id] = row?.projectName;
        });
        setProjectLookup(lookup);
    }, [data]);
    const truncateToTenWords = (text: string, n: number = 15): string => {
        if (text?.length > n) {
            return text?.substring(0, n) + '...';
        }
        return text;
    };

    const [currentListing, setCurrentListing]: any = useState(0);
    const listing: any = useSelector<RootState>(
        ({ auth }) => auth.listing,
        shallowEqual
    );
    const [myProperties, setMyProperties] = useState(listing);
    const [accessToken, setAccessToken]: any = useState("");

    const getToken = async () => {
        let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
        accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
        setAccessToken(accessToken);
    };

    const getProperties = () => {
        getRequestedEvaluations(accessToken)
            .then(response => {
                const filteredData = response.data?.evaluations
                setMyProperties(filteredData);
                setCount(filteredData.length);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        setLoading(true);
        getToken();
        setTimeout(() => {
            getProperties();
        }, 1000);
    }, []);

    useEffect(() => {
        setMyProperties(listing);
    }, [listing]);
    const iconClickHandler = (row: any, type: any) => {
        if (type === "edit") {
            setModalMode("edit");
            setCurrentListing(row);
            setOpen(true);
        }
    };
    const updatePropertyFunction = (values: any) => {
        UpdateEvaluationValue(values, currentListing?._id, accessToken)
            .then(({ data }) => {
                setLoading(true)
                setOpen(false);
                Swal.fire({
                    title: "Property Evaluated Successfully.",
                    text: "",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "",
                    confirmButtonText: "OK",
                })
                dispatch(AuthRedux.actions.setMyListings(data?.evaluation));
                setCount(data.count);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <PageTitle>Property Evaluation</PageTitle>
            {user?.role === "consultant" && user?.isVerified ? (
                <div className="pb-5">
                    <Table
                        columns={columns(iconClickHandler, projectLookup, truncateToTenWords)}
                        data={myProperties}
                        loading={loading}
                        onRowClick={(row: any, type: any) => iconClickHandler(row, type)}
                        isPaginationRequired
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        total={count}
                    />
                    <UpdateEvaluation
                        setOpen={setOpen}
                        open={open}
                        loading={loading}
                        updateEvaluation={
                            modalMode === "edit"
                                ? updatePropertyFunction
                                : updatePropertyFunction
                        }
                        modalMode={modalMode}
                        evaluation={currentListing}
                    />
                </div>
            ) : (
                <div className="card-body p-0 ">
                    {/* begin::Chart */}
                    <div className="alert alert-warning p-5 mt-5 mx-10" role="alert">
                        This is a warning alert{" "}
                        <h3 className="text-danger">
                            Your account is not approved you cannot Post Property!!.
                        </h3>
                    </div>
                    {/* end::Chart */}
                    {/* begin::Stats */}
                    <div className="card-p mt-10 position-relative d-flex justify-content-center">
                        <img src={toAbsoluteUrl("/media/illustrations/sketchy-1/57.svg")} />

                        {/* end::Row */}
                    </div>
                    <span className="d-flex justify-content-center fw-bold fs-4 mb-1 ">
                        No Record Found
                    </span>
                    {/* <span className='d-flex justify-content-center fw-bold fs-4 text-muted mb-5 '>Submit your application to Imlaak support for further assistance</span>  */}
                    {/* end::Stats */}
                </div>
            )}
        </>
    );
};

export default EvaluationPage;
