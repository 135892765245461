import CustomTable, {
  capitalizeFirstLetter,
  IconRender,
} from "app/components/Table";
// import React, { useEffect, useState } from "react";

// import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
// import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { RootState } from "setup";
import Swal from "sweetalert2";
import { ProjectHeader } from "app/components/Project/ProjectHeader";
import { getAllProperty, updateAgency } from "./Crud";
import { Chip, Typography } from "@mui/material";
import { PageLink, PageTitle } from "_metronic/layout/core";
import * as AuthRedux from "../../auth/redux/AuthRedux";
import { Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { blue, green, red } from "@mui/material/colors";
import { Link } from "react-router-dom";
import { StatisticsWidget8 } from "_metronic/partials/widgets/statistics/StatisticsWidget8";
import { deleteProperty, getMyProperties } from "../../../components/property/redux/PropertyCrud";

let i = 1;
const colors = {
  approve: "#47BE7D",
  reject: " #bda588",
  suspend: red[300],
  pending: "#F1BC00",
};

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

const PropertyTable = ({ setAgenciesList }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({});

  const [currentListing, setCurrentListing] = useState(0);
  const listing = useSelector(
    ({ auth }) => auth.listing,
    shallowEqual
  );
  const [myProperties, setMyProperties] = useState(listing);
  const [accessToken, setAccessToken] = useState("");
  const [agencies, setAllAgencies] = useState([]);
  const [modalMode, setModalMode] = useState("edit");

  const getToken = async () => {
    let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
    accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
    setAccessToken(accessToken);
  };

  // const filtersChanged = (filters) => {
  //   setFilter(filters);
  //   setLoading(true);
  //   setTimeout(() => {
  //     getAgencies(filters);
  //   }, 1000);
  // };

  debugger

  const getProperties = (filters) => {
    getMyProperties(accessToken, filters)
      .then(({ data }) => {
        dispatch(AuthRedux.actions.setMyListings(data.property));
        setCount(data.count);
      })
      .catch(() => { })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    getToken();
    setTimeout(() => {
      getProperties(filter);
    }, 1000);
  }, []);

  useEffect(() => {
    setMyProperties(listing);
  }, [listing]);

  const onClickCreate = () => {
    setOpen(true);
    setCurrentListing([]);
    setModalMode("create");
  };

  useEffect(() => {
    document
      .getElementById("create-button")
      ?.addEventListener("click", onClickCreate);
  }, []);
  const iconClickHandler = (data, type) => {
    if (type) {
      Swal.fire({
        title: `Attempting to ${type} `,
        text: `Are you sure you want to ${type} `,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((status) => {
        if (status.isConfirmed) {
          setLoading(true);
          console.log(data[6], "data._id")
          updateAgency(data[6], type)
            .then(({ data, status }) => {
              let text = "";
              let icon = "error";
              if (status === 200) {
                text = `Successfully changed status`;
                icon = "success";
              } else {
                text = data.message;
                icon = "error";
              }
              Swal.fire({
                title: "Status Changed",
                text: text,
                icon: icon,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
              });
              setAllAgencies(data);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
        }
      });
    }
  };

  const createProjectFunction = (values) => {
    setLoading(true);
    setOpen(false);
    Swal.fire({
      title: "Project Listing?",
      text: "Do you want to List this Project for sale?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No Don't List, Add as an asset",
      confirmButtonText: "Yes List this",
    })
      .then((value) => {
        if (value.isConfirmed) {
          values = { ...values, isListed: true };
        }
        // createProject(values, accessToken)
        //   .then(({ data }) => {
        //     dispatch(AuthRedux.actions.setMyListings(data.project));
        //     setCount(data.count);
        //   })
        //   .catch(() => {})
        //   .finally(() => setLoading(false));
      })
      .finally(() => { });
  };

  const updateProjectFunction = (values) => {
    setLoading(true);
    // updateProject(values, currentListing?._id, accessToken)
    //   .then(({ data }) => {
    //     dispatch(AuthRedux.actions.setMyListings(data.project));
    //     setCount(data.count);
    //   })
    //   .catch(() => {})
    //   .finally(() => setLoading(false));
  };
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const columns = [
    { name: "title", label: "Property Title", options: { filterOptions: { fullWidth: true } } },
    { name: "category", label: "Category", options: { filterOptions: { fullWidth: true } } },
    { name: "subCategory", label: "Sub Category", options: { filterOptions: { fullWidth: true } } },
    { name: "price", label: "Price", options: { filterOptions: { fullWidth: true } } },
    { name: "bedrooms", label: "Beds", options: { filterOptions: { fullWidth: true } } },
    { name: "bathrooms", label: "Baths", options: { filterOptions: { fullWidth: true } } },

    {
      name: "_id",
      options: {
        filter: false,
        display: false
      }
    },

  ];


  const options = {
    filterType: "dropdown",
    responsive: "scroll",
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };

  const filteredData = myProperties.filter((row) => row?.isDeleted === false);

  return (
    <>
      <div className="row">
        {/* <div className="col-md-3">
          <Link to="/approveUsers">
            <StatisticsWidget8
              className="mb-5 text-white"
              svgIcon="/media/icons/duotune/communication/com005.svg"
              color="success"
              iconColor="white"
              title=''
              bgcolor=''
              description="approved"
            />
          </Link>
        </div> */}
        {/* <div className="col-md-3">
          <Link to="/totalUsers">
            <StatisticsWidget8
              className="mb-5 text-white"
              svgIcon="/media/icons/duotune/graphs/gra007.svg"
              color=""
              iconColor="white"
              title=''
              bgcolor='#bf3939'
              description="Suspended"
            />
          </Link>
        </div>
        <div className="col-md-3">
          <Link to="/totalUsers">
            <StatisticsWidget8
              className="mb-5 text-white"
              svgIcon="/media/icons/duotune/general/gen008.svg"
              color="warning"
              iconColor="white"
              title=''
              bgcolor=''
              description="Landlords"
            />
          </Link>
        </div>
        <div className="col-md-3">
          <Link to="/totalUsers">
            <StatisticsWidget8
              className="mb-5 text-white"
              svgIcon="/media/icons/duotune/layouts/lay002.svg"
              color=""
              iconColor="white"
              title=''
              bgcolor='#333'
              description="Consultants"
            />
          </Link>
        </div> */}
      </div>

      {/* <ProjectHeader filter={filter} setFilter={filtersChanged} /> */}
      <div className="pb-5">
        <CacheProvider value={muiCache}>
          <MUIDataTable
            title={""}
            data={filteredData}
            loading={loading}
            columns={columns}
            options={options}
            onRowClick={(filteredData, type) => iconClickHandler(filteredData, type)}
          />
        </CacheProvider>
      </div>

    </>
  );
}


export default PropertyTable;