/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { AsideMenuItem } from "./AsideMenuItem";
import { useDispatch } from "react-redux";
import * as auth from "../../../../app/components/auth/redux/AuthRedux";

export function EmployeeMenu() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(auth.actions.logout());
  };

  return (
    <>
      {/* remove this agency line */}
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/layouts/lay009.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/profile-details"
        icon="/media/icons/duotune/communication/com013.svg"
        title="My Account & Profile"
        fontIcon="bi-app-indicator"
      />

      {/* <AsideMenuItem
        to='/agencyWebsite'
        icon='/media/icons/duotune/files/fil013.svg'
        title='Agency Website'
        fontIcon='bi-app-indicator'
      /> */}
      <AsideMenuItem
        to="/consultants"
        icon="/media/icons/duotune/abstract/abs027.svg"
        title="Consultants"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to='/PropertyLandlords'
        icon='/media/icons/duotune/files/fil013.svg'
        title='Landlords'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/changeConsultant'
        icon='/media/icons/duotune/general/gen049.svg'
        title='Change Consultants'
        fontIcon='bi-app-indicator'
      />

      {/* <AsideMenuItem
        to="/property"
        icon="/media/icons/duotune/general/gen001.svg"
        title="Property"
        fontIcon="bi-app-indicator"
      />  */}
      {/* <AsideMenuItem
        to="/properties"
        icon="/media/icons/duotune/general/gen001.svg"
        title="Other Properties"
        fontIcon="bi-app-indicator"
      />*/}
      <AsideMenuItem
        to="/amenities"
        icon="/media/icons/duotune/general/gen020.svg"
        title="Feature and Amenities"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/projectDeveloper"
        icon="/media/icons/duotune/abstract/abs043.svg"
        title="Developer"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/projectName"
        title="Project Name"
        icon="/media/icons/duotune/maps/map007.svg"
      />
      {/* <AsideMenuItem
        to="/ProjectNameTesting"
        title="Project Name Testing"
        icon="/media/icons/duotune/maps/map007.svg"
      /> */}

      <AsideMenuItem
        to='/projects'
        icon='/media/icons/duotune/art/art002.svg'
        title='Projects'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to="/inventory"
        icon="/media/icons/duotune/finance/fin001.svg"
        title="Imlaak Inventory"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to='/ownership'
        icon='/media/icons/duotune/maps/map005.svg'
        title='Property Ownership'
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/reports'
        icon='/media/icons/duotune/arrows/arr070.svg'
        title='Report'
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/application'
        icon='/media/icons/duotune/files/fil012.svg'
        title='Applications'
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/listings'
        icon='/media/icons/duotune/art/art002.svg'
        title='Listings'
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/plotListings'
        icon="/media/icons/duotune/maps/map008.svg"
        title='Plot Listings'
        fontIcon='bi-app-indicator'
      />

<AsideMenuItem
        to="/location"
        icon="/media/icons/duotune/communication/com009.svg"
        title="Location"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/building"
        icon="/media/icons/duotune/ecommerce/ecm008.svg"
        title="Building"
        fontIcon="bi-app-indicator"
      /> */}
      {/* 
      <AsideMenuItem
        to="/reservations"
        icon="/media/icons/duotune/general/gen059.svg"
        title="Reservations"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to='/Integration'
        icon='/media/icons/duotune/arrows/arr029.svg'
        title='Integrations (Preview)'
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItemWithSub
        to='/contact'
        title='Contacts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem
          to='/contact/tenants'
          title='Tenants'
          icon='/media/icons/duotune/communication/com013.svg'

        />
        <AsideMenuItem
          to='/contact/owners'
          title='Owners'
          icon='/media/icons/duotune/communication/com014.svg'
        />
        <AsideMenuItem
          to='/contact/suppliers'
          title='Suppliers'
          icon='/media/icons/duotune/communication/com008.svg'

        />
      </AsideMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Setting
          </span>
        </div>
      </div> */}
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Dashboard Setting'
        fontIcon='bi-layers'
      /> */}
      {/* <AsideMenuItem
        to='/apps/chat'
        title='More'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen023.svg'
      > */}
      {/* <AsideMenuItem
          to='/apps/chat'
          title='Help Center'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/general/gen046.svg'
        >

      <AsideMenuItem
        to='/apps/chat'
        title='Sign Out'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/arrows/arr077.svg'
      >
         {/* <a onClick={logout} className='menu-link px-5'>
        <img className='mx-3' src='/media/icons/duotune/arrows/arr077.svg'/>
          Sign Out
        </a> */}
      {/* </AsideMenuItem> */}
      {/* 
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div> */}
    </>
  );
}
