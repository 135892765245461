/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PageTitle } from "../../../_metronic/layout/core";
import BgImage from "../../../_metronic/assets/media/svg/misc/taieri.svg";
import {
    StatisticsWidget5,
    AssetsCarts,
    MixedWidget11,
    MixedWidget8,
    TablesWidget5,
    ChartsWidget1,
    ListsWidget2,
} from "../../../_metronic/partials/widgets";
import {
    getLandlordProperties,
    getMyEvaluations,
    getMyProperties,
    requestEvaluation,
    requestEvaluation as requestEvaluationApi,
} from "../../components/property/redux/PropertyCrud";
import * as AuthRedux from "../../components/auth/redux/AuthRedux";
import { RootState } from "../../../setup";
import { Link, useHistory } from "react-router-dom";
import DashboardTable from "./DashboardTable";
import { toAbsoluteUrl } from "_metronic/helpers";
import { StatisticsWidget7 } from "_metronic/partials/widgets/statistics/StatisticsWidget7";
import { Button } from "react-bootstrap-v5";
import { StatisticsWidget9 } from "_metronic/partials/widgets/statistics/StatisticsWidget9";

const DashboardPage = ({
    properties,
    data,
    myEval,
    requestEvaluation,
}: any) => (
    <>
        {/* begin::Row */}
        <div className="row gy-5 g-xl-8">
            <div className="col-xxl-12">
                <AssetsCarts
                    className="card-xl-stretch mb-xl-8"
                    chartColor="danger"
                    chartHeight="200px"
                    strokeColor="#cb1e46"
                    properties={properties}
                />
            </div>

            <div className="col-xxl-4"></div>
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
                <ListsWidget2
                    className="card-xl-stretch mb-xl-8"
                    properties={properties}
                    requestEvaluation={requestEvaluation}
                    myEval={myEval}
                />
            </div>
            {/* <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div> */}
        </div>
        {/* end::Row */}
    </>
);

const DashboardDemo: FC = () => {
    const intl = useIntl();
    const [accessToken, setAccessToken]: any = useState("");
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [myEval, setMyEval] = useState([]);
    const getToken = async () => {
        let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
        accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
        setAccessToken(accessToken);
    };
    const getProperties = (filters: any) => {
        getLandlordProperties(accessToken, filters)
            .then(({ data }) => {
                dispatch(AuthRedux.actions.setMyListings(data.property));
            })
            .catch(() => { })
            .finally(() => setLoading(false));

        getMyEvaluations(accessToken)
            .then(({ data }) => {
                setMyEval(data?.evaluation);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        getToken();
        setTimeout(() => {
            getProperties(filter);
        }, 1000);
    }, []);

    const requestEvaluation = (activeProperty: any) => {
        return requestEvaluationApi(accessToken, activeProperty);
    };

    const properties: any = useSelector<RootState>(
        ({ auth }) => auth.listing,
        shallowEqual
    );
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );

    const history = useHistory();

    // useEffect(() => {
    //     if (user?.role === "admin") {
    //         history.push("/admin/dashboard");
    //     } else if (user?.role === "landlord") {
    //         history.push("/landlord/dashboard");
    //     } else if (user?.role === "employee") {
    //         history.push("/employee/dashboard");
    //     } else if (user?.role === "demo") {
    //         history.push("/demo/dashboard");
    //     } else {
    //         history.push("/dashboard");
    //     }
    // }, [user]);
    return (
        <>
            <PageTitle breadcrumbs={[]}>
                {intl.formatMessage({ id: "MENU.DASHBOARD" })}
            </PageTitle>
            <DashboardPage
                requestEvaluation={requestEvaluation}
                myEval={myEval}
                properties={properties || []}
            />
        </>
    );
};

export { DashboardDemo };
