/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  StatisticsWidget5,
  StatisticsWidgetAdmin,
  AssetsCarts,
  MixedWidget11,
  ChartsWidget1,
} from "../../../_metronic/partials/widgets";
import {
  getMyEvaluations,
  getMyProperties,
  requestEvaluation as requestEvaluationApi,
} from "../../components/property/redux/PropertyCrud";
import * as AuthRedux from "../../components/auth/redux/AuthRedux";
import { RootState } from "../../../setup";
import { Link, useHistory } from "react-router-dom";
import DashboardTable from "./DashboardTable";
import { EngageWidget10 } from "_metronic/partials/widgets/_new/EngageWidget10";
import { KTCardBody } from "_metronic/helpers/components/KTCardBody";
// import UsersTable from "app/components/Admin/UsersTable";
import { toAbsoluteUrl } from "_metronic/helpers";
import { StatisticsWidget8 } from "_metronic/partials/widgets/statistics/StatisticsWidget8";
import { Charts } from "app/components/widgets/components/Charts";
import { StatisticsWidget9 } from "_metronic/partials/widgets/statistics/StatisticsWidget9";
import { getAllUsers } from "app/components/Admin/AllUsers/Crud";

const DashboardPage = ({ data }: any) => (
  <>
    <div className="row g-5 g-xl-8">
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3">
        <Link to="/consultants">
          <StatisticsWidgetAdmin
            className="card-xl-stretch mb-5 mb-xl-8 text-dark "
            svgIcon="/media/icons/duotune/communication/com006.svg"
            color="white"
            iconColor="dark"
            title={data?.data?.length ?? 0}
            description="Total Users"
          />
        </Link>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3">
        <Link to="/propertyLandlords">
          <StatisticsWidgetAdmin
            className="card-xl-stretch mb-5 mb-xl-8 text-dark "
            svgIcon="/media/icons/duotune/communication/com005.svg"
            color="white"
            iconColor="dark"
            title={
              data?.data?.filter((item: { approvalStatus: string, role: string }) => item?.approvalStatus === "approved" && item?.role === 'landlord').length ?? 0
            }
            description="Approved Landlords"
          />
        </Link>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3">
        <Link to="/suspendedAccounts">
          <StatisticsWidgetAdmin
            className="card-xl-stretch mb-5 mb-xl-8 text-dark"
            svgIcon="/media/icons/duotune/graphs/gra007.svg"
            color="white"
            iconColor="dark"
            title={
              data?.data?.filter(
                (item: { status: string }) => item.status === "suspended"
              ).length ?? 0
            }
            description="Suspended Consultants"
          />
        </Link>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3">
        <Link to="/">
          <StatisticsWidgetAdmin
            className="card-xl-stretch mb-5 mb-xl-8 text-dark"
            svgIcon="/media/icons/duotune/general/gen008.svg"
            color="white"
            iconColor="dark"
            title={
              data?.data?.filter(
                (item: { status: string }) => item.status === "reject"
              ).length ?? 0
            }
            description="Featured Consultants"
          />
        </Link>
      </div>
    </div>
    {/* begin::Row */}
    <div className="row g-2 g-xl-4 ">
      {/* begin::Col */}
      <div className="col-xxl-8">
        {/* <Charts /> */}
        <ChartsWidget1 className="" />
        {/* <EngageWidget10 className='h-md-39' /> */}
      </div>
      {/* end::Col */}

      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-2 mb-md-5 mb-xl-10">
        <Link to="/propertyLandlords">
          <StatisticsWidgetAdmin
            className="h-md-40 mb-5 mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/graphs/gra007.svg"
            color="info"
            iconColor="white"
            title={
              data?.data?.filter(
                (item: { role: string }) => item.role === "landlord"
              ).length ?? 0
            }
            description="Landlords"
          />
        </Link>
        <Link to="/managers">
          <StatisticsWidgetAdmin
            className="h-md-40  mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/graphs/gra007.svg"
            color="dark"
            iconColor="white"
            title={
              data?.data?.filter(
                (item: { role: string }) => item.role === "employee"
              ).length ?? 0
            }
            description="Imlaak Managers"
          />
        </Link>
      </div>
      {/* end::Col */}
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-2 mb-md-5 mb-xl-10">
        <Link to="/consultants">
          <StatisticsWidgetAdmin
            className="h-md-40 mb-5 mb-xl-8 text-dark"
            svgIcon="/media/icons/duotune/graphs/gra007.svg"
            color="white"
            iconColor="dark"
            title={
              data?.data?.filter(
                (item: { role: string }) => item.role === "consultant"
              ).length ?? 0
            }
            description="Consultants"
          />
        </Link>
        <Link to="">
          <StatisticsWidgetAdmin
            className="h-md-40  mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/graphs/gra007.svg"
            color="danger"
            iconColor="white"
            title={
              data?.data?.filter(
                (item: { role: string }) => item.role === "admin"
              ).length ?? 0
            }
            description="Admin"
          />
        </Link>
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* <div className='row g-5 g-xl-8'>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5">
        <Link to="/crafted/pages/area/documents">
          <StatisticsWidget5
            className="h-md-40 mb-5 mb-xl-10 text-white"
            svgIcon="/media/icons/duotune/communication/com006.svg"
            color="info"
            iconColor="white"
            title={data?.count}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            bgcolor=''
            description="Consultants"
          />
        </Link>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5">
        <Link to="/crafted/pages/area/documents">
          <StatisticsWidget5
            className="h-md-40 mb-5 mb-xl-10 text-white"
            svgIcon="/media/icons/duotune/graphs/gra007.svg"
            color="warning"
            iconColor="white"
            title={
              data?.data?.filter((item: any) => item.status === "pending")
                ?.length ?? 0
            }
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            bgcolor=''
            description="Agents"
          />
        </Link>
      </div>

      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5">
        <Link to="/apps/consumer-information/users">
          <StatisticsWidget5
            className="h-md-40 mb-5 mb-xl-10 text-white"
            svgIcon="/media/icons/duotune/communication/com005.svg"
            color="success"
            iconColor="white"
            title={
              data?.data?.filter((item: any) => item.status === "approved")
                ?.length ?? 0
            }
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            bgcolor=''
            description="Investors"
          />
        </Link>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5">
        <Link to="/crafted/pages/area/connections">
          <StatisticsWidget5
            className="h-md-40 mb-5 mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/general/gen008.svg"
            color=""
            iconColor="white"
            title={
              data?.data?.filter(
                (item: any) =>
                  item.status === "reject" || item.status === "suspended"
              )?.length ?? 0
            }
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            bgcolor='#bf3939'
            description="Brokers"
          />
        </Link>
      </div>
      
      <div className="col-xxl-6">
        <EngageWidget10 className="h-md-39" />
      </div>
    </div> */}

    {/* <DashboardTable /> */}
  </>
);

const DashboardAdmin: FC = () => {
  const intl = useIntl();
  const [accessToken, setAccessToken]: any = useState("");
  const dispatch = useDispatch();
  const user: any =
    useSelector<RootState>(({ auth }) => auth.user, shallowEqual) ?? {};
  const [loading, setLoading] = useState(false);
  const getToken = async () => {
    let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
    accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");

    setAccessToken(accessToken);
  };

  const [filter, setFilter] = useState({});
  const [agencies, setAllAgencies] = useState([]);
  const [count, setCount] = useState(0);

  const filtersChanged = (filters: any) => {
    setFilter(filters);
    setLoading(true);
    setTimeout(() => {
      getAgencies(filters);
    }, 1000);
  };

  const getAgencies = (filters: any) => {
    getAllUsers(accessToken, filters)
      .then(({ data }) => {
        setAllAgencies(data.data);
        setAgenciesList(data);

        setCount(data?.count);
      })
      .catch(() => { })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    getToken();
    setTimeout(() => {
      getAgencies(filter);
    }, 1000);
  }, []);

  useEffect(() => {
    setLoading(true);
    getToken();
  }, []);
  const history = useHistory();

  const properties: any = useSelector<RootState>(
    ({ auth }) => auth.listing,
    shallowEqual

  );
  // useEffect(() => {
  //   if (user?.role === "admin") {
  //     history.push("/admin/dashboard");
  //   } else if (user?.role === "landlord") {
  //     history.push("/landlord/dashboard");
  //   } else if (user?.role === "employee") {
  //     history.push("/employee/dashboard");
  //   } else {
  //     history.push("/dashboard");
  //   }
  // }, [user]);

  const [agenciesList, setAgenciesList] = useState({});

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage data={agenciesList} total={count} />
      {/* <KTCardBody className="py-4 bg-white rounded">
        <UsersTable setAgenciesList={setAgenciesList} />
      </KTCardBody> */}
    </>
  );
};

export { DashboardAdmin };
