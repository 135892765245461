import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const LastLocation = () => {
    const history = useHistory();

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            // Cancel the default behavior
            event.preventDefault();
            // Check if history.location.state exists and is an object
            const state = history.location.state && typeof history.location.state === 'object' ? history.location.state : {};
            // Set a dummy state in the history
            history.replace(history.location.pathname, { ...state, persist: true });
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [history]);

    return null;
};

export default LastLocation;
