import CustomTable, {
    capitalizeFirstLetter,
    IconRender,
} from "app/components/Table";
import React, { useEffect, useState } from "react";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import Swal from "sweetalert2";
import { ProjectHeader } from "app/components/Project/ProjectHeader";
// import { getAllAgencies, updateAgency } from "../Crud";
import { Chip, Typography } from "@mui/material";
import { blue, green, red } from "@mui/material/colors";
import { getAllApproveUsers, updateAgency } from "./Crud";
import { PageLink, PageTitle } from "_metronic/layout/core";
import { getAllUsers } from "../AllUsers/Crud";

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: "",
        path: "/crafted/account/overview",
        isSeparator: false,
        isActive: false,
    },
    {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
    },
];

let i = 1;
const colors: any = {
    approve: "#47BE7D",
    approved: "#47BE7D",
    reject: " #bda588",
    suspended: red[300],
    pending: "#F1BC00",
};
const columns = (iconClickHandler: any) => [
    {
        name: "S.no",
        key: "",
        numaric: true,
        isSortable: true,
        renderContent: () => i++,
    },
    {
        name: "User Name",
        key: "action",
        numaric: false,
        isSortable: true,
        component: (row: any) => {
            return <>{capitalizeFirstLetter(`${row?.fname}`)}</>;
        },
    },
    {
        name: "Contact",
        key: "action",
        numaric: false,
        isSortable: true,
        component: (row: any) => {
            return <>{capitalizeFirstLetter(`${row?.contact}`)}</>;
        },
    },
    {
        name: "Role",
        key: "role",
        numaric: true,
        isSortable: true,
        renderContent: (value: string) => capitalizeFirstLetter(value),
    },

    { name: "Email", key: "email", numaric: false, isSortable: true },
    {
        name: "Status",
        key: "status",
        numaric: false,
        isSortable: true,
        renderContent: (value: string) => (
            <Chip
                sx={{ background: colors[value], color: "#fff", fontWeight: "bold" }}
                label={capitalizeFirstLetter(value)}
            />
        ),
    },
    {
        name: "Action",
        key: "action",
        component: (row: any) => (
            <>
                <select
                    className="form-select form-select-solid"
                    aria-label="Select example"
                    onChange={(event) => iconClickHandler(row, event.target.value)}
                >
                    <option>Edit </option>
                    <option value="approved">Approve</option>
                    <option value="suspended">Suspend</option>
                </select>
            </>
        ),
    },
];
type props = {
    setAgenciesList: any;
};

const TotalUsersTable: React.FC<props> = ({ setAgenciesList }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState({});

    const [currentListing, setCurrentListing]: any = useState(0);
    const listing: any = useSelector<RootState>(
        ({ auth }) => auth.listing,
        shallowEqual
    );
    const [myProperties, setMyProperties] = useState(listing);
    const [accessToken, setAccessToken]: any = useState("");
    const [agencies, setAllAgencies] = useState<any>([]);
    const [modalMode, setModalMode]: any = useState("edit");

    const getToken = async () => {
        let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
        accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
        setAccessToken(accessToken);
    };

    const filtersChanged = (filters: any) => {
        setFilter(filters);
        setLoading(true);
        setTimeout(() => {
            getAgencies(filters);
        }, 1000);
    };

    const getAgencies = (filters: any) => {
        getAllUsers(accessToken, filters)
            .then(({ data }) => {
                setAllAgencies(data.data);
                setAgenciesList(data);

                setCount(data?.count);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        setLoading(true);
        getToken();
        setTimeout(() => {
            getAgencies(filter);
        }, 1000);
    }, []);

    useEffect(() => {
        setMyProperties(listing);
    }, [listing]);

    const onClickCreate = () => {
        setOpen(true);
        setCurrentListing([]);
        setModalMode("create");
    };

    useEffect(() => {
        document
            .getElementById("create-button")
            ?.addEventListener("click", onClickCreate);
    }, []);
    const iconClickHandler = (row: any, type: any) => {
        if (type) {
            Swal.fire({
                title: `Attempting to ${type} an account`,
                text: `Are you sure you want to ${type} this Account`,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((status) => {
                if (status.isConfirmed) {
                    setLoading(true);
                    updateAgency(row._id, type)
                        .then(({ data, status }) => {
                            let text = "";
                            let icon: any = "error";
                            if (status === 200) {
                                text = `Status Successfully changed.`;
                                icon = "success";
                            } else {
                                text = data.message;
                                icon = "error";
                            }
                            Swal.fire({
                                title: "Status Changed",
                                text: text,
                                icon: icon,
                                showCancelButton: false,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Ok",
                            });
                            getAgencies(data);
                        })
                        .catch(() => { })
                        .finally(() => setLoading(false));
                }
            });
        }
    };

    const filteredData = agencies.filter((row: any) => row.role === "landlord" || row.role === "consultant");
    return (
        <>
            <PageTitle>Total Users</PageTitle>
            <div className="pb-5">
                <CustomTable
                    columns={columns(iconClickHandler)}
                    data={filteredData}
                    loading={loading}
                    onRowClick={(row: any, type: any) => iconClickHandler(row, type)}
                    isPaginationRequired
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    total={count}
                />
            </div>
        </>
    );
};

export default TotalUsersTable;
