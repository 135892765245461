import * as React from "react"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { makeStyles } from "@mui/styles"
import { AppTextColor, AppErrorColor } from "../../theme/Colors"

import {
  Badge,
  Chip,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  useFormControl,
} from "@mui/material"
const useStyles = makeStyles(() => ({
  headingStyles: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
    // margin: '0px 0px 10px 0px !important',
  },
}))

export const projectTypeDropDown = [
  { title: "Apartment", year: 1994 },
  { title: "Townhouse", year: 1972 },
  { title: "Villa Compound", year: 1974 },
  { title: "Residential Plot ", year: 2008 },
  { title: "Residential Building", year: 1957 },
  { title: "Villa", year: 1993 },
  { title: "Penthouse", year: 1994 },
  {
    title: "Hotel Apartment",
    year: 2003,
  },
  { title: "Residential Floor", year: 1966 },
]

const DropDown = ({
  options,
  value,
  onChangeHandler,
  error,
  id,
  label,
  helperText,
}: any) => {
  const classes = useStyles()

  function HeadingText() {
    const { focused } = useFormControl() || {}

    const headingColor = React.useMemo(() => {
      if (error) {
        return AppErrorColor
      }

      if (focused && !error) {
        return "#007FFF"
      }

      return AppTextColor
    }, [focused])

    return (
      <Typography
        // fontSize={18}
        className={classes.headingStyles}
        color={headingColor}
      >
        {label}
      </Typography>
    )
  }
  return (
    <FormControl style={{ width: "100%" }}>
      <HeadingText />
      <Select
        style={{ width: "100%" }}
        sx={{ backgroundColor: "#fcfcfb" }}
        error={error}
        id={id}
        value={value}
        label={<HeadingText />}
        onChange={onChangeHandler}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {options.map((item: any) => {
          return (
            <MenuItem
              key={item.value}
              value={item.value}
              disabled={item.disabled}
            >
              {item.label}
              {item.badge && (
                <Chip
                  size='small'
                  sx={{ marginLeft: "8px" }}
                  label={item.badge}
                  color='primary'
                  variant='filled'
                />
              )}
            </MenuItem>
          )
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export const projectTypeOptions = [
  {
    value: "townhouse",
    label: "Townhouse",
  },
  {
    value: "apartment",
    label: "apartment",
  },
]

export const amenitiesCategories = [
  {
    value: "Health & Fitness",
    label: "Health & Fitness",
  }, {
    value: "Security & Safety",
    label: "Security & Safety",
  }, {
    value: "BUILDING ACCESS & SERVICES",
    label: "BUILDING ACCESS & SERVICES",
  }, {
    value: "IN-UNIT AMENITIES",
    label: "IN-UNIT AMENITIES",
  }, {
    value: "Food & Beverages",
    label: "Food & Beverages",
  }, {
    value: "Pool",
    label: "Pool",
  }, {
    value: "TECHNOLOGY & CONNECTIVITY",
    label: "TECHNOLOGY & CONNECTIVITY",
  }, {
    value: "LAUNDRY & CLEANING",
    label: "LAUNDRY & CLEANING",
  }, {
    value: "FITNESS",
    label: "FITNESS",
  }, {
    value: "POOLS",
    label: "POOLS",
  }, {
    value: "HOSPITALITY",
    label: "HOSPITALITY",
  }, {
    value: "CONSTRUCTION",
    label: "CONSTRUCTION",
  }, {
    value: "CUISINE",
    label: "CUISINE",
  }, {
    value: "General",
    label: "General",
  }, {
    value: "Health Facility",
    label: "Health Facility",
  }, {
    value: "KIDS AREA",
    label: "KIDS AREA",
  }, {
    value: "CONVENIENCE",
    label: "CONVENIENCE",
  }, {
    value: "Recreation and Entertainment",
    label: "Recreation and Entertainment",
  }, {
    value: "Business",
    label: "Business",
  }, {
    value: "Restaurant Facilities",
    label: "Restaurant Facilities",
  }, {
    value: "Private Locker",
    label: "Private Locker",
  }, {
    value: "Recreation Facility",
    label: "Recreation Facility",
  }, {
    value: "House Keeping",
    label: "House Keeping",
  }, {
    value: "Health Bar",
    label: "Health Bar",
  },
  {
    value: "Villa",
    label: "Villa",
  },
  {
    value: "TownHouse",
    label: "TownHouse",
  },
  {
    value: "PentHouse",
    label: "PentHouse",
  },
  {
    value: "Villa Compound",
    label: "Villa Compound",
  },
  {
    value: "Hotel Apartment",
    label: "Hotel Apartment",
  },
  {
    value: "Residential Plot",
    label: "Residential Plot",
  },
  {
    value: "Residential Floor",
    label: "Residential Floor",
  },
  {
    value: "Residential Building",
    label: "Residential Building",
  },
  {
    value: "Office",
    label: "Office",
  },
  {
    value: "Shop",
    label: "Shop",
  },
  {
    value: "Warehouse",
    label: "Warehouse",
  },
  {
    value: "Labour Camp",
    label: "Labour Camp",
  },
  {
    value: "Commercial Floor",
    label: "Commercial Floor",
  },
  {
    value: "Bulk Unit",
    label: "Bulk Unit",
  },
  {
    value: "Commercial Plot",
    label: "Commercial Plot",
  },
  {
    value: "Commercial Villa",
    label: "Commercial Villa",
  },
  {
    value: "Commercial Building",
    label: "Commercial Building",
  },
  {
    value: "Factory",
    label: "Factory",
  },
  {
    value: "Industrial Land",
    label: "Industrial Land",
  },
  {
    value: "Mixed Use Land",
    label: "Mixed Use Land",
  },
  {
    value: "Showroom",
    label: "Showroom",
  },
  {
    value: "Other Commercial",
    label: "Other Commercial",
  },
]

export const projectTypeCategories = [
  {
    value: "TownHouse",
    label: "TownHouse",
  },
  {
    value: "Deluxe Apartment",
    label: "Deluxe Apartment",
  },
  {
    value: "Apartment",
    label: "Apartment",
  },
  {
    value: "Villa Compound",
    label: "Villa Compound",
  },
  {
    value: "Serviced Apartment",
    label: "Serviced Apartment",
  },
  {
    value: "Hotel Apartment",
    label: "Hotel Apartment",
  },
  {
    value: "Villa",
    label: "Villa",
  },
  {
    value: "Condominiums",
    label: "Condominiums",
  },
  {
    value: "PentHouse",
    label: "PentHouse",
  },
  {
    value: "Shop",
    label: "Shop",
  },
  {
    value: "Showroom",
    label: "Showroom",
  },
  {
    value: "Office",
    label: "Office",
  },
  {
    value: "Building",
    label: "Building",
  },
]

export default DropDown
