import React, { Suspense } from "react"
import { BrowserRouter } from "react-router-dom"
import { I18nProvider } from "../_metronic/i18n/i18nProvider"
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core"
import AuthInit from "./components/auth/redux/AuthInit"
import { Routes } from "./routing/Routes"
import { ThemeProvider } from "@mui/material/styles"


import theme from "app/components/mui-themes/theme";
import { SnackbarProvider } from "notistack";
import GeneralProvider from "./Context/General";
import PinProvider from "./Context/PinState";

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter>
      <PinProvider >
        <SnackbarProvider>
          <GeneralProvider>
            <I18nProvider>
              <ThemeProvider theme={theme}>
                <LayoutProvider>
                  <AuthInit>
                    <Routes />
                  </AuthInit>
                </LayoutProvider>
              </ThemeProvider>
            </I18nProvider>
          </GeneralProvider>
        </SnackbarProvider>
      </PinProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
