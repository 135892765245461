/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserModel } from '../../../../app/components/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { Languages } from './Languages'
import * as auth from '../../../../app/components/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { toAbsoluteUrl } from '../../../helpers'
import Profile from '../../../assets/media/avatars/my.jpg'
import { API as apiHost } from "app/utils/config";
interface Avatar {
  url: string;
}

interface User {
  userAvatar?: Avatar[];
}
const HeaderUserMenu: FC = () => {
  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }
  const storedToken = localStorage.getItem("persist:accessToken");
  const jwtToken = storedToken ? JSON.parse(storedToken).accessToken?.replaceAll('"', "") : "";

  const Portal = process?.env?.LANDLORD_PORTAL

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              src={user?.userAvatar ? `${user?.userAvatar?.[0]?.url}` : Profile}
              alt={user?.fname || 'Imlaak'}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center text-capitalize fs-5'>
              {user?.fname}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{user?.isVerified ? 'verfied' : 'not verified'}</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to='/crafted/account/overview' className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      {user?.role === "landlord" || user?.role === "consultant" ? (
        <div className='menu-item px-5'>
          <a href='/listings' className='menu-link px-5'>
            <span className='menu-text'>My Listings</span>
            <span className='menu-badge'>
              <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
            </span>
          </a>
        </div>
      ) : null}

      <div
        className='menu-item px-5 d-none'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5 d-none'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>
      <div className='menu-item px-5 my-1'>
        <a href={`https://landlord.imlaak.com?token=${jwtToken}`} className='menu-link px-5'>
          Go to Landlord Portal | FSBO
        </a>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div >
  )
}

export { HeaderUserMenu }
