/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { getCSSVariableValue } from '../../../assets/ts/_utils'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidget8: React.FC<Props> = ({ className, chartColor, chartHeight }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chart1Options(chartColor, chartHeight))
    if (chart1) {
      chart1.render()
    }

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 pt-5 pb-3'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Sales</span>
          <span className='text-muted fw-bold fs-7'>Sales Ratio</span>
        </h3>

        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
            <Dropdown1 />
          </div> */}

      </div>
      <div className='card-body p-0 d-flex flex-column-reverse'>

        <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
        {/* end::Chart */}

        <div className='mt-5 px-8 pt-10 d-flex flex-column'>
          {/* begin::Item */}
          <div className='d-flex flex-stack mb-5 py-4'>
            {/* begin::Section */}
            <div className='d-flex align-items-center me-2'>
              {/* begin::Symbol */}
              <div className='symbol symbol-50px me-3'>
                <div className='symbol-label bg-light'>
                  <img
                    src={toAbsoluteUrl('/media/icons/duotune/communication/com013.svg')}
                    alt=''
                    className='h-50'
                  />
                </div>
              </div>
              {/* end::Symbol */}

              {/* begin::Title */}
              <div>
                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                  Top Property Areas
                </a>
                <div className='fs-7 text-muted fw-bold mt-1'>DHA PHASE 6</div>
              </div>
              {/* end::Title */}
            </div>
            {/* end::Section */}

            {/* begin::Label */}
            {/* <div className='badge badge-light fw-bold py-4 px-3'>82+</div> */}
            {/* end::Label */}
          </div>
          {/* end::Item */}

          <div className='d-flex flex-stack mb-5 py-4'>
            {/* begin::Section */}
            <div className='d-flex align-items-center me-2'>
              {/* begin::Symbol */}
              <div className='symbol symbol-50px me-3'>
                <div className='symbol-label bg-light'>
                  <img
                    src={toAbsoluteUrl('/media/icons/duotune/general/gen056.svg')}
                    alt=''
                    className='h-50'
                  />
                </div>
              </div>

              <div>
                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                  Top Sales
                </a>
                <div className='fs-7 text-muted fw-bold mt-1'>GULBERG III</div>
              </div>
            </div>
            {/* <div className='badge badge-light fw-bold py-4 px-3'>1231+</div> */}
          </div>

          <div className='d-flex flex-stack mb-5 py-4'>
            {/* begin::Section */}
            <div className='d-flex align-items-center me-2'>
              {/* begin::Symbol */}
              <div className='symbol symbol-50px me-3'>
                <div className='symbol-label bg-light'>
                  <img
                    src={toAbsoluteUrl('/media/icons/duotune/abstract/abs029.svg')}
                    alt=''
                    className='h-50'
                  />
                </div>
              </div>

              <div>
                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                  Expected Sales
                </a>
                <div className='fs-7 text-muted fw-bold mt-1'>BAHARIA TOWN</div>
              </div>
            </div>
            {/* <div className='badge badge-light fw-bold py-4 px-3'>1231+</div> */}
          </div>
          {/* end::Item */}
        </div>
      </div>
    </div>
  )
}

const chart1Options = (chartColor: string, chartHeight: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-primary-800')
  const strokeColor = getCSSVariableValue('--bs-primary-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
  const barColor = getCSSVariableValue('--bs-primary')

  return {
    series: [
      {
        color: '#263c76',
        name: 'Net Profit',
        data: [30, 30, 60, 25, 25, 40],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.375,
        stops: [25, 50, 100],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [barColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: 65,
      labels: {
        show: false,
        style: {
          colors: [labelColor],
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return 'Rs. ' + val + ' Million'
        },
      },
    },
    // colors: [lightColor],
    markers: {
      colors: ["#263c67"],
      strokeColors: ["#263c67"],
      strokeWidth: 3,
    },
  }
}

export { MixedWidget8 }
