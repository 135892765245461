import { capitalizeFirstLetter, IconRender } from "app/components/Table";
import Table from "app/components/Table";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import EditAgent from "./CreateUpdate";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Button } from "@mui/material";
import { PageTitle, useLayout } from "_metronic/layout/core";
import { useEffect } from "react";
import { RootState } from "setup";
import { shallowEqual, useSelector } from "react-redux";

const columns = (iconClickHandler: any) => [
    {
        name: "Landlord's Name",
        key: "fname",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    },
    {
        name: "Contact",
        key: "contact",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    },
    {
        name: "Email",
        key: "email",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => (value)
    },
    {
        name: "Action",
        key: "action",
        component: (row: any) => (
            <>
                <select
                    className="form-select form-select-solid cursor-pointer text-white bg-warning"
                    aria-label="Select example"
                    onChange={(event) => iconClickHandler(row, event.target.value)}
                >
                    <option className="text-white bg-primary" selected disabled>Select</option>
                    <option className="text-black bg-light" value="requested">Approve</option>
                    <option className="text-black bg-light" value="rejected">Reject</option>
                </select>
            </>
        )
    },
];

export function ConsultantLandlordsApproval() {
    const { getAll, create, createLandlord, update, deleteConsultantId, LandlordApprovalRequest } = APIS.users;
    const {
        data,
        loading,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count,
        open,
        setOpen,
        modalMode,
        updateFunction,
        createFunction,
        createLandlordFunction,
        current,
        iconClickHandler,
        setCurrent,
        deleteConsultantFunction,
    } = useFetchData({ getAll, create, createLandlord, update, deleteConsultantId, LandlordApprovalRequest });
    const { setClickButtonHandler } = useLayout();

    useEffect(() => {
        setClickButtonHandler(() => () => {
            setOpen(true);
            setCurrent({});
        });
    }, []);

    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    // Filter data to only show consultant's Landlords
    const filteredData = data.filter((row: any) => row?.role === "landlord" && row?.approvalStatus === "requested" && row?.consultant.toString() === user._id);
    const landlordCount = filteredData.length;
    return (
        <>
            <PageTitle>Landlord Approval</PageTitle>
            <div className="pb-5">
                <Table
                    columns={columns(iconClickHandler("/myLandlords"))}
                    data={filteredData}
                    loading={loading}
                    onRowClick={(row: any, type: any) =>
                        iconClickHandler("/myLandlords")(row, type)
                    }
                    isPaginationRequired
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    total={landlordCount}
                />
            </div>
        </>
    );
}
