/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { MouseEventHandler, useEffect, useState } from "react";
import { StatisticsWidget6 } from "..";

import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  AppTextColor,
  AppErrorColor,
} from "../../../../app/components/theme/Colors";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import moment from "moment";
import useFetchData from "app/hooks/useFetchData ";
import { APIS } from "Constants";
import NumberFormatter from "app/shared/Input/NumberFormatter";

type Props = {
  className: string;
  properties: any;
  requestEvaluation?: any;
  myEval?: any;
};
const useStyles = makeStyles(() => ({
  headingStyles: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
    // margin: '0px 0px 10px 0px !important',
  },
}));
const ListsWidget2: React.FC<Props> = ({
  className,
  properties,
  requestEvaluation = () => { },
  myEval = [],
}) => {
  const [activeProperty, setActiveProperty]: any = useState();
  const [currentProperty, setCurrentProperty]: any = useState({});
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [currentValuation, setCurrentValuation]: any = useState({});


  const onChangeHandler = async (event: any) => {
    setActiveProperty(event.target.value);
    const thisProp = await properties.filter((val: any) => val?._id === event.target.value)?.[0];
    const thisVal = await myEval.filter((val: any) => val?.inventory?._id === event.target.value)?.[0];
    setCurrentValuation(thisVal);
    setCurrentProperty(thisProp);
  };
  let currentPrice = 0;

  if (currentValuation) {
    currentPrice = currentValuation?.currentEvaluation && currentValuation?.inventory && currentValuation?.inventory?.price
      ? currentValuation?.currentEvaluation - currentValuation.inventory?.price
      : 0;
  }
  const formatNumber = (value: number, decimals = 2): string => {
    if (value >= 1e9) {
      return `PKR ${(value / 1e9).toFixed(decimals)} Billion`;
    } else if (value >= 1e6) {
      return `PKR ${(value / 1e6).toFixed(decimals)} Million`;
    } else if (value >= 1e3) {
      return `PKR ${(value / 1e3).toFixed(decimals)} K`;
    } else {
      return value?.toString();
    }
  };
  const formattedNumber = formatNumber(currentPrice)
  // Project LookUp
  const { getAllNames } = APIS.ProjectName;
  const { projectNameData } = useFetchData({ getAllNames });
  const [projectLookup, setProjectLookup] = useState<any>({});

  useEffect(() => {
    const lookup: any = {};
    projectNameData.forEach((row: any) => {
      lookup[row?._id] = row?.projectName;
    });
    setProjectLookup(lookup);
  }, [projectNameData]);

  // currency Format

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0">
        <h3 className="card-title fw-bolder text-dark">Property Portfolio</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      <Grid container spacing={2} className="mx-2">
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label" color='secondary'>Select Property</InputLabel> */}

            <Typography
              // fontSize={18}
              className={classes.headingStyles}
              color={"#0f2e5a"}
            >
              Select Property
            </Typography>
            <Select
              // labelId="demo-simple-select-label"
              sx={{ backgroundColor: "#0f2e5a", color: "#fff" }}
              id={"property type"}
              value={activeProperty}
              placeholder="Select Property"
              label={"Select Property"}
              onChange={onChangeHandler}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem selected disabled>Select Property</MenuItem>
              {properties?.map((property: any) => (
                <MenuItem key={property?._id} value={property?._id} className="text-capitalize">
                  {property?.ProjectSelecting === 'Registered_project' ? (
                    `${property?.title} in ${projectLookup[property?.projectName]} on ${property?.floorNo} Floor`
                  ) : (
                    `${property?.title} on ${property?.floorNo} Floor`
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          {activeProperty ? (
            <>
              <Typography
                className={classes.headingStyles}
                color={"#0f2e5a"}
              >
                Request Evaluation
              </Typography>
              {loading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "relative",
                    bottom: "18px",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <Button
                  sx={{ height: "51px" }}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading || currentValuation?.status === "requested"}
                  onClick={() => {
                    setLoading(true);
                    requestEvaluation(activeProperty)
                      .then((data: any) => {
                        if (data.status === 200) {
                          Swal.fire({
                            title: "Evaluation Request",
                            text: "Successfully Sent",
                            icon: "success",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Okay",
                          });
                        }
                      })
                      .finally(() => setLoading(false));
                  }}
                >
                  {currentValuation?.status === "requested"
                    ? "Requested"
                    : "Request Evaluation"}
                </Button>
              )}
            </>
          ) : null}
        </Grid>
      </Grid>
      <p className="mx-7 mb-xl-4 mt-5 fw-bold fs-25">
        {/* <b style={{ color: "#50CD89" }}>
          {currentValuation?.status === "requested"
            ? `(You have requested a new evaluation. Your Consultant is looking into your property for evaluation.)`
            : null}{" "}
        </b> */}
        {currentValuation?.status === "requested" ? (
          <Alert severity="warning" onClose={() => { }} style={{ fontWeight: '700', fontSize: '14px', width: '95%' }}>
            (You have already requested a new evaluation. Your Consultant is looking into your property for evaluation.)
            <br />
            {currentValuation?.updatedAt ? "Last Requested at " + moment(currentValuation?.updatedAt).format("DD-MMM-YYYY hh:mm a") : null}
          </Alert>
        ) : (
          <p className="mb-xl-4 mt-3 fw-bold fs-20 text-success">
            {currentValuation?.updatedAt ? "Last Evaluated at " + moment(currentValuation?.updatedAt).format("DD-MMM-YYYY hh:mm a") : null}
          </p>
        )}
      </p>
      <StatisticsWidget6
        className="mb-xl-0"
        color={`${currentValuation?.currentEvaluation && currentValuation?.inventory?.price !== undefined
          ? (currentValuation.currentEvaluation - currentValuation.inventory.price >= 0 ? "success" : "danger")
          : "success"}`}
        title={`${formattedNumber}`}
        description={
          `${currentValuation?.currentEvaluation && currentValuation?.inventory?.price !== undefined
            ? (currentValuation.currentEvaluation - currentValuation.inventory.price >= 0 ? "Net Gain" : "Net Loss")
            : "Net Gain"}`
          // : "Evaluation Pending"}`
        }

        progress={`${currentValuation?.netGain || 0}%`}
      />
      <div className="separator my-2"></div>
      <span
        className=" mx-7 mb-xl-4  mt-0
           fw-bold fs-25"
      >
        <b>
          PKR{" "}
          {/* {new Intl.NumberFormat().format( parseInt( currentValuation?.currentEvaluation || currentProperty?.price || 0 ) )} */}
          <NumberFormatter value={currentValuation?.currentEvaluation || currentProperty?.price || 0} />
          {" "}
        </b>
      </span>
      <span className="mx-7 mb-xl-4 mt-n2 fw-bolder fs-20">
        Current Evaluation
      </span>
      <div className="separator my-2"></div>

      <span
        className=" mx-7 mb-xl-4  mt-0
           fw-bold fs-25"
      >
        <b>
          PKR{" "}
          {/* {new Intl.NumberFormat().format(
            parseInt(currentProperty?.price || 0)
          )} */}
          <NumberFormatter value={currentProperty?.price || 0} />
          {" "}
        </b>{" "}
      </span>
      <span className="mx-7 mb-xl-4  mt-n2 fw-bolder fs-25">
        Purchase Price{" "}
      </span>
      <div className="my-2"></div>

      {/* end::Body */}
    </div>
  );
};

export { ListsWidget2 };
