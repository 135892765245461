import React, { useEffect, useState } from "react";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
    PageLink,
    PageTitle,
    usePageData,
} from "../../../_metronic/layout/core";
import { PropertyHeader } from "./PropertyHeader";
import Table, { capitalizeFirstLetter, IconRender } from "../Table";
import { UpdateConsultantPropertyValues, addConsultantPropertyInventory, deleteConsultantProperty, getConsultantInventory, markingPropertySold } from "./redux/PropertyCrud";
import * as AuthRedux from "../auth/redux/AuthRedux";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { createProperty } from "./redux/PropertyCrud";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "_metronic/helpers/AssetHelpers";
import useFetchData from "app/hooks/useFetchData ";
import { APIS } from "Constants";
import CreateConsultantProperty from "./components/CreateConsultantProperty";
import { EyeIcon } from "@heroicons/react/solid";
import UpdateConsultantProperty from "./components/UpdateConsultantProperty";
import PropertySold from "./components/PropertySold";

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
    },
];
const columns = (iconClickHandler: any, projectLookup: any) => {
    return [
        {
            name: "Building",
            key: "projectName",
            numaric: false,
            isSortable: true,
            renderContent: (value: any) => capitalizeFirstLetter(
                <div className="overflow-ellipsis" title={value}>
                    <div className="overflow-ellipsis text-capitalize" title={value}>
                        {projectLookup[value] || value}
                    </div>
                </div>
            ),
        }, {
            name: "Category",
            key: "category",
            numaric: false,
            isSortable: true,
            renderContent: (value: any) => capitalizeFirstLetter(value),
        }, {
            name: "Sub Category",
            key: "subCategory",
            numaric: false,
            isSortable: true,
            renderContent: (value: any) => capitalizeFirstLetter(value),
        }, {
            name: "Floor",
            key: "floorNo",
            numaric: false,
            isSortable: true,
            renderContent: (value: any) => capitalizeFirstLetter(value),
        }, {
            name: "Apartment No",
            key: "appartmentNo",
            numaric: false,
            isSortable: true,
            renderContent: (value: any) => capitalizeFirstLetter(value),
        }, {
            name: "Mark",
            key: "action",
            component: (row: any) => (
                <>
                    <button
                        className="btn text-white"
                        style={{ background: "#263c67" }} value='sold'
                        onClick={(event) => {
                            const target = event.target as HTMLButtonElement;
                            iconClickHandler(row, target.value)
                        }}
                    >
                        Mark as Sold
                    </button>
                </>
            ),
        }, {
            name: "View",
            key: "action",
            component: (row: any) => (
                <>
                    <Link to={`/propertyDetail/${row._id}`}>
                        <EyeIcon style={{ width: "20px" }} />
                    </Link>
                    <button
                        className="btn text-white d-none"
                        style={{ background: "#263c67" }} value='image'
                        onClick={(event) => {
                            const target = event.target as HTMLButtonElement;
                            iconClickHandler(row, target.value)
                        }}
                    >
                        Image
                    </button>                </>
            ),
        }, {
            name: "Action",
            key: "action",
            component: (row: any) => (
                <>
                    {/* {IconRender(InfoOutlinedIcon, iconClickHandler)} */}
                    {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "edit")}
                    {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, "delete")}
                </>
            ),
        },
    ];
};

const ConsultantPropertyPage: React.FC = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open4, setOpen4] = useState(false);

    const { getAllNames } = APIS.ProjectName;
    const { projectNameData } = useFetchData({ getAllNames });
    const [projectLookup, setProjectLookup] = useState<any>({});

    useEffect(() => {
        const lookup: any = {};
        projectNameData.forEach((row: any) => {
            lookup[row?._id] = row?.projectName;
        });
        setProjectLookup(lookup);
    }, [projectNameData]);

    const [currentListing, setCurrentListing]: any = useState(0);
    const listing: any = useSelector<RootState>(
        ({ auth }) => auth.listing,
        shallowEqual
    );
    const [myProperties, setMyProperties] = useState(listing);
    const [accessToken, setAccessToken]: any = useState("");
    const [modalMode, setModalMode]: any = useState("edit");

    const getToken = async () => {
        let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
        accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
        setAccessToken(accessToken);
    };

    // Consultant Properties
    useEffect(() => {
        getConsultantInventory(accessToken)
            .then(response => {
                const filteredData = response.data?.properties?.filter((row: any) => row?.isDeleted === false);
                const sortedData = filteredData.sort((a: any, b: any) => {
                    const dateA = new Date(a.updatedAt);
                    const dateB = new Date(b.updatedAt);
                    return dateB.getTime() - dateA.getTime();
                });
                setMyProperties(sortedData);
                setCount(filteredData.length);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    }, []);

    const onClickCreate = () => {
        setOpen(true);
        setCurrentListing([]);
        setModalMode("create");
    };

    useEffect(() => {
        document
            .getElementById("create-button")
            ?.addEventListener("click", onClickCreate);
    }, []);

    const iconClickHandler = (row: any, type: any) => {
        if (type === "edit") {
            setModalMode("edit");
            setCurrentListing(row);
            setOpen2(true);
        } else if (type === "add") {
            setModalMode("add");
            setOpen(true);
        } else if (type === "delete") {
            Swal.fire({
                title: "",
                text: "Are you sure you want to delete this Property",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((status) => {
                if (status.isConfirmed) {
                    setLoading(true);
                    deleteConsultantProperty(row._id, accessToken)
                        .then(({ data, status }) => {
                            let text = "";
                            let icon: any = "error";
                            if (status === 200) {
                                text = "Successfully Deleted";
                                icon = "success";
                            } else {
                                text = data.message;
                                icon = "error";
                            }
                            Swal.fire({
                                title: "Property Deleted",
                                text: text,
                                icon: icon,
                                showCancelButton: false,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: "#d33",
                                confirmButtonText: "Ok",
                            });
                            const propertyAfterDelete = getConsultantInventory(accessToken) as any
                            setMyProperties(propertyAfterDelete?.properties)
                            setCount(propertyAfterDelete?.properties?.length)
                            dispatch(AuthRedux.actions.setMyListings(propertyAfterDelete?.properties));

                        })
                        .catch(() => { })
                        .finally(() => setLoading(false));
                }
            });
        } else if (type === "sold") {
            Swal.fire({
                title: "Property Sold!",
                text: "Are you sure you want to mark this property as sold?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((status) => {
                if (status.isConfirmed) {
                    setModalMode("sold");
                    setCurrentListing(row);
                    setOpen4(true);
                }
            });
        }
    };
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    const { getAll } = APIS.users;
    const { data } = useFetchData({ getAll });
    const consultantLandlords = data.filter((row: any) => row?.consultant === user?._id && row?.role === 'landlord')
    const consultantLandlordIds = consultantLandlords?.map((landlord: any) => landlord._id);

    const createPropertyFunction = (values: any) => {
        setLoading(true);
        setOpen(false);
        values = { ...values };
        if (values?.ProjectSelecting === "Registered_project") {
            addConsultantPropertyInventory(values, accessToken)
                .then(({ data }) => {
                    if (data?.success) {
                        Swal.fire({
                            title: "",
                            text: "Property added Successfully.",
                            icon: "success",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "OK",
                        })
                        const filteredData = data?.property?.filter((row: any) => { return consultantLandlordIds.includes(row?.landlordId) });
                        dispatch(AuthRedux.actions.setMyListings(filteredData));
                        setMyProperties(filteredData)
                        setCount(filteredData?.length);
                    }
                })
                .catch(() => { })
                .finally(() => setLoading(false));
        } else {
            createProperty(values, accessToken)
                .then(({ data }) => {
                    if (data?.success) {
                        Swal.fire({
                            title: "",
                            text: "Property added Successfully.",
                            icon: "success",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "OK",
                        })
                        const filteredData = data?.property?.filter((row: any) => { return consultantLandlordIds.includes(row.landlordId) });
                        dispatch(AuthRedux.actions.setMyListings(filteredData));
                        setMyProperties(filteredData)
                        setCount(filteredData?.length);
                    }
                })
                .catch(() => { })
                .finally(() => setLoading(false));
        }
    };

    const updatePropertyFunction = (values: any) => {
        setLoading(true);
        UpdateConsultantPropertyValues(values, currentListing?._id, accessToken)
            .then(({ data }) => {
                if (data?.success) {
                    Swal.fire({
                        title: "",
                        text: "Property updated Successfully.",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "OK",
                    })
                    setOpen2(false);
                    const filteredData = data?.property?.filter((row: any) => { return consultantLandlordIds.includes(row?.landlordId) });
                    dispatch(AuthRedux.actions.setMyListings(filteredData));
                    setMyProperties(filteredData)
                    setCount(filteredData?.length);
                }
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };

    const soldPropertyFunction = (values: any) => {
        setLoading(true);
        markingPropertySold(values, currentListing?._id, accessToken)
            .then(({ data }) => {
                if (data?.success) {
                    Swal.fire({
                        title: "Property Marked",
                        text: "Property marked as sold Successfully.",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "OK",
                    })
                    const filteredData = data?.property?.filter((row: any) => { return consultantLandlordIds.includes(row?.landlordId) });
                    dispatch(AuthRedux.actions.setMyListings(filteredData));
                    setMyProperties(filteredData)
                    setCount(filteredData?.length);
                    setOpen4(false);
                }
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <PageTitle>Property</PageTitle>
            {user?.status === "approved" ? (
                <div className="pb-5">
                    <Table
                        columns={columns(iconClickHandler, projectLookup)}
                        data={myProperties}
                        loading={loading}
                        onRowClick={(row: any, type: any) => iconClickHandler(row, type)}
                        isPaginationRequired
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        total={count}
                    />
                    <CreateConsultantProperty
                        setOpen={setOpen}
                        open={open}
                        loading={loading}
                        updateProperty={
                            modalMode === "add"
                                ? createPropertyFunction
                                : createPropertyFunction
                        }
                        modalMode={modalMode}
                        property={currentListing}
                    />
                    <UpdateConsultantProperty
                        setOpen={setOpen2}
                        open={open2}
                        loading={loading}
                        updateProperty={
                            modalMode === "edit"
                                ? updatePropertyFunction
                                : updatePropertyFunction
                        }
                        modalMode={modalMode}
                        property={currentListing}
                    />
                    <PropertySold
                        setOpen={setOpen4}
                        open={open4}
                        loading={loading}
                        updateProperty={
                            modalMode === "sold"
                                ? soldPropertyFunction
                                : ''
                        }
                        modalMode={modalMode}
                        property={currentListing}
                    />
                </div>
            ) : (
                <div className="card-body p-0 ">
                    {/* begin::Chart */}
                    <div className="alert alert-warning p-5 mt-5 mx-10" role="alert">
                        This is a warning alert{" "}
                        <h3 className="text-danger">
                            Your account is not approved you cannot Post Property!!.
                        </h3>
                    </div>
                    {/* end::Chart */}
                    {/* begin::Stats */}
                    <div className="card-p mt-10 position-relative d-flex justify-content-center">
                        <img src={toAbsoluteUrl("/media/illustrations/sketchy-1/57.svg")} />

                        {/* end::Row */}
                    </div>
                    <span className="d-flex justify-content-center fw-bold fs-4 mb-1 ">
                        No Record Found
                    </span>
                    {/* <span className='d-flex justify-content-center fw-bold fs-4 text-muted mb-5 '>Submit your application to Imlaak support for further assistance</span>  */}
                    {/* end::Stats */}
                </div>
            )}
        </>
    );
};

export default ConsultantPropertyPage;
