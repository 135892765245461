import { useEffect, useState, useMemo, useRef } from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    FormControl,
    FormHelperText,
    Chip,
    Paper,
    Skeleton,
    MenuItem,
    Select,
    Typography,
    FormControlLabel,
    useFormControl,
    Switch,
} from "@mui/material";
import Modal from "../../Modal";
import { City, State, Country } from "country-state-city"
import CustomInput from "../../Inputs";
import { useFormik } from "formik";
import * as yup from "yup";
import JoditEditor from "jodit-react";
import { makeStyles } from "@mui/styles";
import * as AuthRedux from "../../auth/redux/AuthRedux";
import Loader from "../../Loader";
import useFetchData from "app/hooks/useFetchData ";
import { uniqBy } from "lodash";
import { APIS } from "Constants";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";


interface InventoryItem {
    projectName: string;
    appartmentNo: string;
    floorNo: string;
    category: string;
    subCategory: string;
    title: string;
    _id: string;
    consultantId: string;
    isListed: boolean;
    projectSelecting: string;
    description: string;
}

const validationSchema = yup.object({
    // projectName: yup.string().required("Floor No is required"),
    // floorNo: yup.string().required("Floor No is required"),
    // apartmentNo: yup.number().required("Apartment No is required"),
    // salePrice: yup.number().required("Sale price is required"),
});

const useStyles = makeStyles(() => ({
    heading: {
        background: "#263c67",
        color: "#fff",
        fontWeight: "700 !important",
    },
    headingStyles: {
        fontSize: "14px !important",
        fontWeight: "700 !important",
    },
    description: {
        padding: "8.5px 14px",
        width: "100%",
        pointerEvents: "none",
        border: "1px solid",
        borderColor: "#093d5c",
        borderRadius: "5px",
        height: "45px",
    },
}));

type PropertySold = {
    open: any;
    setOpen: any;
    property?: any;
    AddProperty?: any;
    loading?: boolean;
    updateProperty?: any;
    modalMode?: any;
    error?: any;
    label?: string;
    value?: string;
};

const PropertySold = ({
    open,
    setOpen,
    property,
    loading,
    modalMode,
    updateProperty,
}: PropertySold) => {
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );

    const formik = useFormik({
        initialValues: {
            category: property?.category || "",
            subCategory: property?.subCategory || "",
            owner: [
                {
                    soldPrice: "",
                    soldDate: "",
                },
            ],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const { owner, } = values;
            const newValues = { owner, sold: true, isListed: false, }
            updateProperty(newValues);
        },
    });


    return (
        <Modal
            setOpen={() => setOpen(!open)}
            open={open}
            title={
                modalMode === "sold"
                    ? ` Mark as Sold Property`
                    : "Empty Property"
            }
            cancelButtonTitle="Cancel"
            acceptButtonVarient="contained"
            acceptButtonTitle={
                modalMode === "sold"
                    ? ` Mark as Sold Property`
                    : "Empty Property"
            }
            handleAccept={() => formik.handleSubmit()}
            handleClose={() => formik.resetForm()}
            loading={loading}
            isActionable={true}
        >
            {loading ? (
                <Box
                    sx={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        left: "50%",
                        top: "30%",
                    }}
                >
                    <Loader top='"60%' left='"50%' />
                </Box>
            ) : null}
            <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <CustomInput
                            id="title"
                            label="Title *"
                            value={property.title}
                            type="text"
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <CustomInput
                            id="category"
                            label="Category *"
                            value={property.category}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <CustomInput
                            id="subCategory"
                            label="Sub-Category *"
                            value={property.subCategory}
                            type="text"
                            disabled
                        />
                    </Grid>
                    {formik.values.owner.map((owner, index) => (
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id={`owner.soldPrice${index}`}
                                    label="Selling Price *"
                                    placeholder=""
                                    value={owner?.soldPrice}
                                    type="number"
                                    onChangeHandler={(event: any) => {
                                        const newOwners = [...formik.values.owner];
                                        newOwners[index].soldPrice = event.target.value;
                                        formik.setFieldValue("owner", newOwners);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id={`owner.soldDate${index}`}
                                    label="Selling Date*"
                                    placeholder="Purchasing Date"
                                    value={owner?.soldDate}
                                    type="date"
                                    onChangeHandler={(event: any) => {
                                        const newOwners = [...formik.values.owner];
                                        newOwners[index].soldDate = event.target.value;
                                        formik.setFieldValue("owner", newOwners);
                                    }}
                                />
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Box>
        </Modal >
    );
};

export default PropertySold;
