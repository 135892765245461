/* eslint-disable jsx-a11y/anchor-is-valid */
import { capitalizeFirstLetter, IconRender } from "app/components/Table";
import Table from "app/components/Table";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import EditBlockLocation from "./CreateUpdate";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Button } from "@mui/material";
import { useLayout } from "_metronic/layout/core";
import { useEffect } from "react";
const columns = (iconClickHandler: any) => [
  {
    name: "Country",
    key: "countryId",
    numaric: false,
    isSortable: true,
    renderContent: (value: any) => capitalizeFirstLetter(value?.countryLocation),
  },
  {
    name: "Province",
    key: "provinceId",
    numaric: false,
    isSortable: true,
    renderContent: (value: any) => capitalizeFirstLetter(value.provinceLocation),
  },
  {
    name: "City",
    key: "cityId",
    numaric: false,
    isSortable: true,
    renderContent: (value: any) => capitalizeFirstLetter(value.cityLocation),
  },
  {
    name: "Community ",
    key: "communityId",
    numaric: false,
    isSortable: true,
    renderContent: (value: any) => capitalizeFirstLetter(value.communityLocation),
  },
  // {
  //   name: "Sector",
  //   key: "action",
  //   component: (row: any) => (
  //     <>
  //       {row.sectorPhaseLocation} {row.sectorPhaseNumber}
  //     </>
  //   ),
  // },
  {
    name: "Block/Project",
    key: "blockProject",
    numaric: false,
    isSortable: true,
    renderContent: (value: any) => capitalizeFirstLetter(value),
  },
  {
    name: "Block",
    key: "blockLocation",
    numaric: false,
    isSortable: true,
    renderContent: (value: any) => capitalizeFirstLetter(value),
  },
  {
    name: "Action",
    key: "action",
    component: (row: any) => (
      <>
        {/* {IconRender(InfoOutlinedIcon, iconClickHandler)} */}
        {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "EDIT")}
        {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, "delete")}
      </>
    ),
  },
];

export function Details() {
  const { getAll, create, update, delete: deleteUrl } = APIS.blockLocations;
  const {
    data,
    loading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    open,
    setOpen,
    modalMode,
    updateFunction,
    createFunction,
    current,
    iconClickHandler,
    setCurrent,
  } = useFetchData({ getAll, create, update, deleteUrl });
  console.log(data)
  const { setClickButtonHandler } = useLayout();

  useEffect(() => {
    setClickButtonHandler(() => () => {
      setOpen(true);
      setCurrent({});
    });
  }, []);

  return (
    <>
      <Table
        columns={columns(iconClickHandler("/block/edit"))}
        data={data}
        loading={loading}
        onRowClick={(row: any, type: any) =>
          iconClickHandler("/block/edit")(row, type)
        }
        isPaginationRequired
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        total={count}
      />
      <EditBlockLocation
        setOpen={setOpen}
        open={open}
        loading={loading}
        update={modalMode === "EDIT" ? updateFunction : createFunction}
        type={modalMode}
        data={current}
      />
    </>
  );
}
