import React from 'react';

interface NumberFormatterProps {
    value: any;
    decimals?: any; // Optional: number of decimal places
}

const NumberFormatter: React.FC<NumberFormatterProps> = ({ value, decimals = 2 }) => {
    const formatNumber = (value: any): any => {
        if (value >= 1e9) {
            return `${(value / 1e9).toFixed(decimals)} Billion`;
        } else if (value >= 1e6) {
            return `${(value / 1e6).toFixed(decimals)} Million`;
        } else if (value >= 1e3) {
            return `${(value / 1e3).toFixed(decimals)} K`;
        } else {
            return value?.toString();
        }
    };

    return <>{formatNumber(value)}</>;
};

export default NumberFormatter;
