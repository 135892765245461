import { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { API as apiHost } from "app/utils/config";
import Swal from "sweetalert2";
import { createContext } from "react";

interface IPinContext {
  pins: any;
  getPins: any;
  addPin: any;
  onSearch: any;
  getMe: any;
  updateUserStatus: any;
  getallUser: any;
  userData: any;
  deletePin: any;
  users: any;
  loading: boolean;
  setLoading: any;
  firstLoad: boolean;
  pageDetails: any;
  setFirstLoad: any;
  currentPin: any;
  currentUser: any;
  detailsModalOpen: boolean;
  setCurrentUser: any;
  clickedMarker: any;
  setDetailsModalOpen: any;
  getCurrentMap: any;
  mapChangeHandler: any;
  filter: any;
  dispatchFilter: any;
  applyFilters: any;
  getPhaseforDropdown: any;
  dropDownphases: any;
}

const updateCurrentUrl = ({ value }: any) => {
  const nextURL = `/plotListings/?openedMap=${value}`;
  const nextTitle = "";
  const nextState = { additionalInformation: "" };
  // This will create a new entry in the browser's history, without reloading
  window.history.pushState(nextState, nextTitle, nextURL);
};

const PinContextInitial = {
  pins: {},
  getPins: () => { },
  addPin: () => { },
  onSearch: () => { },
  getMe: () => { },
  updateUserStatus: () => { },
  getallUser: () => { },
  userData: {},
  deletePin: () => { },
  users: [],
  loading: false,
  setLoading: () => { },
  firstLoad: false,
  pageDetails: {},
  setFirstLoad: () => { },
  currentPin: "",
  currentUser: {},
  detailsModalOpen: false,
  setCurrentUser: () => { },
  clickedMarker: () => { },
  setDetailsModalOpen: () => { },
  getCurrentMap: () => { },
  mapChangeHandler: () => { },
  filter: {},
  dispatchFilter: () => { },
  applyFilters: () => { },
  getPhaseforDropdown: () => { },
  dropDownphases: [],
};

export const PinContext = createContext<IPinContext>(PinContextInitial);

function reducer(state: any, action: any) {
  switch (action.type) {
    case "updateProperty":
      return { ...state, plotCat: action.value };
    case "updateArea":
      return { ...state, areaCat: action.value };
    case "updatePrice":
      return { ...state, price: action.value };
    case "decrement":
      return { count: state.count - 1 };

    default:
      throw new Error();
  }
}

const initialState: any = {
  price: [0, 0],
};

const PinProvider = (props: any) => {
  // const token = localStorage.getItem('accessToken')
  // const [loading, setLoading] = useState(false)
  const [pins, setPins] = useState([]);
  const [userData, setUser] = useState("");
  const [filter, dispatchFilter] = useReducer(reducer, initialState);
  const [dropDownphases, setDropDownphases] = useState([]);

  // map changing logic
  const [pageDetails, setPageDetails] = useState({
    text: "Imlaak Plot Listing Site",
    value: "",
    mapName: "Phase 1 and 2",
  });

  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [currentPin, setCurrentPin] = useState({});
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [evaluation, setEvaluation] = useState([]);

  const applyFilters = () => {
    getPins(filter);
  };

  useEffect(() => {
    setTimeout(() => {
      setFirstLoad(false);
    }, 1500);
  }, []);

  const clickedMarker = (data: any, user: any) => {
    setCurrentPin(data);
    setCurrentUser(user);
    setDetailsModalOpen(true);
  };

  const mapChangeHandler = (value: any) => {
    // debugger
    setLoading(true);
    setPageDetails(value);
    updateCurrentUrl(value);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const getCurrentMap = (data: any) => {
    // debugger
    if (window.location.pathname.includes("/plotListings")) {
      // debugger
      // console.log("home page")
      const activeMap = new URLSearchParams(window.location.search).get(
        "openedMap"
      );
      let updatedMapView;
      if (activeMap) {
        // debugger
        updatedMapView = data?.[0].lahore.filter(
          (item: any) => item.imageName === activeMap
        );
        if (updatedMapView?.length === 0) {
          updatedMapView = data?.[1].bahawalpur.filter(
            (item: any) => item.imageName === activeMap
          );
        }
        if (updatedMapView?.length === 0) {
          updatedMapView = data?.[2].multan.filter(
            (item: any) => item.imageName === activeMap
          );
        }
        if (updatedMapView?.length === 0) {
          updatedMapView = data?.[3].gujrawala.filter(
            (item: any) => item.imageName === activeMap
          );
        }
        if (updatedMapView?.length === 0) {
          updatedMapView = data?.[0].lahore;
        }
      } else {
        updatedMapView = data?.[0].lahore;
        // debugger
      }
      mapChangeHandler({
        text: updatedMapView?.[0].imageLabel,
        value: updatedMapView?.[0].imageName,
        image: updatedMapView?.[0].imageUrl,
      });
    }
  };

  // end map changing logic

  //fetch all note
  const getPins = async (query: any) => {
    //todo api call
    const filters = JSON.stringify(query);
    const response = await axios.get(`${apiHost}/api/pins/getAllPins`, {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${tk}`
      },
      params: { filters: filters },
    });
    setPins(response.data);
    return response;
  };

  const onSearch = async (text: any) => {
    if (text === "") {
      getPins({});
    }
    if (text !== "") {
      let filterPins = pins.filter((pin: any) => pin.plotno.includes(text));
      setPins(filterPins);
    }
  };

  //add a PIN
  const addPin = async (pinData: any) => {
    // const tk = localStorage.getItem('accessToken')
    const { accessToken } = JSON.parse(
      localStorage.getItem("persist:accessToken") || "{}"
    );
    const accessToken1 = accessToken.replaceAll('"', "");
    const response = await fetch(`${apiHost}/apipins/createPin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken1}`,
      },
      body: JSON.stringify(pinData),
    });
    const pin = await response.json();
    if (response.status === 200) {
      Swal.fire({
        title: "Pin addition",
        text: "You have successfully added a ",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    }
    return pin;
  };

  //DELETE PIN
  const deletePin = async (pinData: any) => {
    // const tk = localStorage.getItem('accessToken')
    const { accessToken } = JSON.parse(
      localStorage.getItem("persist:accessToken") || "{}"
    );
    const accessToken1 = accessToken.replaceAll('"', "");
    const response = await fetch(`${apiHost}/apipins/deletePin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken1}`,
      },
      body: JSON.stringify(pinData),
    });
    const pin = await response.json();
    return pin;
  };

  //

  const getMe = async () => {
    setLoading(true);
    const { accessToken } = JSON.parse(
      localStorage.getItem("persist:accessToken") || "{}"
    );
    const accessToken1 = accessToken.replaceAll('"', "");

    debugger;
    if (accessToken1) {
      try {
        const response = await axios.get(`${apiHost}/api/auth/finduser`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken1}`,
          },
        });

        if (response.status === 404) {
          localStorage.removeItem('accessToken');
          setUser("");
          setLoading(false);
        } else if (response.status === 200) {
          const user = await response.data;
          setUser(user);
          // console.log(user, "user a gya hai");
          setLoading(false);
          return user;
        }
      } catch (e) {
        setLoading(false);
        // localStorage.removeItem('accessToken');
        // setUser('')
      }
    }
  };

  const updateUserStatus = async (id: any, data: any) => {
    try {
      // const tk = localStorage.getItem('accessToken')
      const { accessToken } = JSON.parse(
        localStorage.getItem("persist:accessToken") || "{}"
      );
      const accessToken1 = accessToken.replaceAll('"', "");

      await axios.post(
        `${apiHost}/apiauth/updateUserStatus`,
        { userId: id, status: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken1}`,
          },
        }
      );
      getallUser();
    } catch (err) {
      // console.log("error is there");

      console.log(err);
    }
  };
  const [users, setusers] = useState([]);

  const getallUser = async () => {
    try {
      const { accessToken } = JSON.parse(
        localStorage.getItem("persist:accessToken") || "{}"
      );
      const accessToken1 = accessToken.replaceAll('"', "");
      const response = await axios.get(`${apiHost}/apiauth/getAllUsers`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken1}`,
        },
      });
      // if (response.status === 401) {
      // 	// setusers({ error: 'you are not authorize for this operation' })
      // }
      const alluser = await response.data;

      setusers(alluser.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getPhaseforDropdown = async () => {
    try {
      const dropdownPhases = await axios.get(
        `${apiHost}/apiphase/getPhaseforDropdown`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(dropdownPhases.data, "dropdownPhases")
      setDropDownphases(dropdownPhases.data.items);
      getCurrentMap(dropdownPhases.data.items);
    } catch (err) {

      console.log(err);
    }
  };

  const getEvaluation = async () => {
    try {
      const tk = localStorage.getItem("authToken");
      const allEvaluation: any = await axios.get(
        `${apiHost}/api/evaluation/getEvaluations`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tk}`,
          },
          params: { limit: 20, offset: 0 },
        }
      );
      setEvaluation(allEvaluation);
    } catch (err) {
      console.log(err);
    }
  };

  const carryOutEvaluation = async (data: any) => {
    try {
      const tk = localStorage.getItem("authToken");

      const response = await axios.post(
        `${apiHost}/apievaluation/carryOutEvaluation`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );

      const allEvaluation = response.data; // Extract the data from the response object

      setEvaluation(allEvaluation); // Pass the extracted data as an array to the setEvaluation function
    } catch (err) {
      console.log(err);
    }
  };

  const value = {
    pins,
    getPins,
    addPin,
    onSearch,
    getMe,
    updateUserStatus,
    getallUser,
    userData,
    deletePin,
    users,
    loading,
    setLoading,
    firstLoad,
    pageDetails,
    setFirstLoad,
    currentPin,
    currentUser,
    detailsModalOpen,
    setCurrentUser,
    clickedMarker,
    setDetailsModalOpen,
    getCurrentMap,
    mapChangeHandler,
    filter,
    dispatchFilter,
    applyFilters,
    getPhaseforDropdown,
    dropDownphases,
    getEvaluation,
    evaluation,
    carryOutEvaluation,
  };

  return (
    <PinContext.Provider value={value}>{props.children}</PinContext.Provider>
  );
};
export default PinProvider;
