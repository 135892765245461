import React from "react";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MegaMenu } from "./MegaMenu";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "_metronic/helpers";
import { Link } from "react-router-dom";

export function AgencyMenuInner() {
  const intl = useIntl();
  return (
    <>
      {/* <MenuItem
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        to="/dashboard"
        icon="/media/icons/duotune/layouts/lay009.svg"
      /> */}
      {/* <MenuItem title='Post Listing' to='' />
      <MenuItem title='My Listing' to='' /> */}

      {/* <a className="mt-7 ml-3" href="http:/imlaak.com/">
        Go to Imlaak.com
      </a> */}
    </>
  );
}
