/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useDispatch } from "react-redux";
import * as auth from "../../../../app/components/auth/redux/AuthRedux";

export function LandlordMenuMain() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(auth.actions.logout());
  };

  return (
    <>
      {/* remove this agency line */}
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/layouts/lay009.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/profile-details"
        icon="/media/icons/duotune/communication/com013.svg"
        title="My Account & Profile"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItem
        to="/property"
        icon="/media/icons/duotune/general/gen001.svg"
        title="Property Management"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItem
        to="/listings"
        icon="/media/icons/duotune/art/art002.svg"
        title="Listings"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItem
        to="/LandlordConsultant"
        icon="/media/icons/duotune/general/gen049.svg"
        title="Consultant"
        fontIcon="bi-app-indicator"
      />
    </>
  );
}
