import { Settings } from "app/components/accounts/components/settings/Settings";
import Reservations from "app/components/reservation/ReservationPage";
import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { FallbackView } from "../../_metronic/partials";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { DashboardEmployee } from "../pages/dashboard/DashboardEmployee";
import { MenuTestPage } from "../pages/MenuTestPage";
import { DashboardAdmin } from "../pages/dashboard/DashboardAdmin";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "setup";
import PropertyTypePage from "app/components/PropertyType/PropertyTypePage";
import CountryLocationePage from "app/components/Locations/CountryLocation/CountryLocationPage";
import ProvinceLocationePage from "app/components/Locations/ProvinceLocation/ProvinceLocationPage";
import CityLocationePage from "app/components/Locations/CityLocation/CityLocationPage";
import CommunityLocationePage from "app/components/Locations/CummunityLocation/CommunityLocationPage";
import SectorLocationePage from "app/components/Locations/SectorLocation/SectorLocationPage";
import BlockLocationePage from "app/components/Locations/BlockLocation/BlockLocationPage";
import ApproveUsers from "app/components/Admin/ApproveUsers/ApproveUserTable";
import PendingUsers from "app/components/Admin/PindingUsers/PindingUserTable";
import RejectedUsers from "app/components/Admin/RejectedUsers/RejectedUserTable";
import AllAgents from "app/components/Admin/AllAgents/AllAgentsTable";

import AllAgenciesTable from "app/components/Admin/AllAgencies/AllAgencies";
import UsersTable from "app/components/Admin/AllUsers/UsersTable";
import totalLandlords from "app/components/Landlord/AllUsers/LandlordTable";
import EmploymentDetailPage from "app/components/Employment/components/EmploymentDetailPage";
import { ProfileDetails } from "app/components/accounts/components/settings/cards/ProfileDetails";
import { ProfilePage } from "app/components/ProfilePage";
import { DashboardLandlord } from "app/pages/dashboard/DashboardLord";
import ProjectNamePage from "app/components/ProjectName/ProjectNamePage";
import { TotalListings } from "app/pages/dashboard/TotalListings";
import PropertyTable from "app/components/Listing/propertyTable/PropertyTable";
import PropertyTableData from "app/components/PropertyTables/DataTables";
import Evaluation from "app/components/Evaluation/evaluation";
import AllPropertiesPage from "app/components/property/AllPropertiesPage";
import UsersLandlordsPage from "app/components/LandlordsStaff/UsersLandlordsPage";
import TotalUsersTable from "app/components/Admin/ApproveUsers/TotalUsersTable";
import SuspendedUsersTable from "app/components/Admin/ApproveUsers/suspendedUsersTable";
import UserPropertyPage from "app/components/inventory/UserPropertyPage";
import ManagerPage from "app/components/ManagerStaff/ManagerPage";
import ChangeOwnerPage from "app/components/inventory/ChangeOwnerPage";
import ConsultantPropertyPage from "app/components/property/ConsultantPropertyPage";
import RoleBasedRoute from "./RoleBasedRoute";
import ConsultantListingPropertyPage from "app/components/property/ConsultantListingPropertyPage";
import { ProjectNameTesting } from "app/components/ProjectNames/components/ProjectNameTesting";
import { Registration } from "app/components/auth/components/Registration";
import { Verification } from "app/components/auth/components/Verification";
import { DashboardDemo } from "app/pages/dashboard/DashboardDemo";
import DemoPropertyPage from "app/components/property/DemoPropertyPage";
import DemoListingPage from "app/components/property/DemoListingPage";
import PropertyDetailPage from "app/components/property/PropertyDetailPage";
import { ConsultantLandlordsApproval } from "app/components/LandlordsStaff/components/ConsultantLandlordsApproval";
import { AllConsultants } from "app/components/ConsultantsStaff/AllConsultants";
import ListingPage from "app/components/Listing/ListingPage";
import ConsultantUnVerifiedPropertyPage from "app/components/property/ConsultantUnVerifiedPropertyPage";
import EvaluationPage from "app/components/Evaluation/EvaluationPage";
import { ConsultantDetails } from "app/components/ConsultantsStaff/ConsultantDetails";
import { LandlordsOfConsultant } from "app/components/ConsultantsStaff/LandlordsOfConsultant";
import { PropertiesOfTheLandlordsOfConsultant } from "app/components/ConsultantsStaff/PropertiesOfTheLandlordsOfConsultant";
import { ConsultantChangeRequest } from "app/components/LandlordsStaff/components/ConsultantChangeRequest";
import ConsultantEvaluationPage from "app/components/Evaluation/ConsultantEvaluationPage";


interface PrivateRouteProps {
  requiredRole?: string;
  role?: string;
}

export function PrivateRoutes({ requiredRole }: PrivateRouteProps) {
  const BuilderPageWrapper = lazy(
    () => import("../pages/layout-builder/BuilderPageWrapper")
  );
  const BuilderPageWrapper2 = lazy(
    () => import("../pages/Post-Listing/BuilderPageWrapper")
  );
  const BlogMainPage = lazy(() => import("../components/Blogs/BlogMainPage"));
  const AccountPage = lazy(() => import("../components/accounts/AccountPage"));
  const ContactPage = lazy(() => import("../components/contact/ContactPage"));
  const IntegrationPage = lazy(
    () => import("../components/integration/IntegrationPage")
  );
  const WidgetsPage = lazy(() => import("../components/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../components/apps/chat/ChatPage"));
  const ApplicationPage = lazy(
    () => import("../components/application/ApplicationPage")
  );
  const ReservationCheckInAndOut = lazy(
    () =>
      import("../components/reservation/components/ReservationCheckInAndout")
  );

  // const ProfileDetails = lazy(() => import('../components/accounts/components/settings/cards/ProfileDetails')
  // )
  const PropertyPage = lazy(
    () => import("../components/property/PropertyPage")
  );
  const InventoryPage = lazy(
    () => import("../components/inventory/PropertyPage")
  );
  const BuilderPageWrapper3 = lazy(
    () => import("../pages/str-dashbord/BuilderPageWrapper")
  );
  const ProjectPage = lazy(() => import("../components/Project/ProjectPage"));
  const BookingPage = lazy(() => import("../components/Booking/BookingPage"));
  const RentPage = lazy(() => import("../components/Rent/RentPage"));

  const ReportsPage = lazy(() => import("../components/reports/ReportsPage"));
  const PlotListing = lazy(() => import("../pages/plotListing"));
  const MapPage = lazy(() => import("../components/MapPage/mapPage"));

  const Building = lazy(() => import("../components/hotels/hotelPage"));
  const MapWithSearchBox = lazy(() => import("../components/GoogleMap/GoogleMapAPI"));

  // const AgenciesPage = lazy(() => import("../components/Pages/AgenciesPage"));

  // const ReservationPage = lazy(
  //   () => import("../components/reservation/ReservationPage")
  // );
  // const ReservationCrud = lazy(
  //   () => import("../components/reservation/components/CreateUpdate")
  // );
  const DeveloperPage = lazy(
    () => import("../components/developer/developerPage")
  );
  const CareerPage = lazy(() => import("../components/career/CareerPage"));
  const EstimationValuePage = lazy(
    () => import("../components/EstimationValue/estimationValuePage")
  );

  const Employment = lazy(
    () => import("../components/Employment/employmentPage")
  );
  const AmenitiesPage = lazy(
    () => import("../components/amenities/amenitiesPage")
  );
  const HotelCrud = lazy(
    () => import("../components/hotels/components/CreateUpdate")
  );
  const EmploymentCrud = lazy(
    () => import("../components/Employment/components/CreateUpdate")
  );
  const ReservationCrud = lazy(
    () => import("../components/reservation/components/CreateUpdate")
  );
  const ReservationPage = lazy(
    () => import("../components/reservation/ReservationPage")
  );
  const PropertyConsultantsStaffPage = lazy(
    () => import("../components/ConsultantsStaff/ConsultantsStaffPage")
  );
  const PropertyLandlordsPage = lazy(
    () => import("../components/LandlordsStaff/PropertyLandlordsPage")
  );
  const LandlordConsultant = lazy(
    () => import("../components/LandlordsStaff/LandlordConsultant")
  );
  const AgencyWebsitePage = lazy(
    () => import("../components/AgencyWebsite/AgencyWebsitePage")
  );
  const PropertyManagement = lazy(
    () => import("../components/PropertyManagement/PropertyManagementPage")
  );

  const user: any =
    useSelector<RootState>(({ auth }) => auth.user, shallowEqual) ?? {};
  const history = useHistory();
  const location = useLocation();

  // useEffect(() => {
  //   if (user?.role === "landlord" && history.location.pathname === "/profile") {
  //     history.push("/profile");
  //   } else if (user?.role === "admin") {
  //     history.push("/admin/dashboard");
  //   } else if (user?.role === "landlord") {
  //     history.push("/landlord/dashboard");
  //   } else if (user?.role === "consultant") {
  //     history.push("/dashboard");
  //   } else if (user?.role === "employee") {
  //     history.push("/employee/dashboard");
  //   } else if (user?.role === "demo") {
  //     history.push("/demo/dashboard");
  //   } else {
  //     history.push("");
  //   }
  // }, [user]);
  // User role for Routing
  const hasRequiredRole = user && user?.role === requiredRole;

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>

        {/* Navigate to Dashboard */}
        {user.role === 'admin' ? (
          <Route path='/dashboard' component={DashboardAdmin} />
        ) : user.role === 'employee' ? (
          <Route path='/dashboard' component={DashboardEmployee} />
        ) : user.role === 'consultant' ? (
          <Route path='/dashboard' component={DashboardWrapper} />
        ) : user.role === 'landlord' ? (
          <Route path='/dashboard' component={DashboardLandlord} />
        ) : (
          <Route path='/dashboard' component={DashboardDemo} />
        )}


        <Route path="/str" component={BuilderPageWrapper3} />
        <Route path="/auth/registration" component={Registration} />
        <Route path="/auth/verification" component={Verification} />

        <Route path="/builder" component={BuilderPageWrapper} />
        {/* <Route path="/str" component={BuilderPageWrapper2} /> */}
        <Route path="/evaluation" component={Evaluation} />
        <Route path="/plotListings" component={PlotListing} />
        <Route path="/maps" component={MapPage} />
        <Route path="/profile-details" component={AccountPage} />
        <Route path="/profile" component={ProfilePage} />
        {/* // <Route path='/profile' component={WizardsPage} /> */}
        <Route path="/widgets" component={WidgetsPage} />
        <Route path="/application" component={ApplicationPage} />
        <Route path="/ProfileDetails" component={ProfileDetails} />
        <Route path="/blogs" component={BlogMainPage} />
        <Route path="/widgets" component={WidgetsPage} />
        <Route path="/application" component={ApplicationPage} />
        <Route path="/table" component={PropertyTableData} />
        <Route path="/bookings" component={BookingPage} />
        <Route path="/rent" component={RentPage} />
        <Route path="/reports" component={ReportsPage} />
        <Route path="/crafted/account" component={AccountPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/integration" component={IntegrationPage} />
        <Route path="/apps/chat" component={ChatPage} />
        <Route path="/menu-test" component={MenuTestPage} />
        <Route path="/ownership" component={ChangeOwnerPage} />
        <Route path="/managers" component={ManagerPage} />

        <Route path="/agencyWebsite" component={AgencyWebsitePage} />
        <Route path="/propertyManagement" component={PropertyManagement} />
        <Route path="/career" component={CareerPage} />
        <Route path="/estimationValue" component={EstimationValuePage} />
        <Route path="/employment" component={Employment} />
        <Route path="/employment/view/:id" exact component={EmploymentCrud} />
        <Route path="/PropertyCategory" component={PropertyTypePage} />
        <Route path="/country" component={CountryLocationePage} />
        <Route path="/province" component={ProvinceLocationePage} />
        <Route path="/city" component={CityLocationePage} />
        <Route path="/community" component={CommunityLocationePage} />
        <Route path="/sector" component={SectorLocationePage} />
        <Route path="/block" component={BlockLocationePage} />
        <Route path="/totalUsers" component={UsersTable} />
        <Route path="/allUsers" component={TotalUsersTable} />
        <Route path="/suspendedAccounts" component={SuspendedUsersTable} />
        <Route path="/totalLandlords" component={totalLandlords} />
        <Route path="/propertyTable" component={PropertyTable} />
        <Route path="/totalListings" component={TotalListings} />

        <Route
          path="/EmploymentDetailPage/view/:id"
          component={EmploymentDetailPage}
        />
        <Route path="/approveUsers" component={ApproveUsers} />
        <Route path="/pendingUsers" component={PendingUsers} />
        <Route path="/AllAgencies" component={AllAgenciesTable} />
        <Route path="/rejectedUsers" component={RejectedUsers} />
        <Route path="/AllAgents" component={AllAgents} />

        <Route path="/properties" component={AllPropertiesPage} />
        <Route path="/amenities" component={AmenitiesPage} />

        <Route path="/building" component={Building} />
        <Route path="/MapWithSearchBox" component={MapWithSearchBox} />
        <Route path="/ProjectNameTesting" component={ProjectNameTesting} />


        {/* Consultant's Routes */}
        <RoleBasedRoute path="/dashboard" component={DashboardWrapper} requiredRole="consultant" />
        <RoleBasedRoute path="/myLandlords" component={UsersLandlordsPage} requiredRole="consultant" />
        <RoleBasedRoute path="/landlordApproval" component={ConsultantLandlordsApproval} requiredRole="consultant" />
        <RoleBasedRoute path="/consultantProperty" component={ConsultantPropertyPage} requiredRole="consultant" />
        <RoleBasedRoute path="/unverifiedProperties" component={ConsultantUnVerifiedPropertyPage} requiredRole="consultant" />
        <RoleBasedRoute path="/listingProperty" component={ConsultantListingPropertyPage} requiredRole="consultant" />
        <RoleBasedRoute path="/propertyDetail/:id" component={PropertyDetailPage} requiredRole="consultant" />
        <RoleBasedRoute path="/evaluationsPage" component={EvaluationPage} requiredRole="consultant" />
        <RoleBasedRoute path="/evaluationProperty" component={ConsultantEvaluationPage} requiredRole="consultant" />

        {/* Employee Routes */}
        <RoleBasedRoute path="/dashboard" component={DashboardEmployee} requiredRole="employee" />
        <RoleBasedRoute path="/projectName" component={ProjectNamePage} requiredRole="employee" />
        <Route path="/consultants" component={AllConsultants} />
        <Route path="/LandlordsOfConsultant/:id" component={LandlordsOfConsultant} />
        <Route path="/consultantDetails/:id" component={ConsultantDetails} />
        <Route path="/propertiesOfTheLandlord/:id" component={PropertiesOfTheLandlordsOfConsultant} />
        <Route path="/PropertyLandlords" component={PropertyLandlordsPage} />
        <Route path="/changeConsultant" component={ConsultantChangeRequest} />
        <Route path="/inventory" component={InventoryPage} />
        <Route path="/projects" component={ProjectPage} />
        <Route path="/projectDeveloper" component={DeveloperPage} />

        {/* Landlord Routes */}
        <RoleBasedRoute path="/dashboard" component={DashboardLandlord} requiredRole="landlord" />
        <RoleBasedRoute path="/property" component={PropertyPage} requiredRole="landlord" />
        <RoleBasedRoute path="/landlordConsultant" component={LandlordConsultant} requiredRole="landlord" />
        <RoleBasedRoute path="/listings" component={ListingPage} requiredRole="landlord" />

        {/* Admin Routes */}
        <RoleBasedRoute path="/dashboard" component={DashboardAdmin} requiredRole="admin" />
        <RoleBasedRoute path="/projectName" component={ProjectNamePage} requiredRole="admin" />

        {/* Demo Routes */}
        <RoleBasedRoute path="/dashboard" component={DashboardDemo} requiredRole="demo" />
        <RoleBasedRoute path="/propertyDemo" component={DemoPropertyPage} requiredRole="demo" />
        <RoleBasedRoute path="/listingDemo" component={DemoListingPage} requiredRole="demo" />

        {/* <Route path="/building" component={Building} />
        <Route path="/hotels/create" exact component={HotelCrud} />
        <Route path="/hotels/edit/:id" exact component={HotelCrud} />
        <Route path="/reservations/:type/:id">
          <ReservationCheckInAndOut />
        </Route>
        <Route path="/reservations/create" exact component={ReservationCrud} />
        <Route
          path="/reservations/edit/:id"
          exact
          component={ReservationCrud}
        /> */}
        {/* <Route path="/reservations" exact component={ReservationPage} /> */}

        {/* <Redirect from="/auth" to="/dashboard" />
        <Redirect to="error/404" /> */}
        <Redirect exact from="/" to="/dashboard" />
      </Switch>
    </Suspense>
  );
}
