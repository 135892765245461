import React, { useContext, useEffect, useState, useDeferredValue } from "react";
import { DoDisturbOn, CheckCircle, Cancel } from "@mui/icons-material";
import Box from "@mui/material/Box";
// import Dashboard from "../../components/admin/Dashboard/Dashboard";
// import SideBar from '../../components/admin/Sidebar/Sidebar'
import { Alert, Button, CircularProgress, FormHelperText, Grid, Typography } from "@mui/material";
import { getQueryParam, setQueryParams } from "../../utils/queryManipulation";
import Table, { capitalizeFirstLetter, IconRender } from '../../components/Table'
import NumberFormat from 'react-number-format';
import { PinContext } from "../../services/context/pin/PinContext";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import TextField from "@mui/material/TextField";
import { useReducer } from "react";
import { numberToNotation } from "../../utils/numberToNotation";
import Modal from '../../components/Modal'


import TableMui from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";

const Swal = require("sweetalert2");

const TextInput = (value, onChange, name) => {
    // const [internalValue, setInternalValue] = useState(value)
    // const deferredValue = useDeferredValue(internalValue);

    const changeHandler = (event) => {
        onChange(event.target.value, name);

        // setInternalValue(event.target.value)
    }

    // useEffect(() => {
    //     debugger
    //     // onChange(deferredValue);
    // }, [deferredValue])
    return <Box display='flex' alignItems='center'>
        <TextField autoFocus type='number' variant='standard' name={name} onChange={changeHandler} sx={{ width: '60%' }} />

    </Box>
}

const columns = (iconClickHandler, onChange, activeRow, loading) => {
    return [
        // { name: 'Id', key: '_id', numaric: false, isSortable: true, },
        {
            name: "Name",
            key: "property",
            numaric: false,
            isSortable: true,
            renderContent: (value) => `${value?.title}`,
        },
        {
            name: "Location",
            key: "property",
            numaric: false,
            isSortable: true,
            renderContent: (value) =>
                `${value?.location?.lat} , ${value?.location?.lng}`,
        },
        { name: "Status", key: "status", numaric: false, isSortable: true },
        {
            name: "User",
            key: "user",
            numaric: false,
            isSortable: true,
            renderContent: (value) => `${value.fname} ${value.lname}`,
        },
        {
            name: "Current Price",
            key: "property",
            numaric: true,
            isSortable: true,
            renderContent: (value) =>
                numberToNotation(parseFloat(value?.price), 10) + " PKR",
        },
        {
            name: "Current Evaluation Price",
            key: "currentEvaluation",
            numaric: false,
            isSortable: true,
            renderContent: (value) =>
                value ? numberToNotation(parseFloat(value), 10) : 0 + " PKR",
        },
        {
            name: "Profit/loss in %",
            key: "netGain",
            numaric: false,
            isSortable: true,
            renderContent: (value) => (value ? value : 0 + " %"),
        },
        // { name: 'Net Gain', key: 'netGain', numaric: false, isSortable: true, renderContent: (value) => <TextInput value={value} name="netGain" onChange={onChange} /> },
        {
            name: "Action",
            key: "action",
            component: (row) => (
                <>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={activeRow?._id !== row?._id}
                        onClick={iconClickHandler}
                    >
                        Evaluate
                    </Button>
                    {/* {IconRender(InfoOutlinedIcon, iconClickHandler)} */}
                    {/* {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, 'edit')} */}
                    {/* {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, 'delete')} */}
                </>
            ),
        },
    ]
}
const Evaluation = () => {

    const { getallUser, updateUserStatus, users, getMe, userData, loading, getEvaluation, evaluation, setEvaluation, carryOutEvaluation } = useContext(PinContext);
    const [currentUsers, setCurrentUsers] = useState([])
    const [activeRowIndex, setActiveRowIndex] = useState(-1)
    const [open, setOpen] = useState(false)

    // const [evaluationValues, dispatch] = useReducer((state, action) => {
    //     state = {
    //         ...state,
    //         ...action.payload
    //     }
    // }, {})

    const [currentEvaluation, setCurrentEvaluation] = useState(0)
    const [activeRow, setActiveRow] = useState()
    const userChangeHandler = (status) => {

        setQueryParams('status', status)
        const filteredUsers = users?.filter((user) => user.status === status);
        setCurrentUsers(filteredUsers)
    }

    useEffect(() => {
        const getData = async () => {
            await getMe()
        }
        getData()
        getEvaluation()
    }, []);


    const success = () => {
        Swal.fire({
            title: "Evaluation Successfully submitted",
            text: "You have assigned new evaluated value to this Property",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Okay",
            zIndex: 1
        })
    };


    // Suspended


    // useEffect(() => {
    //     const token = window.localStorage.getItem("authToken")
    //     if (!token) return window.location.href = '/login'
    // }, [])

    const iconClickHandler = () => {
        setOpen(true)
    }

    const onChange = (value, filedName) => {
        // setCurrentEvaluation(value)
        // // let updateEvaluation = evaluation.data.evaluation
        // // updateEvaluation[activeRowIndex] = {
        // //     ...updateEvaluation[activeRowIndex],
        // //     [filedName]: value
        // // }

        // // setEvaluation({
        // //     ...evaluation.data,
        // //     evaluation: updateEvaluation
        // // })
        // for(var i ){
        //     if(ev._id === activeRow._id){
        //         index 
        //     }
        // }
        // console.log(indexOfItem, 'index of item')
        // debugger
        // dispatch({ payload: { [event.target.name]: event.target.value } })
        // console.log('onChange', event)
    }
    const handleSubmit = async () => {
        setOpen(false)
        await carryOutEvaluation({
            ...activeRow,
            currentEvaluation
        })
        success()
        getEvaluation()
    }
    const resetForm = () => {

    }

    if (!loading && Object.keys(userData).length > 0 && userData?.role !== 'consultant') {
        return <Alert severity="error">Unauthorize Access. You cannot visit this page.</Alert>
    }


    const inPercent = (((currentEvaluation - activeRow?.property?.price) / activeRow?.property?.price) * 100).toFixed(2)
    return (
        <>
            <div className="pb-5">
                {loading && currentUsers.length === 0 ? (
                    <Box zIndex='1' backgroundColor='white' p={2} position="absolute" top={'48%'} left={'calc(calc(100% - 262px) / 2)'} display='flex' alignItems='center' flexDirection={'column'} justifyCenter='center' >
                        <CircularProgress />
                    </Box>
                ) : <>
                    {/* <SideBar /> */}
                    <div className="" id="main">
                        <div className="row row-offcanvas row-offcanvas-left">
                            <Box className="">
                                <Table
                                    columns={columns(iconClickHandler, onChange, activeRow, loading)}
                                    data={evaluation?.data?.evaluation}
                                    loading={loading}
                                    onRowClick={(row, index) => { setActiveRow(row); setActiveRowIndex(index) }}
                                    isPaginationRequired
                                    page={0}
                                    setPage={() => { }}
                                    rowsPerPage={20}
                                    setRowsPerPage={() => { }}
                                    total={evaluation?.data?.count}
                                />
                                {
                                    open ?
                                        <Modal
                                            setOpen={() => setOpen(!open)}
                                            open={open}
                                            title={` Evaluate ${activeRow?.property?.name} proposed by ${activeRow?.user?.fname}`}
                                            cancelButtonTitle="Cancel"
                                            acceptButtonVarient="contained"
                                            acceptButtonTitle="Update Property"
                                            handleAccept={handleSubmit}
                                            handleClose={resetForm}
                                            loading={loading}
                                            isActionable={true}
                                        >
                                            <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">

                                                <Grid container columnSpacing={2}>
                                                    <Grid item md={12}>
                                                        <Typography sx={{ fontWeight: 700, fontSize: '14 ', mb: 2, color: '#1565c0' }} >Please enter the evaluated price of the property. Based on this price the Net Profit or Net loss of the property is calculated so carefully enter the evaluated Price</Typography>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                        <TextField
                                                            fullWidth
                                                            label={"evaluated Price"}
                                                            variant="outlined"
                                                            required
                                                            type='number'
                                                            onChange={(e) => setCurrentEvaluation(e.target.value)}
                                                            value={currentEvaluation}
                                                        />
                                                        {/* <Typography sx={{ fontWeight: 500, fontSize: '14 ', mt: 1, mx: 2 }} > Old Price = {activeRow.property.price}</Typography>
                                        <Typography sx={{ fontWeight: 500, fontSize: '14 ', mt: 1, mx: 2 }} > New Price = {currentEvaluation}</Typography>
                                        <Typography sx={{ fontWeight: 500, fontSize: '14 ', mt: 1, mx: 2 }} > % = {activeRow.property.price / currentEvaluation * 100}</Typography> */}
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        price in millions
                                                        {currentEvaluation ? <Typography variant='subtitle2' textAlign={'center'} >
                                                            {numberToNotation(parseFloat(currentEvaluation), 1)} PKR
                                                        </Typography> : null}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {currentEvaluation ?
                                                <Box sx={{ marginTop: '50px' }}>

                                                    <Typography sx={{ fontWeight: 700, fontSize: '24px ', mt: 1, mx: 2 }} > States</Typography>

                                                    <TableMui sx={{ width: '100%', marginTop: '0px' }}>
                                                        <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>

                                                            <TableCell component="th" scope="column"> <Typography variant="h6">Old Price</Typography></TableCell>
                                                            <TableCell component="th" scope="column"><Typography variant="h6"> New Price</Typography></TableCell>
                                                            <TableCell component="th" scope="column"><Typography variant="h6"> Profit/Loss</Typography></TableCell>
                                                            <TableCell component="th" scope="column"><Typography variant="h6"> in %.</Typography></TableCell>
                                                        </TableRow>
                                                        <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
                                                            <TableCell component="td" scope="column"><Typography variant="subtitle1"> {new Intl.NumberFormat().format(parseInt(activeRow?.property?.price || 0))}</Typography></TableCell>
                                                            <TableCell component="td" scope="column"><Typography variant="subtitle1"> {new Intl.NumberFormat().format(parseInt(currentEvaluation || 0))}</Typography></TableCell>
                                                            <TableCell component="td" scope="column"><Typography variant="subtitle1">{new Intl.NumberFormat().format(parseInt(currentEvaluation - activeRow?.property?.price || 0))}</Typography></TableCell>
                                                            <TableCell component="td" scope="column"><Typography variant="subtitle1"> {inPercent}</Typography></TableCell>
                                                        </TableRow>
                                                    </TableMui>
                                                </Box>
                                                : null}
                                        </Modal>
                                        : null
                                }
                            </Box>
                        </div>
                    </div>
                </>
                }
            </div>
        </>
    );
};

export default Evaluation;
