import { APIS } from "Constants";
import Field from "app/components/Inputs/asyncSelectWithPagination";

export const CountryDropdown = ({
  name = "Country Location",
  label = "Select Country",
  onChangeOption,
  required,
  items,
  countrydata,
  ...rest
}: any) => {
  return (
    <>
      <Field
        type="asyncOptionsWithPagination"
        name={name}
        label={label}
        onChange={onChangeOption}
        apiOptions={{
          url: APIS.CountryLocations.getAll,
          keys: ["countryLocation"],
        }}
        defaultTheme="black"
        {...rest}
      />
    </>
  );
};

export const ProvinceDropdown = ({
  name = "province",
  label = "Select Province",
  onChangeOption,
  required,
  countryId,
  ...rest
}: any) => {
  const filterBy = (items: any) => {
    if (countryId) {
      const it = items.filter((item: any) => item.countryId._id === countryId);
      return it;
    } else {
      return items;
    }
  };
  return (
    <>
      <Field
        type="asyncOptionsWithPagination"
        name={name}
        disabled={!countryId}
        label={label}
        onChange={onChangeOption}
        cacheUniqs={[countryId]}
        apiOptions={{
          url: APIS.provinceLocations.getAll,
          keys: ["provinceLocation"],
          filterBy,
        }}
        defaultTheme="black"
        {...rest}
      />
    </>
  );
};

export const CityDropdown = ({
  name = "city",
  label = "Select City",
  onChangeOption,
  required,
  provinceId,
  ...rest
}: any) => {
  const filterBy = (items: any) => {
    if (provinceId) {
      const it = items.filter(
        (item: any) => item.provinceId._id === provinceId
      );
      return it;
    } else {
      return items;
    }
  };
  return (
    <>
      <Field
        type="asyncOptionsWithPagination"
        name={name}
        label={label}
        onChange={onChangeOption}
        apiOptions={{
          url: APIS.cityLocations.getAll,
          keys: ["cityLocation"],
          filterBy,
        }}
        cacheUniqs={[provinceId]}
        defaultTheme="black"
        {...rest}
      />
    </>
  );
};

export const CommunityDropdown = ({
  name = "community",
  label = "Select Community",
  onChangeOption,
  required,
  cityId,
  ...rest
}: any) => {
  const filterBy = (items: any) => {
    if (cityId) {
      const it = items.filter((item: any) => item.cityId._id === cityId);
      return it;
    } else {
      return items;
    }
  };
  return (
    <>
      <Field
        type="asyncOptionsWithPagination"
        name={name}
        label={label}
        onChange={onChangeOption}
        cacheUniqs={[filterBy]}
        apiOptions={{
          url: APIS.communityLocations.getAll,
          keys: ["communityLocation"],
          filterBy,
        }}
        defaultTheme="black"
        {...rest}
      />
    </>
  );
};

export const SectorDropdown = ({
  name = "sector",
  label = "Select Sector/Phase",
  onChangeOption,
  required,
  communityId,
  ...rest
}: any) => {
  const filterBy = (items: any) => {
    if (communityId) {
      const it = items.filter(
        (item: any) => item.communityId._id === communityId
      );
      return it;
    } else {
      return items;
    }
  };
  return (
    <>
      <Field
        type="asyncOptionsWithPagination"
        name={name}
        label={label}
        onChange={onChangeOption}
        cacheUniqs={[communityId]}
        apiOptions={{
          url: APIS.sectorLocations.getAll,
          keys: ["sectorPhaseLocation"],
          filterBy,
        }}
        defaultTheme="black"
        {...rest}
      />
    </>
  );
};

export const BlockDropdown = ({
  name = "block",
  label = "Select Building",
  onChangeOption,
  required,
  ...rest
}: any) => {
  return (
    <>
      <Field
        type="asyncOptionsWithPagination"
        name={name}
        label={label}
        onChange={onChangeOption}
        apiOptions={{
          url: APIS.blockLocations.getAll,
          keys: ["blockLocation"],
        }}
        defaultTheme="black"
        {...rest}
      />
    </>
  );
};

// export default CountryLocations;
