import React, { FC } from "react";
import { Field, ErrorMessage, useField } from "formik";

const Step2: FC = () => {
  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-12">
        <h2 className="fw-bolder text-dark">Introduce Yourself</h2>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="fv-row mb-10">
            <label className="form-label required">Name</label>
            <Field
              name="fname"
              className="form-control form-control-lg form-control-solid"
            />
            <div className="text-danger mt-2">
              <ErrorMessage name="fname" />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="fv-row mb-10">
            <label className="form-label required">Phone</label>

            <Field
              name="contact"
              className="form-control form-control-lg form-control-solid"
            />
            <div className="text-danger mt-2">
              <ErrorMessage name="contact" />
            </div>
          </div>
        </div>
      </div>

      <div className="fv-row mb-10">
        <label className="form-label required">Description</label>
        <Field
          name="description"
          as="textarea"
          rows={4}
          className="form-control form-control-lg form-control-solid"
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="description" />
        </div>
      </div>
    </div>
  );
};

export { Step2 };
