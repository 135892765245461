export const APIS = {
  hotels: {
    getAll: "hotel/getAllHotels",
    getById: "hotel/getHotelById",
    create: "hotel/create",
    update: "hotel/update",
    delete: "hotel/delete",
  },
  projects: {
    getAllProject: "project/getAllProjects",
    create: "project/createProject",
    update: "project/updateProject",
    delete: "project/deleteProject",
  },
  phase: {
    getAll: "phase/getAllPhase",
    getById: "/getPhaseById/:id",
    getPhasesforDropdown: "/getPhaseforDropdown",
    create: "phase/addPhase",
    update: "phase/updatePhase/:_id",
    delete: "phase/deletePhase/:id",
  },
  gallery: {
    getAll: "galleryImages/getAll",
  },
  developers: {
    getAll: "developer/getAllDevelopers",
    getAllDevelopers: "developer/getAllDevelopers",
    create: "developer/create",
    update: "developer/update",
    delete: "developer/delete",
  },
  careers: {
    getAll: "career/getAllJobs",
    create: "career/create",
    update: "career/update",
    delete: "career/delete",
  },
  estimationValues: {
    getAll: "estimationValue/getAll",
    create: "estimationValue/create",
    update: "estimationValue/update",
    delete: "estimationValue/delete",
  },
  employment: {
    getAll: "employment/getAll",
    create: "employment/create",
    update: "employment/update",
    getById: "employment/getByEmploymentId",
    delete: "employment/delete",

  },
  users: {
    getConsultantDetails: "auth/getConsultantDetails",
    getAllConsultants: "auth/getAllConsultants",
    createLandlord: "auth/addLandlordByConsultant",
    getAll: "auth/getAllAgentsByPostedBy",
    create: "auth/register",
    update: "auth/updateLandlordProfile",
    delete: "auth/deleteAccount",
    deleteConsultantId: "auth/deleteConsultantId",
    LandlordApprovalRequest: "auth/LandlordApprovalRequest",
    resendWelcomeEmail: "auth/resendWelcomeEmail"
  },
  landlord: {
    getAll: "auth/getAllLandlords",
    create: "auth/register",
    update: "auth/updateLandlordProfile",
    delete: "auth/deleteAccount",
  },
  changeConsultant: {
    getAllRequests: "changeConsultant/getAllRequests",
    updateChangeRequest: "changeConsultant/updateChangeRequest",
    delete: "changeConsultant/rejectRequest",
  },
  propertyCategories: {
    getAll: "propertyCategory/getAllPropertyCategories",
    create: "propertyCategory/create",
    update: "propertyCategory/update",
    delete: "propertyCategory/delete",
  },
  CountryLocations: {
    getAll: "countryLocation/getAllCountriesLocations",
    // getuniqueDataLocations : "multipleLocation/getuniqueDataLocations",
    create: "countryLocation/create",
    update: "countryLocation/update",
    delete: "countryLocation/delete",
  },
  ProjectName: {
    getAll: "projectName/getAllProjectnames",
    getAllNames: "projectName/getAllProjectnames",
    getAllProjectNamesByProperty: "projectName/getAllProjectNamesByProperty",
    // getuniqueDataLocations : "multipleLocation/getuniqueDataLocations",
    create: "projectName/create",
    update: "projectName/update",
    delete: "projectName/delete",
  },
  provinceLocations: {
    getAll: "provinceLocation/getAllProvincesLocations",
    create: "provinceLocation/create",
    update: "provinceLocation/update",
    delete: "provinceLocation/delete",
  },
  cityLocations: {
    getAll: "cityLocation/getAllcitiesLocations",
    create: "cityLocation/create",
    update: "cityLocation/update",
    delete: "cityLocation/delete",
  },
  communityLocations: {
    getAll: "communityLocation/getAllCommunitiesLocations",
    create: "communityLocation/create",
    update: "communityLocation/update",
    delete: "communityLocation/delete",
  },
  sectorLocations: {
    getAll: "sectorLocation/getAllSectorsLocations",
    create: "sectorLocation/create",
    update: "sectorLocation/update",
    delete: "sectorLocation/delete",
  },
  blockLocations: {
    getAll: "blockLocation/getAllBlocksLocations",
    create: "blockLocation/create",
    update: "blockLocation/update",
    delete: "blockLocation/delete",
  },
  amenities: {
    getAll: "amenities/getAllAmenities",
    getAllAmenities: "amenities/getAllAmenities",
    create: "amenities/create",
    update: "amenities/update",
    delete: "amenities/delete",
  },
  reservations: {
    getAll: "reservation/getAllReservations",
    getById: "reservation/getById",
    getMyReservations: "reservation/getMyReservations",
    create: "reservation/create",
    update: "reservation/update",
    checkinReservation: "reservation/checkinReservation",
    checkoutReservation: "reservation/checkoutReservation",
    delete: "reservation/delete",
  },
  inventory: {
    getAllInventory: "inventory/getAllInventories",
    create: "inventory/createProperty",
    update: "inventory/updateProperty",
    delete: "inventory/deleteProperty",
    deleteConsultantProperty: "inventory/deleteConsultantProperty"
  },
  review: {
    getAllReviews: "reviews/allReviews",
    createReview: "reviews/createReview",
    updateReview: "reviews/updateReview",
    landlordReviews: "reviews/landlordReviews",
  },
};
