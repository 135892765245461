import { useEffect, useState, useMemo, useRef } from "react";
import {
    Box,
    Button,
    Divider,
    Grid,

    FormControl,
    FormHelperText,
    Chip,
    Paper,
    Skeleton,
    MenuItem,
    Select,
    Typography,
    FormControlLabel,
    useFormControl,
    Switch,
} from "@mui/material";
import Modal from "../../Modal";
import CustomInput from "../../Inputs";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import MapView from "../../Map/Map";


import DropDown, {
    propertyCategoryOptions,
    propertyTypeOptions,
    propertyFloorOptions,
    propertyCitiesOptions,
    Purpose,
    CompletedStatus,
    rentFrequency,
    furnishingStatus,
    ProjectSelecting,
    propertyOwnerOptions,
    landlordsData,

} from "../../DropDownOptions/DropDownOptions";
import Loader from "../../Loader";
import {
    ImageUploader,
    ImageViewer,
} from "app/components/Inputs/imageSelector";
import { useImageMethod } from "app/hooks/useImageUpload";
import { API } from "app/utils/config";
import MultipleSelectCheckmarks from "app/components/Inputs/DropdownWithCheckBox";
import { amenitiesCategories } from "app/components/amenities/components/DropDownOptions";
import { projectCitiesOptions } from "app/components/Project/components/DropDownOptions";
import useFetchData from "app/hooks/useFetchData ";
import { uniqBy } from "lodash";
import { APIS } from "Constants";
import {
    CityDropdown,
    CommunityDropdown,
    CountryDropdown,
    ProvinceDropdown,
} from "app/ServerSideDropdowns/LocationsDropdown";
import ConsultantDropdown from "app/ServerSideDropdowns/ConsultantDropdown";
import JoditEditor from "jodit-react";
import currency from "currency.js";
import axios from "axios";
import { ProjectNameDropdown } from "app/ServerSideDropdowns/ProjectNameDropdown";
import LandlordsDropdown from "app/ServerSideDropdowns/LandlordsDropdown";
import { AppErrorColor, AppTextColor } from "app/components/theme/Colors";
import { capitalizeFirstLetter } from "app/components/Table";

const validationSchema = yup.object({
    // category: yup.string().required("Category is required"),
    // subCategory: yup.string().required("SubCategory is required"),
    // purpose: yup.string().required("Purpose is required"),
    // country: yup.string().required("Country is required"),
    // province: yup.string().required("Province is required"),
    // city: yup.string().required("City is required"),
    // zipCode: yup.string().required("Property zip-code is required"),
    // state: yup.string().required("Property state is required"),
    // community: yup.string().required("community is required"),
    // provinceLocation: yup.string().required("ProvinceLocation is required"),
    // cityLocation: yup.string().required("CityLocation is required"),
    // communityLocation: yup.string().required("CommunityLocation is required"),
    // title: yup.string().required("title is required"),
    // description: yup.string().required("description is required"),
    // price: yup.number().required("Property price is required"),
    // areaType: yup.string().required("Property Area Type is required"),
    // area: yup.number().required("Property Area is required"),
    // projectType: yup.array().required("Project Type is required"),
    // projectNamemanual: yup.array().required("Project Name Type is required"),
    // images: yup.array().notRequired(),
    // location: yup.object({
    //   lat: yup.number().required().label("listing is required"),
    //   lng: yup.number().required().label("listing is required"),
    // }),
    // amenties: yup.object(),
});

const useStyles = makeStyles(() => ({
    heading: {
        background: "#263c67",
        color: "#fff",
        fontWeight: "700 !important",
    },
    headingStyles: {
        fontSize: "14px !important",
        fontWeight: "700 !important",
    },
    description: {
        padding: "8.5px 14px",
        width: "100%",
        pointerEvents: "none",
        border: "1px solid",
        borderColor: "#093d5c",
        borderRadius: "5px",
        height: "45px",
    },
}));

type UpdatePropertyPropsOwner = {
    openOne: any;
    setOpenOne: any;
    property?: any;
    updateProperty?: any;
    loading?: boolean;
    modalMode?: any;
    error?: any,

    label?: string;
    value?: string;
};

const UpdatePropertyOwner = ({
    openOne,
    setOpenOne,
    property,
    updateProperty,
    loading,
    modalMode,
    error,
    label,
    value,
}: UpdatePropertyPropsOwner) => {
    const onClick = (data: any) => {
        formik.setFieldValue("location", data?.lngLat);
    };
    const formik = useFormik({
        initialValues: {
            projectName: property?.projectName || "",
            category: property?.category || "",
            subCategory: property?.subCategory || "",
            owner: [
                {
                    landlordId: "" || "64df1aa59c7dbf93f1508530",
                    purchasePrice: "",
                    date: "",
                },
            ],
            ownerType: property?.ownerType || "",
            furnishingStatus: property?.furnishingStatus || "",
            purpose: property?.purpose || "",
            country: property?.country || "",
            province: property?.province || "",
            city: property?.city || "",
            rentType: property?.rentType || "",
            rentSystem: property?.rentSystem || "",

            state: property?.state || "",
            // zipCode: property?.zipCode || "",
            // community: property?.community || "",
            projectname: property?.projectname || "",
            // landlordname: property?.landlordname || "",
            // provinceLocation: property?.provinceLocation || "",
            // cityLocation: property?.cityLocation || "",
            // communityLocation: property?.communityLocation || "",
            title: property?.title || "",
            rentalIncome: property?.rentalIncome || "",
            description: property?.description || "",
            price: property?.price || 0,
            advanceAmount: property?.advanceAmount || 0,
            noOfInstallments: property?.noOfInstallments || 0,
            monthlyInstallments: property?.monthlyInstallments || 0,
            possessionAmount: property?.possessionAmount || 0,
            rentFrequency: property?.rentFrequency || "",
            bedrooms: property?.bedrooms || 0,
            bathrooms: property?.bathrooms || 0,
            floorNo: property?.floorNo || 0,
            appartmentNo: property?.appartmentNo || 0,
            completionStatus: property?.completionStatus || "",
            projectNamemanual: property?.projectNamemanual || "",
            ProjectSelecting: property?.ProjectSelecting || "",
            areaType: property?.areaType || "",
            area: property?.area || 0,
            location: property?.location || {},
            images: property?.images || [],
            amenities: property?.amenties || {},
            projectType: property?.projectType || [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values, "values of property");
            updateProperty(values.owner);
        },
    });

    useEffect(() => {
        formik.setFieldValue("projectName", property?.projectName);
        formik.setFieldValue("title", property?.title);
        formik.setFieldValue("category", property?.category);
        formik.setFieldValue("subCategory", property?.subCategory);
        formik.setFieldValue("purpose", property?.subCategory);
        formik.setFieldValue("description", property?.description);
        formik.setFieldValue("price", property?.price);
        formik.setFieldValue("area", property?.area);
        formik.setFieldValue("bedrooms", property?.bedrooms);
        formik.setFieldValue("bathrooms", property?.bathrooms);
        formik.setFieldValue("floorNo", property?.floorNo);
        formik.setFieldValue("appartmentNo", property?.appartmentNo);
        formik.setFieldValue("images", property?.images);
    }, [property]);

    // Get Project Name
    const { getAllNames } = APIS.ProjectName;
    const { projectNameData } = useFetchData({ getAllNames });
    const [projectLookup, setProjectLookup] = useState<any>({});

    useEffect(() => {
        // Create a lookup object for projectName id's to project Names
        if (projectNameData && projectNameData.length > 0) {
            const lookup: any = {};
            projectNameData.forEach((row: any) => {
                lookup[row?._id] = row?.projectName;
            });
            setProjectLookup(lookup);
        }
    }, [projectNameData]);


    //start Image upload files
    const { uploadImage, images, setImages, files, setFiles } = useImageMethod();
    const [id, setId] = useState("");

    const onSubmitImage = async (event: any) => {
        let formData = new FormData(); //formdata object
        for (var i = 0; i < files.length; ++i) {
            formData.append(files[i].name, files[i]);
        }
        // formData.append('images', files)
        const res: any = await uploadImage({ params: formData });
        if (res?.data?.succes) {
            formik.setFieldValue("images", res?.data?.image);
            setImages([]);
            setFiles([]);
        }
    };
    // End images upload files
    // landlord print


    const [dropdownOptions, setDropdownOptions] = useState<string[]>([]);
    const [selectedOption, setSelectedOption] = useState<string>('');

    useEffect(() => {
        fetch(APIS.users.getAll)
            .then(response => response.json())
            .then(data => {
                setDropdownOptions(data);
            })
            .catch(error => {
                console.error('Error fetching dropdown options:', error);
            });
    }, []);

    const handleDropdownSelect = (selectedValue: string) => {
        setSelectedOption(selectedValue);
    };


    // End Landlord Print
    // Start project amenitieslist
    // const { getAll } = APIS.amenities;
    // const { data } = useFetchData({ getAll });
    const { getAll, create, update, delete: deleteUrl } = APIS.users;
    const { data } = useFetchData({ getAll, create, update, deleteUrl });

    const AmenitiesList: any = useMemo(() => {
        let oneToOneData: any = [];
        if (data.length > 0 && formik?.values?.projectType?.length > 0) {
            data.forEach((amenityRow: any) => {
                amenityRow.categories.forEach((category: any) => {
                    if (
                        Boolean(
                            formik.values.projectType.find((type: any) => type === category)
                        )
                    ) {
                        amenityRow.list.forEach((amenity: any) => {
                            oneToOneData.push({
                                label: amenity.title,
                                value: amenity.title,
                                description: amenity.description,
                                key: amenity.title,
                            });
                        });
                    }
                });
            });
            return uniqBy(oneToOneData, "label");
        }
        return [];
    }, [data, formik.values.projectType]);

    const [amenties, setAmenities]: any = useState({});
    const [viewPort, setViewPort] = useState({
        latitude: 31.5497,
        longitude: 74.3436,
        zoom: 8,
    });
    const changedAmenity = (event: any, index: number) => {
        let shallowAmenities = amenties;
        shallowAmenities = {
            ...shallowAmenities,
            [event.target.name]: true,
        };
        setAmenities(shallowAmenities);
        const toSetAmenities = AmenitiesList.filter(
            (amenity: any) => shallowAmenities[amenity.label]
        );
        formik.setFieldValue("amenities", toSetAmenities);
    };

    const submitHandler = (event: any) => {
        event.preventDefault();
    };

    // END PROPERTY AMENTIES

    const editor = useRef(null);
    // start FormatPrice function
    function formatPrice(price: number) {
        const suffixes = ["", "K", "M", "B", "T"];
        const suffixNum = Math.floor(("" + price).length / 3);
        let shortValue = parseFloat(
            (suffixNum !== 0 ? price / Math.pow(1000, suffixNum) : price).toPrecision(
                2
            )
        );
        if (shortValue % 1 !== 0) {
            shortValue = parseFloat(shortValue.toFixed(1));
        }
        return shortValue + suffixes[suffixNum];
    }
    // End formatprice function
    const state = {
        message:
            "If there is any rental income coming from this property please right it's rental income else leave empty.",
    };

    useEffect(() => {
        let title = "";

        if (formik.values.furnishingStatus === "furnished") {
            title += ` Furnished - `;
        } else {
            title += ` `;
        }

        if (formik.values.subCategory) {
            title += ` ${formik.values.subCategory}`;
        }

        if (formik.values.bedrooms) {
            title += ` - ${formik.values.bedrooms} bedroom(s)`;
        }

        formik.setFieldValue("title", title);
    }, [
        property,
        modalMode,
        formik.values.furnishingStatus,
        formik.values.subCategory,
        formik.values.bedrooms,
    ]);
    const [landlord, setLandlord] = useState<any[]>([]);
    useEffect(() => {
        // Filter and map the data within the useEffect
        const filteredLandlords = data.filter((row: any) => row.role === 'landlord');
        const mappedLandlords = filteredLandlords.map((landlord: any) => ({
            value: landlord?._id,
            label: landlord?.fname,
        }));

        // Update the state with the mapped data
        setLandlord(mappedLandlords);
    }, [data]);
    const classes = useStyles();
    function HeadingText() {
        const { focused } = useFormControl() || {};
        const headingColor = useMemo(() => {
            if (error) {
                return AppErrorColor;
            }

            if (focused && !error) {
                return "#007FFF";
            }

            return AppTextColor;
        }, [focused]);
        return (
            <Typography

                className={classes.headingStyles}
                fontSize={18}

                color={headingColor}
            >
                Owner Name
            </Typography>
        );
    }
    function HeadingText2() {
        const { focused } = useFormControl() || {};
        const headingColor = useMemo(() => {
            if (error) {
                return AppErrorColor;
            }

            if (focused && !error) {
                return "#007FFF";
            }

            return AppTextColor;
        }, [focused]);
        return (
            <Typography

                className={classes.headingStyles}
                fontSize={18}

                color={headingColor}
            >
                Project Name
            </Typography>
        );
    }


    const { getAllProject } = APIS.projects;
    const { projectData } = useFetchData({ getAllProject });
    const [projects, setProjects] = useState<any[]>([]);


    useEffect(() => {
        // Filter and map the data within the useEffect
        const mappedProjects = projectData.map((item: any) => ({
            value: item?._id,
            label: item?.projectName,
        }));

        // Update the state with the mapped data
        setProjects(mappedProjects);
    }, [projectData]);

    return (
        <Modal
            setOpen={() => setOpenOne(!openOne)}
            open={openOne}
            title={
                modalMode === "edit"
                    ? ` Update Property`
                    : ""
            }

            cancelButtonTitle="Cancel"
            acceptButtonVarient="contained"
            acceptButtonTitle={
                modalMode === "edit"
                    ? ` Update Property`
                    : ""
            }
            handleAccept={() => formik.handleSubmit()}
            handleClose={() => formik.resetForm()}
            loading={loading}
            isActionable={true}
        >
            {loading ? (
                <Box
                    sx={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        left: "50%",
                        top: "30%",
                    }}
                >
                    <Loader top='"60%' left='"50%' />
                </Box>
            ) : null}
            <Box rowGap="10px" display="flex" flexDirection="column" mt="10px" >
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>
                        {/* <FormControl style={{ width: "100%" }}>
                            <HeadingText2 />
                            <Select
                                style={{ width: "100%" }}
                                sx={{ backgroundColor: "#fcfcfb" }}

                                id="projectName"
                                value={formik.values.projectName}
                                label={<HeadingText2 />}
                                onChange={(event: any) => {
                                    formik.setFieldValue("projectName", event.target.value);
                                }}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                {projects.map((item: any) => {
                                    return (
                                        <MenuItem
                                            key={item.value}
                                            value={item.value}
                                            disabled={item.disabled}
                                        >
                                            {item.label}
                                            {item.badge && (
                                                <Chip
                                                    size="small"
                                                    sx={{ marginLeft: "8px" }}
                                                    label={item.badge}
                                                    color="primary"
                                                    variant="filled"
                                                />
                                            )}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl> */}
                        <CustomInput
                            id="projectName"
                            label="Building Name *"
                            value={projectLookup[formik.values.projectName] || ''}
                            type="text"
                            disabled
                        />
                        {/* <ProjectNameDropdown
                            id="projectName"
                            onChangeOption={(value: any) => {
                                formik.setFieldValue("projectName", value.projectName);
                                formik.setFieldValue("projectName", value._id);
                            }}
                            isError={
                                formik.touched?.projectName &&
                                Boolean(formik.errors.projectName)
                            }
                            helperText={
                                formik.touched.projectName ? formik.errors.projectName : ""
                            }
                        /> */}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        {/* <DropDown
                            id="floorNo"
                            label="Floor No *"
                            value={formik.values.floorNo}
                            onChangeHandler={(event: any) => {
                                formik.setFieldValue("floorNo", event.target.value);
                            }}
                            isError={
                                formik.touched.floorNo && Boolean(formik.errors.floorNo)
                            }
                            helperText={formik.touched.floorNo ? formik.errors.floorNo : ""}
                            options={propertyFloorOptions}
                            disabled
                        /> */}
                        <CustomInput
                            id="floorNo"
                            label="Floor No *"
                            placeholder="Type here..."
                            value={capitalizeFirstLetter(formik.values.floorNo)}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <CustomInput
                            id="appartmentNo"
                            label="Appartment No *"
                            placeholder="Type here..."
                            value={capitalizeFirstLetter(formik.values.appartmentNo)}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <CustomInput
                            id="category"
                            label="Category*"
                            value={capitalizeFirstLetter(formik.values.category)}
                            type="text"
                            disabled
                        />
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={6}>
                        <DropDown
                            id="subCategory"
                            label="Property SubCategory *"
                            value={formik.values.subCategory}
                            onChangeHandler={(event: any) => {
                                formik.setFieldValue("subCategory", event.target.value);
                            }}
                            isError={
                                formik.touched.subCategory && Boolean(formik.errors.subCategory)
                            }
                            helperText={
                                formik.touched.subCategory ? formik.errors.subCategory : ""
                            }
                            options={propertyCategoryOptions.filter(
                                (item: any) => item.category === formik.values.category
                            )}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={6}>
                        <CustomInput
                            id="area"
                            label="Area *"
                            placeholder="Type here..."
                            value={formik.values.area}
                            type="number"
                            disabled
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    {formik.values.owner.map((owner, index) => (
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id={`owner.purchasePrice${index}`}
                                    label="New Purchase Price *"
                                    placeholder=""
                                    value={owner?.purchasePrice}
                                    type="number"
                                    onChangeHandler={(event: any) => {
                                        // formik.setFieldValue(`owner.purchasePrice${index}`, event.target.value);
                                        const newOwners = [...formik.values.owner];
                                        newOwners[index].purchasePrice = event.target.value;
                                        formik.setFieldValue("owner", newOwners);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id={`owner.date${index}`}
                                    label="Purchasing Date*"
                                    placeholder="Purchasing Date"
                                    value={owner?.date}
                                    type="date"
                                    onChangeHandler={(event: any) => {
                                        // formik.setFieldValue(`owner.date${index}`, event.target.value);
                                        const newOwners = [...formik.values.owner];
                                        newOwners[index].date = event.target.value;
                                        formik.setFieldValue("owner", newOwners);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="ownerType"
                                    label="Owner *"
                                    value={formik.values.ownerType}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("ownerType", event.target.value);
                                    }}
                                    isError={
                                        formik.touched.ownerType && Boolean(formik.errors.ownerType)
                                    }
                                    helperText={
                                        formik.touched.ownerType ? formik.errors.ownerType : ""
                                    }
                                    options={propertyOwnerOptions}
                                />
                            </Grid>

                            {formik.values.ownerType === "Others" && (
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl style={{ width: "100%" }}>
                                        <HeadingText />
                                        <Select
                                            style={{ width: "100%" }}
                                            sx={{ backgroundColor: "#fcfcfb" }}
                                            id={id}
                                            // value={owner?.landlordId}
                                            label={<HeadingText />}
                                            onChange={(event: any) => {
                                                const newOwners = [...formik.values.owner];
                                                newOwners[index].landlordId = event.target.value;
                                                formik.setFieldValue("owner", newOwners);
                                            }}
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                        >
                                            {landlord.map((item: any) => {
                                                return (
                                                    <MenuItem
                                                        key={item.value}
                                                        value={item.value}
                                                        disabled={item.disabled}
                                                    >
                                                        {item.label}
                                                        {item.badge && (
                                                            <Chip
                                                                size="small"
                                                                sx={{ marginLeft: "8px" }}
                                                                label={item.badge}
                                                                color="primary"
                                                                variant="filled"
                                                            />
                                                        )}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                        </>
                    ))}
                </Grid>
            </Box>
        </Modal>
    );
};

export default UpdatePropertyOwner;
