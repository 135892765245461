import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '_metronic/helpers';
import { PageTitle } from '_metronic/layout/core';
import axios from 'axios';
import { API } from 'app/utils/config';
import { Button } from 'react-bootstrap-v5';

const EmploymentDetailPage = () => {
//   const { getAll, create, update, getById, deleteUrl } = API.employment;
  const params = useParams<{id: string}>();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${API}/api/employment/getByEmploymentId/${params?.id}`)
      .then(response => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        setError(error);
        setIsLoading(false);
      });
  }, [params?.id]);


  console.log(data?.id[0],"datadaata")
    
    return (
        <>
            <PageTitle>Application Details</PageTitle>
            <div className="card mt-4 mb-5 mb-xl-10">
                <div className="card-body pt-9">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                        <div className="me-7 mb-4">
                            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                <img
                                    src={toAbsoluteUrl(
                                        "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                    )}
                                    alt=""
                                />
                            </div>
                        </div>

                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                {data?.id.map((item:any ,index:any) => ( 
                                <div className="d-flex flex-column" key={index}>
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1 text-capitalize" >
                                           {item.name} {item.lname}
                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                        <KTSVG
                                            path="/media/icons/duotune/communication/com006.svg"
                                            className="svg-icon-4 me-1"
                                        />
                                        <div className="d-flex align-items-center text-gray-700 me-5 mb-2" >
                                        {item.contact}
                                        </div>
                                        <div className="d-flex align-items-center text-gray-700 mb-2" >
                                            <KTSVG
                                                path="/media/icons/duotune/communication/com011.svg"
                                                className="svg-icon-4 me-1"
                                            />
                                            {item.email}
                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                        <div className="d-flex justify-content-center">
                                            <KTSVG
                                                path="/media/icons/duotune/general/gen001.svg"
                                                className="svg-icon-4 me-1"
                                            />
                                            <div className="text-gray-700 me-5 mb-2" >
                                            {item.address}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center fw-bold fs-6 mb-4 pe-2">
                                        <KTSVG
                                            path="/media/icons/duotune/general/gen008.svg"
                                            className="svg-icon-4 me-1"
                                        />
                                        <div className="text-gray-700 me-5 mb-2" >
                                            {item.coverLetter}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                        <div className="text-gray-700 me-5 mb-2" >
                                            <Button>Preview Resume</Button>
                                        </div>
                                    </div>


                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmploymentDetailPage