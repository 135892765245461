/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Dropdown1 } from '../../../_metronic/partials'
import { useLocation } from 'react-router'

const filters = [

  {
    name: 'projectCategory',
    value: 'rent',
    display: 'For Rent'
  },
  {
    name: 'projectCategory',
    value: 'sale',
    display: 'For Sale'
  },
  {
    name: 'projectType',
    value: 'commercial',
    display: 'Commercial',
  },
  {
    name: 'projectType',
    value: 'residential',
    display: 'Residential',
  },
  {
    name: 'reset',
    value: 'reset',
    display: 'Reset'
  },
]

const ProjectHeader = ({ filter, setFilter }: any) => {
  const location = useLocation()
  const updateFilter = (item: any) => {
    let updatedFilter = {}
    if (item.name === 'reset') {
      updatedFilter = {}
    }
    else {
      updatedFilter = {
        ...filter,
        [item?.name]: item?.value
      }
    }
    setFilter(updatedFilter)
  }
  return (

    <>
      {/* <div className='card mb-5 mb-xl-2'>
        <div className='card-body pt-1 pb-0'>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap' style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '24px',
            }}>
              {
                filters.map((item) => {
                  return <li key={item.value} className='nav-item' style={{
                    cursor: 'pointer',
                    padding: '8px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '4px',
                    margin: '8px',
                    outline: filter[item.name] === item.value ? '1px solid lightgreen' : 'none',
                    color: filter[item.name] === item.value ? 'lightgreen' : 'inherit'
                  }} onClick={() => { updateFilter(item) }}>
                    {item.display}
                  </li>
                })
              }

            </ul>
          </div>
        </div>
      </div> */}


    </>
  )
}

export { ProjectHeader }
