import { useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import Modal from '../../Modal'
import CustomInput from '../../Inputs'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Loader from '../../Loader'
const validationSchema = yup.object({
    currentEvaluation: yup.number().required('Evaluation Value is required'),

})

type UpdateEvaluationProps = {
    open: any;
    setOpen: any;
    evaluation?: any;
    loading?: boolean;
    updateEvaluation?: any;
    modalMode?: any;
    value?: string;
}
const UpdateEvaluation = ({
    open,
    setOpen,
    evaluation,
    loading,
    modalMode,
    updateEvaluation,
    value,
}: UpdateEvaluationProps) => {

    const formik = useFormik({
        initialValues: {
            currentEvaluation: evaluation?.currentEvaluation || '',
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            updateEvaluation(values)
            formik.resetForm()
        },
    })

    return (
        <Modal
            setOpen={() => setOpen(!open)}
            open={open}
            title={
                modalMode === "edit"
                    ? ` Update Evaluation `
                    : "Evaluation"}
            cancelButtonTitle="Cancel"
            acceptButtonVarient="contained"
            acceptButtonTitle={
                modalMode === "edit"
                    ? ` Update Evaluation `
                    : "Evaluation"}
            handleAccept={() => formik.handleSubmit()}
            handleClose={() => formik.resetForm()}
            loading={loading}
            isActionable={true}
        >
            {loading ?
                <Box
                    sx={{
                        height: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        left: '50%',
                        top: '30%'
                    }}
                >
                    <Loader top='"60%' left='"50%' />
                </Box>
                : null}
            <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={12}>
                        <CustomInput
                            id="currentEvaluation"
                            label="Evaluation Value"
                            placeholder="Type here..."
                            type="number"
                            value={formik.values.currentEvaluation}
                            onChangeHandler={
                                formik.handleChange
                            }
                            isError={formik.touched?.currentEvaluation && Boolean(formik.errors.currentEvaluation)}
                            helperText={formik.touched.currentEvaluation ? formik.errors.currentEvaluation : ''}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default UpdateEvaluation
