import { capitalizeFirstLetter, IconRender } from "app/components/Table";
import Table from "app/components/Table";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { PageTitle, useLayout } from "_metronic/layout/core";
import { useEffect, useState } from "react";
import moment from "moment";
import { EyeIcon } from "@heroicons/react/solid";
import UpdateChangeConsultantRequest from "./UpdateChangeConsultantRequest";

const columns = (iconClickHandler: any, toggleModal: (row: any) => void) => [
    {
        name: "Landlord's Name",
        key: "landlordName",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    }, {
        name: "Landlord's Email",
        key: "landlordEmail",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => (value),
    }, {
        name: "Current Consultant",
        key: "currentConsultantName",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value.length > 15 ? `${value.substring(0, 15)}...` : value),
    }, {
        name: "Reason",
        key: "reason",
        numaric: false,
        isSortable: true,
        renderContent: (value: string) => value.length > 15 ? `${value.substring(0, 15)}...` : value,
    }, {
        name: "Change Consultant Type",
        key: "selectionType",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => (value),
    }, {
        name: "Status",
        key: "requestStatus",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    }, {
        name: "Date",
        key: "createAt",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => moment(value).format("DD MMM YYYY, h:mm A"),
    }, {
        name: "View",
        key: "action",
        numaric: false,
        isSortable: true,
        component: (row: any) => (
            <>
                <div className="">
                    <button className="" onClick={() => toggleModal(row)} style={{ border: 'none', background: 'none' }}>
                        <EyeIcon className="h-5 w-5" style={{ width: '20px' }} />
                    </button>
                </div>
                {/* {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "edit")}
                {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, "delete")} */}
            </>
        ),
    }, {
        name: "Action",
        key: "action",
        numaric: false,
        isSortable: true,
        component: (row: any) => (
            <>
                {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "EDIT")}
                {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, "deleteChangeConsultantRequest")}
            </>
        ),
    },
];

export function ConsultantChangeRequest() {
    const [open2, setOpen2] = useState(false)
    const [currentRow, setCurrentRow] = useState<any>(null);
    const { getAllRequests, updateChangeRequest, delete: deleteUrl } = APIS.changeConsultant;
    const {
        data,
        loading,
        page,
        open,
        setOpen,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count,
        modalMode,
        updateChangeConsultantFunction,
        current,
        iconClickHandler,
        setCurrent,
    } = useFetchData({ getAllRequests, updateChangeRequest, deleteUrl });
    const { setClickButtonHandler } = useLayout();

    const toggleModal = (row: any = null) => {
        setOpen2(!open2);
        setCurrentRow(row);
    };
    useEffect(() => {
        setClickButtonHandler(() => () => {
            setOpen(true);
            setCurrent({});
        });
    }, []);
    let filteredData: any
    useEffect(() => {
        filteredData = data?.filter((row: any) => row?.requestStatus !== 'rejected')
    }, [data])
    return (
        <>
            <PageTitle>Consultant Change Requests</PageTitle>
            <div className="pb-5">
                <Table
                    columns={columns(iconClickHandler("/changeConsultant"), toggleModal)}
                    data={filteredData}
                    loading={loading}
                    onRowClick={(row: any, type: any) =>
                        iconClickHandler("/changeConsultant")(row, type)
                    }
                    isPaginationRequired
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    total={count}
                />
            </div>


            <div className={`modal fade ${open2 ? 'show d-block' : 'd-none'}`} tabIndex={-1}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Request Details</h5>
                            <button type="button" className="btn-close" onClick={toggleModal}></button>
                        </div>
                        <div className="modal-body">
                            {currentRow && (
                                <>
                                    <p><strong>Landlord's Name:</strong> {capitalizeFirstLetter(currentRow.landlordName)}</p>
                                    <p><strong>Landlord's Email:</strong> {currentRow.landlordEmail}</p>
                                    <p><strong>Current Consultant:</strong> {capitalizeFirstLetter(currentRow.currentConsultantName)}</p>
                                    <p><strong>Reason for Change:</strong> {currentRow.reason}</p>
                                    <p><strong>Change Consultant Type:</strong> {currentRow.selectionType}</p>

                                    {currentRow?.selectionType === 'By MySelf' && (
                                        <div className="">
                                            <p className="">Landlord has suggested a consultant</p>
                                            <p><strong>Consultant Name:</strong> {capitalizeFirstLetter(currentRow.landlordName)}</p>
                                            <p><strong>Consultant Email:</strong> {currentRow.landlordEmail}</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={toggleModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <UpdateChangeConsultantRequest
                setOpen={setOpen}
                open={open}
                loading={loading}
                update={modalMode === "EDIT" ? updateChangeConsultantFunction : ''}
                type={modalMode}
                data={current}
            />
        </>
    );
}
