import { GET_ALL_PENDING_USERS, UPDATE_AGENCY_STATUS } from "app/constants/api";
import axios from "axios";

export function getAllPendingUsers(accessToken: string | null, filter: any = {}) {
  return axios.get(`${GET_ALL_PENDING_USERS}`, {
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`
    //   },
    params: { filters: filter },
  });
}

export function updateAgency(userId: string, status: string) {
  return axios.post(`${UPDATE_AGENCY_STATUS}`, { status, userId });
}
