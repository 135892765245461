import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import { AppTextColor, AppErrorColor } from "../../../theme/Colors";

import {
  Badge,
  Chip,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  useFormControl,
} from "@mui/material";
const useStyles = makeStyles(() => ({
  headingStyles: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
    // margin: '0px 0px 10px 0px !important',
  },
}));

const DropDown = ({
  options,
  value,
  onChangeHandler,
  error,
  id,
  label,
  helperText,
}: any) => {
  const classes = useStyles();

  function HeadingText() {
    const { focused } = useFormControl() || {};

    const headingColor = React.useMemo(() => {
      if (error) {
        return AppErrorColor;
      }

      if (focused && !error) {
        return "#007FFF";
      }

      return AppTextColor;
    }, [focused]);

    return (
      <Typography
        // fontSize={18}
        className={classes.headingStyles}
        color={headingColor}
      >
        {label}
      </Typography>
    );
  }
  return (
    <FormControl style={{ width: "100%" }}>
      <HeadingText />
      <Select
        style={{ width: "100%" }}
        error={error}
        id={id}
        value={value}
        label={<HeadingText />}
        onChange={onChangeHandler}
        displayEmpty
        sx={{
          backgroundColor: "#fcfcfb",
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
        }}
        inputProps={{ "aria-label": "Without label" }}
      >
        {options.map((item: any) => {
          return (
            <MenuItem
              key={item.value}
              value={item.value}
              disabled={item.disabled}
            >
              {item.label}
              {item.badge && (
                <Chip
                  size="small"
                  sx={{ marginLeft: "8px" }}
                  label={item.badge}
                  color="primary"
                  variant="filled"
                />
              )}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export const blockProjectOptions = [
  {
    value: "block",
    label: "Block",
  },
  {
    value: "project",
    label: "Project",
  },
];

export default DropDown;
