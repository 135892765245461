import React, { FC, lazy, useEffect } from "react";
import { Redirect, Switch, Route, useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { PrivateRoutes } from "./PrivateRoutes";
import { Logout, AuthPage } from "../components/auth";
import { ErrorsPage } from "../components/errors/ErrorsPage";
import { RootState } from "../../setup";
import * as auth from '../components/auth/redux/AuthRedux'
import { MasterInit } from "../../_metronic/layout/MasterInit";
import WizardsPage from "../components/ProfilePage/ProfilePage";
import { Verification } from "../components/auth/components/Verification";
import PinProvider from "app/services/context/pin/PinContext";
import ResetPassword from "app/components/auth/components/ResetPassword";
import useLastLocation from "./LastLocation";
import VerificationComponent from "app/components/auth/components/VerificationComponent";

const Routes: FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");
  if (token) {
    dispatch(auth.actions.login(token));
  }


  const history = useHistory();
  const isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  // useEffect(() => {
  //   const lastLocation = localStorage.getItem('lastLocation');
  //   if (lastLocation) {
  //     history.push(lastLocation);
  //   }
  // }, [history]);

  // useLastLocation();

  return (
    <>
      <Switch>
        <Route path="/account/verification" component={Verification} />
        <Route path="/account/verificationComponent/:token" component={VerificationComponent} />
        <Route path="/account/resetPassword/:token/:id" component={ResetPassword} />

        {!isAuthorized ? (
          <Route>
            <AuthPage />
          </Route>
        ) : (
          <Redirect from="/auth" to="/dashboard" />
        )}
        {isAuthorized ? (
          <Route exact path='/profile' component={WizardsPage} />
        ) : (
          <Redirect from='/auth' to='/dashboard' />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : (
          <>
            <PinProvider>``
              <MasterLayout>
                <PrivateRoutes />
              </MasterLayout>
            </PinProvider>
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  );
};

export { Routes };
