import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Modal from "../../Modal";
import CustomInput from "../../Inputs";
import { useFormik } from "formik";
import * as yup from "yup";
import Loader from "../../Loader";
import { useImageMethod } from "app/hooks/useImageUpload";
import { API } from "app/utils/config";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { RootState } from "setup";
import { shallowEqual, useSelector } from "react-redux";

const validationSchema = yup.object({
  fname: yup.string().required("Landlord's name is required"),
  email: yup
    .string()
    .required("Landlord's email is required")
    .email("Invalid email address"),
  password: yup
    .string()
    .required("Landlord's password is required")
    .min(8, "Password must be at least 8 characters long"),
  contact: yup.string().required("landlord's contact is required"),
});

type EditProps = {
  open: any;
  setOpen: any;
  data?: any;
  update?: any;
  loading?: boolean;
  type: "ADD" | "EDIT";
};

const Edit = ({
  open,
  setOpen,
  data,
  update,
  loading,
  type = "EDIT",
}: EditProps) => {
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  const formik = useFormik({
    initialValues: {
      fname: data?.fname || "",
      email: data?.email || "",
      password: "12345678",
      contact: data?.contact || "",
      role: "landlord",
      isVerified: true,
      status: "approved",
      approvalStatus: "pending",
      consultant: user?._id || "yes",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      update(values);
      formik.resetForm();
    },
  });
  useEffect(() => {
    if (Object.keys(data).length > 0) formik.setValues({ ...data });
  }, [data, open]);

  return (
    <Modal
      setOpen={() => setOpen(!open)}
      open={open}
      title={
        type === "EDIT"
          ? `Update Landlord ` + data?.fname?.toUpperCase()
          : "Add New Landlord"
      }
      cancelButtonTitle="Cancel"
      acceptButtonVarient="contained"
      acceptButtonTitle={
        type === "EDIT"
          ? ` Update Landlord`
          : "Add New Landlord"
      }
      handleAccept={() => formik.handleSubmit()}
      handleClose={() => formik.resetForm()}
      loading={loading}
      isActionable={true}
    >
      {loading ? (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "30%",
          }}
        >
          <Loader top='"60%' left='"50%' />
        </Box>
      ) : null}
      <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="fname"
              label="Landlords Name"
              placeholder="Type here..."
              value={formik.values.fname}
              onChangeHandler={formik.handleChange}
              isError={formik.touched?.fname && Boolean(formik.errors.fname)}
              helperText={formik.touched.fname ? formik.errors.fname : ""}
            />
          </Grid>
          {type !== "EDIT" ? (
            <Grid item xs={12} sm={12} md={6}>
              <CustomInput
                id="email"
                label="Email"
                placeholder="Type here..."
                value={formik.values.email}
                onChangeHandler={formik.handleChange}
                isError={formik.touched?.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email ? formik.errors.email : ""}
              />
            </Grid>
          ) : null}

          <Grid item xs={12} sm={12} md={12}>
            <Typography sx={{ fontWeight: "bold" }}>Phone number</Typography>
            <div
              style={{
                fontSize: "30.2rem",
              }}
            >
              <PhoneInput
                country={"pk"}
                value={formik.values.contact}
                onChange={(phone: any) =>
                  formik.setFieldValue("contact", phone)
                }
                inputProps={{
                  name: "contact",
                  id: "contact",
                  required: true,
                  autoFocus: true,
                  placeholder: "Enter phone number",
                  style: {
                    fontSize: "1.2rem", // change the font size as per your preference
                    marginTop: "1rem", // change the margin as per your preference
                    height: "50px",
                    width: "328px",
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Edit;
