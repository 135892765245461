import React from "react";
import { GoogleLogin } from "react-google-login";
// import googleSvg from "images/Google.svg";
import { toAbsoluteUrl } from '../../../../_metronic/helpers'



const GoogleLoginButton = () => {
  const responseGoogle = (data:any) => {
    console.log("ihsan khan");
    console.log(data);
    // debugger;
  };
  return (

    
    <div className="application">

      {/* <GoogleLogin
      className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
        clientId="885794475045-o1r637kjaikh2j24re3polga1faiprst.apps.googleusercontent.com"
        buttonText="Continue with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      /> */}
       <GoogleLogin
    clientId="885794475045-o1r637kjaikh2j24re3polga1faiprst.apps.googleusercontent.com"
    render={renderProps => (
      <button
    className="btn btn-flex flex-center btn-light btn-lg w-100 mb-2"
       onClick={renderProps.onClick} disabled={renderProps.disabled}>
         <img
       alt='Logo'
       src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
       className='h-20px me-3'
     />Continue with Google</button>
    )}
    buttonText="Login"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  />
      {/* <div className="g-signin2" dataOnsuccess="onSignIn"></div> */}
    </div>
  );
};

export default GoogleLoginButton;
