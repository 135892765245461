import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ProfileLayout } from '../../../_metronic/layout/ProfileLayout'
import { ProfileUpdate } from './components/ProfileUpdateStepper'


const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/profile',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const ProfilePage: React.FC = () => {

  return (
    <>
    {/* <ProfileLayout >
    </ProfileLayout> */}
      {/* <PageTitle breadcrumbs={wizardsBreadCrumbs}>Profile</PageTitle> */}
      <ProfileUpdate />
    </>

  )
}

export default ProfilePage
