import { capitalizeFirstLetter, IconRender } from "app/components/Table";
import Table from "app/components/Table";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import { PageTitle, useLayout } from "_metronic/layout/core";
import { useEffect, useState } from "react";
import { RootState } from "setup";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getConsultantDetailsById } from "../auth/redux/AuthCRUD";
import moment from "moment";

const columns = (iconClickHandler: any, projectLookup: any, landlordNameLookup: any,) => [
    {
        name: "Landlord",
        key: "landlordId",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => (
            <div className="overflow-ellipsis" title={value}>
                <div className="overflow-ellipsis text-capitalize" title={value}>
                    {landlordNameLookup[value]}
                </div>
            </div>
        ),
    },
    {
        name: "Building",
        key: "projectName",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => (
            <div className="overflow-ellipsis" title={value}>
                <div className="overflow-ellipsis text-capitalize" title={value}>
                    {projectLookup[value] || value}
                </div>
            </div>
        ),
    },
    {
        name: "Sub Category",
        key: "subCategory",
        numaric: false,
        isSortable: true,
    }, {
        name: "Floor No",
        key: "floorNo",
        numaric: false,
        isSortable: true,
    },
    {
        name: "Apartment No",
        key: "appartmentNo",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => value,
    },
    {
        name: "Purchase Price",
        key: "price",
        numaric: true,
        isSortable: true,
        renderContent: (value: any) =>
            new Intl.NumberFormat().format(parseInt(value || 0)),
    },
    {
        name: "Status",
        key: "isVerified",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => (value ? "Verified" : "Unverified"),
    },
    {
        name: "Listed",
        key: "isListed",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => (value ? "Listed" : "Not Listed"),
    },
    {
        name: "Last Updated",
        key: "updateAt",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => moment(value).format("hh:mm a (DD-MMM-YYYY)"),
    },
];

interface RouteParams {
    id: string;
}
export function PropertiesOfTheLandlordsOfConsultant() {
    let { id } = useParams<RouteParams>();

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const { getAllNames } = APIS.ProjectName;
    const { getAll } = APIS.users;
    const { projectNameData, data } = useFetchData({ getAll, getAllNames });
    const [projectLookup, setProjectLookup] = useState<any>({});
    const [landlordNameLookup, setLandlordNameLookup] = useState<any>({});

    useEffect(() => {
        // Create a lookup object for projectName id's to project Names
        const lookup: any = {};
        projectNameData.forEach((row: any) => {
            lookup[row?._id] = row?.projectName;
        });
        setProjectLookup(lookup);
    }, [projectNameData]);

    // Lookup for Landlord Name
    useEffect(() => {
        const lookup: any = {};
        data.forEach((row: any) => {
            lookup[row?._id] = row?.fname;
        });
        setLandlordNameLookup(lookup);
    }, [data]);

    const [consultantLandlordsProperties, setConsultantLandlordsProperties] = useState([]);
    const [consultantName, setConsultantName] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getConsultantDetailsById(id);
                setConsultantLandlordsProperties(response?.consultant?.properties);
                setCount(response?.consultant?.properties?.length)
                setConsultantName(response?.consultant?.fname)
                setLoading(false)
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [id]);

    const {
        iconClickHandler,
    } = useFetchData({});

    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    const propertiesData = consultantLandlordsProperties
    return (
        <>
            <PageTitle>{consultantName} Landlord's Properties</PageTitle>
            <div className="pb-5">
                <Table
                    columns={columns(iconClickHandler("/landlordsOfConsultant"), projectLookup, landlordNameLookup)}
                    data={propertiesData}
                    loading={loading}
                    onRowClick={(row: any, type: any) =>
                        iconClickHandler("/landlordsOfConsultant")(row, type)
                    }
                    isPaginationRequired
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    total={count}
                />
            </div>
        </>
    );
}
