import React from "react";
import { MenuItem } from "./MenuItem";
import { useIntl } from "react-intl";

export function MenuInner() {
  const intl = useIntl();
  return (
    <>
      <MenuItem
        icon="/media/icons/duotune/layouts/lay009.svg"
        title="STR Dashboard"
        to="/str"
      />
    </>
  );
}
