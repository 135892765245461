import { useGetUtilMethod } from "app/hooks/useAxios"
import { createContext, memo, useEffect, useState } from "react"

export const GeneralContext = createContext({
  getOptions: (a: any, b: any, filterBy: any) => {},
  selectedItem: {},
  setSelectedItem: (data: any) => {},

});

const GeneralProvider = ({ children }: { children: JSX.Element }) => {
  const { getUtilMethod } = useGetUtilMethod()
  const [selectedItem, setSelectedItem]: any = useState({})

  const getOptions = async (api: string, key: string[], filterBy?: any) => {
    let options: { [key: string]: any }[] = []
    const { status, data }: any = await getUtilMethod({ url: api })
    if (status === 200) {
      options = data?.items?.map((item: { [key: string]: any }, i: number) => {
        let label = ""
        label = key.reduce((prev: any, next: any, index: any) => {
          return index === 0
            ? (prev = item[next])
            : (prev = prev + " ~ " + item[next])
        }, "")
        const items = {
          ...item,
          label: label,
          value: item._id,
        }
        return items
      })
      if (filterBy) {
        const filtered = filterBy(options)
        return {
          options: filtered,
          hasMore: false,
        }
      }
      return {
        options,
        hasMore: false,
      }
    } else {
      return {
        options: [],
        hasMore: false,
      }
    }
  }

  return (
    <GeneralContext.Provider
      value={{ getOptions, selectedItem, setSelectedItem }}
    >
      {children}
    </GeneralContext.Provider>
  )
}

export const withGeneralProvider = (Component: any, additionlProps?: any) => {
  const Comp = (props: any) => {
    return (
      <GeneralProvider>
        <Component {...props} />
      </GeneralProvider>
    )
  }
  return memo(Comp)
}

export default GeneralProvider
