import { useEffect, useState, useMemo } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import { AppTextColor, AppErrorColor } from "../theme/Colors";

import {
  Badge,
  Chip,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  useFormControl,
} from "@mui/material";
import useFetchData from "app/hooks/useFetchData ";
import { APIS } from "Constants";
const useStyles = makeStyles(() => ({
  headingStyles: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },
}));

export let landlordsData: any[] = [];

const DropDown = ({
  options,
  value,
  onChangeHandler,
  error,
  id,
  label,
  helperText,
}: any) => {
  const classes = useStyles();

  function HeadingText() {
    const { focused } = useFormControl() || {};

    const headingColor = useMemo(() => {
      if (error) {
        return AppErrorColor;
      }

      if (focused && !error) {
        return "#007FFF";
      }

      return AppTextColor;
    }, [focused]);

    return (
      <Typography
        // fontSize={18}
        className={classes.headingStyles}
        color={headingColor}
      >
        {label}
      </Typography>
    );
  }
  const { getAll, create, update, delete: deleteUrl } = APIS.users;
  const { data, } = useFetchData({ getAll, create, update, deleteUrl });
  const [landlord, setLandlord] = useState<any[]>([]);
  useEffect(() => {
    // Filter and map the data within the useEffect
    const filteredLandlords = data.filter((row: any) => row.role === 'landlord');
    const mappedLandlords = filteredLandlords.map((landlord: any) => ({
      value: landlord?._id,
      label: landlord?.fname,
    }));

    // Update the state with the mapped data
    setLandlord(mappedLandlords);
  }, [data]);
  return (
    <FormControl style={{ width: "100%" }}>
      <HeadingText />
      <Select
        style={{ width: "100%" }}
        sx={{ backgroundColor: "#fcfcfb" }}
        error={error}
        id={id}
        value={value}
        label={<HeadingText />}
        onChange={onChangeHandler}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {options.map((item: any) => {
          return (
            <MenuItem
              key={item.value}
              value={item.value}
              disabled={item.disabled}
            >
              {item.label}
              {item.badge && (
                <Chip
                  size="small"
                  sx={{ marginLeft: "8px" }}
                  label={item.badge}
                  color="primary"
                  variant="filled"
                />
              )}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export const propertyTypeOptions = [
  {
    value: "Residential",
    label: "Residential",
  },
  {
    value: "Commercial",
    label: "Commercial",
  },
];


export const consultantTypeOptions = [
  {
    value: "By Imlaak",
    label: "By Imlaak",
  },
  {
    value: "By MySelf",
    label: "By MySelf",
  },
];

export const propertyFloorOptions = [
  { "value": "LG", "label": "LG" },
  { "value": "Ground", "label": "Ground" },
  { "value": "Mezanine", "label": "Mezanine" },
  { "value": "1", "label": "1" },
  { "value": "2", "label": "2" },
  { "value": "3", "label": "3" },
  { "value": "4", "label": "4" },
  { "value": "5", "label": "5" },
  { "value": "6", "label": "6" },
  { "value": "7", "label": "7" },
  { "value": "8", "label": "8" },
  { "value": "9", "label": "9" },
  { "value": "10", "label": "10" },
  { "value": "11", "label": "11" },
  { "value": "12", "label": "12" },
  { "value": "13", "label": "13" },
  { "value": "14", "label": "14" },
  { "value": "15", "label": "15" },
  { "value": "16", "label": "16" },
  { "value": "17", "label": "17" },
  { "value": "18", "label": "18" },
  { "value": "19", "label": "19" },
  { "value": "20", "label": "20" },
  { "value": "21", "label": "21" },
  { "value": "22", "label": "22" },
  { "value": "23", "label": "23" },
  { "value": "24", "label": "24" },
  { "value": "25", "label": "25" },
  { "value": "26", "label": "26" },
  { "value": "27", "label": "27" },
  { "value": "28", "label": "28" },
  { "value": "29", "label": "29" },
  { "value": "30", "label": "30" },
  { "value": "31", "label": "31" },
  { "value": "32", "label": "32" },
  { "value": "33", "label": "33" },
  { "value": "34", "label": "34" },
  { "value": "35", "label": "35" },
  { "value": "36", "label": "36" },
  { "value": "37", "label": "37" },
  { "value": "38", "label": "38" },
  { "value": "39", "label": "39" },
  { "value": "40", "label": "40" },
  { "value": "41", "label": "41" },
  { "value": "42", "label": "42" },
  { "value": "43", "label": "43" },
  { "value": "44", "label": "44" },
  { "value": "45", "label": "45" },
  { "value": "46", "label": "46" },
  { "value": "47", "label": "47" },
  { "value": "48", "label": "48" },
  { "value": "49", "label": "49" },
  { "value": "50", "label": "50" },
  { "value": "51", "label": "51" },
  { "value": "52", "label": "52" },
  { "value": "53", "label": "53" },
  { "value": "54", "label": "54" },
  { "value": "55", "label": "55" },
  { "value": "56", "label": "56" },
  { "value": "57", "label": "57" },
  { "value": "58", "label": "58" },
  { "value": "59", "label": "59" },
  { "value": "60", "label": "60" },
  { "value": "61", "label": "61" },
  { "value": "62", "label": "62" },
  { "value": "63", "label": "63" },
  { "value": "64", "label": "64" },
  { "value": "65", "label": "65" },
  { "value": "66", "label": "66" },
  { "value": "67", "label": "67" },
  { "value": "68", "label": "68" },
  { "value": "69", "label": "69" },
  { "value": "70", "label": "70" },
  { "value": "71", "label": "71" },
  { "value": "72", "label": "72" },
  { "value": "73", "label": "73" },
  { "value": "74", "label": "74" },
  { "value": "75", "label": "75" },
  { "value": "76", "label": "76" },
  { "value": "77", "label": "77" },
  { "value": "78", "label": "78" },
  { "value": "79", "label": "79" },
  { "value": "80", "label": "80" },
  { "value": "81", "label": "81" },
  { "value": "82", "label": "82" },
  { "value": "83", "label": "83" },
  { "value": "84", "label": "84" },
  { "value": "85", "label": "85" },
  { "value": "86", "label": "86" },
  { "value": "87", "label": "87" },
  { "value": "88", "label": "88" },
  { "value": "89", "label": "89" },
  { "value": "90", "label": "90" },
  { "value": "91", "label": "91" },
  { "value": "92", "label": "92" },
  { "value": "93", "label": "93" },
  { "value": "94", "label": "94" },
  { "value": "95", "label": "95" },
  { "value": "96", "label": "96" },
  { "value": "97", "label": "97" },
  { "value": "98", "label": "98" },
  { "value": "99", "label": "99" },
  { "value": "100", "label": "100" },
  { "value": "101", "label": "101" },
  { "value": "102", "label": "102" },
  { "value": "103", "label": "103" },
  { "value": "104", "label": "104" },
  { "value": "105", "label": "105" },
  { "value": "106", "label": "106" },
  { "value": "107", "label": "107" },
  { "value": "108", "label": "108" },
  { "value": "109", "label": "109" },
  { "value": "110", "label": "110" },
  { "value": "111", "label": "111" },
  { "value": "112", "label": "112" },
  { "value": "113", "label": "113" },
  { "value": "114", "label": "114" },
  { "value": "115", "label": "115" },
  { "value": "116", "label": "116" },
  { "value": "117", "label": "117" },
  { "value": "118", "label": "118" },
  { "value": "119", "label": "119" },
  { "value": "120", "label": "120" },
  { "value": "121", "label": "121" },
  { "value": "122", "label": "122" },
  { "value": "123", "label": "123" },
  { "value": "124", "label": "124" },
  { "value": "125", "label": "125" },
  { "value": "126", "label": "126" },
  { "value": "127", "label": "127" },
  { "value": "128", "label": "128" },
  { "value": "129", "label": "129" },
  { "value": "130", "label": "130" },
  { "value": "131", "label": "131" },
  { "value": "132", "label": "132" },
  { "value": "133", "label": "133" },
  { "value": "134", "label": "134" },
  { "value": "135", "label": "135" },
  { "value": "136", "label": "136" },
  { "value": "137", "label": "137" },
  { "value": "138", "label": "138" },
  { "value": "139", "label": "139" },
  { "value": "140", "label": "140" },
  { "value": "141", "label": "141" },
  { "value": "142", "label": "142" },
  { "value": "143", "label": "143" },
  { "value": "144", "label": "144" },
  { "value": "145", "label": "145" },
  { "value": "146", "label": "146" },
  { "value": "147", "label": "147" },
  { "value": "148", "label": "148" },
  { "value": "149", "label": "149" },
  { "value": "150", "label": "150" },
  { "value": "151", "label": "151" },
  { "value": "152", "label": "152" },
  { "value": "153", "label": "153" },
  { "value": "154", "label": "154" },
  { "value": "155", "label": "155" },
  { "value": "156", "label": "156" },
  { "value": "157", "label": "157" },
  { "value": "158", "label": "158" },
  { "value": "159", "label": "159" },
  { "value": "160", "label": "160" },
  { "value": "161", "label": "161" },
  { "value": "162", "label": "162" },
  { "value": "163", "label": "163" },
  { "value": "164", "label": "164" },
  { "value": "165", "label": "165" },
  { "value": "166", "label": "166" },
  { "value": "167", "label": "167" },
  { "value": "168", "label": "168" },
  { "value": "169", "label": "169" },
  { "value": "170", "label": "170" },
  { "value": "171", "label": "171" },
  { "value": "172", "label": "172" },
  { "value": "173", "label": "173" },
  { "value": "174", "label": "174" },
  { "value": "175", "label": "175" },
  { "value": "176", "label": "176" },
  { "value": "177", "label": "177" },
  { "value": "178", "label": "178" },
  { "value": "179", "label": "179" },
  { "value": "180", "label": "180" },
  { "value": "181", "label": "181" },
  { "value": "182", "label": "182" },
  { "value": "183", "label": "183" },
  { "value": "184", "label": "184" },
  { "value": "185", "label": "185" },
  { "value": "186", "label": "186" },
  { "value": "187", "label": "187" },
  { "value": "188", "label": "188" },
  { "value": "189", "label": "189" },
  { "value": "190", "label": "190" },
  { "value": "191", "label": "191" },
  { "value": "192", "label": "192" },
  { "value": "193", "label": "193" },
  { "value": "194", "label": "194" },
  { "value": "195", "label": "195" },
  { "value": "196", "label": "196" },
  { "value": "197", "label": "197" },
  { "value": "198", "label": "198" },
  { "value": "199", "label": "199" },
  { "value": "200", "label": "200" },
  { "value": "201", "label": "201" },
  { "value": "202", "label": "202" },
  { "value": "203", "label": "203" },
  { "value": "204", "label": "204" },
  { "value": "205", "label": "205" },
  { "value": "206", "label": "206" },
  { "value": "207", "label": "207" },
  { "value": "208", "label": "208" },
  { "value": "209", "label": "209" },
  { "value": "210", "label": "210" },
];

export const furnishingStatus = [
  {
    value: "furnished",
    label: "Furnished",
  },
  {
    value: "semi-furnished",
    label: "Semi-Furnished",
  },
  {
    value: "unfurnished",
    label: "Unfurnished",
  },
];

export const propertyOwnerOptions = [
  {
    value: "Imlaak",
    label: "Imlaak",
  },
  {
    value: "Others",
    label: "Others",
  },
]

export const propertyCategoryOptions = [
  {
    value: "Apartment",
    label: "Apartment",
    category: "Residential",
  },
  {
    value: "Serviced Apartment",
    label: "Serviced Apartment",
    category: "Residential",
  },
  {
    value: "Condominium",
    label: "Condominium",
    category: "Residential",
  },
  {
    value: "Hotel room",
    label: "Hotel room",
    category: "Residential",
  },
  {
    value: "Serviced Apartment",
    label: "Serviced Apartment",
    category: "Residential",
  },
  {
    value: "Hotel Apartment ",
    label: "Hotel Apartment ",
    category: "Residential",
  },
  {
    value: "PentHouse",
    label: "PentHouse",
    category: "Residential",
  },
  // {
  //   value: "Villa",
  //   label: "Villa",
  //   category: "Residential",
  // },
  // {
  //   value: "TownHouse",
  //   label: "TownHouse",
  //   category: "Residential",
  // },
  // {
  //   value: "Villa Compound",
  //   label: "Villa Compound",
  //   category: "Residential",
  // },
  // {
  //   value: "Hotel Apartment",
  //   label: "Hotel Apartment",
  //   category: "Residential",
  // },
  // {
  //   value: "Residential Plot",
  //   label: "Residential Plot",
  //   category: "Residential",
  // },
  // {
  //   value: "Residential Floor",
  //   label: "Residential Floor",
  //   category: "Residential",
  // },
  // {
  //   value: "Residential Building",
  //   label: "Residential Building",
  //   category: "Residential",
  // },
  {
    value: "Office",
    label: "Office",
    category: "Commercial",
  },
  {
    value: "Shop",
    label: "Shop",
    category: "Commercial",
  },
  {
    value: "Buildings",
    label: "Buildings",
    category: "Commercial",
  },
  {
    value: "Showroom",
    label: "Showroom",
    category: "Commercial",
  },
];

export const propertylanguagesOptions = [
  {
    value: "Urdu",
    label: "Urdu",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "Punjabi",
    label: "Punjabi",
  },
  {
    value: "Sindhi",
    label: "Sindhi",
  },
  {
    value: "Pashto",
    label: "Pashto",
  },
  {
    value: "Kashmiri",
    label: "Kashmiri",
  },
  {
    value: "Balochi",
    label: "Balochi",
  },
  {
    value: "Saraiki",
    label: "Saraiki",
  },
  {
    value: "Hindko",
    label: "Hindko",
  },
  {
    value: "Brahui",
    label: "Brahui",
  },
  {
    value: "Shina",
    label: "Shina",
  },
  {
    value: "Wakhi",
    label: "Wakhi",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
  {
    value: "Persian",
    label: "Persian",
  },
  {
    value: "French",
    label: "French",
  },
];

export const Purpose = [
  {
    value: "for_sale",
    label: "For Sale",
  },
  {
    value: "for_rent",
    label: "For Rent",
    disabled: "true",
    badge: "Coming Soon",
  },
];
export const Rent = [
  {
    value: "for_ltr",
    label: "For LTR",
  },
  {
    value: "for_str",
    label: "For STR",
    // disabled: "true",
    // badge: "comming soon",
  },
];
export const RentSystem = [
  {
    value: "for_pool",
    label: "For Pool",
  },
  {
    value: "for_individual",
    label: "For Individual",
    // disabled: "true",
    // badge: "comming soon",
  },
];

export const ProjectSelecting = [
  {
    value: "Registered_project",
    label: "Registered  Building",
  },
  {
    value: "add_building",
    label: "Add Building",
  },
];
export const ConsultantTypeSelecting = [
  {
    value: "choose_yourself",
    label: "Select Consultant by Yourself",
  },
  {
    value: "choose_by_imlaak",
    label: "Let Imlaak Choose Consultant for You",
  },
];
export const rentFrequency = [
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "weekly",
    label: "Weekly",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
  {
    value: "yearly",
    label: "Yearly",
  },
];
export const CompletedStatus = [
  {
    label: "Ready to move in",
    value: "Ready to move in",
  },
  {
    label: "Off-Plan",
    value: "Off-Plan",
  },
];
export const areaTypeOptions = [
  {
    label: "Sq. Ft.",
    value: "sq_ft",
  },
  {
    label: "Sq. Yd.",
    value: "sq_yd",
  },
  {
    label: "Marla",
    value: "marla",
  },
  {
    label: "Sq. M.",
    value: "sq_m",
  },
  {
    label: "Kanal.",
    value: "kanal",
  },
];

export const propertyspecialtiesOptions = [
  {
    label: "Residential Sales",
    value: "residential_Sales",
  },
  {
    label: "Residential Leasing",
    value: "residential_Leasing",
  },
  {
    label: "Commercial Sales",
    value: "commercial_Sales",
  },
  {
    label: "Commercial Leasing",
    value: "commercial_Leasing",
  },
  {
    label: "Off-Plan Sales",
    value: "off-Plan_Sales",
  },
];

export const propertyCitiesOptions = [
  {
    value: "Karachi",
    label: "Karachi",
    latitude: 24.86,
    longitude: 67.01,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "admin",
    population: "14835000",
    population_proper: "14835000",
  },
  {
    value: "Lahore",
    label: "Lahore",
    latitude: 31.5497,
    longitude: 74.3436,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "admin",
    population: "11021000",
    population_proper: "11021000",
  },
  {
    value: "Faisalabad",
    label: "Faisalabad",
    latitude: 31.418,
    longitude: 73.079,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "3203846",
    population_proper: "3203846",
  },
  {
    value: "Rawalpindi",
    label: "Rawalpindi",
    latitude: 33.6007,
    longitude: 73.0679,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "2098231",
    population_proper: "2098231",
  },
  {
    value: "Gujranwala",
    label: "Gujranwala",
    latitude: 32.15,
    longitude: 74.1833,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "2027001",
    population_proper: "2027001",
  },
  {
    value: "Peshawar",
    label: "Peshawar",
    latitude: 34.0,
    longitude: 71.5,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "admin",
    population: "1970042",
    population_proper: "1970042",
  },
  {
    value: "Multan",
    label: "Multan",
    latitude: 30.1978,
    longitude: 71.4711,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "1871843",
    population_proper: "1871843",
  },
  {
    value: "Saidu Sharif",
    label: "Saidu Sharif",
    latitude: 34.75,
    longitude: 72.3572,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "1860310",
    population_proper: "1860310",
  },
  {
    value: "Hyderabad value",
    label: "Hyderabad value",
    latitude: 25.3792,
    longitude: 68.3683,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "1732693",
    population_proper: "1732693",
  },
  {
    value: "Islamabad",
    label: "Islamabad",
    latitude: 33.6989,
    longitude: 73.0369,
    country: "Pakistan",
    iso2: "PK",
    state: "Islāmābād",
    capital: "primary",
    population: "1014825",
    population_proper: "1014825",
  },
  {
    value: "Quetta",
    label: "Quetta",
    latitude: 30.192,
    longitude: 67.007,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "admin",
    population: "1001205",
    population_proper: "1001205",
  },
  {
    value: "Bahawalpur",
    label: "Bahawalpur",
    latitude: 29.3956,
    longitude: 71.6722,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "762111",
    population_proper: "762111",
  },
  {
    value: "Sargodha",
    label: "Sargodha",
    latitude: 32.0836,
    longitude: 72.6711,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "659862",
    population_proper: "659862",
  },
  {
    value: "Sialkot value",
    label: "Sialkot value",
    latitude: 32.5,
    longitude: 74.5333,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "655852",
    population_proper: "655852",
  },
  {
    value: "Sukkur",
    label: "Sukkur",
    latitude: 27.6995,
    longitude: 68.8673,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "499900",
    population_proper: "499900",
  },
  {
    value: "Larkana",
    label: "Larkana",
    latitude: 27.56,
    longitude: 68.2264,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "490508",
    population_proper: "490508",
  },
  {
    value: "Chiniot",
    label: "Chiniot",
    latitude: 31.7167,
    longitude: 72.9833,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "477781",
    population_proper: "477781",
  },
  {
    value: "Shekhupura",
    label: "Shekhupura",
    latitude: 31.7083,
    longitude: 74.0,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "473129",
    population_proper: "473129",
  },
  {
    value: "Jhang value",
    label: "Jhang value",
    latitude: 31.2681,
    longitude: 72.3181,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "414131",
    population_proper: "414131",
  },
  {
    value: "Dera Ghazi Khan",
    label: "Dera Ghazi Khan",
    latitude: 30.05,
    longitude: 70.6333,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "399064",
    population_proper: "399064",
  },
  {
    value: "Gujrat",
    label: "Gujrat",
    latitude: 32.5736,
    longitude: 74.0789,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "390533",
    population_proper: "390533",
  },
  {
    value: "Rahimyar Khan",
    label: "Rahimyar Khan",
    latitude: 28.4202,
    longitude: 70.2952,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "353203",
    population_proper: "353203",
  },
  {
    value: "Kasur",
    label: "Kasur",
    latitude: 31.1167,
    longitude: 74.45,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "314617",
    population_proper: "314617",
  },
  {
    value: "Mardan",
    label: "Mardan",
    latitude: 34.1958,
    longitude: 72.0447,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "300424",
    population_proper: "300424",
  },
  {
    value: "Mingaora",
    label: "Mingaora",
    latitude: 34.7717,
    longitude: 72.36,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "",
    population: "279914",
    population_proper: "279914",
  },
  {
    value: "Nawabshah",
    label: "Nawabshah",
    latitude: 26.2442,
    longitude: 68.41,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "263102",
    population_proper: "263102",
  },
  {
    value: "Sahiwal",
    label: "Sahiwal",
    latitude: 30.6706,
    longitude: 73.1064,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "247706",
    population_proper: "247706",
  },
  {
    value: "Mirpur Khas",
    label: "Mirpur Khas",
    latitude: 25.5269,
    longitude: 69.0111,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "236961",
    population_proper: "236961",
  },
  {
    value: "Okara",
    label: "Okara",
    latitude: 30.81,
    longitude: 73.4597,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "232386",
    population_proper: "232386",
  },
  {
    value: "Mandi Burewala",
    label: "Mandi Burewala",
    latitude: 30.15,
    longitude: 72.6833,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "203454",
    population_proper: "203454",
  },
  {
    value: "Jacobabad",
    label: "Jacobabad",
    latitude: 28.2769,
    longitude: 68.4514,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "200815",
    population_proper: "200815",
  },
  {
    value: "Saddiqabad",
    label: "Saddiqabad",
    latitude: 28.3006,
    longitude: 70.1302,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "189876",
    population_proper: "189876",
  },
  {
    value: "Kohat",
    label: "Kohat",
    latitude: 33.5869,
    longitude: 71.4414,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "170800",
    population_proper: "170800",
  },
  {
    value: "Muridke",
    label: "Muridke",
    latitude: 31.802,
    longitude: 74.255,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "163268",
    population_proper: "163268",
  },
  {
    value: "Muzaffargarh",
    label: "Muzaffargarh",
    latitude: 30.0703,
    longitude: 71.1933,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "163268",
    population_proper: "163268",
  },
  {
    value: "Khanpur",
    label: "Khanpur",
    latitude: 28.6471,
    longitude: 70.662,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "160308",
    population_proper: "160308",
  },
  {
    value: "Gojra",
    label: "Gojra",
    latitude: 31.15,
    longitude: 72.6833,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "157863",
    population_proper: "157863",
  },
  {
    value: "Mandi Bahauddin",
    label: "Mandi Bahauddin",
    latitude: 32.5861,
    longitude: 73.4917,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "157352",
    population_proper: "157352",
  },
  {
    value: "Abbottabad",
    label: "Abbottabad",
    latitude: 34.15,
    longitude: 73.2167,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "148587",
    population_proper: "148587",
  },
  {
    value: "Turbat",
    label: "Turbat",
    latitude: 26.0031,
    longitude: 63.0544,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "147791",
    population_proper: "75694",
  },
  {
    value: "Dadu",
    label: "Dadu",
    latitude: 26.7319,
    longitude: 67.775,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "146179",
    population_proper: "146179",
  },
  {
    value: "Bahawalnagar",
    label: "Bahawalnagar",
    latitude: 29.9944,
    longitude: 73.2536,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "141935",
    population_proper: "141935",
  },
  {
    value: "Khuzdar",
    label: "Khuzdar",
    latitude: 27.8,
    longitude: 66.6167,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "",
    population: "141395",
    population_proper: "141395",
  },
  {
    value: "Pakpattan",
    label: "Pakpattan",
    latitude: 30.35,
    longitude: 73.4,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "139525",
    population_proper: "139525",
  },
  {
    value: "Tando Allahyar",
    label: "Tando Allahyar",
    latitude: 25.4667,
    longitude: 68.7167,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "133487",
    population_proper: "133487",
  },
  {
    value: "Ahmadpur East",
    label: "Ahmadpur East",
    latitude: 29.1453,
    longitude: 71.2617,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "128112",
    population_proper: "128112",
  },
  {
    value: "Vihari",
    label: "Vihari",
    latitude: 30.0419,
    longitude: 72.3528,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "128034",
    population_proper: "128034",
  },
  {
    value: "Jaranwala",
    label: "Jaranwala",
    latitude: 31.3342,
    longitude: 73.4194,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "127973",
    population_proper: "127973",
  },
  {
    value: "New Mirpur",
    label: "New Mirpur",
    latitude: 33.1333,
    longitude: 73.75,
    country: "Pakistan",
    iso2: "PK",
    state: "Azad Kashmir",
    capital: "minor",
    population: "124352",
    population_proper: "124352",
  },
  {
    value: "Kamalia",
    label: "Kamalia",
    latitude: 30.7258,
    longitude: 72.6447,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "121401",
    population_proper: "121401",
  },
  {
    value: "Kot Addu",
    label: "Kot Addu",
    latitude: 30.47,
    longitude: 70.9644,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "120479",
    population_proper: "120479",
  },
  {
    value: "Nowshera",
    label: "Nowshera",
    latitude: 34.0153,
    longitude: 71.9747,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "118594",
    population_proper: "118594",
  },
  {
    value: "Swabi",
    label: "Swabi",
    latitude: 34.1167,
    longitude: 72.4667,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "115018",
    population_proper: "115018",
  },
  {
    value: "Khushab",
    label: "Khushab",
    latitude: 32.2917,
    longitude: 72.35,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "110868",
    population_proper: "110868",
  },
  {
    value: "Dera Ismail Khan",
    label: "Dera Ismail Khan",
    latitude: 31.8167,
    longitude: 70.9167,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "109686",
    population_proper: "109686",
  },
  {
    value: "Chaman",
    label: "Chaman",
    latitude: 30.921,
    longitude: 66.4597,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "",
    population: "107660",
    population_proper: "107660",
  },
  {
    value: "Charsadda",
    label: "Charsadda",
    latitude: 34.1453,
    longitude: 71.7308,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "105414",
    population_proper: "105414",
  },
  {
    value: "Kandhkot",
    label: "Kandhkot",
    latitude: 28.4,
    longitude: 69.3,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "",
    population: "105011",
    population_proper: "105011",
  },
  {
    value: "Chishtian",
    label: "Chishtian",
    latitude: 29.7958,
    longitude: 72.8578,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "101659",
    population_proper: "101659",
  },
  {
    value: "Hasilpur",
    label: "Hasilpur",
    latitude: 29.6967,
    longitude: 72.5542,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "99171",
    population_proper: "99171",
  },
  {
    value: "Attock Khurd",
    label: "Attock Khurd",
    latitude: 33.7667,
    longitude: 72.3667,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "97374",
    population_proper: "97374",
  },
  {
    value: "Muzaffarabad",
    label: "Muzaffarabad",
    latitude: 34.37,
    longitude: 73.4711,
    country: "Pakistan",
    iso2: "PK",
    state: "Azad Kashmir",
    capital: "",
    population: "96000",
    population_proper: "96000",
  },
  {
    value: "Mianwali",
    label: "Mianwali",
    latitude: 32.5853,
    longitude: 71.5436,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "95007",
    population_proper: "95007",
  },
  {
    value: "Jalalpur Jattan",
    label: "Jalalpur Jattan",
    latitude: 32.7667,
    longitude: 74.2167,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "90130",
    population_proper: "90130",
  },
  {
    value: "Bhakkar",
    label: "Bhakkar",
    latitude: 31.6333,
    longitude: 71.0667,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "88472",
    population_proper: "88472",
  },
  {
    value: "Zhob",
    label: "Zhob",
    latitude: 31.3417,
    longitude: 69.4486,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "88356",
    population_proper: "50537",
  },
  {
    value: "Dipalpur",
    label: "Dipalpur",
    latitude: 30.6708,
    longitude: 73.6533,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "87176",
    population_proper: "87176",
  },
  {
    value: "Kharian",
    label: "Kharian",
    latitude: 32.811,
    longitude: 73.865,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "85765",
    population_proper: "85765",
  },
  {
    value: "Mian Channun",
    label: "Mian Channun",
    latitude: 30.4397,
    longitude: 72.3544,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "82586",
    population_proper: "82586",
  },
  {
    value: "Bhalwal",
    label: "Bhalwal",
    latitude: 32.2653,
    longitude: 72.9028,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "82556",
    population_proper: "82556",
  },
  {
    value: "Jamshoro",
    label: "Jamshoro",
    latitude: 25.4283,
    longitude: 68.2822,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "80000",
    population_proper: "80000",
  },
  {
    value: "Pattoki",
    label: "Pattoki",
    latitude: 31.0214,
    longitude: 73.8528,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "77210",
    population_proper: "77210",
  },
  {
    value: "Harunabad",
    label: "Harunabad",
    latitude: 29.61,
    longitude: 73.1361,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "77206",
    population_proper: "77206",
  },
  {
    value: "Kahror Pakka",
    label: "Kahror Pakka",
    latitude: 29.6236,
    longitude: 71.9167,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "76098",
    population_proper: "76098",
  },
  {
    value: "Toba Tek Singh",
    label: "Toba Tek Singh",
    latitude: 30.9667,
    longitude: 72.4833,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "75943",
    population_proper: "75943",
  },
  {
    value: "Samundri",
    label: "Samundri",
    latitude: 31.0639,
    longitude: 72.9611,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "73911",
    population_proper: "73911",
  },
  {
    value: "Shakargarh",
    label: "Shakargarh",
    latitude: 32.2628,
    longitude: 75.1583,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "73160",
    population_proper: "73160",
  },
  {
    value: "Sambrial",
    label: "Sambrial",
    latitude: 32.475,
    longitude: 74.3522,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "71766",
    population_proper: "71766",
  },
  {
    value: "Shujaabad",
    label: "Shujaabad",
    latitude: 29.8803,
    longitude: 71.295,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "70595",
    population_proper: "70595",
  },
  {
    value: "Hujra Shah Muqim",
    label: "Hujra Shah Muqim",
    latitude: 30.7408,
    longitude: 73.8219,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "70204",
    population_proper: "70204",
  },
  {
    value: "Kabirwala",
    label: "Kabirwala",
    latitude: 30.4068,
    longitude: 71.8667,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "70123",
    population_proper: "70123",
  },
  {
    value: "Mansehra",
    label: "Mansehra",
    latitude: 34.3333,
    longitude: 73.2,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "69085",
    population_proper: "69085",
  },
  {
    value: "Lala Musa",
    label: "Lala Musa",
    latitude: 32.7006,
    longitude: 73.9558,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "67283",
    population_proper: "67283",
  },
  {
    value: "Chunian",
    label: "Chunian",
    latitude: 30.9639,
    longitude: 73.9803,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "64386",
    population_proper: "64386",
  },
  {
    value: "Nankana Sahib",
    label: "Nankana Sahib",
    latitude: 31.4492,
    longitude: 73.7124,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "63073",
    population_proper: "63073",
  },
  {
    value: "Bannu",
    label: "Bannu",
    latitude: 32.9889,
    longitude: 70.6056,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "62191",
    population_proper: "62191",
  },
  {
    value: "Pasrur",
    label: "Pasrur",
    latitude: 32.2681,
    longitude: 74.6675,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "58644",
    population_proper: "58644",
  },
  {
    value: "Timargara",
    label: "Timargara",
    latitude: 34.8281,
    longitude: 71.8408,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "58050",
    population_proper: "58050",
  },
  {
    value: "Parachinar",
    label: "Parachinar",
    latitude: 33.8992,
    longitude: 70.1008,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "",
    population: "55685",
    population_proper: "55685",
  },
  {
    value: "Chenab Nagar",
    label: "Chenab Nagar",
    latitude: 31.75,
    longitude: 72.9167,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "53965",
    population_proper: "53965",
  },
  {
    value: "Gwadar",
    label: "Gwadar",
    latitude: 25.1264,
    longitude: 62.3225,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "51901",
    population_proper: "23364",
  },
  {
    value: "Abdul Hakim",
    label: "Abdul Hakim",
    latitude: 30.5522,
    longitude: 72.1278,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "51494",
    population_proper: "51494",
  },
  {
    value: "Hassan Abdal",
    label: "Hassan Abdal",
    latitude: 33.8195,
    longitude: 72.689,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "50044",
    population_proper: "50044",
  },
  {
    value: "Tank",
    label: "Tank",
    latitude: 32.2167,
    longitude: 70.3833,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "44996",
    population_proper: "44996",
  },
  {
    value: "Hangu",
    label: "Hangu",
    latitude: 33.5281,
    longitude: 71.0572,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "39766",
    population_proper: "39766",
  },
  {
    value: "Risalpur Cantonment",
    label: "Risalpur Cantonment",
    latitude: 34.0049,
    longitude: 71.9989,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "",
    population: "36653",
    population_proper: "36653",
  },
  {
    value: "Karak",
    label: "Karak",
    latitude: 33.1167,
    longitude: 71.0833,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "35844",
    population_proper: "35844",
  },
  {
    value: "Kundian",
    label: "Kundian",
    latitude: 32.4522,
    longitude: 71.4718,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "",
    population: "35406",
    population_proper: "35406",
  },
  {
    value: "Umarkot",
    label: "Umarkot",
    latitude: 25.3614,
    longitude: 69.7361,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "35059",
    population_proper: "35059",
  },
  {
    value: "Chitral",
    label: "Chitral",
    latitude: 35.8511,
    longitude: 71.7889,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "31100",
    population_proper: "31100",
  },
  {
    value: "Dainyor",
    label: "Dainyor",
    latitude: 35.9206,
    longitude: 74.3783,
    country: "Pakistan",
    iso2: "PK",
    state: "Gilgit-Baltistan",
    capital: "",
    population: "25000",
    population_proper: "25000",
  },
  {
    value: "Kulachi",
    label: "Kulachi",
    latitude: 31.9286,
    longitude: 70.4592,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "",
    population: "24738",
    population_proper: "24738",
  },
  {
    value: "Kalat",
    label: "Kalat",
    latitude: 29.0258,
    longitude: 66.59,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "22879",
    population_proper: "22879",
  },
  {
    value: "Kotli",
    label: "Kotli",
    latitude: 33.5156,
    longitude: 73.9019,
    country: "Pakistan",
    iso2: "PK",
    state: "Azad Kashmir",
    capital: "minor",
    population: "21462",
    population_proper: "21462",
  },
  {
    value: "Gilgit",
    label: "Gilgit",
    latitude: 35.9208,
    longitude: 74.3144,
    country: "Pakistan",
    iso2: "PK",
    state: "Gilgit-Baltistan",
    capital: "minor",
    population: "8851",
    population_proper: "8851",
  },
  {
    value: "Narowal",
    label: "Narowal",
    latitude: 32.102,
    longitude: 74.873,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Khairpur Mir’s",
    label: "Khairpur Mir’s",
    latitude: 27.5295,
    longitude: 68.7592,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Khanewal",
    label: "Khanewal",
    latitude: 30.3017,
    longitude: 71.9321,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Jhelum",
    label: "Jhelum",
    latitude: 32.9333,
    longitude: 73.7333,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Haripur",
    label: "Haripur",
    latitude: 33.9942,
    longitude: 72.9333,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Shikarpur",
    label: "Shikarpur",
    latitude: 27.9556,
    longitude: 68.6382,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Rawala Kot",
    label: "Rawala Kot",
    latitude: 33.8578,
    longitude: 73.7604,
    country: "Pakistan",
    iso2: "PK",
    state: "Azad Kashmir",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Hafizabad",
    label: "Hafizabad",
    latitude: 32.0709,
    longitude: 73.688,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Lodhran",
    label: "Lodhran",
    latitude: 29.5383,
    longitude: 71.6333,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Malakand",
    label: "Malakand",
    latitude: 34.5656,
    longitude: 71.9304,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Attock value",
    label: "Attock value",
    latitude: 33.7667,
    longitude: 72.3598,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Batgram",
    label: "Batgram",
    latitude: 34.6796,
    longitude: 73.0263,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Matiari",
    label: "Matiari",
    latitude: 25.5971,
    longitude: 68.4467,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Ghotki",
    label: "Ghotki",
    latitude: 28.0064,
    longitude: 69.315,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Naushahro Firoz",
    label: "Naushahro Firoz",
    latitude: 26.8401,
    longitude: 68.1227,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Alpurai",
    label: "Alpurai",
    latitude: 34.9,
    longitude: 72.6556,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Bagh",
    label: "Bagh",
    latitude: 33.9803,
    longitude: 73.7747,
    country: "Pakistan",
    iso2: "PK",
    state: "Azad Kashmir",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Daggar",
    label: "Daggar",
    latitude: 34.5111,
    longitude: 72.4844,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Leiah",
    label: "Leiah",
    latitude: 30.9646,
    longitude: 70.9444,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Tando Muhammad Khan",
    label: "Tando Muhammad Khan",
    latitude: 25.1239,
    longitude: 68.5389,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Chakwal",
    label: "Chakwal",
    latitude: 32.93,
    longitude: 72.85,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Badin",
    label: "Badin",
    latitude: 24.6558,
    longitude: 68.8383,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Lakki",
    label: "Lakki",
    latitude: 32.6072,
    longitude: 70.9123,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Rajanpur",
    label: "Rajanpur",
    latitude: 29.1041,
    longitude: 70.3297,
    country: "Pakistan",
    iso2: "PK",
    state: "Punjab",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Dera Allahyar",
    label: "Dera Allahyar",
    latitude: 28.4167,
    longitude: 68.1667,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Shahdad Kot",
    label: "Shahdad Kot",
    latitude: 27.8473,
    longitude: 67.9068,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Pishin",
    label: "Pishin",
    latitude: 30.5833,
    longitude: 67.0,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Sanghar",
    label: "Sanghar",
    latitude: 26.0464,
    longitude: 68.9481,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Upper Dir",
    label: "Upper Dir",
    latitude: 35.2074,
    longitude: 71.8768,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Thatta",
    label: "Thatta",
    latitude: 24.7461,
    longitude: 67.9243,
    country: "Pakistan",
    iso2: "PK",
    state: "Sindh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Dera Murad Jamali",
    label: "Dera Murad Jamali",
    latitude: 28.5466,
    longitude: 68.2231,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Kohlu",
    label: "Kohlu",
    latitude: 29.8965,
    longitude: 69.2532,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Mastung",
    label: "Mastung",
    latitude: 29.7997,
    longitude: 66.8455,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Dasu",
    label: "Dasu",
    latitude: 35.2917,
    longitude: 73.2906,
    country: "Pakistan",
    iso2: "PK",
    state: "Khyber Pakhtunkhwa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Athmuqam",
    label: "Athmuqam",
    latitude: 34.5717,
    longitude: 73.8972,
    country: "Pakistan",
    iso2: "PK",
    state: "Azad Kashmir",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Loralai",
    label: "Loralai",
    latitude: 30.3705,
    longitude: 68.5979,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Barkhan",
    label: "Barkhan",
    latitude: 29.8977,
    longitude: 69.5256,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Musa Khel Bazar",
    label: "Musa Khel Bazar",
    latitude: 30.8594,
    longitude: 69.8221,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Ziarat",
    label: "Ziarat",
    latitude: 30.3814,
    longitude: 67.7258,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Gandava",
    label: "Gandava",
    latitude: 28.6132,
    longitude: 67.4856,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Sibi",
    label: "Sibi",
    latitude: 29.543,
    longitude: 67.8773,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Dera Bugti",
    label: "Dera Bugti",
    latitude: 29.0362,
    longitude: 69.1585,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Eidgah",
    label: "Eidgah",
    latitude: 35.3471,
    longitude: 74.8563,
    country: "Pakistan",
    iso2: "PK",
    state: "Gilgit-Baltistan",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Uthal",
    label: "Uthal",
    latitude: 25.8072,
    longitude: 66.6228,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Khuzdar",
    label: "Khuzdar",
    latitude: 27.7384,
    longitude: 66.6434,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Chilas",
    label: "Chilas",
    latitude: 35.4206,
    longitude: 74.0967,
    country: "Pakistan",
    iso2: "PK",
    state: "Gilgit-Baltistan",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Panjgur",
    label: "Panjgur",
    latitude: 26.9644,
    longitude: 64.0903,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Gakuch",
    label: "Gakuch",
    latitude: 36.1736,
    longitude: 73.7667,
    country: "Pakistan",
    iso2: "PK",
    state: "Gilgit-Baltistan",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Qila Saifullah",
    label: "Qila Saifullah",
    latitude: 30.7008,
    longitude: 68.3598,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Kharan",
    label: "Kharan",
    latitude: 28.5833,
    longitude: 65.4167,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Aliabad",
    label: "Aliabad",
    latitude: 36.3078,
    longitude: 74.6178,
    country: "Pakistan",
    iso2: "PK",
    state: "Gilgit-Baltistan",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Awaran",
    label: "Awaran",
    latitude: 26.4568,
    longitude: 65.2314,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    value: "Dalbandin",
    label: "Dalbandin",
    latitude: 28.8885,
    longitude: 64.4062,
    country: "Pakistan",
    iso2: "PK",
    state: "Balochistān",
    capital: "minor",
    population: "",
    population_proper: "",
  },
];

export default DropDown;
