import React, { useEffect, useState, useMemo, useRef } from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    FormControl,
    FormHelperText,
    Chip,
    Paper,
    Skeleton,
    MenuItem,
    Select,
    Typography,
    FormControlLabel,
    useFormControl,
    Switch,
    InputLabel,
} from "@mui/material";
import Modal from "../../Modal";
import { City, State, Country } from "country-state-city"
import CustomInput from "../../Inputs";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import MapView from "../../Map/Map";

import DropDown, {
    propertyCategoryOptions,
    propertyTypeOptions,
    propertyFloorOptions,
    propertyCitiesOptions,
    Purpose,
    CompletedStatus,
    furnishingStatus,
    ProjectSelecting,
} from "../../DropDownOptions/DropDownOptions";
import Loader from "../../Loader";
import {
    ImageUploader,
    ImageViewer,
} from "app/components/Inputs/imageSelector";
import { useImageMethod } from "app/hooks/useImageUpload";
import { API } from "app/utils/config";
import MultipleSelectCheckmarks from "app/components/Inputs/DropdownWithCheckBox";
import { amenitiesCategories } from "app/components/amenities/components/DropDownOptions";
import useFetchData from "app/hooks/useFetchData ";
import { uniqBy } from "lodash";
import { APIS } from "Constants";
import JoditEditor from "jodit-react";
import axios from "axios";
import { ProjectNameDropdown } from "app/ServerSideDropdowns/ProjectNameDropdown";
import { AppErrorColor, AppTextColor } from "app/components/theme/Colors";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "setup";
import NumberFormatter from "app/shared/Input/NumberFormatter";

interface InventoryItem {
    projectName: string;
    appartmentNo: string;
    floorNo: string;
    category: string;
    subCategory: string;
    projectType: string;
    title: string;
    _id: string;
    consultantId: string;
    landlordId: string;
    country: string;
    city: string;
    province: string;
    state: string;
    placeId: string;
    description: string;
    completionStatus: string;
    purchasePrice?: string;
    purchasingDate?: string;
    landlordName?: string;
    owner: [];
}

const validationSchema = yup.object({
    // category: yup.string().required("Category is required"),
    // subCategory: yup.string().required("SubCategory is required"),
    // floorNo: yup.string().required("Floor No is required"),
    // apartmentNo: yup.number().required("Apartment No is required"),
    // country: yup.string().required("Country is required"),
    // province: yup.string().required("Province is required"),
    // city: yup.string().required("City is required"),
    // zipCode: yup.string().required("Property zip-code is required"),
    // state: yup.string().required("Property state is required"),
    // community: yup.string().required("community is required"),
    // provinceLocation: yup.string().required("ProvinceLocation is required"),
    // cityLocation: yup.string().required("CityLocation is required"),
    // communityLocation: yup.string().required("CommunityLocation is required"),
    // title: yup.string().required("title is required"),
    // description: yup.string().required("description is required"),
    // price: yup.number().required("Purchase price is required"),
    // areaType: yup.string().required("Property Area Type is required"),
    // area: yup.number().required("Property Area is required"),
    // projectType: yup.array().required("Project Type is required"),
    // projectNamemanual: yup.array().required("Project Name Type is required"),
    // images: yup.array().notRequired(),
    // location: yup.object({
    //   lat: yup.number().required().label("listing is required"),
    //   lng: yup.number().required().label("listing is required"),
    // }),
    // amenties: yup.object(),
});

const useStyles = makeStyles(() => ({
    heading: {
        background: "#263c67",
        color: "#fff",
        fontWeight: "700 !important",
    },
    headingStyles: {
        fontSize: "14px !important",
        fontWeight: "700 !important",
    },
    description: {
        padding: "8.5px 14px",
        width: "100%",
        pointerEvents: "none",
        border: "1px solid",
        borderColor: "#093d5c",
        borderRadius: "5px",
        height: "45px",
    },
}));

type UpdateConsultantPropertyProps = {
    open: any;
    setOpen: any;
    property?: any;
    AddProperty?: any;
    loading?: boolean;
    updateProperty?: any;
    modalMode?: any;
    error?: any;
    label?: string;
    value?: string;
};

const UpdateConsultantProperty = ({
    open,
    setOpen,
    property,
    AddProperty,
    loading,
    modalMode,
    error,
    label,
    updateProperty,
    value,
}: UpdateConsultantPropertyProps) => {
    const ownerArray = property?.owner ? property?.owner : undefined;
    const lastIndex = ownerArray?.length > 0 ? ownerArray?.length - 1 : -1; // Use -1 to indicate an invalid index
    const lastOwner = lastIndex >= 0 ? ownerArray[lastIndex] : { landlordName: '', purchasePrice: '', purchasingDate: '' };

    const onClick = (data: any) => {
        formik.setFieldValue("location", data?.lngLat);
    };

    const formik = useFormik({
        initialValues: {
            projectName: property?.projectName || "",
            category: property?.category || "",
            subCategory: property?.subCategory || "",
            furnishingStatus: property?.furnishingStatus || "",
            owner: property?.owner || [
                {
                    landlordName: '',
                    purchasePrice: "",
                    purchasingDate: "",
                    landlord: "",
                },
            ],
            purchasePrice: property?.owner?.purchasePrice,
            purchasingDate: property?.owner?.purchasingDate,
            isVerified: property?.isVerified || true,
            purpose: "for_sale",
            country: property?.country || "",
            province: property?.province || "",
            cityName: property?.cityName || "",
            rentType: property?.rentType || "",
            rentSystem: property?.rentSystem || "",

            state: property?.state || "",
            // zipCode: property?.zipCode || "",
            // community: property?.community || "",
            projectname: property?.projectname || "",
            // provinceLocation: property?.provinceLocation || "",
            // cityLocation: property?.cityLocation || "",
            // communityLocation: property?.communityLocation || "",
            title: property?.title || "",
            rentalIncome: property?.rentalIncome || "",
            description: property?.description || "",
            price: property?.price || 0,
            salePrice: property?.salePrice || 0,
            advanceAmount: property?.advanceAmount || 0,
            noOfInstallments: property?.noOfInstallments || 0,
            monthlyInstallments: property?.monthlyInstallments || 0,
            possessionAmount: property?.possessionAmount || 0,
            rentFrequency: property?.rentFrequency || "",
            bedrooms: property?.bedrooms || 0,
            bathrooms: property?.bathrooms || 0,
            floorNo: property?.floorNo || 0,
            appartmentNo: property?.appartmentNo || 0,
            completionStatus: property?.completionStatus || "",
            projectNamemanual: property?.projectNamemanual || "",
            ProjectSelecting: property?.ProjectSelecting || "",
            areaType: property?.areaType || "",
            area: property?.area || 0,
            location: property?.location || {},
            images: property?.images || [],
            amenities: property?.amenties || {},
            consultantId: property?.consultantId || "",
            landlordId: property?.landlordId || "",
            placeId: property?.placeId || "",
            projectType: property?.projectType || [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const { ProjectSelecting, owner, purpose, appartmentNo, floorNo, projectName, landlordId, completionStatus, amenities, description, projectType, price } = values;
            const RegisteredValues = { landlordId, owner, completionStatus, amenities, projectType, price, description, ownerLastIndex: lastIndex };
            if (ProjectSelecting === "Registered_project") {
                updateProperty(RegisteredValues)
            }
            else {
                updateProperty(values);
            }
        },
    });

    useEffect(() => {
        formik.setFieldValue("category", property?.category || "");
        formik.setFieldValue("projectName", property?.projectName || "");
        formik.setFieldValue("ProjectSelecting", property?.ProjectSelecting || "");
        formik.setFieldValue("subCategory", property?.subCategory || "");
        formik.setFieldValue("isVerified", property?.isVerified);
        formik.setFieldValue("purpose", "for_sale");
        formik.setFieldValue("furnishingStatus", property?.furnishingStatus || "")
        formik.setFieldValue("completionStatus", property?.completionStatus || "")
        formik.setFieldValue("purchasePrice", property?.owner?.purchasePrice || "")

        formik.setFieldValue(`owner[${lastIndex}].landlordName`, lastOwner?.landlordName || "");
        formik.setFieldValue(`owner[${lastIndex}].purchasingDate`, lastOwner?.purchasingDate || "");
        formik.setFieldValue(`owner[${lastIndex}].purchasePrice`, lastOwner?.purchasePrice || "");
        formik.setFieldValue(`owner[${lastIndex}].landlord`, lastOwner?.landlord || "");
        formik.setFieldValue(`landlordId`, property?.landlordId || "");
        // formik.setFieldValue("province", property?.province || "");
        // formik.setFieldValue("cityName", property?.cityName || "");
        // formik.setFieldValue("community", property?.community || "");
        formik.setFieldValue("title", property?.title || "");
        formik.setFieldValue("description", property?.description || "");
        formik.setFieldValue("projectType", property?.projectType || []);
        formik.setFieldValue("amenities", property?.amenities || []);
        formik.setFieldValue("location", property?.location);
        formik.setFieldValue("name", property?.name || "" || "");
        formik.setFieldValue("price", property?.price || "");
        formik.setFieldValue("salePrice", property?.salePrice || "");
        formik.setFieldValue("advanceAmount", property?.advanceAmount || "");
        formik.setFieldValue("possessionAmount", property?.possessionAmount || "");
        formik.setFieldValue("noOfInstallments", property?.noOfInstallments || "");
        formik.setFieldValue("area", property?.area || "");
        formik.setFieldValue("areaType", property?.areaType);
        formik.setFieldValue("state", property?.location?.state || "");
        formik.setFieldValue("bedrooms", property?.bedrooms || "");
        formik.setFieldValue("bathrooms", property?.bathrooms || "");
        formik.setFieldValue("floorNo", property?.floorNo || "");
        formik.setFieldValue("appartmentNo", property?.appartmentNo || "");

        formik.setFieldValue("cityName", property?.cityName || "");
        formik.setFieldValue("province", property?.province || "");
        formik.setFieldValue("state", property?.state || "");
        formik.setFieldValue("country", property?.country || "");
        formik.setFieldValue("placeId", property?.placeId || "");
        formik.setFieldValue("areaName", property?.areaName || "");
        formik.setFieldValue("sector", property?.sector || "");
        formik.setFieldValue("street", property?.street || "");
        formik.setFieldValue("address", property?.address || "");
    }, [property]);

    const [id, setId] = useState("");


    // Project LookUp
    const { getAllNames } = APIS.ProjectName;
    const { projectNameData } = useFetchData({ getAllNames });
    const [projectLookup, setProjectLookup] = useState<any>({});

    useEffect(() => {
        // Create a lookup object for projectName id's to project Names
        const lookup: any = {};
        projectNameData.forEach((row: any) => {
            lookup[row?._id] = row?.projectName;
        });
        setProjectLookup(lookup);
    }, [projectNameData]);

    // Amenities List
    const { getAllAmenities } = APIS.amenities;
    const { amenitiesData } = useFetchData({ getAllAmenities });
    const AmenitiesList: any = useMemo(() => {
        let oneToOneData: any = [];
        if (amenitiesData.length > 0 && formik?.values?.projectType?.length > 0) {
            amenitiesData.forEach((amenityRow: any) => {
                amenityRow.categories.forEach((category: any) => {
                    if (
                        Boolean(
                            formik.values.projectType.find((type: any) => type === category)
                        )
                    ) {
                        amenityRow.list.forEach((amenity: any) => {
                            oneToOneData.push({
                                label: amenity.title,
                                value: amenity.title,
                                description: amenity.description,
                                key: amenity.title,
                            });
                        });
                    }
                });
            });
            return uniqBy(oneToOneData, "label");
        }
        return [];
    }, [amenitiesData, formik.values.projectType]);

    const [amenties, setAmenities]: any = useState({});
    useEffect(() => {
        if (Array.isArray(formik.values.amenities)) {
            // Using reduce to transform the array into an object
            const initialAmenities = formik.values.amenities.reduce<Record<string, boolean>>((acc, amenity) => {
                const { label } = amenity;
                acc[label] = true;
                return acc;
            }, {});

            setAmenities(initialAmenities); // Update the state with the initial amenities
        }
    }, [formik.values.amenities]);
    // const changedAmenity = (event: any, index: number) => {
    //     let shallowAmenities = amenties;
    //     const { name, checked } = event.target;
    //     shallowAmenities = {
    //         ...shallowAmenities,
    //         [event.target.name]: true,
    //         [name]: checked,
    //     };
    //     setAmenities(shallowAmenities);
    //     const toSetAmenities = AmenitiesList.filter(
    //         (amenity: any) => shallowAmenities[amenity.label]
    //     );
    //     formik.setFieldValue("amenities", toSetAmenities);
    // };
    const changedAmenity = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, checked } = event.target;
        setAmenities((currentAmenities: any) => {

            const updatedAmenities = {
                ...currentAmenities,
                [name]: checked,
            };

            const toSetAmenities = AmenitiesList.filter((amenity: any) => updatedAmenities[amenity.label]);
            formik.setFieldValue("amenities", toSetAmenities);

            return updatedAmenities;
        });
    };
    // End of AMenities List

    // Getting Landlords
    const { getAll } = APIS.users;
    const { data } = useFetchData({ getAll });
    const [landlord, setLandlord] = useState<any[]>([]);

    useEffect(() => {
        // Filter and map the data within the useEffect
        const filteredLandlords = data?.filter((row: any) => row.role === "landlord" && row.consultant === consultantId);
        const mappedLandlords = filteredLandlords.map((landlord: any) => ({
            value: landlord?._id,
            label: landlord?.fname,
            consultant: landlord?.consultant,
        }));
        // Update the state with the mapped data
        setLandlord(mappedLandlords);
    }, [data]);
    // End of Getting Landlord

    const submitHandler = (event: any) => {
        event.preventDefault();
    };

    // END PROPERTY AMENTIES

    const editor = useRef(null);
    // start FormatPrice function
    function formatPrice(price: number) {
        const suffixes = ["", "K", "M", "B", "T"];
        const suffixNum = Math.floor(("" + price).length / 3);
        let shortValue = parseFloat(
            (suffixNum !== 0 ? price / Math.pow(1000, suffixNum) : price).toPrecision(
                2
            )
        );
        if (shortValue % 1 !== 0) {
            shortValue = parseFloat(shortValue.toFixed(1));
        }
        return shortValue + suffixes[suffixNum];
    }

    useEffect(() => {
        let title = "";
        if (formik.values.furnishingStatus === "furnished") {
            title += ` Furnished - `;
        } else {
            title += ` `;
        }

        if (formik.values.subCategory) {
            title += ` ${formik.values.subCategory}`;
        }

        if (formik.values.bedrooms) {
            title += ` - ${formik.values.bedrooms} bedroom(s)`;
        }

        formik.setFieldValue("title", title);
    }, [
        property,
        modalMode,
        formik.values.furnishingStatus,
        formik.values.subCategory,
        formik.values.bedrooms,
    ]);

    const classes = useStyles();
    function HeadingText() {
        const { focused } = useFormControl() || {};
        const headingColor = useMemo(() => {
            if (error) {
                return AppErrorColor;
            }

            if (focused && !error) {
                return "#007FFF";
            }

            return AppTextColor;
        }, [focused]);
        return (
            <Typography
                className={classes.headingStyles}
                fontSize={18}
                color={headingColor}
            >
                Select New Landlord
            </Typography>
        );
    }
    function HeadingText2() {
        const { focused } = useFormControl() || {};
        const headingColor = useMemo(() => {
            if (error) {
                return AppErrorColor;
            }

            if (focused && !error) {
                return "#007FFF";
            }

            return AppTextColor;
        }, [focused]);
        return (
            <Typography
                className={classes.headingStyles}
                fontSize={18}
                color={headingColor}
            >
                Apartment No
            </Typography>
        );
    }
    function HeadingText3() {
        const { focused } = useFormControl() || {};
        const headingColor = useMemo(() => {
            if (error) {
                return AppErrorColor;
            }

            if (focused && !error) {
                return "#007FFF";
            }

            return AppTextColor;
        }, [focused]);
        return (
            <Typography
                className={classes.headingStyles}
                fontSize={18}
                color={headingColor}
            >
                Select Landlord
            </Typography>
        );
    }
    const { getAllProject } = APIS.projects;
    const { projectData } = useFetchData({ getAllProject });
    const [projects, setProjects] = useState<any[]>([]);


    useEffect(() => {
        // Filter and map the data within the useEffect
        const mappedProjects = projectData.map((item: any) => ({
            value: item?._id,
            label: item?.projectName,
            country: item?.country,
            state: item?.state,
            city: item?.city,
            placeId: item?.placeId,
        }));
        // Update the state with the mapped data
        setProjects(mappedProjects);
    }, [projectData]);

    // For Fetching Values for Selected Project
    const [projectNameValue, setProjectNameValue] = useState({});

    const { getAllInventory } = APIS.inventory;
    const { inventoryData } = useFetchData({ getAllInventory });

    const selectedProjectData: InventoryItem[] = inventoryData?.filter((row: InventoryItem) => row?.projectName === projectNameValue);

    const uniqueFloorNumbers: string[] = Array.from(new Set(selectedProjectData.map((row: InventoryItem) => row?.floorNo)));

    const selectedFloorNo: string[] = uniqueFloorNumbers.slice().sort((a, b) => {
        const numA = parseInt(a, 10); // Convert string to number
        const numB = parseInt(b, 10); // Convert string to number

        const isANumber = !isNaN(numA);
        const isBNumber = !isNaN(numB);

        if (!isANumber && !isBNumber) {
            // If both are strings, sort them alphabetically
            return a.localeCompare(b);
        } else if (!isANumber && isBNumber) {
            // Place strings before numbers
            return -1;
        } else if (isANumber && !isBNumber) {
            // Place numbers after strings
            return 1;
        } else {
            // Both are numbers, sort numerically
            return numA - numB;
        }
    });

    const [selectedFloorNoValue, setSelectedFloorNoValue] = useState({});

    const selectedApartments: InventoryItem[] = selectedProjectData.filter((row: InventoryItem) => row?.floorNo === selectedFloorNoValue);
    // const selectedDataApartments: string[] = Array.from(new Set(selectedApartments.map((row: InventoryItem) => row?.appartmentNo)));

    const selectedDataApartments = Array.from(new Set(selectedApartments.map((row: InventoryItem) => {
        return {
            appartmentNo: row?.appartmentNo,
            isLandlordIdEmpty: !row?.landlordId, // true if consultantId is empty
        };
    })));
    const FinalApartmentNo = selectedDataApartments
        .sort((a, b) => a.appartmentNo.localeCompare(b.appartmentNo))
        .map(apartment => ({
            ...apartment,
            appartmentNo: apartment.appartmentNo.charAt(0).toUpperCase() + apartment.appartmentNo.slice(1)
        }));


    const [apartmentNumberValue, setApartmentNumberValue] = useState({});
    const selectedPropertyDetails: InventoryItem[] = selectedApartments.filter((row: InventoryItem) => row?.appartmentNo === apartmentNumberValue);

    const [apartmentId, setApartmentId] = useState({});
    useEffect(() => {
        if (selectedPropertyDetails.length > 0) {
            setApartmentId(selectedPropertyDetails?.[0]?._id);
        }
    }, [selectedPropertyDetails]);
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    // For Consultant's id to Add in Property
    const consultantId = user?._id;

    // Get Location
    const [address, setAddress] = useState<string>('');
    const [formattedAddress, setFormattedAddress] = useState<string>('');
    const [coordinates, setCoordinates] = useState<string>('');
    const [placeIdValue, setPlaceIdValue] = useState<string>('');
    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
        handleGetCoordinates();
    };
    const handleGetCoordinates = async () => {
        try {
            // Replace 'YOUR_API_KEY' with your actual Google Maps API key
            const apiKey = process?.env?.REACT_APP_GOOGLE_MAPS_KEY;
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    address
                )}&key=${apiKey}`
            );

            if (response.ok) {
                const data = await response.json();
                if (data.results.length > 0) {
                    const location = data.results[0].geometry.location;
                    const addressComponents = data.results[0].address_components;
                    const placeId = data.results[0].place_id;
                    const formattedAddressValue = data.results[0].formatted_address;
                    // Extract city and country names from address components
                    let city = '';
                    let state = '';
                    let country = '';
                    addressComponents.forEach((component: any) => {
                        if (component.types.includes('locality')) {
                            city = component.long_name;
                        } else if (component.types.includes('administrative_area_level_1')) {
                            state = component.long_name;
                        } else if (component.types.includes('country')) {
                            country = component.long_name;
                        }
                    });

                    formik.setValues({
                        ...formik.values,
                        cityName: city,
                        province: state,
                        country: country,
                        placeId: placeId,
                    });
                    setCoordinates(location);
                    // setCity(city);
                    // setState(state);
                    // setCountry(country);
                    setPlaceIdValue(placeId);
                    setFormattedAddress(formattedAddressValue)
                } else {
                    console.error('No results found for the provided address.');
                }
            } else {
                console.error('Error fetching data from the geocoding API.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    // const lastIndex = property?.owner && property?.owner?.length > 0 ? property?.owner?.length - 1 : 0;
    // const lastOwner = lastIndex !== undefined ? property?.owner![lastIndex] : undefined;


    return (
        <Modal
            setOpen={() => setOpen(!open)}
            open={open}
            title={
                modalMode === "edit"
                    ? ` Update Property ${property?.ProjectSelecting}`
                    : "Property"
            }
            cancelButtonTitle="Cancel"
            acceptButtonVarient="contained"
            acceptButtonTitle={
                modalMode === "edit"
                    ? ` Update Property`
                    : "Property"
            }
            handleAccept={() => formik.handleSubmit()}
            handleClose={() => formik.resetForm()}
            loading={loading}
            isActionable={true}
        >
            {loading ? (
                <Box
                    sx={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        left: "50%",
                        top: "30%",
                    }}
                >
                    <Loader top='"60%' left='"50%' />
                </Box>
            ) : null}
            <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">

                <Grid container spacing={2}>

                    {property?.ProjectSelecting === "Registered_project" && (
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="projectName"
                                    label="Building Name *"
                                    value={projectLookup[property?.projectName] || property?.projectName}
                                    type="text"
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>
                                <CustomInput
                                    id="floorNo"
                                    label="Floor No *"
                                    value={property?.floorNo}
                                    type="text"
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>
                                <CustomInput
                                    id="appartmentNo"
                                    label="Apartment No *"
                                    value={property?.appartmentNo}
                                    type="text"
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="category"
                                    label="Category *"
                                    value={formik.values.category}
                                    type="text"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="subCategory"
                                    label="Sub-Category *"
                                    value={formik.values.subCategory}
                                    type="text"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="furnishingStatus"
                                    label="Furnishing Status *"
                                    value={formik.values.furnishingStatus}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue(
                                            "furnishingStatus",
                                            event.target.value
                                        );
                                    }}
                                    isError={
                                        formik.touched.category && Boolean(formik.errors.category)
                                    }
                                    helperText={
                                        formik.touched.category ? formik.errors.category : ""
                                    }
                                    options={furnishingStatus}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="completionStatus"
                                    label="Completion Status *"
                                    value={formik.values.completionStatus}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("completionStatus", event.target.value);
                                    }}
                                    isError={
                                        formik.touched.completionStatus &&
                                        Boolean(formik.errors.completionStatus)
                                    }
                                    helperText={
                                        formik.touched.completionStatus
                                            ? formik.errors.completionStatus
                                            : ""
                                    }
                                    options={CompletedStatus}
                                />
                            </Grid>
                            <>
                                {lastIndex >= 0 && (
                                    <React.Fragment key={lastIndex}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <CustomInput
                                                id={`owner.${lastIndex}.landlordName`}
                                                label="Landlord Name *"
                                                placeholder=""
                                                value={formik?.values?.owner[lastIndex]?.landlordName}
                                                type="text"
                                                onChangeHandler={(e: any) => {
                                                    // const newOwners = [...formik.values.owner];
                                                    // newOwners[lastIndex].landlordName = e.target.value;
                                                    formik.setFieldValue(`owner.${lastIndex}.landlordName`, e.target.value);
                                                }}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <CustomInput
                                                id={`owner.${lastIndex}.purchasePrice`}
                                                label="Purchase Price *"
                                                placeholder=""
                                                value={formik?.values?.owner[lastIndex]?.purchasePrice}
                                                type="number"
                                                onChangeHandler={(e: any) => {
                                                    const newOwners = [...formik.values.owner];
                                                    newOwners[lastIndex].purchasePrice = e.target.value;
                                                    formik.setFieldValue("owner", newOwners);
                                                    formik.setFieldValue("price", e.target.value);
                                                }}
                                            />
                                            <div className="py-">
                                                {/* <p className="fw-bolder">(Amount =  <NumberFormatter value={lastOwner.purchasePrice || owner.purchasePrice} /> )</p> */}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <CustomInput
                                                id={`owner.${lastIndex}.purchasingDate`}
                                                label="Purchasing Date*"
                                                placeholder="Purchasing Date"
                                                value={formik?.values?.owner[lastIndex]?.purchasingDate}
                                                type="date"
                                                onChangeHandler={(event: any) => {
                                                    formik.setFieldValue(`owner[${lastIndex}].purchasingDate`, event.target.value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormControl style={{ width: "100%" }}>
                                                <HeadingText />
                                                <Select
                                                    style={{ width: "100%" }}
                                                    sx={{ backgroundColor: "#fcfcfb" }}
                                                    id={id}
                                                    label={<HeadingText />}
                                                    onChange={(event: any) => {
                                                        const newOwners = [...formik.values.owner];
                                                        // newOwners[index].landlordName = event.target.label;
                                                        // formik.setFieldValue("owner", newOwners);
                                                        formik.values.landlordId = event.target.value;
                                                        formik.setFieldValue("landlordId", event.target.value)
                                                        newOwners[lastIndex].landlord = event.target.value;
                                                        formik.setFieldValue("owner", newOwners);
                                                        const selectedLandlord = landlord?.find((item: any) => item?.value === event.target.value);
                                                        if (selectedLandlord) {
                                                            const newOwners = [...formik.values.owner];
                                                            newOwners[lastIndex].landlordName = selectedLandlord.label; // Set landlordName from selected option's label
                                                            formik.setFieldValue("owner", newOwners);
                                                        }
                                                    }}
                                                    displayEmpty
                                                    inputProps={{ "aria-label": "Without label" }}
                                                >
                                                    {landlord.map((item: any) => {
                                                        return (
                                                            <MenuItem
                                                                key={item.value}
                                                                value={item.value}
                                                                disabled={item.disabled}
                                                                sx={{ textTransform: "capitalize" }}
                                                            >
                                                                {item.label}
                                                                {item.badge && (
                                                                    <Chip
                                                                        size="small"
                                                                        sx={{ marginLeft: "8px", textTransform: "capitalize" }}
                                                                        label={item.badge}
                                                                        color="primary"
                                                                        variant="filled"
                                                                    />
                                                                )}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </>

                            <Grid item xs={12} sm={12} md={12}>
                                <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px", padding: "10px 0" }}>Select Property Type for Features/Amenities</label>
                                <MultipleSelectCheckmarks
                                    name="projectType"
                                    tagName="Property Type*"
                                    value={formik.values.projectType ?? ""}
                                    onChange={(event: any) => {
                                        formik.setFieldValue("projectType", event.target.value);
                                    }}
                                    keyToSelect={"label"}
                                    options={amenitiesCategories}
                                    isError={
                                        formik.touched.projectType && Boolean(formik.errors.projectType)
                                    }
                                    helperText={
                                        formik.touched.projectType ? formik.errors.projectType : ""
                                    }
                                />
                            </Grid>

                            <Box className="dropdown">
                                <label className="required form-label m-4">Property Features</label>
                                <button
                                    type="button"
                                    className="btn text-light mt-2 btn-sm dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-bs-auto-close="outside"
                                    style={{ backgroundColor: "#181c32" }}
                                >
                                    + Add Features
                                </button>
                                <form className="dropdown-menu p-4 " onSubmit={submitHandler}>
                                    <Box
                                        className="row"
                                        sx={{
                                            width: 350,
                                        }}
                                    >
                                        {AmenitiesList?.map(
                                            (
                                                amenity: {
                                                    label: any;
                                                    key: string | number | undefined;
                                                },
                                                index: number
                                            ) => {
                                                return (
                                                    <Box className="col-md-6 mt-3">
                                                        <Box className="form-check">
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`dropdownCheck${index}`}
                                                            >
                                                                {amenity.label}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id={`dropdownCheck${index}`}
                                                                value={formik.values.amenities || amenties[amenity.key as string]}
                                                                checked={amenties[amenity.key as string]}
                                                                name={amenity.key as string}
                                                                onChange={(event) => changedAmenity(event, index)}
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            }
                                        )}
                                    </Box>
                                </form>
                            </Box>

                            <Grid item xs={12} sm={12} md={12}>
                                <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px" }}>Description</label>
                                <JoditEditor
                                    ref={editor}
                                    value={formik.values.description || selectedPropertyDetails[0]?.description}
                                    onChange={(newContent) =>
                                        formik.setFieldValue("description", newContent)
                                    }
                                />
                            </Grid>

                        </>
                    )}

                    {property?.ProjectSelecting === "add_building" && (
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="projectName"
                                    label="Building Name *"
                                    placeholder="Type here..."
                                    value={formik.values.projectName}
                                    onChangeHandler={formik.handleChange}
                                    isError={
                                        formik.touched.projectName &&
                                        Boolean(formik.errors.projectName)
                                    }
                                    helperText={
                                        formik.touched.projectName ? formik.errors.projectName : ""
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="floorNo"
                                    label="Floor No *"
                                    value={formik.values.floorNo}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("floorNo", event.target.value);
                                    }}
                                    isError={formik.touched.floorNo && Boolean(formik.errors.floorNo)}
                                    helperText={formik.touched.floorNo ? formik.errors.floorNo : ""}
                                    options={propertyFloorOptions}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="appartmentNo"
                                    label="Appartment No *"
                                    placeholder="Type here..."
                                    value={formik.values.appartmentNo}
                                    type="text"
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("appartmentNo", event.target.value.toUpperCase());
                                    }}
                                    isError={
                                        formik.touched.appartmentNo &&
                                        Boolean(formik.errors.appartmentNo)
                                    }
                                    helperText={
                                        formik.touched.appartmentNo ? formik.errors.appartmentNo : ""
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="category"
                                    label="Property Category *"
                                    value={formik.values.category}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("category", event.target.value);
                                    }}
                                    isError={
                                        formik.touched.category && Boolean(formik.errors.category)
                                    }
                                    helperText={formik.touched.category ? formik.errors.category : ""}
                                    options={propertyTypeOptions}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="subCategory"
                                    label="Property SubCategory *"
                                    value={formik.values.subCategory}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("subCategory", event.target.value);
                                    }}
                                    isError={
                                        formik.touched.subCategory && Boolean(formik.errors.subCategory)
                                    }
                                    helperText={
                                        formik.touched.subCategory ? formik.errors.subCategory : ""
                                    }
                                    options={propertyCategoryOptions.filter(
                                        (item: any) => item.category === formik.values.category
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="furnishingStatus"
                                    label="Furnishing Status *"
                                    value={formik.values.furnishingStatus}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue(
                                            "furnishingStatus",
                                            event.target.value
                                        );
                                    }}
                                    isError={
                                        formik.touched.category && Boolean(formik.errors.category)
                                    }
                                    helperText={
                                        formik.touched.category ? formik.errors.category : ""
                                    }
                                    options={furnishingStatus}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="area"
                                    label="Area (Sq/m)*"
                                    placeholder="Type here..."
                                    value={formik.values.area}
                                    type="number"
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("area", event.target.value);
                                    }}
                                    isError={formik.touched.area && Boolean(formik.errors.area)}
                                    helperText={formik.touched.area ? formik.errors.area : ""}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="rentalIncome"
                                    label="Rental Income (Optional)"
                                    placeholder="Type here..."
                                    value={formik.values.rentalIncome} // set default value to "N/A"
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("rentalIncome", event.target.value);
                                    }}
                                    isError={
                                        formik.touched.rentalIncome &&
                                        Boolean(formik.errors.rentalIncome)
                                    }
                                    helperText={
                                        formik.touched.rentalIncome ? formik.errors.rentalIncome : ""
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="salePrice"
                                    label="Sale Price (PKR) (optional):"
                                    placeholder="Type here..."
                                    value={formik.values.salePrice}
                                    type="number"
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("salePrice", event.target.value);
                                    }}
                                    isError={formik.touched.salePrice && Boolean(formik.errors.salePrice)}
                                    helperText={formik.touched.salePrice ? formik.errors.salePrice : ""}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="completionStatus"
                                    label="Completion Status *"
                                    value={formik.values.completionStatus}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("completionStatus", event.target.value);
                                    }}
                                    isError={
                                        formik.touched.completionStatus &&
                                        Boolean(formik.errors.completionStatus)
                                    }
                                    helperText={
                                        formik.touched.completionStatus
                                            ? formik.errors.completionStatus
                                            : ""
                                    }
                                    options={CompletedStatus}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="furnishingStatus"
                                    label="Furnishing Status *"
                                    value={formik.values.furnishingStatus}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue(
                                            "furnishingStatus",
                                            event.target.value
                                        );
                                    }}
                                    isError={
                                        formik.touched.category && Boolean(formik.errors.category)
                                    }
                                    helperText={
                                        formik.touched.category ? formik.errors.category : ""
                                    }
                                    options={furnishingStatus}
                                />
                            </Grid>

                            {formik.values.category === "Residential" && (
                                <>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id="bedrooms"
                                            label="Bedrooms *"
                                            placeholder="Type here..."
                                            value={formik.values.bedrooms}
                                            type="number"
                                            onChangeHandler={formik.handleChange}
                                            isError={
                                                formik.touched.bedrooms && Boolean(formik.errors.bedrooms)
                                            }
                                            helperText={
                                                formik.touched.bedrooms ? formik.errors.bedrooms : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id="bathrooms"
                                            label="Bathrooms *"
                                            placeholder="Type here..."
                                            value={formik.values.bathrooms}
                                            type="number"
                                            onChangeHandler={formik.handleChange}
                                            isError={
                                                formik.touched.bathrooms && Boolean(formik.errors.bathrooms)
                                            }
                                            helperText={
                                                formik.touched.bathrooms ? formik.errors.bathrooms : ""
                                            }
                                        />
                                    </Grid>
                                </>
                            )}

                            {lastIndex >= 0 && (
                                <React.Fragment key={lastIndex}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id={`owner.${lastIndex}.landlordName`}
                                            label="Landlord Name *"
                                            placeholder=""
                                            value={formik?.values?.owner[lastIndex]?.landlordName}
                                            type="text"
                                            onChangeHandler={(e: any) => {
                                                // const newOwners = [...formik.values.owner];
                                                // newOwners[lastIndex].landlordName = e.target.value;
                                                formik.setFieldValue(`owner.${lastIndex}.landlordName`, e.target.value);
                                            }}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id={`owner.${lastIndex}.purchasePrice`}
                                            label="Purchase Price *"
                                            placeholder=""
                                            value={formik?.values?.owner[lastIndex]?.purchasePrice}
                                            type="number"
                                            onChangeHandler={(e: any) => {
                                                const newOwners = [...formik.values.owner];
                                                newOwners[lastIndex].purchasePrice = e.target.value;
                                                formik.setFieldValue("owner", newOwners);
                                                formik.setFieldValue("price", e.target.value);
                                            }}
                                        />
                                        <div className="py-">
                                            {/* <p className="fw-bolder">(Amount =  <NumberFormatter value={lastOwner.purchasePrice || owner.purchasePrice} /> )</p> */}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id={`owner.${lastIndex}.purchasingDate`}
                                            label="Purchasing Date*"
                                            placeholder="Purchasing Date"
                                            value={formik?.values?.owner[lastIndex]?.purchasingDate}
                                            type="date"
                                            onChangeHandler={(event: any) => {
                                                formik.setFieldValue(`owner[${lastIndex}].purchasingDate`, event.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <FormControl style={{ width: "100%" }}>
                                            <HeadingText />
                                            <Select
                                                style={{ width: "100%" }}
                                                sx={{ backgroundColor: "#fcfcfb" }}
                                                id={id}
                                                label={<HeadingText />}
                                                onChange={(event: any) => {
                                                    const newOwners = [...formik.values.owner];
                                                    // newOwners[index].landlordName = event.target.label;
                                                    // formik.setFieldValue("owner", newOwners);
                                                    formik.values.landlordId = event.target.value;
                                                    formik.setFieldValue("landlordId", event.target.value)
                                                    newOwners[lastIndex].landlord = event.target.value;
                                                    formik.setFieldValue("owner", newOwners);
                                                    const selectedLandlord = landlord?.find((item: any) => item?.value === event.target.value);
                                                    if (selectedLandlord) {
                                                        const newOwners = [...formik.values.owner];
                                                        newOwners[lastIndex].landlordName = selectedLandlord.label; // Set landlordName from selected option's label
                                                        formik.setFieldValue("owner", newOwners);
                                                    }
                                                }}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                {landlord.map((item: any) => {
                                                    return (
                                                        <MenuItem
                                                            key={item.value}
                                                            value={item.value}
                                                            disabled={item.disabled}
                                                            sx={{ textTransform: "capitalize" }}
                                                        >
                                                            {item.label}
                                                            {item.badge && (
                                                                <Chip
                                                                    size="small"
                                                                    sx={{ marginLeft: "8px", textTransform: "capitalize" }}
                                                                    label={item.badge}
                                                                    color="primary"
                                                                    variant="filled"
                                                                />
                                                            )}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </React.Fragment>
                            )}

                            {/* Location */}
                            <Grid item xs={12} sm={12} md={12}>
                                <CustomInput
                                    id="address"
                                    label="Enter Address/Building Name with City"
                                    placeholder="Type here..."
                                    value={address}
                                    onChangeHandler={handleAddressChange}
                                />
                                {coordinates && (
                                    <>
                                        <div className="pt-3">
                                            <p className="fw-bolder">(Address: &nbsp;&nbsp;&nbsp; {formattedAddress})</p>
                                        </div>
                                    </>
                                )}
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="country"
                                    label="Country *"
                                    value={property?.country || country}
                                    type="text"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="province"
                                    label="Province*"
                                    value={property?.province || state}
                                    type="text"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="cityName"
                                    label="City*"
                                    value={property?.cityName || city}
                                    type="text"
                                    disabled
                                />
                            </Grid> */}

                            <Grid item xs={12} sm={12} md={12}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <CustomInput
                                        id="title"
                                        label="Property Title *"
                                        placeholder="Type here..."
                                        value={selectedPropertyDetails[0]?.title || formik.values.title}
                                        onChangeHandler={formik.handleChange}
                                        isError={formik.touched.title && Boolean(formik.errors.title)}
                                        helperText={formik.touched.title ? formik.errors.title : ""}
                                        disabled
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px" }}>Description</label>
                                <JoditEditor
                                    ref={editor}
                                    value={formik.values.description}
                                    onChange={(newContent) =>
                                        formik.setFieldValue("description", newContent)
                                    }
                                />
                            </Grid>

                            {/* Amenities */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px", padding: "10px 0" }}>Select Property Type for Features/Amenities</label>
                                    <MultipleSelectCheckmarks
                                        name="projectType"
                                        tagName="Property Type*"
                                        value={formik.values.projectType ?? ""}
                                        onChange={(event: any) => {
                                            formik.setFieldValue("projectType", event.target.value);
                                        }}
                                        keyToSelect={"label"}
                                        options={amenitiesCategories}
                                        isError={
                                            formik.touched.projectType && Boolean(formik.errors.projectType)
                                        }
                                        helperText={
                                            formik.touched.projectType ? formik.errors.projectType : ""
                                        }
                                    />
                                </Grid>


                                <Box className="dropdown">
                                    <label className="required form-label m-4">Property Features</label>
                                    <button
                                        type="button"
                                        className="btn text-light mt-2 btn-sm dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-bs-auto-close="outside"
                                        style={{ backgroundColor: "#181c32" }}
                                    >
                                        + Add Features
                                    </button>
                                    <form className="dropdown-menu p-4 " onSubmit={submitHandler}>
                                        <Box
                                            className="row"
                                            sx={{
                                                width: 350,
                                            }}
                                        >
                                            {AmenitiesList?.map(
                                                (
                                                    amenity: {
                                                        label: any;
                                                        key: string | number | undefined;
                                                    },
                                                    index: number
                                                ) => {
                                                    return (
                                                        <Box className="col-md-6 mt-3">
                                                            <Box className="form-check">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={`dropdownCheck${index}`}
                                                                >
                                                                    {amenity.label}
                                                                </label>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`dropdownCheck${index}`}
                                                                    value={formik.values.amenities || amenties[amenity.key as string]}
                                                                    checked={amenties[amenity.key as string]}
                                                                    name={amenity.key as string}
                                                                    onChange={(event) => changedAmenity(event, index)}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    );
                                                }
                                            )}
                                        </Box>
                                    </form>
                                </Box>
                            </Grid>
                            {/* End Amenities */}
                        </>
                    )}
                </Grid>

            </Box>
        </Modal>
    );
}

export default UpdateConsultantProperty;
