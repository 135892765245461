import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Modal from "../../Modal";
import CustomInput from "../../Inputs";
import { Formik, useField, useFormik } from "formik";
import * as yup from "yup";
import Loader from "../../Loader";
import { RootState } from "setup";
import { shallowEqual, useSelector } from "react-redux";
import ConsultantDropdown from "app/ServerSideDropdowns/ConsultantDropdown";
import Consultant from "app/components/ProfilePage/components/steps/Consultant";
import FormImpl from "react-bootstrap-v5/lib/esm/Form";
import DropDown from "app/components/DropDownOptions/DropDownOptions";
import useFetchData from "app/hooks/useFetchData ";
import { APIS } from "Constants";

const validationSchema = yup.object({
    consultant: yup.string().required("Landlord's consultant is required")
});

type UpdateChangeConsultantRequestProps = {
    open: any;
    setOpen: any;
    data?: any;
    update?: any;
    loading?: boolean;
    type: string;
};

const UpdateChangeConsultantRequest = ({
    open,
    setOpen,
    data,
    update,
    loading,
    type = "EDIT",
}: UpdateChangeConsultantRequestProps) => {
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    const { getAllConsultants } = APIS.users
    const { consultantsData } = useFetchData({ getAllConsultants })
    const [consultantValue, setConsultantValue] = useState('')
    const [consultants, setConsultants] = useState({})
    const formik = useFormik({
        initialValues: {
            _id: '',
            consultant: ' ',
            requestStatus: 'completed',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            update(values);
            formik.resetForm();
        },
    });
    useEffect(() => {
        const consultantsArray = consultantsData.filter((row: any) => row?._id !== data?.consultant)
        const allConsultants = consultantsArray.map((consultant: any) => ({
            value: consultant?._id,
            label: consultant?.fname,
        }));
        setConsultants(allConsultants)
    }, [consultantsData]);
    useEffect(() => {
        formik.setFieldValue('_id', data?._id)
    }, [data])
    return (
        <Modal
            setOpen={() => setOpen(!open)}
            open={open}
            title={type === 'EDIT' ? "Change Consultant" : 'none'}
            cancelButtonTitle="Cancel"
            acceptButtonVarient="contained"
            acceptButtonTitle={type === 'EDIT' ? "Change Consultant" : 'none'}
            handleAccept={() => formik.handleSubmit()}
            handleClose={() => formik.resetForm()}
            loading={loading}
            isActionable={true}
        >
            {loading ? (
                <Box
                    sx={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        left: "50%",
                        top: "30%",
                    }}
                >
                    <Loader top='"60%' left='"50%' />
                </Box>
            ) : null}
            <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <DropDown
                            id="consultant"
                            label="Please Select a Consultant*"
                            value={formik.values.consultant}
                            onChangeHandler={(event: any) => {
                                formik.setFieldValue("consultant", event.target.value);
                                setConsultantValue(event.target.value);
                            }}
                            isError={
                                formik.touched.consultant && Boolean(formik.errors.consultant)
                            }
                            helperText={formik.touched.consultant ? formik.errors.consultant : ""}
                            options={consultants}
                        />
                        {consultantValue && (
                            <div className="col-lg-6 mt-2">
                                <Consultant id={consultantValue} />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default UpdateChangeConsultantRequest;
