/* eslint-disable jsx-a11y/anchor-is-valid */
import { Stack } from "@mui/material";
import { url } from "inspector";
import React from "react";
import { KTSVG } from "../../../helpers";

type Props = {
    className: string;
    color: string;
    svgIcon: string;
    iconColor: string;
    title: string;
    titleColor?: string;
    description: string;
    descriptionColor?: string;
    bgcolor: string
};

const StatisticsWidget8: React.FC<Props> = ({
    className,
    color,
    svgIcon,
    iconColor,
    title,
    titleColor,
    description,
    descriptionColor,
    bgcolor,
}) => {
    return (
        <div className={`card bg-${color} hoverable ${className}`} style={{ backgroundColor: bgcolor, backgroundSize: '100%' }}>
            <div className="card-body">
                <div className={`fw-semibold pt-1 text-capitalize pb-4 fs-4 text-${descriptionColor}`}>
                    {description}
                </div>
                <Stack direction={"row"} alignItems="center" spacing={2}>
                    <div className="d-flex justify-content-between fourthblockicon">
                        <KTSVG
                            path={svgIcon}
                            className={`svg-icon-${iconColor} svg-icon-3x ms-n1`}
                        />
                        <div className={`text-${titleColor} fw-bold fs-4 mb-2`}>
                            {title}
                        </div>
                    </div>
                </Stack>

            </div>
        </div>
    );
};

export { StatisticsWidget8 };
