/* eslint-disable jsx-a11y/anchor-is-valid */
import { Stack } from "@mui/material";
import { url } from "inspector";
import React from "react";
import { KTSVG } from "../../../helpers";

type Props = {
    className: string;
    color: string;
    svgIcon: string;
    title: string;
    description: string;
    bgcolor: string
    textColor: string
};

const StatisticsWidget7: React.FC<Props> = ({
    className,
    color,
    svgIcon,
    title,
    description,
    bgcolor,
    textColor,
}) => {
    return (
        <div className={`card bg-${color} hoverable px-0 pb-0 ${className}`} style={{ backgroundColor: bgcolor, backgroundSize: '100%' }}>
            <div className="card-body pt-3 pb-3 px-1 d-flex flex-column text-center ">
                <KTSVG
                    path={svgIcon}
                    className={`svg-icon-3x ms-n1`}
                />
                <div className={`fw-bold fs-7`} style={{ color: textColor, }}>
                    {title}
                </div>
                <div className={`fw-semibold pt-2`} style={{ color: '#8e9091', fontSize: '11px' }}>
                    {description}
                </div>
            </div>
        </div>
    );
};

export { StatisticsWidget7 };
