import React from "react";
// import FacebookLogin from 'react-facebook-login';
import { Redirect } from "react-router-dom";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'



const FacebookLoginButton = () => {
  const responseFacebook = (data) => {
  };
  const componentClicked = () => {
    // console.log(componentClicked)
  }
  return (
    // // <div className="application">
    //   {/* <FacebookLogin
    // appId='945499022825118'
    // autoLoad={true}
    // fields="name,email,picture"
    // onClick={componentClicked}
    // callback={responseFacebook} />
    //   {/* <div className="g-signin2" dataOnsuccess="onSignIn"></div> */}
    // {/* </div> */} */}

    <FacebookLogin
      appId="945499022825118"
      autoLoad={false}
      fields="name,email,picture"
      onClick={componentClicked}
      callback={responseFacebook}
      render={renderProps => (
        <button
          className="btn btn-flex flex-center btn-light btn-lg w-100 mb-2"
          onClick={renderProps.onClick}>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          /> Continue with Facebook</button>
      )}
    />
  );
};

export default FacebookLoginButton;
