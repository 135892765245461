/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PageTitle } from "../../../_metronic/layout/core";
import BgImage from "../../../_metronic/assets/media/svg/misc/taieri.svg";
import {
  StatisticsWidget5,
  AssetsCarts,
  MixedWidget11,
  MixedWidget8,
  TablesWidget5,
  ChartsWidget1,
} from "../../../_metronic/partials/widgets";
import {
  getMyEvaluations,
  getMyProperties,
  requestEvaluation as requestEvaluationApi,
} from "../../components/property/redux/PropertyCrud";
import * as AuthRedux from "../../components/auth/redux/AuthRedux";
import { RootState } from "../../../setup";
import { Link, useHistory } from "react-router-dom";
import DashboardTable from "./DashboardTable";
import { toAbsoluteUrl } from "_metronic/helpers";
import { StatisticsWidget7 } from "_metronic/partials/widgets/statistics/StatisticsWidget7";
import { Button } from "react-bootstrap-v5";
import { StatisticsWidget9 } from "_metronic/partials/widgets/statistics/StatisticsWidget9";
import { getAllUsers } from "app/components/Admin/AllUsers/Crud";
import { Item1 } from "_metronic/partials/content/activity/Item1";

const DashboardPage = ({ data }: any) => (
  <>
    {/* begin::Row */}
    {/* <div className="row g-2 g-xl-4 mb-5 mb-xl-4">
      <div className="col-xxl-8">
        <ChartsWidget1 className="" />
      </div>

      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-2 mb-md-5 mb-xl-10">
        <Link to="/AllAgents">
          <StatisticsWidget9
            className="h-md-40 mb-5 mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/communication/com006.svg"
            color="info"
            iconColor="white"
            title={data?.count}
            img={""}
            bgcolor=""
            description="Total Agents"
          />
        </Link>
        <Link to="/pendingUsers">
          <StatisticsWidget9
            className="h-md-40 mb-5 mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/graphs/gra007.svg"
            color="warning"
            iconColor="white"
            title=""
            img={""}
            bgcolor=""
            description="pending"
          />
        </Link>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-2 mb-md-5 mb-xl-10">
        <Link to="/approveUsers">
          <StatisticsWidget9
            className="h-md-40 mb-5 mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/communication/com005.svg"
            color="success"
            iconColor="white"
            title=""
            img={""}
            bgcolor=""
            description="approved"
          />
        </Link>
        <Link to="/rejectedUsers">
          <StatisticsWidget9
            className="h-md-40 mb-5 mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/general/gen008.svg"
            color=""
            iconColor="white"
            title=""
            img={""}
            bgcolor="#bf3939"
            description="Rejected"
          />
        </Link>
      </div>
    </div> */}

    <div className="row">

      <div className="col-xxl-8">
        {/* <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8 shadow' /> */}
        <div className="row">
          <div className="col-6">
            <Link to="/allUsers">
              <StatisticsWidget5
                className="card-xl-stretch mb-5 mb-xl-8 fw-bold text-white shadow"
                svgIcon="/media/icons/duotune/abstract/abs031.svg"
                color=""
                iconColor="white"
                title={
                  data?.data?.filter(
                    (item: { role: string }) => item.role! !== "admin").length ?? 0
                }
                description="Total Users"
                img={toAbsoluteUrl("/media/patterns/vector-1.png")}
                bgcolor="#263c67"
              />
            </Link>
          </div>

          <div className="col-6">
            <Link to="/propertyLandlords">
              <StatisticsWidget5
                className="card-xl-stretch mb-5 mb-xl-8 text-white"
                svgIcon="/media/icons/duotune/communication/com005.svg"
                color=""
                iconColor="white"
                title={
                  data?.data?.filter(
                    (item: { role: string }) => item.role === "landlord"
                  ).length ?? 0
                }
                description="Landlords"
                img={toAbsoluteUrl("/media/patterns/vector-1.png")}
                bgcolor="#387034"
              />
            </Link>
          </div>

          <div className="col-6">
            <Link to="/consultants">
              <StatisticsWidget5
                className="card-xl-stretch mb-5 mb-xl-8 shadow fw-bold text-black"
                svgIcon="/media/icons/duotune/general/gen001.svg"
                color=""
                iconColor="black"
                title={
                  data?.data?.filter(
                    (item: { role: string }) => item.role === "consultant"
                  ).length ?? 0
                }
                description="Consultants"
                img={toAbsoluteUrl("/media/patterns/vector-1.png")}
                bgcolor="#fff"
              />
            </Link>
          </div>

          <div className="col-6">
            <Link to="/suspendedAccounts">
              <StatisticsWidget5
                className="card-xl-stretch mb-5 mb-xl-8 text-white fw-bold shadow"
                svgIcon="/media/icons/duotune/graphs/gra007.svg"
                color=""
                iconColor="white"
                title={
                  data?.data?.filter(
                    (item: { status: string }) => item.status === "suspended"
                  ).length ?? 0
                }
                description="Suspended Accounts"
                img={toAbsoluteUrl("/media/patterns/vector-1.png")}
                bgcolor="#bf840d"
              />
            </Link>
          </div>

          <div className="col-12 px-3 rounded">
            <div
              className="card bgi-no-repeat bgi-size-contain px-5 py-3 mb-lg-10"
              style={{
                backgroundImage: `url('${BgImage}')`,
                backgroundSize: "",
                backgroundColor: "#1B283F",
                backgroundPosition: "right",
              }}
            >
              <div className="row">
                <div className="col-6 pt-2">
                  <h1 className="fs-1 fw-normal text-white">
                    Download our Mobile App
                  </h1>
                  <div className="px-5 pt-5">
                    <p className="text-white">
                      Visit{" "}
                      <a
                        className="text-white pl-2 fw-bolder"
                        href="wwww.imlaak.com"
                      >
                        Imlaak.com
                      </a>
                    </p>
                    <Button className="text-white">Get Now</Button>
                  </div>
                </div>
                <div className="col-2 mt-6 text-white">
                  <StatisticsWidget7
                    className="card-xl-stretch mb-5 text-danger"
                    svgIcon="/media/svg/android.svg"
                    color=""
                    title=""
                    textColor="#f03c3c"
                    description="Get App for Android Devices"
                    bgcolor="#fff"
                  />
                </div>
                <div className="col-2 text-white mt-6">
                  <StatisticsWidget7
                    className="card-xl-stretch mb-5 text-danger"
                    svgIcon="/media/svg/ios.svg"
                    color=""
                    title=""
                    textColor="#0794eb"
                    description="Get App for IOS Devices"
                    bgcolor="#fff"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-4 pb-7">
        <MixedWidget8
          className="card-xl-stretch shadow"
          chartColor="success"
          chartHeight="150px"
        />
      </div>
    </div>

    {/* <div className='row g-5 g-xl-8'>
      <div className='col-xl-3'>
        <Link to='/crafted/pages/area/overview'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-dark'
            svgIcon='/media/icons/duotune/communication/com005.svg'
            color='secondary'
            iconColor='black'
            title={data?.SiteIdData}
            description='All Users'
          />
        </Link>
      </div>
      <div className='col-xl-3'>
        <Link to='/crafted/pages/area/projects'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-white'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='warning'
            iconColor='white'
            title={data?.DISCOData}
            description='Total Properties'
          />
        </Link>
      </div>

      <div className='col-xl-3 '>
        <Link to='/crafted/pages/area/campaigns'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-white'
            svgIcon='/media/icons/duotune/abstract/abs031.svg'
            color='success'
            iconColor='white'
            title={data?.CircleData}
            description='Listed Properties'
          />
        </Link>
      </div>


      <div className='col-xl-3 '>
        <Link to='/crafted/pages/area/campaigns'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 text-white'
            svgIcon='/media/icons/duotune/general/gen001.svg'
            color='primary'
            iconColor='white'
            title={data?.CircleData}
            description='Unlisted Properties'
          />
        </Link>
      </div>
    </div> */}

    {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-12'>
        <MixedWidget11
          className='card-xxl-stretch mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
    </div> */}
    {/* <DashboardTable /> */}
  </>
);

const DashboardEmployee: FC = () => {
  const intl = useIntl();
  const [accessToken, setAccessToken]: any = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [myEval, setMyEval] = useState([]);
  const [agenciesList, setAgenciesList] = useState({});
  const [agencies, setAllAgencies] = useState([]);
  const [count, setCount] = useState(0);

  const getToken = async () => {
    let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
    accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
    setAccessToken(accessToken);
  };
  const getProperties = (filters: any) => {
    getMyProperties(accessToken, filters)
      .then(({ data }) => {
        dispatch(AuthRedux.actions.setMyListings(data.property));
        setCount(data?.count);

      })
      .catch(() => { })
      .finally(() => setLoading(false));

    // getMyEvaluations(accessToken)
    //   .then(({ data }) => {
    //     setMyEval(data?.evaluation);
    //   })
    //   .catch(() => { })
    //   .finally(() => setLoading(false));
  };
  const getAgencies = (filters: any) => {
    getAllUsers(accessToken, filters)
      .then(({ data }) => {
        setAllAgencies(data.data);
        setAgenciesList(data);

        // setCount(data?.count);
      })
      .catch(() => { })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    getToken();
    setTimeout(() => {
      getAgencies(filter);
    }, 1000);

    setTimeout(() => {
      getProperties(filter);
    }, 1000);

  }, []);
  const requestEvaluation = (activeProperty: any) => {
    return requestEvaluationApi(accessToken, activeProperty);
  };

  const properties: any = useSelector<RootState>(
    ({ auth }) => auth.listing,
    shallowEqual
  );
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  const history = useHistory();

  // useEffect(() => {
  //   if (user?.role === "admin") {
  //     history.push("/admin/dashboard");
  //   } else if (user?.role === "landlord") {
  //     history.push("/landlord/dashboard");
  //   } else if (user?.role === "employee") {
  //     history.push("/employee/dashboard");
  //   } else {
  //     history.push("/dashboard");
  //   }
  // }, [user]);
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage
        data={agenciesList}
        requestEvaluation={requestEvaluation}
        myEval={myEval}
        properties={properties || []}
      />
    </>
  );
};

export { DashboardEmployee };
