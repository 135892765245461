import { capitalizeFirstLetter, IconRender } from "app/components/Table";
import Table from "app/components/Table";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import EditAgent from "./CreateUpdate";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Button } from "@mui/material";
import { useLayout } from "_metronic/layout/core";
import { useEffect } from "react";
import { toAbsoluteUrl } from "_metronic/helpers";
import { RootState } from "setup";
import { shallowEqual, useSelector } from "react-redux";

const columns = (iconClickHandler: any) => [
    {
        name: "Landlord's Name",
        key: "fname",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    },
    {
        name: "Contact",
        key: "contact",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    },
    {
        name: "Email",
        key: "email",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => (value)
    }, {
        name: "Status",
        key: "approvalStatus",
        renderContent: (value: string) => {
            if (value === 'pending') {
                return <button className="btn btn-warning">{capitalizeFirstLetter(value)}</button>;
            } else if (value === 'requested') {
                return <button className="btn btn-info">{capitalizeFirstLetter(value)}</button>;
            } else if (value === 'rejected') {
                return <button className="btn btn-danger">{capitalizeFirstLetter(value)}</button>;
            } else if (value === 'approved') {
                return <button className="btn btn-success">{capitalizeFirstLetter(value)}</button>;
            }
            return <span>{value}</span>;
        },
    }, {
        name: "Invitation",
        key: "action",
        component: (row: any) => (
            <>
                <button
                    className="btn text-white"
                    style={{ background: "#263c67" }} value='resend_Invitation'
                    onClick={(event) => {
                        const target = event.target as HTMLButtonElement;
                        iconClickHandler(row, target.value)
                    }}
                >
                    Resend
                </button>
            </>
        ),
    }, {
        name: "Action",
        key: "action",
        component: (row: any) => (
            <>
                {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "EDIT")}
                {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, "deleteConsultant")}
            </>
        ),
    },
];

export function UserLandlordsDetails() {
    const { getAll, create, createLandlord, update, deleteConsultantId, resendWelcomeEmail } = APIS.users;
    const {
        data,
        loading,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count,
        open,
        setOpen,
        modalMode,
        updateFunction,
        createLandlordFunction,
        current,
        iconClickHandler,
        setCurrent,
        deleteConsultantFunction,
    } = useFetchData({ getAll, create, createLandlord, update, deleteConsultantId, resendWelcomeEmail });
    const { setClickButtonHandler } = useLayout();

    useEffect(() => {
        setClickButtonHandler(() => () => {
            setOpen(true);
            setCurrent({});
        });
    }, []);

    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    // Filter data to only show consultant's Landlords
    const filteredData = data.filter((row: any) => row?.role === "landlord" && row?.approvalStatus !== "rejected" && row?.consultant?.toString() === user._id);

    return (
        <>
            <div className="pb-5">
                <Table
                    columns={columns(iconClickHandler("/myLandlords"))}
                    data={filteredData}
                    loading={loading}
                    onRowClick={(row: any, type: any) =>
                        iconClickHandler("/myLandlords")(row, type)
                    }
                    isPaginationRequired
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    total={count}
                />
            </div>

            <EditAgent
                setOpen={setOpen}
                open={open}
                loading={loading}
                update={modalMode === "EDIT" ? updateFunction : createLandlordFunction}
                type={modalMode}
                data={current}
            />

        </>
    );
}
