import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

interface postUtil {
  url: string;
  params?: any;
}

interface getUtil {
  url?: string;
  params?: any;
}

// axios.interceptors.response.use(
//   (res) => res,
//   (err) => {
//     throw new Error(err.response.data.message);
//   }
// );

export const useImageMethod = () => {
  const API_END_POINT = process?.env.REACT_APP_API_URL + "api";
  const { enqueueSnackbar } = useSnackbar();

  const [floorImage, setFloorImage]: any = useState('');
  const [paymentImage, setPaymentImage]: any = useState('');
  const [floorImages, setFloorImages] = useState<Array<any>>([]);
  const [floorFiles, setFloorFiles] = useState<Array<any>>([]);

  const [images, setImages] = useState<Array<any>>([]);
  const [files, setFiles] = useState<Array<any>>([]);

  const uploadImage = async ({ params = {} }: Partial<getUtil>) => {
    try {
      const res = await axios.post(API_END_POINT + "/images/upload", params, {
        headers: { "content-type": "multipart/form-data" },
      });
      if (res.status === 205) {
        enqueueSnackbar("Data Duplication Error data must be unique", {
          variant: "error",
        });
      } else if (res.status !== 200) {
        console.log("error occurred");
        enqueueSnackbar(
          "some error occurred on backend with status " + res.statusText,
          {
            variant: "error",
          }
        );
      } else if (res.status === 200) {
        enqueueSnackbar(res.data.message, {
          variant: "success",
        });
      }
      return res;
    } catch (err) {
      enqueueSnackbar("some error occurred on backend with status " + err, {
        variant: "error",
      });
      return {
        status: 500,
        data: {},
      };
    }
  };

  const removeImage = (imageType: 'floorImage' | 'paymentImage' | 'images' | 'floorImages' | 'userImage', index = null) => {
    switch (imageType) {
      case 'floorImage':
        setFloorImage('');
        break;
      case 'paymentImage':
        setPaymentImage('');
        break;
      case 'images':
        if (index !== null) {
          setImages(prevImages => prevImages.filter((_, i) => i !== index));
        }
        break;
      case 'userImage':
        if (index !== null) {
          setImages(prevImages => prevImages.filter((_, i) => i !== index));
        }
        break;
      case 'floorImages':
        if (index !== null) {
          setFloorImages(prevImages => prevImages.filter((_, i) => i !== index));
        }
        break;
      // Add cases for other types of images if needed
      default:
        console.log('Invalid image type');
    }
  };

  return {
    uploadImage,
    images,
    setImages,
    files,
    setFiles,
    floorImage,
    setFloorImage,
    floorFiles,
    setFloorFiles,
    floorImages,
    setFloorImages,
    paymentImage,
    setPaymentImage,
    removeImage,
  };
};
