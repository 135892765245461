/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useDispatch } from "react-redux";
import * as auth from "../../../../app/components/auth/redux/AuthRedux";

export function AsideMenuMain() {
  const intl = useIntl();
  const dispatch = useDispatch();
  // const logout = () => {
  //   dispatch(auth.actions.logout());
  // };

  return (
    <>
      {/* remove this admin line */}
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/layouts/lay009.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/profile-details"
        icon="/media/icons/duotune/communication/com013.svg"
        title="My Account"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItemWithSub
        to="/property Management"
        title="Post Listing"
        icon="/media/icons/duotune/maps/map008.svg"
        fontIcon="bi-person"
      >
        <AsideMenuItem
          to="/property"
          icon="/media/icons/duotune/general/gen001.svg"
          title="Property"
          fontIcon="bi-app-indicator"
        />
        <AsideMenuItem
          to="/listings"
          icon="/media/icons/duotune/art/art002.svg"
          title="Listings"
          fontIcon="bi-app-indicator"
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to="/amenities"
        icon="/media/icons/duotune/general/gen020.svg"
        title="Feature and Amenities"
        fontIcon="bi-app-indicator"
      />


      <AsideMenuItem
        to="/projectDeveloper"
        icon="/media/icons/duotune/art/art010.svg"
        title="Developer"
        fontIcon="bi-app-indicator"
      />

      {/* <AsideMenuItem
        to="/MapWithSearchBox"
        icon="/media/icons/duotune/art/art010.svg"
        title="MapWithSearchBox"
        fontIcon="bi-app-indicator"
      /> */}

      <AsideMenuItem
        to="/projects"
        icon="/media/icons/duotune/ecommerce/ecm008.svg"
        title="Projects"
        fontIcon="bi-app-indicator"
      />
      {/* <AsideMenuItem
        to="/bookings"
        icon="/media/icons/duotune/ecommerce/ecm008.svg"
        title="Bookings"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/rent"
        icon="/media/icons/duotune/ecommerce/ecm008.svg"
        title="Rent"
        fontIcon="bi-app-indicator"
      /> */}
      <AsideMenuItem
        to="/reports"
        icon="/media/icons/duotune/ecommerce/ecm010.svg"
        title="Reports"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/table"
        icon="/media/icons/duotune/files/fil002.svg"
        title="Property Tables"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItem
        to="/estimationValue"
        icon="/media/icons/duotune/finance/fin007.svg"
        title="Estimation Value"
        fontIcon="bi-app-indicator"
      />

      {/* <AsideMenuItem
        to="/employment"
        icon="/media/icons/duotune/art/art005.svg"
        title="Employment"
        fontIcon="bi-briefcase"
      />
      <AsideMenuItem
        to="/blogs"
        icon="/media/icons/duotune/general/gen038.svg"
        title="Blogs"
        fontIcon="bi-app-indicator"
      /> */}
      <AsideMenuItem
        to="/totalListings"
        icon="/media/icons/duotune/art/art010.svg"
        title="Total Listings"
        fontIcon="bi-app-indicator"
      />
      {/* <AsideMenuItem
        to="/plotListings"
        icon="/media/icons/duotune/maps/map008.svg"
        title="Plot Listings"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItem
        to="/maps"
        icon="/media/icons/duotune/general/gen006.svg"
        title="Maps"
        fontIcon="bi-app-indicator"
      /> */}

      <AsideMenuItem
        to="/evaluation"
        icon="/media/icons/duotune/technology/teh002.svg"
        title="Evaluation"
        fontIcon="bi-app-indicator"
      />
      {/* <AsideMenuItem
        to="/property"
        icon="/media/icons/duotune/general/gen001.svg"
        title="Property"
        fontIcon="bi-app-indicator"
      /> */}

      {/* <AsideMenuItem
        to='/application'
        icon='/media/icons/duotune/files/fil012.svg'
        title='Applications'
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/plotListings'
        icon="/media/icons/duotune/maps/map008.svg"
        title='Plot Listings'
        fontIcon='bi-app-indicator'
      />


      <AsideMenuItem
        to="/building"
        icon="/media/icons/duotune/ecommerce/ecm008.svg"
        title="Building"
        fontIcon="bi-app-indicator"
      /> */}
      {/* 
      <AsideMenuItem
        to="/reservations"
        icon="/media/icons/duotune/general/gen059.svg"
        title="Reservations"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to='/Integration'
        icon='/media/icons/duotune/arrows/arr029.svg'
        title='Integrations (Preview)'
        fontIcon='bi-app-indicator'
      /> */}
      <AsideMenuItem
        to="/projectName"
        title="Project Name"
        icon="/media/icons/duotune/maps/map007.svg"
      />

      <AsideMenuItem
        to="/consultants"
        icon="/media/icons/duotune/abstract/abs027.svg"
        title="Property Consultants"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/managers"
        icon="/media/icons/duotune/abstract/abs029.svg"
        title="Imlaak Managers"
        fontIcon="bi-app-indicator"
      />

      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-uppercase fs-8 ls-1">Setting</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/contact"
        title="Dashboard Setting"
        icon="/media/icons/duotune/general/gen019.svg"
        fontIcon="bi-person"
      >
        <AsideMenuItem
          to="/projectName"
          title="Project Name"
          icon="/media/icons/duotune/maps/map007.svg"
        /> 

         <AsideMenuItem
          to="/PropertyCategory"
          title="Property Category"
          icon="/media/icons/duotune/arrows/arr029.svg"
        />
        <AsideMenuItemWithSub
          to="/locations"
          title="Locations"
          icon="/media/icons/duotune/maps/map008.svg"
          fontIcon="bi-person"
        >
          <AsideMenuItem
            to="/country"
            title="Country"
            icon="/media/icons/duotune/maps/map007.svg"
          />
          <AsideMenuItem
            to="/province"
            title="Province"
            icon="/media/icons/duotune/maps/map007.svg"
          />
          <AsideMenuItem
            to="/city"
            title="City"
            icon="/media/icons/duotune/maps/map007.svg"
          />
          <AsideMenuItem
            to="/community"
            title="Community"
            icon="/media/icons/duotune/maps/map007.svg"
          />
          <AsideMenuItem
            to="/sector"
            title="Sector"
            icon="/media/icons/duotune/maps/map007.svg"
          />
          <AsideMenuItem
            to="/block"
            title="Block"
            icon="/media/icons/duotune/maps/map007.svg"
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub> */}

      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Dashboard Setting'
        fontIcon='bi-layers'
      />  */}

      {/* <AsideMenuItem
        to='/apps/chat'
        title='More'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen023.svg'
      > */}
      {/* <AsideMenuItem
          to='/apps/chat'
          title='Help Center'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/general/gen046.svg'
        >

      <AsideMenuItem
        to='/apps/chat'
        title='Sign Out'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/arrows/arr077.svg'
      >
         {/* <a onClick={logout} className='menu-link px-5'>
        <img className='mx-3' src='/media/icons/duotune/arrows/arr077.svg'/>
          Sign Out
        </a> */}
      {/* </AsideMenuItem> */}
      {/* 
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div> */}
    </>
  );
}
