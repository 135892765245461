import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  useFormControl,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useMemo } from "react";
import { AppTextColor, AppErrorColor } from "../theme/Colors";
import { makeStyles } from "@mui/styles";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";

const useStyles = makeStyles(() => ({
  headingStyles: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
    // margin: '0px 0px 10px 0px !important',
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : " #233a65",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    height: "29.69px",
    textTransform: "capitalize",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const CustomInput = ({
  label,
  placeholder,
  id,
  isError,
  helperText,
  value,
  onChangeHandler,
  maxLength,
  onKeyDown,
  type,
  disabled = false,
  ...rest
}: CustomInputProps | any) => {
  const classes = useStyles();

  function HeadingText() {
    const { focused } = useFormControl() || {};

    const headingColor = useMemo(() => {
      if (isError) {
        return AppErrorColor;
      }

      if (focused && !isError) {
        return "#007FFF";
      }

      return AppTextColor;
    }, [focused]);

    return (
      <Typography
        // fontSize={18}
        className={classes.headingStyles}
        color={headingColor}
      >
        {label}
      </Typography>
    );
  }

  return (
    <FormControl variant="standard" fullWidth>
      {/* <HeadingText /> */}
      {/* <FormControl variant="standard"> */}
      {/* <InputLabel shrink htmlFor="bootstrap-input"> */}
      <HeadingText />
      {/* </InputLabel> */}
      <BootstrapInput
        type={type}
        disabled={disabled}
        sx={{ width: "100%" }}
        defaultValue=""
        id={id ? id : `bootstrap-input-${label}`}
        name={id ? id : `bootstrap-input-${label}`}
        fullWidth
        placeholder={placeholder}
        error={isError}
        value={value}
        onChange={onChangeHandler}
        inputProps={{ maxLength: maxLength, ...rest }}
        onKeyDown={onKeyDown}

        {...rest}
      />
      {/* </FormControl> */}
      {/* <TextField
        id={id ? id : `bootstrap-input-${label}`}
        name={id ? id : `bootstrap-input-${label}`}
        fullWidth
        placeholder={label ?? ''}
        error={isError}
        value={value}
        onChange={onChangeHandler}
        inputProps={{ maxLength: maxLength }}
        onKeyDown={onKeyDown}
        size="small"
        variant='outlined'
        label={<HeadingText />}
      /> */}
      <FormHelperText error={isError}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export type CustomInputProps = {
  id?: string;
  label: string;
  placeholder?: string;
  isError?: boolean;
  helperText?: any;
  value: string;
  onChangeHandler: any;
  maxLength?: number;
  onKeyDown?: any;
  type?: "text" | "number" | "email";
  disabled?: boolean;
};
export default CustomInput;
