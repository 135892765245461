import CustomTable, {
  capitalizeFirstLetter,
  IconRender,
} from "app/components/Table";
import React, { useEffect, useState } from "react";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import Swal from "sweetalert2";
import { ProjectHeader } from "app/components/Project/ProjectHeader";
import { getAllPendingUsers, updateAgency } from "./Crud";
import { Chip, Typography } from "@mui/material";
import { blue, green, red } from "@mui/material/colors";
import { PageLink, PageTitle } from "_metronic/layout/core";

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: "",
    path: "/crafted/account/overview",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

let i = 1;
const colors: any = {
  approve: "#47BE7D",
  reject: " #bda588",
  suspend: red[300],
  pending: "#F1BC00",
};
const columns = (iconClickHandler: any) => [
  // { name: 'Id', key: '_id', numaric: false, isSortable: true, },
  {
    name: "S.no",
    key: "",
    numaric: true,
    isSortable: true,
    renderContent: () => i++,
  },
  {
    name: "Position",
    key: "role",
    numaric: true,
    isSortable: true,
    renderContent: (value: string) => capitalizeFirstLetter(value),
  },

  {
    name: "Consultant Name",
    key: "action",
    numaric: false,
    isSortable: true,
    component: (row: any) => {
      return <>{capitalizeFirstLetter(`${row?.fname}`)}</>;
    },
  },

  { name: "Email", key: "email", numaric: false, isSortable: true },
  {
    name: "Status",
    key: "status",
    numaric: false,
    isSortable: true,
    renderContent: (value: string) => (
      <Chip
        sx={{ background: colors[value], color: "#fff", fontWeight: "bold" }}
        label={capitalizeFirstLetter(value)}
      />
      // {}
      // </Chip>
    ),
  },
  {
    name: "Action",
    key: "action",
    component: (row: any) => (
      <>
        {/* {IconRender(InfoOutlinedIcon, iconClickHandler)} */}
        {/* {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "edit")}
        {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, "delete")} */}
        <select
          className="form-select form-select-solid"
          aria-label="Select example"
          onChange={(event) => iconClickHandler(row, event.target.value)}
        >
          <option>Edit </option>
          {/* <option value="delete">Delete </option> */}
          <option value="approved">Approved</option>
          <option value="reject">Reject</option>
          <option value="suspended">Suspended</option>
          <option value="pending">Pending</option>
        </select>
      </>
    ),
  },
];
type props = {
  setAgenciesList: any;
};

const PindingUserTable: React.FC<props> = ({ setAgenciesList }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({});

  const [currentListing, setCurrentListing]: any = useState(0);
  const listing: any = useSelector<RootState>(
    ({ auth }) => auth.listing,
    shallowEqual
  );
  const [myProperties, setMyProperties] = useState(listing);
  const [accessToken, setAccessToken]: any = useState("");
  const [agencies, setAllAgencies] = useState<any>([]);
  const [modalMode, setModalMode]: any = useState("edit");

  const getToken = async () => {
    let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
    accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
    setAccessToken(accessToken);
  };

  const filtersChanged = (filters: any) => {
    setFilter(filters);
    setLoading(true);
    setTimeout(() => {
      getAgencies(filters);
    }, 1000);
  };

  const getAgencies = (filters: any) => {
    getAllPendingUsers(accessToken, filters)
      .then(({ data }) => {
        setAllAgencies(data.data);
        setAgenciesList(data);

        setCount(data?.count);
      })
      .catch(() => { })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    getToken();
    setTimeout(() => {
      getAgencies(filter);
    }, 1000);
  }, []);

  useEffect(() => {
    setMyProperties(listing);
  }, [listing]);

  const onClickCreate = () => {
    setOpen(true);
    setCurrentListing([]);
    setModalMode("create");
  };

  useEffect(() => {
    document
      .getElementById("create-button")
      ?.addEventListener("click", onClickCreate);
  }, []);
  const iconClickHandler = (row: any, type: any) => {
    // if (type === "edit") {
    //   // setModalMode("edit");
    //   // setCurrentListing(row);
    //   // setOpen(true);
    // } else
    if (type) {
      Swal.fire({
        title: `Attempting to ${type} an Agency`,
        text: `Are you sure you want to ${type} this Project`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((status) => {
        if (status.isConfirmed) {
          setLoading(true);
          updateAgency(row._id, type)
            .then(({ data, status }) => {
              let text = "";
              let icon: any = "error";
              if (status === 200) {
                text = `Successfully changed status`;
                icon = "success";
              } else {
                text = data.message;
                icon = "error";
              }
              Swal.fire({
                title: "Status Changed",
                text: text,
                icon: icon,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
              });
              setAllAgencies(data);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
        }
      });
    }
  };

  const createProjectFunction = (values: any) => {
    setLoading(true);
    setOpen(false);
    Swal.fire({
      title: "Project Listing?",
      text: "Do you want to List this Project for sale?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No Don't List, Add as an asset",
      confirmButtonText: "Yes List this",
    })
      .then((value: any) => {
        if (value.isConfirmed) {
          values = { ...values, isListed: true };
        }
        // createProject(values, accessToken)
        //   .then(({ data }) => {
        //     dispatch(AuthRedux.actions.setMyListings(data.project));
        //     setCount(data.count);
        //   })
        //   .catch(() => {})
        //   .finally(() => setLoading(false));
      })
      .finally(() => { });
  };

  const updateProjectFunction = (values: any) => {
    setLoading(true);
    // updateProject(values, currentListing?._id, accessToken)
    //   .then(({ data }) => {
    //     dispatch(AuthRedux.actions.setMyListings(data.project));
    //     setCount(data.count);
    //   })
    //   .catch(() => {})
    //   .finally(() => setLoading(false));
  };

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Pending Users</PageTitle>

      <ProjectHeader filter={filter} setFilter={filtersChanged} />
      <CustomTable
        columns={columns(iconClickHandler)}
        data={agencies}
        loading={loading}
        onRowClick={(row: any, type: any) => iconClickHandler(row, type)}
        isPaginationRequired
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        total={count}
      />
    </>
  );
};

export default PindingUserTable;
