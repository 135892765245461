const API_URL = process?.env.REACT_APP_API_URL + 'api'
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/finduser`
export const LOGIN_URL = `${API_URL}/auth/login`
export const CONSULTANT_DETAILS_BY_ID = `${API_URL}/auth/getConsultantDetails`
export const UpdatePassword = `${API_URL}/auth/updatePassword`
export const REGISTER_URL = `${API_URL}/auth/registerAfterVerification`
export const REGISTRATION_URL = `${API_URL}/auth/register`
export const VERIFY_EMAIL = `${API_URL}/auth/verification`
export const LandlordsReview = `${API_URL}/reviews/landlordReviews`
export const VERIFICATION_URL = `${API_URL}/auth/verifyLandlordAccount`
export const APPROVAL_REQUEST = `${API_URL}/auth/LandlordApprovalRequest`
export const CHANGE_CONSULTANT = `${API_URL}/changeConsultant/changeRequest`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgotPassword`
export const GET_MY_LANDLORDS = `${API_URL}/auth/getMyLandlords`
export const UPDATE_LANDLORD_PROFILE = `${API_URL}/auth/updateLandlordProfile`
export const updateLandlordProfileImage = `${API_URL}/auth/updateLandlordProfileImage`
export const UPDATE_USER_PROFILE = `${API_URL}/auth/updateUserProfile`
export const CREATE_PROPERTY = `${API_URL}/inventory/createProperty`
export const CREATE_INVENTORY = `${API_URL}/inventory/createProperty`
export const CREATE_BOOKING = `${API_URL}/booking/createBooking`
export const GET_MY_PROPERTIES = `${API_URL}/inventory/getMyProperties`
export const GET_ALL_INVENTORY = `${API_URL}/inventory/getAllProperties`
export const GET_CONSULTANT_INVENTORY_WITH_EVALUATION = `${API_URL}/inventory/getPropertiesWithEvaluation`
export const CREATE_UPDATE_EVALUATION_BY_CONSULTANT = `${API_URL}/evaluation/evaluationByConsultant`
export const GET_ALL_INVENTORIES = `${API_URL}/inventory/getAllPropertiesByMan`
export const GET_CONSULTANT_INVENTORY = `${API_URL}/inventory/getConsultantProperties`
export const GET_CONSULTANT_PROPERTIES = `${API_URL}/inventory/getPropertiesOfConsultant`
export const GET_MY_INVENTORY = `${API_URL}/inventory/getMyProperties`
export const GET_LANDLORD_INVENTORY = `${API_URL}/inventory/getLandlordProperties`
export const UPDATE_EVALUATION = `${API_URL}/evaluation/carryOutEvaluation`
export const GET_MY_EVALUATION = `${API_URL}/evaluation/getMyEvaluations`
export const GET_REQUESTED_EVALUATION = `${API_URL}/evaluation/evaluationRequests`
export const UPDATE_PROPERTY = `${API_URL}/inventory/updateProperty`
export const UPDATE_INVENTORY = `${API_URL}/inventory/updateProperty`
export const UPDATE_CONSULTANTINVENTORY = `${API_URL}/inventory/addPropertyByConsultant`
export const UPDATE_CONSULTANT_PROPERTY = `${API_URL}/inventory/updateConsultantProperty`
export const UPDATE_OWNER = `${API_URL}/inventory/updateOwner`
export const DELETE_PROPERTY = `${API_URL}/inventory/deleteProperty`
export const DELETE_INVENTORY = `${API_URL}/inventory/deleteProperty`
export const DELETE_ConsultantProperty = `${API_URL}/inventory/deleteConsultantProperty`
export const MARK_AS_SOLD = `${API_URL}/inventory/markAsSold`

export const DELETE_INVENTORYPROPERLY = `${API_URL}/inventory/deletePropertyProperly`

export const REQUEST_EVALUATION = `${API_URL}/evaluation/requestEvaluation`
export const GET_ALL_USERS = `${API_URL}/auth/getAllUsers`
export const GET_ALL_LANDLORDS = `${API_URL}/auth/getAllLandlords`
export const GET_LANDLORDS_DETAILS = `${API_URL}/auth/getLandlordsDetails`
export const GET_ALL_AGENCIES = `${API_URL}/auth/getAllConsultants`
export const GET_ALL_APPROVE_USERS = `${API_URL}/auth/getAllApproveUsers`
export const GET_ALL_PENDING_USERS = `${API_URL}/auth/getAllPendingUsers`
export const GET_ALL_REJECTED_USERS = `${API_URL}/auth/getAllRejectedUsers`
export const GET_ALL_AGENTS = `${API_URL}/auth/getAllAgents`
export const GET_ALL_PROJECT = `${API_URL}/project/getAllProjects`


export const UPDATE_AGENCY_STATUS = `${API_URL}/auth/agencyStatusUpdate`

export const CREATE_PROJECT = `${API_URL}/project/createProject`
export const GET_ALL_PROPERTIES = `${API_URL}/inventory/getAllProperties`
// export const GET_MY_PROPERTIES = `${API_URL}/inventory/getMyProperties`
// export const GET_MY_EVALUATION = `${API_URL}/evaluation/getMyEvaluations`
export const UPDATE_PROJECT = `${API_URL}/project/updateProject`
export const DELETE_PROJECT = `${API_URL}/project/deleteProject`
export const Get_PROJECT_BY_ID = `${API_URL}/project/getProjectById`
// export const REQUEST_EVALUATION = `${API_URL}/evaluation/requestEvaluation`


export const GET_MY_BLOGS = `${API_URL}/blog/getAllBlogs`
export const CREATE_BLOG = `${API_URL}/blog/add`
export const UPDATE_BLOG = `${API_URL}/blog/updateBlog`
export const DELETE_BLOG = `${API_URL}/blog/deleteBlog`
export const GET_BY_ID = `${API_URL}/blog/gitById`
