import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select } from 'redux-saga/effects'
import { updateProfile } from './ProfileCrud'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  updateProfile: '[updateProfile] Action',
  updateProfileSaga: '[updateProfileSaga] Action',
  updateProfileInSaga: '[updateProfileInSaga] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: localStorage.getItem('accessToken'),
}

export interface IAuthState {
  user?: any
  accessToken?: string | undefined | null
}

export const reducer = persistReducer(
  { storage, key: 'accessToken', whitelist: ['user', 'accessToken'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.updateProfile: {
        const user = action.payload?.user
        return { ...state, user }
      }

      default:
        return state
    }
  }
)

export const actions = {
  updateProfile: (user: any) => { debugger; return ({ type: actionTypes.updateProfile, payload: {} }) },
  updateProfileSaga: (user: any) => { debugger; return ({ type: actionTypes.updateProfileInSaga }) },

}

export function* saga() {

  yield takeLatest(actionTypes.updateProfileInSaga, function* updateProfileSaga(props) {
    console.log(props, 'props')
    // @ts-ignore
    const getToken = (state) => state.auth.accessToken;
    // @ts-ignore
    let token = yield select(getToken)
    // const { data: user } = yield updateProfile('a', 'b', 'c')
    // yield put(actions.updateProfile(user))
  })

}
