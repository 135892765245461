import React, { FC, useState } from "react";
import { ErrorMessage, Field, useField, } from "formik";
import CustomInput from "app/components/Inputs";
import { IUpdateAccount, inits } from "../CreateAccountWizardHelper";
import { RootState } from "setup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const Step4: FC = () => {
  // const [addressField,] = useField("address");
  const [countryField, countryMeta, countryHelpers] = useField("country");
  const [provinceField, provinceMeta, provinceHelpers] = useField("province");
  const [cityField, cityMeta, cityHelpers] = useField("city");
  const [locationField, locationMeta, locationHelpers] = useField("address");;
  const auth: any = useSelector<RootState>(({ auth }) => auth, shallowEqual);
  const [initValues] = useState<IUpdateAccount>(inits(auth));


  // Address using GoogleAPi
  const [address, setAddress] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  const [formattedAddress, setFormattedAddress] = useState<string>('');
  const [coordinates, setCoordinates] = useState<string>('');
  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
    handleGetCoordinates();
  };
  const handleGetCoordinates = async () => {
    try {
      const apiKey = process?.env?.REACT_APP_GOOGLE_MAPS_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${apiKey}`
      );

      if (response.ok) {
        const data = await response.json();
        if (data.results.length > 0) {
          const location = data.results[0].geometry.location;
          const addressComponents = data.results[0].address_components;
          const placeId = data.results[0].place_id;
          const formattedAddressValue = data.results[0].formatted_address;
          locationHelpers.setValue(formattedAddress)
          // Extract city and country names from address components
          let city = '';
          let state = '';
          let country = '';
          addressComponents.forEach((component: any) => {
            if (component.types.includes('locality')) {
              city = component.long_name;
              cityHelpers.setValue(city);
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name;
              provinceHelpers.setValue(province);
            } else if (component.types.includes('country')) {
              country = component.long_name;
              countryHelpers.setValue(country);
            }
          });
          setCoordinates(location);
          setFormattedAddress(formattedAddressValue)
          setCountry(country);
          setProvince(state);
          setCity(city);
        } else {
          console.error('No results found for the provided address.');
        }
      } else {
        console.error('Error fetching data from the geocoding API.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <>
      <div className="w-100">
        <div className="fv-row">
          <div className="pb-10 pb-lg-12">
            <h2 className="fw-bolder text-dark">
              Please Enter Your Address
            </h2>
          </div>
          <div className="row">
            <div className="fv-row mb-10">
              <label className="form-label">Address</label>

              <CustomInput
                style={{ background: "#F5F8FA", border: "none" }}
                id="address"
                placeholder="Type here..."
                value={address}
                onChangeHandler={handleAddressChange}
                required
              />
              {/* <Field name="address">
              </Field> */}
              {coordinates && (
                <div className="pt-3">
                  <p className="fw-bolder">(Address: &nbsp;&nbsp;&nbsp; {formattedAddress})</p>
                </div>)}
              <div className="text-danger mt-2">
                <ErrorMessage name="address" />
              </div>
            </div>
            <div className="fv-row mb-10">
              <label className="form-label">Country</label>
              {/* <CustomInput
                style={{ background: "#F5F8FA", border: "none" }}
                id="country"
                value={country}
                disabled
              /> */}
              <Field name="country" className="form-control form-control-lg form-control-solid" disabled />
              <div className="text-danger mt-2">
                <ErrorMessage name="country" />
              </div>
            </div>
            <div className="fv-row mb-10">
              <label className="form-label">Province/State</label>
              {/* <CustomInput
                style={{ background: "#F5F8FA", border: "none" }}
                id="province"
                value={province}
                disabled
              /> */}
              <Field name="province" className="form-control form-control-lg form-control-solid" disabled />
            </div>
            <div className="fv-row mb-10">
              <label className="form-label">City</label>
              {/* <CustomInput
                style={{ background: "#F5F8FA", border: "none" }}
                id="city"
                value={city}
                disabled
              /> */}
              <Field name="city" className="form-control form-control-lg form-control-solid" disabled />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Step4 };
