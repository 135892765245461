import React, { useEffect, useState } from "react";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
    PageLink,
    PageTitle,
    usePageData,
} from "../../../_metronic/layout/core";
import { PropertyHeader } from "./PropertyHeader";
import Table, { capitalizeFirstLetter, IconRender } from "../Table";
import { UpdateConsultantPropertyValues, addConsultantPropertyInventory, deleteConsultantProperty, getConsultantInventory, markingPropertySold } from "./redux/PropertyCrud";
import * as AuthRedux from "../auth/redux/AuthRedux";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { updateProperty, createProperty } from "./redux/PropertyCrud";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "_metronic/helpers/AssetHelpers";
import useFetchData from "app/hooks/useFetchData ";
import { APIS } from "Constants";
import CreateConsultantProperty from "./components/CreateConsultantProperty";
import { EyeIcon } from "@heroicons/react/solid";
import UpdateConsultantProperty from "./components/UpdateConsultantProperty";
import ImageTestingComponent from "./components/ImageTestingComponent";
import PropertySold from "./components/PropertySold";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
    },
];
const columns = (iconClickHandler: any, projectLookup: any, landlordLookup: any) => {
    return [
        {
            name: "Building",
            key: "projectName",
            numaric: false,
            isSortable: true,
            renderContent: (value: any) => capitalizeFirstLetter(
                <div className="overflow-ellipsis" title={value}>
                    <div className="overflow-ellipsis text-capitalize" title={value}>
                        {projectLookup[value] || value}
                    </div>
                </div>
            ),
        }, {
            name: "Floor",
            key: "floorNo",
            numaric: false,
            isSortable: true,
            renderContent: (value: any) => capitalizeFirstLetter(value),
        },
        {
            name: "Apartment No",
            key: "appartmentNo",
            numaric: false,
            isSortable: true,
            renderContent: (value: any) => capitalizeFirstLetter(value),
        }, {
            name: "Landlord",
            key: "landlordId",
            numaric: false,
            isSortable: true,
            renderContent: (value: any) => capitalizeFirstLetter(
                <div className="overflow-ellipsis" title={value}>
                    <div className="overflow-ellipsis text-capitalize" title={value}>
                        {landlordLookup[value]}
                    </div>
                </div>
            ),
        }, {
            name: "Status",
            key: "isVerified",
            numaric: false,
            isSortable: true,
            renderContent: (value: any) => (value ? "Verified" : "Unverified"),
        },
        {
            name: "Action",
            key: "action",
            component: (row: any) => (
                <>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: '16px', fontWeight: '600' }}>Select</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select"
                            onChange={(event) => iconClickHandler(row, event.target.value)}
                        >
                            <MenuItem value='verify'>Verify Property</MenuItem>
                        </Select>
                    </FormControl>
                </>
            ),
        },
    ];
};

const ConsultantUnVerifiedPropertyPage: React.FC = () => {
    const dispatch = useDispatch();
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);

    const { getAllNames } = APIS.ProjectName;
    const { getAll } = APIS.users;
    const { projectNameData, data } = useFetchData({ getAllNames, getAll });
    const consultantLandlords = data.filter((row: any) => row?.consultant === user?._id && row?.role === 'landlord')
    const consultantLandlordIds = consultantLandlords?.map((landlord: any) => landlord._id);

    const [projectLookup, setProjectLookup] = useState<any>({});
    const [landlordLookup, setLandlordLookup] = useState<any>({});

    useEffect(() => {
        // Create a lookup object for projectName id's to project Names
        const lookup: any = {};
        projectNameData.forEach((row: any) => {
            lookup[row?._id] = row?.projectName;
        });
        setProjectLookup(lookup);
    }, [projectNameData]);
    useEffect(() => {
        // Create a lookup object for Landlord's id's to Name
        const lookup: any = {};
        data?.forEach((row: any) => {
            lookup[row?._id] = row?.fname;
        });
        setLandlordLookup(lookup);
    }, [data]);

    const listing: any = useSelector<RootState>(
        ({ auth }) => auth.listing,
        shallowEqual
    );
    const [myProperties, setMyProperties] = useState(listing);
    const [accessToken, setAccessToken]: any = useState("");

    const getToken = async () => {
        let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
        accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
        setAccessToken(accessToken);
    };

    // Consultant Properties
    useEffect(() => {
        getConsultantInventory(accessToken)
            .then(response => {
                const filteredData = response.data?.properties?.filter((row: any) => !row?.isVerified);
                setMyProperties(filteredData);
                setCount(filteredData.length);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    }, []);

    const iconClickHandler = (row: any, type: any) => {
        const values = { isVerified: true }
        if (type === "verify") {
            Swal.fire({
                title: "",
                text: "Are you sure you want to verify this Property",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((status) => {
                if (status.isConfirmed) {
                    setLoading(true);
                    updateProperty(values, row._id, accessToken)
                        .then(({ data, status }) => {
                            let text = "";
                            let icon: any = "error";
                            if (status === 200) {
                                text = "Successfully Verified";
                                icon = "success";
                            } else {
                                text = data.message;
                                icon = "error";
                            }
                            Swal.fire({
                                title: "Property Verified",
                                text: text,
                                icon: icon,
                                showCancelButton: false,
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "Ok",
                            });
                            const filteredData = data?.property?.filter((row: any) => { return consultantLandlordIds.includes(row?.landlordId) && !row?.isVerified });
                            setMyProperties(filteredData)
                            setCount(filteredData?.length)
                            dispatch(AuthRedux.actions.setMyListings(filteredData));
                        })
                        .catch(() => { })
                        .finally(() => setLoading(false));
                }
            });
        }
    };


    return (
        <>
            <PageTitle>Unverified Properties</PageTitle>
            {user?.status === "approved" ? (
                <div className="pb-5">
                    <Table
                        columns={columns(iconClickHandler, projectLookup, landlordLookup)}
                        data={myProperties}
                        loading={loading}
                        onRowClick={(row: any, type: any) => iconClickHandler(row, type)}
                        isPaginationRequired
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        total={count}
                    />
                </div>
            ) : (
                <div className="card-body p-0 ">
                    {/* begin::Chart */}
                    <div className="alert alert-warning p-5 mt-5 mx-10" role="alert">
                        This is a warning alert{" "}
                        <h3 className="text-danger">
                            Your account is not approved you cannot Post Property!!.
                        </h3>
                    </div>
                    {/* end::Chart */}
                    {/* begin::Stats */}
                    <div className="card-p mt-10 position-relative d-flex justify-content-center">
                        <img src={toAbsoluteUrl("/media/illustrations/sketchy-1/57.svg")} />

                        {/* end::Row */}
                    </div>
                    <span className="d-flex justify-content-center fw-bold fs-4 mb-1 ">
                        No Record Found
                    </span>
                    {/* <span className='d-flex justify-content-center fw-bold fs-4 text-muted mb-5 '>Submit your application to Imlaak support for further assistance</span>  */}
                    {/* end::Stats */}
                </div>
            )}
        </>
    );
};

export default ConsultantUnVerifiedPropertyPage;
