import { useEffect, useRef, useState } from "react";
import { Box, Button, Card, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import Modal from "../../Modal";
import CustomInput from "../../Inputs";
import { useFormik } from "formik";
import * as yup from "yup";
import Loader from "../../Loader";
import { API } from "app/utils/config";
import MultipleSelectCheckmarks from "app/components/Inputs/DropdownWithCheckBox";
import {
  propertylanguagesOptions,
  propertyspecialtiesOptions,
} from "app/components/DropDownOptions/DropDownOptions";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Delete } from "@mui/icons-material";
import { CloudImage } from "app/shared/Input/CloudImage";
import { v2 as cloudinary } from "cloudinary";

const validationSchema = yup.object({
  // fname: yup.string().required("Consultant's name is required"),
  // email: yup
  //   .string()
  //   .required("Consultant's email is required")
  //   .email("Invalid email address"),
  // // password: yup
  // //   .string()
  // //   .required("Consultant's password is required")
  // //   .min(8, "Password must be at least 8 characters long"),
  // contact: yup.string().required("Consultant's contact is required"),
  // // address: yup.string().required("Consultant's address is required"),
  // languages: yup
  //   .array()
  //   .required("Languages are required")
  //   .min(1, "At least one language must be selected"),
  // experience: yup
  //   .number()
  //   .typeError("Must be a number")
  //   .positive("Must be greater than 0")
  //   .required("Experience is required"),
  // specialties: yup
  //   .array()
  //   .required("specialties are required")
  //   .min(1, "At least one speciality must be selected"),
  // description: yup
  //   .string()
  //   .required("Description is required")
  //   .max(1000, "Description must be at most 500 characters long"),
  // userAvatar: yup.mixed().required("A file is required"),

});

type EditProps = {
  open: any;
  setOpen: any;
  data?: any;
  update?: any;
  loading?: boolean;
  type: "ADD" | "EDIT";
};

const Edit = ({
  open,
  setOpen,
  data,
  update,
  loading,
  type = "EDIT",
}: EditProps) => {

  useEffect(() => {
    if (Object.keys(data)?.length > 0) formik.setValues({ ...data });
  }, [data, open]);

  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const [address, setAddress] = useState<string>('');
  const [formattedAddress, setFormattedAddress] = useState<string>('');
  const [coordinates, setCoordinates] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      _id: data?._id || "",
      fname: data?.fname || "",
      email: data?.email || "",
      password: data?.password || "",
      contact: data?.contact || "",
      userAvatar: data?.userAvatar || [],
      description: data?.description || "",
      specialities: data?.specialities || "",
      specialties: data?.specialties || [],
      languages: data?.languages || [],
      experience: data?.experience || "",
      propertiesfor: data?.propertiesfor || "",
      address: data?.address || "",
      country: data?.country || "",
      province: data?.province || "",
      city: data?.city || "",
      role: 'consultant',
      status: 'approved',
      isVerified: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { _id, userAvatar, ...rest } = values
      let imagesData = [];

      if (type === 'EDIT') {
        const existingImageUrl = data?.userAvatar?.[0]?.public_id;

        if (values?.userAvatar && values?.userAvatar?.public_id) {
          imagesData.push(data?.userAvatar);
        } else {
          await cloudinary.uploader.destroy(existingImageUrl);

          const response = await CloudImage(userAvatar);
          imagesData.push(response);
        }
        const newValues = { ...rest, userAvatar: imagesData, _id };
        update(newValues);
      } else {
        const response = await CloudImage(userAvatar);
        imagesData.push(response);

        const newValues = { ...rest, userAvatar: imagesData };
        console.log("🚀 ~ onSubmit: ~ newValues:", newValues)
        update(newValues);
      }

      formik.resetForm();
      setAddress('')
      setImagePreview(null)
    },
  });

  // Address using GoogleAPi
  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
    handleGetCoordinates();
  };
  const handleGetCoordinates = async () => {
    try {
      // Replace 'YOUR_API_KEY' with your actual Google Maps API key
      const apiKey = process?.env?.REACT_APP_GOOGLE_MAPS_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${apiKey}`
      );

      if (response.ok) {
        const data = await response.json();
        if (data.results.length > 0) {
          const location = data.results[0].geometry.location;
          const addressComponents = data.results[0].address_components;
          const placeId = data.results[0].place_id;
          const formattedAddressValue = data.results[0].formatted_address;
          let city = '';
          let state = '';
          let country = '';
          addressComponents.forEach((component: any) => {
            if (component.types.includes('locality')) {
              city = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name;
            } else if (component.types.includes('country')) {
              country = component.long_name;
            }
          });

          formik.setValues({
            ...formik.values,
            address: formattedAddressValue,
            province: state,
            country: country,
            city: city,
          });
          setCoordinates(location);
          setFormattedAddress(formattedAddressValue)
        } else {
          console.error('No results found for the provided address.');
        }
      } else {
        console.error('Error fetching data from the geocoding API.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  //  UserAvatar
  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };
  useEffect(() => {
    if (formik.values.userAvatar && formik.values.userAvatar?.length > 0) {
      const initialPreviews = formik.values.userAvatar?.map((image: any) => image?.url);
      setImagePreview(initialPreviews);
    }
  }, [formik.values.userAvatar]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (file.size / 1024 > 1024) {
        setError('File size should not exceed 1024 KB (1mb).');
        return;
      } else {
        setError('');
        const imageUrl = URL.createObjectURL(file);
        setImagePreview(imageUrl);
        formik.setFieldValue("userAvatar", file);
      }
    }
  };

  // Handle image removal
  const handleDeleteImage = () => {
    setImagePreview('');
    formik.setFieldValue("userAvatar", null);
    const fileInput = document.getElementById("userAvatar") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  return (
    <Modal
      setOpen={() => setOpen(!open)}
      open={open}
      title={
        type === "EDIT"
          ? `Update Consultant Profile`
          : "Add New Consultant"
      }
      cancelButtonTitle="Cancel"
      acceptButtonVarient="contained"
      acceptButtonTitle={
        type === "EDIT"
          ? `Update Consultant`
          : "Add New Consultant"
      }
      handleAccept={() => formik.handleSubmit()}
      handleClose={() => {
        formik.resetForm()
        setImagePreview(null)
        setAddress('')
        // formik.values
      }}
      loading={loading}
      isActionable={true}
    >
      {
        loading ? (
          <Box
            sx={{
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "50%",
              top: "30%",
            }}
          >
            <Loader top='"60%' left='"50%' />
          </Box >
        ) : null}
      <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="fname"
              label="Consultant's Name"
              placeholder="Type here..."
              value={formik.values.fname}
              onChangeHandler={formik.handleChange}
              isError={formik.touched?.fname && Boolean(formik.errors.fname)}
              helperText={formik.touched.fname ? formik.errors.fname : ""}
            />
          </Grid>

          {type !== "EDIT" ? (<Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="email"
              label="Email"
              placeholder="Type here..."
              value={formik.values.email}
              onChangeHandler={formik.handleChange}
              isError={formik.touched?.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ""}
            />
          </Grid>
          ) : null}
          {type !== "EDIT" ? (
            <Grid item xs={12} sm={12} md={6}>
              <CustomInput
                id="password"
                label="Password"
                placeholder="Type here..."
                value={formik.values.password}
                onChangeHandler={formik.handleChange}
                isError={
                  formik.touched?.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password ? formik.errors.password : ""}
              />
            </Grid>
          ) : null}

          <Grid item xs={12} sm={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>Phone number</Typography>
            <div
              style={{
                fontSize: "30.2rem",
              }}
            >
              <PhoneInput
                country={"pk"}
                value={formik.values.contact}
                onChange={(phone: any) =>
                  formik.setFieldValue("contact", phone)
                }
                inputProps={{
                  name: "contact",
                  id: "contact",
                  required: true,
                  autoFocus: true,
                  placeholder: "Enter phone number",
                  style: {
                    fontSize: "1.2rem", // change the font size as per your preference
                    marginTop: "1rem", // change the margin as per your preference
                    height: "50px",
                    width: "328px",
                  },
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="experience"
              label="Experience in Years"
              type="number"
              placeholder="Type here..."
              value={formik.values.experience}
              onChangeHandler={formik.handleChange}
              isError={
                formik.touched?.experience && Boolean(formik.errors.experience)
              }
              helperText={
                formik.touched.experience ? formik.errors.experience : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="address"
              label="Consultants address"
              placeholder="Type here..."
              value={address}
              onChangeHandler={handleAddressChange}
            />
            {coordinates && (
              <>
                <div className="pt-3">
                  <p className="fw-bolder">(New Address: &nbsp;&nbsp;&nbsp; {formattedAddress})</p>
                </div>
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="Country"
              label="Country"
              placeholder="Country..."
              value={formik.values.country}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="province"
              label="Province/State"
              placeholder="Province..."
              value={formik.values.province}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="city"
              label="City"
              placeholder="City..."
              value={formik.values.city}
              disabled
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <MultipleSelectCheckmarks
              style={{ marginTop: "10px" }}
              name="specialties"
              label="Select Specialties"
              tagName="Select Specialties*"
              value={formik.values.specialties ?? ""}
              onChange={(event: any) => {
                formik.setFieldValue("specialties", event.target.value);
              }}
              keyToSelect={"label"}
              options={propertyspecialtiesOptions}
              isError={
                formik.touched.specialties && Boolean(formik.errors.specialties)
              }
              helperText={
                formik.touched.specialties ? formik.errors.specialties : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <MultipleSelectCheckmarks
              name="languages"
              label="Choose Languages"
              tagName="Choose Languages*"
              value={formik.values.languages ?? ""}
              onChange={(event: any) => {
                formik.setFieldValue("languages", event.target.value);
              }}
              keyToSelect={"label"}
              options={propertylanguagesOptions}
              isError={
                formik.touched.languages && Boolean(formik.errors.languages)
              }
              helperText={
                formik.touched.languages ? formik.errors.languages : ""
              }
            />
          </Grid>

        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <CustomInput
            id="description"
            label="Describe Your Counsultant"
            multiline
            rows={10}
            placeholder="Type here..."
            value={formik.values.description}
            type="number"
            onChangeHandler={formik.handleChange}
            isError={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={
              formik.touched.description ? formik.errors.description : ""
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <h5
            className='text-light p-3 rounded'
            style={{
              background:
                "linear-gradient(45deg,  #233a65 0%, #f5f8fa 62%, #fff 100%)",
            }}
          >
            Consultants Profile Image
          </h5>
          <Grid item xs={12} sm={12} md={12}>
            <div className="bg-white p-3 shadow rounded mt-3">
              <input
                ref={fileInputRef}
                id="developerImage"
                name="developerImage"
                type="file"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <Grid container m={2}>
                {imagePreview ? (
                  <Grid item >
                    <Card elevation={4} sx={{ position: "relative", mb: 2, mx: 2 }}>
                      <div className='image-item'>
                        <img src={imagePreview} alt={`Preview `} style={{ maxWidth: '170px', maxHeight: '170px', borderRadius: '5px' }} />
                        <div className='image-item__btn-wrapper'>
                          <IconButton
                            onClick={handleDeleteImage}
                            sx={{
                              border: "1px dashed red",
                              borderRadius: "50%",
                              position: "absolute",
                              top: "0",
                              right: "0",
                              m: 1,
                            }}
                          >
                            <Delete sx={{ fontSize: 28, fill: "red" }} />
                          </IconButton>
                        </div>
                      </div>
                    </Card>
                  </Grid>

                ) : (
                  <Grid item>
                    <div className="py-1 px-5 bg-white shadow rounded" style={{ textAlign: 'center' }}>
                      <IconButton
                        sx={{
                          border: "1px dashed aquamarine",
                          borderRadius: "50%",
                          width: "110px",
                          height: "110px",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          margin: "6px",
                        }}
                        onClick={handleUploadClick}
                      >
                        <AddAPhotoIcon sx={{ fontSize: 100 }} />
                      </IconButton>
                      <br />
                      <b>Select Image</b>
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>

        </Grid>

      </Box>
    </Modal >
  );
};

export default Edit;
