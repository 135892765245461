/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '_metronic/helpers'
import {Link} from 'react-router-dom'
import {Dropdown1} from '_metronic/partials'
import {useLocation} from 'react-router'

const OrganizationHeader: React.FC = () => {
  const location = useLocation()

  return (
    // <div className='card mb-5 mb-xl-10'>
    //   <div className='card-body pt-9 pb-0'>
        
    //     <div className='d-flex overflow-auto h-55px'>
    //       <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
    //         <li className='nav-item'>
    //           <Link
    //             className={
    //               `nav-link text-active-primary me-6 ` +
    //               (location.pathname === '/crafted/reports/overview' && 'active')
    //             }
    //             to='/crafted/reports/overview'
    //           >
    //             Reports
    //           </Link>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </div>
    <></>
  )
}

export {OrganizationHeader}
