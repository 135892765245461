import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

interface postUtil {
  url: string;
  params?: any;
}

interface getUtil {
  url?: string;
  params?: any;
}

export const useGetUtilMethod = () => {
  const API_END_POINT = process?.env.REACT_APP_API_URL + "api";
  const { enqueueSnackbar } = useSnackbar();
  const getUtilMethod = async ({ url = "", params = {} }: Partial<getUtil>) => {
    const config = {
      params: params,
    };

    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        enqueueSnackbar("some error occured on backend with status " + err, {
          variant: "error",
          style: { top: 0, right: 0, position: "absolute" },
        });
      }
    );
    const res = await axios.get(API_END_POINT + "/" + url, config);
    if (res?.status !== 200) {
      enqueueSnackbar("error:  " + res?.statusText, {
        variant: "error",
        style: { top: 0, right: 0, position: "absolute" },
      });
    }

    return res;
  };
  return {
    getUtilMethod,
  };
};

export const usePostUtilMethod = () => {
  const API_END_POINT = process?.env.REACT_APP_API_URL + "api";
  const { enqueueSnackbar } = useSnackbar();
  // useEffect(() => {
  //   let accessToken = localStorage.getItem("persist:accessToken") || "";
  //   accessToken = JSON.parse(accessToken)?.accessToken;
  //   setToken(accessToken);
  // }, []);

  const postUtilMethod = async ({
    url = "",
    params = {},
  }: Partial<getUtil>) => {
    const config = {
      ...params,
      // Authorization: `Bearer ${token}`,
    };
    try {
      axios.interceptors.response.use(
        (res) => res,
        (err) => {
          enqueueSnackbar("some error occured on backend with status " + err, {
            variant: "error",
            style: { top: 0, right: 0, position: "absolute" },
          });
        }
      );
      const res = await axios.post(API_END_POINT + "/" + url, config);
      if (res?.status === 205) {
        enqueueSnackbar("Data Duplication Error data must be unique", {
          variant: "error",
          // style: { top: 0, right: 0, position: "absolute" },
        });
      }
      else if (res?.status == 401) {
        console.log("error occurred");
        enqueueSnackbar(
          "some error occured on backend with status " + res.statusText,
          {
            variant: "error",
            // style: { top: 0, right: 0, position: "absolute" },
          }
        );
      } else if (res?.status !== 200) {
        console.log("error occurred");
        enqueueSnackbar(
          "some error occured on backend with status " + res.statusText,
          {
            variant: "error",
            // style: { top: 0, right: 0, position: "absolute" },
          }
        );
      } else if (res?.status === 200) {
        enqueueSnackbar(res.data.message, {
          variant: "success",

          // style: { top: 0, right: 0, position: "absolute" },
        });
      }
      return res;
    } catch (err) {
      enqueueSnackbar("some error occured on backend with status " + err, {
        variant: "error",
        // style: { top: 0, right: 0, position: "absolute" },
      });
      return {
        status: 500,
      };
    }
  };
  return {
    postUtilMethod,
  };
};

export const postUtilMethod = ({ url, params = {} }: postUtil) => {
  return axios.post(url, params);
};

export default {
  // getUtilMethod,
  postUtilMethod,
};

export const profilerLog = (
  id: any, // the "id" prop of the Profiler tree that has just committed
  phase: any, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration: any, // time spent rendering the committed update
  baseDuration: any, // estimated time to render the entire subtree without memoization
  startTime: any, // when React began rendering this update
  commitTime: any, // when React committed this update
  interactions: any // t
) => {
  const all = {
    actualDuration, // time spent rendering the committed update
    baseDuration, // estimated time to render the entire subtree without memoization
    commitTime, // when React committed this update
    id, // the "id" prop of the Profiler tree that has just committed
    interactions,
    phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
    startTime, // when React began rendering this update
  };
  process.env.NEXT_PUBLIC_PROFILER &&
    console.log(all, `==================${id}===============`, all.phase);
};
