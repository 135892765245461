import clsx from "clsx";
import React, { FC } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import Profile from "../../../assets/media/avatars/my.jpg";
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  QuickLinks,
  Search,
} from "../../../partials";
import { useLayout } from "../../core";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "setup";
import { profile } from "console";
import { API as apiHost } from "app/utils/config";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
  toolbarButtonIconSizeClass = "svg-icon-1";

const Topbar: FC = () => {
  const { config } = useLayout();
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );
  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* Search */}
      <div
        className={clsx("d-none align-items-stretch", toolbarButtonMarginClass)}
      >
        {/* <Search /> */}
      </div>
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <div
          className={clsx(
            "cursor-pointer symbol",
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          {/* <img src={`${apiHost}/uploads/${user?.userImage}`} alt='user?.fname' className='rounded-circle' /> */}

          <img
            src={user?.userAvatar ? `${user?.userAvatar?.[0]?.url}` : Profile}
            alt={user?.fname || 'Imlaak'}
            className="rounded-circle border-2"
          />
          {/* <img src={'/media/avatars/khan.jpg'} alt='Imlaak' className='rounded-circle' /> */}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === "menu" && (
        <div
          className="d-flex align-items-center d-none  ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/text/txt001.svg"
              className="svg-icon-1"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { Topbar };
