import { Avatar, Button, Card, Grid, IconButton } from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Box } from "@mui/system";
import { Delete, Remove } from "@mui/icons-material";
export function ImageUploader({
  multiple = false,
  files: oldFiles,
  setFiles,
  images = [],
  setImages,
  variant = "normal",
  onSubmit = () => { },
}: {
  multiple: boolean | undefined;
  onSubmit?: any;
  images: any;
  setImages: any;
  files: any;
  setFiles: any;
  variant?: "normal" | "profile";
}) {
  function handleFileSelect(evt: any) {
    let files = evt.target.files; // FileList object

    setFiles([...oldFiles, ...files]);
    // files is a FileList of File objects. List some properties.
    let output: any = [];
    let imagesInternal = [...images];
    let i = 0;
    for (i; i < files.length; ++i) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = function () {
        output.push(reader.result);
      };

      reader.readAsDataURL(file);
    }
  }

  const onImageRemove = (index: number) => {
    let newImages: any = [];
    let newFiles: any = [];
    images.map((_: any, i: any) => (index === i ? null : newImages.push(_)));
    oldFiles.map((_: any, i: any) => (index === i ? null : newFiles.push(_)));
    setImages(newImages);
    setFiles(newFiles);
  };
  const ref = createRef<HTMLInputElement>();
  const clickedImage = () => {
    ref?.current?.click();
  };

  return (
    <Box
      className="ImageUploader"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container columnGap={1}>
        {oldFiles.map((image: any, index: any) => {
          return (
            <ConvertedImageViewer
              index={index}
              image={image}
              onImageRemove={onImageRemove}
            />
          );
        })}
        {images.length === 0 || multiple ? (
          <Grid item>
            <Box display={"flex"} alignItems="center" onClick={clickedImage}>
              <Card elevation={4} sx={{ p: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <input
                    multiple={multiple}
                    ref={ref}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                    onClick={() => { }}
                  />
                  {variant === "profile" ? (
                    <IconButton
                      sx={{
                        border: "1px dashed aquamarine",
                        borderRadius: "50%",
                        width: "100px",
                        height: "100px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        margin: "8px",
                      }}
                    >
                      <Avatar sx={{ fontSize: 60 }} />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        sx={{
                          border: "1px dashed aquamarine",
                          borderRadius: "50%",
                          width: "110px",
                          height: "110px",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          margin: "8px",
                        }}
                      >
                        <AddAPhotoIcon sx={{ fontSize: 100 }} />
                      </IconButton>
                      <br />
                      Click or Drop here
                    </>
                  )}
                </Box>
              </Card>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}

export const ImageViewer = ({ index, image, onImageRemove }: any) => {
  return (
    <Grid item key={index}>
      <Card elevation={4} sx={{ p: 2, position: "relative" }}>
        <div key={index} className="image-item">
          <img src={image} alt="no preview" width={150} height={150} />
          <div className="image-item__btn-wrapper">
            {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
            <IconButton
              onClick={() => onImageRemove(index)}
              sx={{
                border: "1px dashed red",
                borderRadius: "50%",
                margin: "8px",
                position: "absolute",
                top: "0",
                right: "0",
              }}
            >
              <Delete sx={{ fontSize: 28, fill: "red" }} />
            </IconButton>
          </div>
        </div>
      </Card>
    </Grid>
  );
};

export const ProfileImageViewer = ({ index, image }: any) => {
  return (
    <Grid item key={index}>
      <Card elevation={4} sx={{ p: 2, position: "relative" }}>
        <div key={index} className="image-item">
          <img src={image} alt="no preview" width={150} height={150} />
        </div>
      </Card>
    </Grid>
  );
};

export const ConvertedImageViewer = ({ index, image, onImageRemove }: any) => {
  const [output, setOutput] = useState<string | ArrayBuffer | null>("");
  useEffect(() => {
    const reader = new FileReader();
    let output = null;
    reader.onloadend = function () {
      setOutput(reader.result);
    };

    reader.readAsDataURL(image);
  }, []);
  return (
    <ImageViewer index={index} image={output} onImageRemove={onImageRemove} />
  );
};
