import axios from 'axios'
import { UserModel } from '../models/UserModel'
import { LOGIN_URL, REGISTER_URL, REQUEST_PASSWORD_URL, GET_USER_BY_ACCESSTOKEN_URL, VERIFICATION_URL, UpdatePassword, UPDATE_USER_PROFILE, REGISTRATION_URL, VERIFY_EMAIL, APPROVAL_REQUEST, CONSULTANT_DETAILS_BY_ID, LandlordsReview, CHANGE_CONSULTANT, GET_LANDLORDS_DETAILS, UPDATE_LANDLORD_PROFILE, updateLandlordProfileImage } from '../../../constants/api'

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Update Password
export function updatePassword(password: string, newPassword: string, userId: string) {
  return axios.post(UpdatePassword, {
    password,
    newPassword,
    userId,
  })
}

// Server should return AuthModel
export function register(email: string, password: string) {
  return axios.post(REGISTER_URL, {
    email,
    password,
  })
}

export function registration(fname: string, email: string, password: string) {
  return axios.post(REGISTRATION_URL, {
    fname,
    email,
    password,
    role: "landlord",
  })
}

export function verify(email: string, password: string, accessToken: string | null) {
  return axios.post(VERIFICATION_URL, {
    email,
    password,
  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken(token: string) {
  return axios.get(`${GET_USER_BY_ACCESSTOKEN_URL}`, {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    }
  });
}

// Update User
export function UpdateUserProfile(values: any, accessToken: string | null) {
  return axios.post(UPDATE_USER_PROFILE, {
    values: {
      ...values,
    }

  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

// update Landlord Image
export function UpdateLandlordProfileImage(values: any, accessToken: string | null) {
  return axios.post(updateLandlordProfileImage, {
    ...values,
  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

// Email Verification
export function VerifyEmail(token: string) {
  const url = `${VERIFY_EMAIL}/${token}`; // Log the full URL
  return axios.get(url)
}

// Accepting Approval request by Landlord
export function ApprovalRequest(id: string, status: string,) {
  return axios.post(APPROVAL_REQUEST, {
    id: id,
    status,
  },
  )
}

// ConsultantDetailsById
export async function getConsultantDetailsById(id: string): Promise<any> {
  try {
    const response = await fetch(`${CONSULTANT_DETAILS_BY_ID}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch consultant details:", error);
    throw error;
  }
}

export async function getLandlordsReviewById(id: string): Promise<any> {
  try {
    const response = await fetch(`${LandlordsReview}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch consultant details:", error);
    throw error;
  }
}

// Change Consultant
export async function ChangeConsultant(values: any, accessToken: string | null) {
  try {
    const response = await axios.post(CHANGE_CONSULTANT, values, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    // Return the entire response or just the data part based on your need
    return response;
  } catch (error) {
    // Handle or throw the error depending on your error handling strategy
    console.error("Error changing consultant:", error);
    throw error;
  }
}

export function getAllLandlordsDetails(accessToken: string | null) {
  return axios.get(`${GET_LANDLORDS_DETAILS}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    },
  )
}