import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PageTitle } from "../../../_metronic/layout/core";
import BgImage from "../../../_metronic/assets/media/svg/misc/taieri.svg";
import { APIS } from "Constants";
import {
  StatisticsWidget5,
  AssetsCarts,
  MixedWidget11,
  MixedWidget8,
  TablesWidget5,
  ChartsWidget1,
} from "../../../_metronic/partials/widgets";
import {
  getAllProperties,
  getConsultantInventory,
  getMyEvaluations,
  getMyProperties,
  requestEvaluation as requestEvaluationApi,
} from "../../components/property/redux/PropertyCrud";
import * as AuthRedux from "../../components/auth/redux/AuthRedux";
import { RootState } from "../../../setup";
import { Link, useHistory } from "react-router-dom";
import DashboardTable from "./DashboardTable";
import { toAbsoluteUrl } from "_metronic/helpers";
import { StatisticsWidget7 } from "_metronic/partials/widgets/statistics/StatisticsWidget7";
import { Button } from "react-bootstrap-v5";
import { StatisticsWidget9 } from "_metronic/partials/widgets/statistics/StatisticsWidget9";
import useFetchData from "app/hooks/useFetchData ";

interface InventoryItem {
  role: string;
  consultant: string;
  user: string;
  isListed: string;
  isVerified: string;
  landlordId: string;
  consultantId: string;
}

const DashboardPage = ({ properties, data, myEval, listed, unVerified, landlords }: any) => (
  <>
    {/* begin::Row */}
    {/* <div className="row g-2 g-xl-4 mb-5 mb-xl-4">
      <div className="col-xxl-8">
        <ChartsWidget1 className="" />
      </div>

      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-2 mb-md-5 mb-xl-10">
        <Link to="/AllAgents">
          <StatisticsWidget9
            className="h-md-40 mb-5 mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/communication/com006.svg"
            color="info"
            iconColor="white"
            title={data?.count}
            img={""}
            bgcolor=""
            description="Total Agents"
          />
        </Link>
        <Link to="/pendingUsers">
          <StatisticsWidget9
            className="h-md-40 mb-5 mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/graphs/gra007.svg"
            color="warning"
            iconColor="white"
            title=""
            img={""}
            bgcolor=""
            description="pending"
          />
        </Link>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-2 mb-md-5 mb-xl-10">
        <Link to="/approveUsers">
          <StatisticsWidget9
            className="h-md-40 mb-5 mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/communication/com005.svg"
            color="success"
            iconColor="white"
            title=""
            img={""}
            bgcolor=""
            description="approved"
          />
        </Link>
        <Link to="/rejectedUsers">
          <StatisticsWidget9
            className="h-md-40 mb-5 mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/general/gen008.svg"
            color=""
            iconColor="white"
            title=""
            img={""}
            bgcolor="#bf3939"
            description="Rejected"
          />
        </Link>
      </div>
    </div> */}

    <div className="row">
      <div className="col-xxl-4 pb-7">
        <MixedWidget8
          className="card-xl-stretch shadow"
          chartColor="success"
          chartHeight="150px"
        />
      </div>
      <div className="col-xxl-8">
        {/* <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8 shadow' /> */}
        <div className="row">
          <div className="col-6">
            <Link to="/myLandlords">
              <StatisticsWidget5
                className="card-xl-stretch mb-5 mb-xl-8 fw-bold text-white shadow"
                svgIcon="/media/icons/duotune/abstract/abs031.svg"
                color=""
                iconColor="white"
                title={landlords?.length ?? 0}
                description="Total Landlords"
                img={toAbsoluteUrl("/media/patterns/vector-1.png")}
                bgcolor="#263c67"
              />
            </Link>
          </div>

          <div className="col-6">
            <Link to="/consultantProperty">
              <StatisticsWidget5
                className="card-xl-stretch mb-5 mb-xl-8 text-white"
                svgIcon="/media/icons/duotune/communication/com005.svg"
                color=""
                iconColor="white"
                title={properties?.length ?? 0}
                description="Total Properties"
                img={toAbsoluteUrl("/media/patterns/vector-1.png")}
                bgcolor="#387034"
              />
            </Link>
          </div>

          <div className="col-6">
            <Link to="/listingProperty">
              <StatisticsWidget5
                className="card-xl-stretch mb-5 mb-xl-8 shadow fw-bold text-black"
                svgIcon="/media/icons/duotune/general/gen001.svg"
                color=""
                iconColor="black"
                title={listed?.length ?? 0}
                description="Listed Properties"
                img={toAbsoluteUrl("/media/patterns/vector-1.png")}
                bgcolor="#fff"
              />
            </Link>
          </div>

          <div className="col-6">
            <Link to="/unverifiedProperties">
              <StatisticsWidget5
                className="card-xl-stretch mb-5 mb-xl-8 text-white fw-bold shadow"
                svgIcon="/media/icons/duotune/graphs/gra007.svg"
                color=""
                iconColor="white"
                title={unVerified?.length ?? 0}
                description="Unverified Properties"
                img={toAbsoluteUrl("/media/patterns/vector-1.png")}
                bgcolor="#bf840d"
              />
            </Link>
          </div>

          <div className="col-12 px-3 rounded">
            <div
              className="card bgi-no-repeat bgi-size-contain px-5 py-3 mb-lg-10"
              style={{
                backgroundImage: `url('${BgImage}')`,
                backgroundSize: "",
                backgroundColor: "#1B283F",
                backgroundPosition: "right",
              }}
            >
              <div className="row">
                <div className="col-6 pt-2">
                  <h1 className="fs-1 fw-normal text-white">
                    Download our Mobile App
                  </h1>
                  <div className="px-5 pt-5">
                    <p className="text-white">
                      Visit{" "}
                      <a
                        className="text-white pl-2 fw-bolder"
                        href="wwww.imlaak.com"
                        target="_blank"
                      >
                        Imlaak.com
                      </a>
                    </p>
                    <Button className="text-white">Get Now</Button>
                  </div>
                </div>
                <div className="col-2 mt-6 text-white">
                  <StatisticsWidget7
                    className="card-xl-stretch mb-5 text-danger"
                    svgIcon="/media/svg/android.svg"
                    color=""
                    title=""
                    textColor="#f03c3c"
                    description="Get App for Android Devices"
                    bgcolor="#fff"
                  />
                </div>
                <div className="col-2 text-white mt-6">
                  <StatisticsWidget7
                    className="card-xl-stretch mb-5 text-danger"
                    svgIcon="/media/svg/ios.svg"
                    color=""
                    title=""
                    textColor="#0794eb"
                    description="Get App for IOS Devices"
                    bgcolor="#fff"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const DashboardWrapper: FC = () => {

  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  const listing: any = useSelector<RootState>(
    ({ auth }) => auth.listing,
    shallowEqual
  );

  const intl = useIntl();
  const [accessToken, setAccessToken]: any = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [count, setCount] = useState(0);
  const [myProperties, setMyProperties] = useState(listing);
  const [myEval, setMyEval] = useState([]);
  const getToken = async () => {
    let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
    accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
    setAccessToken(accessToken);
  };

  const { getAll } = APIS.users;
  const { data } = useFetchData({ getAll });
  const filteredData: InventoryItem[] = data.filter((row: InventoryItem) => row?.role === "landlord" && row?.consultant === user._id);
  const landlordIDs = filteredData.map((row: any) => row?._id);

  useEffect(() => {
    setLoading(true);
    getToken();
    setTimeout(() => {
      getConsultantInventory(accessToken)
        .then(response => {
          const filteredData = response.data?.properties?.filter((row: any) => row?.isDeleted === false);
          dispatch(AuthRedux.actions.setMyListings(filteredData));
          setMyProperties(filteredData);
          setCount(filteredData.length);
        })
        .catch(() => { })
        .finally(() => setLoading(false));
    }, 1000);
  }, []);

  useEffect(() => {
    setMyProperties(listing);
  }, [listing]);

  const listed: InventoryItem[] = myProperties?.filter((row: InventoryItem) => row?.isListed);
  const UnVerifiedProperties: InventoryItem[] = myProperties?.filter((row: InventoryItem) => !row?.isVerified);

  const requestEvaluation = (activeProperty: any) => {
    return requestEvaluationApi(accessToken, activeProperty);
  };
  const history = useHistory();
  // useEffect(() => {
  //   if (user?.role === "admin") {
  //     history.push("/admin/dashboard");
  //   } else if (user?.role === "landlord") {
  //     history.push("/landlord/dashboard");
  //   } else if (user?.role === "employee") {
  //     history.push("/employee/dashboard");
  //   } else {
  //     history.push("/dashboard");
  //   }
  // }, [user]);
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage
        unVerified={UnVerifiedProperties}
        listed={listed}
        landlords={filteredData}
        requestEvaluation={requestEvaluation}
        myEval={myEval}
        properties={myProperties}
      />

    </>
  );
};

export { DashboardWrapper };
