import { APIS } from "Constants";
import { useState } from "react";
import Field from "app/components/Inputs/asyncSelectWithPagination";
import useFetchData from "app/hooks/useFetchData ";

const ConsultantDropdown = ({
  name = "fname",
  label = "Please Select Consultant",
  onChangeOption,
  required,
  provinceId,
  ...rest
}: any) => {
  const filterBy = (items: any) => {
    let filteredItems = items.filter((item: any) => item?.role === "consultant" && item?.status === "approved");
    return filteredItems;
  };

  return (
    <>
      <Field
        style={{ textTransform: "capitalize" }}
        type="asyncOptionsWithPagination"
        name={name}
        label={label}
        onChange={onChangeOption}
        apiOptions={{
          url: APIS.users.getAll,
          keys: ["fname"],
          filter: {}, // remove filter property from here
          filterBy, // add filterBy function instead
        }}
        defaultTheme="black"
        {...rest}
      />
    </>
  );
};

export default ConsultantDropdown;
