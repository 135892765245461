/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { Verification } from "./components/Verification";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <Switch>
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div
            className="d-flex flex-column flex-lg-row-auto w-xl-700px  positon-xl-relative"
            style={{
              background:
                "linear-gradient(180deg, rgb(35 58 101 / 87%) 0%, rgb(35 58 101) 90%)",
            }}
          >
            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0  w-xl-700px ">
              <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
                <a href="#" className="mb-12">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-1-dark.png")}
                    className="h-45px"
                  />
                </a>
                <h1
                  className="fw-bolder fs-2qx pb-5 pb-md-10"
                  style={{ color: "#fff" }}
                >
                  Welcome to FSBO Portal
                </h1>
                <p className="fw-bold fs-2" style={{ color: "#fff" }}>
                  Pakistan's No-1 Real Estate
                  <br />
                  Consultancy Company
                </p>
              </div>
              <div
                className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-300px"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/illustrations/55.png"
                  )})`,
                }}
              ></div>
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row-fluid py-10">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              {/* begin::Content */}
              <div className="d-flex flex-center flex-column flex-column-fluid p-2  pb-lg-5 h-xl-650px">
                {/* begin::Wrapper */}
                <div className="w-lg-400px bg-white rounded shadow-sm  p-5 p-lg-10 mx-auto">
                  <Route path="/auth/login" component={Login} />
                  <Route path="/auth/registration" component={Registration} />
                  <Route
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                  />
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </div>
                {/* end::Wrapper */}
              </div>
            </div>

          </div>
        </div>

        {/* end::Content */}
        {/* begin::Footer */}

        {/* end::Footer */}
      </div>
      {/* <Route path="/auth/forgot-password" component={ForgotPassword} /> */}
    </Switch>
  );
}
