import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Details } from "./components/Details";

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const ManagerPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/managers/">
          <PageTitle>Imlaak Managers</PageTitle>
          <Details />
        </Route>

        <Redirect from="/managers" exact={true} to="/application/" />
        <Redirect to="/managers/" />
      </Switch>
    </>
  );
};


export default ManagerPage;
