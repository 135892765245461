/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useDispatch } from "react-redux";
import * as auth from "../../../../app/components/auth/redux/AuthRedux";

export function AsideMenuDemo() {
  const intl = useIntl();
  const dispatch = useDispatch();
  // const logout = () => {
  //   dispatch(auth.actions.logout());
  // };

  return (
    <>
      {/* remove this admin line */}
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/layouts/lay009.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/profile-details"
        icon="/media/icons/duotune/communication/com013.svg"
        title="My Account"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItemWithSub
        to=""
        title="Property"
        icon="/media/icons/duotune/maps/map008.svg"
        fontIcon="bi-person"
      >
        <AsideMenuItem
          to="/propertyDemo"
          icon="/media/icons/duotune/general/gen001.svg"
          title="Property"
          fontIcon="bi-app-indicator"
        />
        <AsideMenuItem
          to="/listingDemo"
          icon="/media/icons/duotune/art/art002.svg"
          title="Listings"
          fontIcon="bi-app-indicator"
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to="/LandlordConsultant"
        icon="/media/icons/duotune/abstract/abs027.svg"
        title="Update Consultant"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/reports"
        icon="/media/icons/duotune/ecommerce/ecm010.svg"
        title="Reports"
        fontIcon="bi-app-indicator"
      />
      {/* <AsideMenuItem
        to='/application'
        icon='/media/icons/duotune/files/fil012.svg'
        title='Applications'
        fontIcon='bi-app-indicator'
      /> */}

      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Dashboard Setting'
        fontIcon='bi-layers'
      />  */}

      {/* <AsideMenuItem
        to='/apps/chat'
        title='More'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen023.svg'
      > */}
      {/* <AsideMenuItem
          to='/apps/chat'
          title='Help Center'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/general/gen046.svg'
        >

      <AsideMenuItem
        to='/apps/chat'
        title='Sign Out'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/arrows/arr077.svg'
      >
         {/* <a onClick={logout} className='menu-link px-5'>
        <img className='mx-3' src='/media/icons/duotune/arrows/arr077.svg'/>
          Sign Out
        </a> */}
      {/* </AsideMenuItem> */}
      {/* 
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div> */}
    </>
  );
}
