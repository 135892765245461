import React, { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { Formik, Form, FormikValues } from "formik";
import {
  IUpdateAccount,
  createAccountSchemas,
  inits,
} from "./CreateAccountWizardHelper";
import * as AuthRedux from "../../auth/redux/AuthRedux";
import * as profile from "../redux/ProfileRedux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getMyProperties,
  updateProfile,
} from "../redux/ProfileCrud";
import { RootState } from "../../../../setup";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import { Step4 } from "./steps/Step4";
import { Step5 } from "./steps/Step5";

const ProfileUpdate: FC = () => {
  const auth: any = useSelector<RootState>(({ auth }) => auth, shallowEqual);
  const dispatch = useDispatch();

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  // const [fieldChanger, setFieldChanger]: any = useState(() => { })
  let fieldChanger: any = () => { };
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0]);
  const [initValues] = useState<IUpdateAccount>(inits(auth));
  const [loading, setLoading] = useState(false);
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };
  const modal: any = (title: string, text: string, icon: any) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: false,
      confirmButtonColor: "#157d0b",
      cancelButtonColor: "#d33",
      confirmButtonText: "Okay",
    });
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();

    setCurrentSchema(
      createAccountSchemas[stepper.current.currentStepIndex - 1]
    );
  };
  const userID = auth?.user?._id;
  const accessToken = localStorage.getItem('accessToken');
  const submitStep = (values: IUpdateAccount, actions: FormikValues) => {

    if (!stepper.current) {
      return;
    }
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex]);
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
    } else {
      setLoading(true);
      setTimeout(() => {
        var title, text;
        updateProfile(values, accessToken, userID)
          .then(response => {
            if (response.status === 200 && response.data.success) {
              dispatch(auth.actions.setUser(response?.data?.user));
              title = "Profile Approval Request Sent.";
              text = "Your profile has been completed and sent to the selected consultant for approval.";
              modal(title, text, "success");
              setLoading(false);
            } else {
              dispatch(auth.actions.setUser(response?.data?.user));
              title = "Process Failed.";
              text = "Profile Updating Process Failed.";
              modal(title, text, "error");
              setLoading(false);
            }
          })
          .catch(error => {
            title = "Profile Approval Request Sent.";
            text = "Your profile has been completed and sent to the selected consultant for approval.";
            modal(title, text, "success");
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 1000);
    }
  };

  useEffect(() => {
    getMyProperties(accessToken)
      .then(({ data }) => {
        dispatch(AuthRedux.actions.setMyListings(data.property));
        fieldChanger("listing", data.listing);
      })
      .catch(() => { });
  }, []);

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef]);

  return (
    <div
      ref={stepperRef}
      className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
      id="kt_create_account_stepper"
    >
      <div className="d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9">
        <div className="px-6 px-lg-10 px-xxl-15 py-20">
          <div className="consultant-stepper-nav">
            <div className="stepper-item current" data-kt-stepper-element="nav">
              <div className="stepper-line w-40px"></div>

              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">1</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title">Who you are?</h3>
                <div className="stepper-desc fw-bold">
                  Let us know who you are?
                </div>
              </div>
            </div>


            <div className="stepper-item" data-kt-stepper-element="nav">
              <div className="stepper-line w-40px"></div>

              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">2</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">Address</h3>
                <div className="stepper-desc fw-bold">
                  Add Your address
                </div>
              </div>
            </div>

            <div className="stepper-item" data-kt-stepper-element="nav">
              <div className="stepper-line w-40px"></div>

              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">3</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title">Consultant</h3>
                <div className="stepper-desc fw-bold">
                  Please select your consultant.
                </div>
              </div>
            </div>

            <div className="stepper-item" data-kt-stepper-element="nav">
              <div className="stepper-line w-40px"></div>

              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">4</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title">Business </h3>

                <div className="stepper-desc fw-bold">
                  Let us know your business{" "}
                </div>
              </div>
            </div>

            <div className="stepper-item" data-kt-stepper-element="nav">
              <div className="stepper-line w-40px"></div>

              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">5</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">Completed</h3>
                <div className="stepper-desc fw-bold">Woah, we are here</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row-fluid flex-center bg-white rounded">
        <Formik
          validationSchema={currentSchema}
          initialValues={initValues}
          onSubmit={submitStep}
        >
          {({ setFieldValue, values }) => {
            console.log(values, 'values check karni')
            fieldChanger = setFieldValue;
            // setFieldChanger(setFieldValue)
            return (
              <Form
                className="py-5 w-100 w-xl-700px px-9"
                noValidate
                id="kt_create_account_form"
              >
                <div className="current" data-kt-stepper-element="content">
                  <Step2 />
                </div>

                <div data-kt-stepper-element="content">
                  <Step4 />
                </div>

                <div data-kt-stepper-element="content">
                  <Step3 />
                </div>

                <div data-kt-stepper-element="content">
                  <Step1 />
                </div>


                <div data-kt-stepper-element="content">
                  <Step5 />
                </div>
                <div className="d-flex flex-stack pt-10">
                  <div className="mr-2">
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr063.svg"
                        className="svg-icon-4 me-1"
                      />
                      Back
                    </button>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary me-3"
                    >
                      {loading ? (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <CircularProgress size={20} color="success" />
                        </span>
                      ) : (
                        <span className="indicator-label">
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! - 1
                            ? "Continue"
                            : "Submit"}
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr064.svg"
                            className="svg-icon-3 ms-2 me-0"
                          />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export { ProfileUpdate };
