import { useState, useEffect, ChangeEvent, useRef } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Modal from "app/components/Modal";
import Loader from "app/components/Loader";
import CustomInput from "app/components/Inputs";
import "./newStyle.css";
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
// import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";

let autoComplete: any;

const loadScript = (url: any, callback: any) => {
  let script = document.createElement("script") as any;
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const validationSchema = yup.object({
  projectName: yup.string().required("Project name is required"),
});
interface Coordinates {
  latitude: number;
  longitude: number;
}
type EditProps = {
  open: any;
  setOpen: any;
  data?: any;
  update?: any;
  loading?: boolean;
  type: "ADD" | "EDIT";
};

const Edit = ({
  open,
  setOpen,
  data,
  update,
  loading,
  type = "EDIT",
}: EditProps) => {
  const formik = useFormik({
    initialValues: {
      projectName: data?.projectName || "",
      address: data?.address || "",
      latitude: data?.latitude || "",
      longitude: data?.longitude || "",
      city: data?.city || "",
      state: data?.state || "",
      country: data?.country || "",
      placeId: data?.placeId || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      update(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (Object.keys(data).length > 0) formik.setValues({ ...data });
  }, [data, open]);
  const [address, setAddress] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };


  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  const handleScriptLoad = (updateQuery: any, autoCompleteRef: any) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        // types: ["(cities)"],
      }
    );

    autoComplete.addListener("place_changed", async () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const getAddressComponent = (placeResult: any, componentType: any) => {
    const result = placeResult?.address_components.find((component: any) =>
      component.types.includes(componentType)
    );

    return result ? result.long_name : null;
  };

  const handlePlaceSelect = (updateQuery: any) => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject?.formatted_address;
    updateQuery(query);
    const placeId = addressObject?.place_id;
    const city = getAddressComponent(addressObject, "locality");
    const state = getAddressComponent(
      addressObject,
      "administrative_area_level_1"
    );
    const country = getAddressComponent(addressObject, "country");
    const lat = addressObject?.geometry?.location?.lat();
    const lng = addressObject?.geometry?.location?.lng();

    formik.setValues({
      ...formik.values,
      latitude: lat,
      longitude: lng,
      city: city,
      state: state,
      country: country,
      placeId: placeId,
    });
  };
  console.log(formik.values, "formik values");
  useEffect(() => {
    const REACT_APP_GOOGLE_MAPS_KEY = process?.env?.REACT_APP_GOOGLE_MAPS_KEY;
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  });
  //check

  const handleGetCoordinates = async () => {
    try {
      // Replace 'YOUR_API_KEY' with your actual Google Maps API key
      const apiKey = process?.env?.REACT_APP_GOOGLE_MAPS_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${apiKey}`
      );

      if (response.ok) {
        const data = await response.json();
        if (data.results.length > 0) {
          const location = data.results[0].geometry.location;
          const addressComponents = data.results[0].address_components;
          const placeId = data.results[0].place_id;
          // Extract city and country names from address components
          let city = "";
          let state = "";
          let country = "";
          addressComponents.forEach((component: any) => {
            if (component.types.includes("locality")) {
              city = component.long_name;
            } else if (
              component.types.includes("administrative_area_level_1")
            ) {
              state = component.long_name;
            } else if (component.types.includes("country")) {
              country = component.long_name;
            }
          });

          formik.setValues({
            ...formik.values,
            latitude: location.lat,
            longitude: location.lng,
            city: city,
            state: state,
            country: country,
            placeId: placeId,
          });

          setCoordinates({
            latitude: location.lat,
            longitude: location.lng,
          });
          setCity(city);
          setState(state);
          setCountry(country);
          setLatitude(placeId);
        } else {
          console.error("No results found for the provided address.");
        }
      } else {
        console.error("Error fetching data from the geocoding API.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <Modal
      setOpen={() => setOpen(!open)}
      open={open}
      title={` ${Object.keys(data).length
        ? "Edit Project Name # " + data?.projectName
        : "Add New Project Name"
        } `}
      cancelButtonTitle='Cancel'
      acceptButtonVarient='contained'
      acceptButtonTitle='Add'
      handleAccept={() => formik.handleSubmit()}
      handleClose={() => formik.resetForm()}
      loading={loading}
      isActionable={true}
    >
      {loading ? (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "30%",
          }}
        >
          <Loader top='"60%' left='"50%' />
        </Box>
      ) : null}
      <Box rowGap='10px' display='flex' flexDirection='column' mt='10px'>
        <Typography variant='h5' fontWeight={700}>
          Project Name That you are currently operating
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <CustomInput
              id='projectName'
              label='Project Name'
              placeholder='Type here...'
              value={formik.values.projectName}
              onChangeHandler={formik.handleChange}
              isError={
                formik.touched?.projectName &&
                Boolean(formik.errors.projectName)
              }
              helperText={
                formik.touched.projectName ? formik.errors.projectName : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div
              className='search-location-input'
              style={{ position: "relative" }}
            >
              <label className="fw-bolder" style={{ fontSize: "1.1rem" }}>Type address</label>
              <input
                ref={autoCompleteRef}
                className='form-control py-4 specialInput'
                onChange={(event) => setQuery(event.target.value)}
                placeholder='Search Places ...'
                value={query}
              />
            </div>
            {formik.values && (
              <>
                <div className='pt-3'>
                  <p className='fw-bolder'>
                    Latitude: &nbsp;&nbsp;&nbsp; {formik.values.latitude}
                  </p>
                  <p className='fw-bolder'>
                    Longitude: &nbsp;&nbsp;&nbsp; {formik.values.longitude}
                  </p>
                  <p className='fw-bolder'>
                    City: &nbsp;&nbsp;&nbsp; {formik.values.city}
                  </p>
                  <p className='fw-bolder'>
                    State: &nbsp;&nbsp;&nbsp; {formik.values.state}
                  </p>
                  <p className='fw-bolder'>
                    Country: &nbsp;&nbsp;&nbsp; {formik.values.country}
                  </p>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Edit