import React, { FC } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { ErrorMessage, Field, FieldArray, useField } from "formik";
import { useState } from "react";
import Select from "react-select";
import Consultant from "./Consultant";
import ConsultantDropdown from "app/ServerSideDropdowns/ConsultantDropdown";
import DropDown, {
  ConsultantTypeSelecting,
} from "app/components/DropDownOptions/DropDownOptions";
import { useFormik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "setup";
import { Alert } from "@mui/material";

const Step3: FC = (props: any) => {

  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );
  let statusMessage: string;
  switch (user?.approvalStatus) {
    case 'pending':
      statusMessage = "Consultant has requested for approval.";
      // statusMessage = "<span className='alert alert-warning'>Consultant has requested for approval.</span>";
      break;
    case 'requested':
      statusMessage = "Your request for consultancy is still pending.";
      break;
    default:
      statusMessage = "Approved";
  }
  // const [accountTypeField] = useField("accountType");
  const [consultantField, consultantMeta, consultantHelpers] = useField("consultant");
  console.log("🚀 ~ consultantField:", consultantField)
  // const [toggleState, setToggleState] = useState(1);
  // const toggleTab = (index: any) => {
  //   setToggleState(index);
  // };

  // const [selectedOption, setSelectedOption] = useState("Select Consultant by Yourself");

  return (
    <div className="w-100">
      <div className="mt-5 fv-row mb-5">
        <div className="fv-row mb-5">
          <div className="row">
            <div className="col-lg-12 mt-2">
              <div className="m-2">
                {user?.consultant !== null || '' ? (
                  <Alert severity="warning" style={{ fontWeight: '700', fontSize: '14px', width: '95%' }}>
                    {statusMessage}
                  </Alert>
                ) : (
                  <Field name="consultant cursor-pointer">
                    {({ field, form }: any) => (
                      <div>
                        <ConsultantDropdown
                          onChangeOption={(option: any) => {
                            consultantHelpers.setValue(option.value);
                          }}
                          value={consultantField.value.consultant}
                        />
                        {consultantMeta.touched && consultantMeta.error && (
                          <div className="text-danger mt-2">
                            {consultantMeta.error}
                          </div>
                        )}
                        {consultantField.value ? (
                          <div className="col-lg-6 mt-2">
                            <Consultant id={consultantField.value} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </Field>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Step3 };
