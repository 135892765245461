import { APIS } from "Constants";
import Field from "app/components/Inputs/asyncSelectWithPagination";

export const ProjectNamePropertyDropdown = ({
    name = "projectName",
    label = "Select Project Name",
    onChangeOption,
    value,
    ...rest
}: any) => {
    const handleProjectNameChange = (selectedOption: any) => {
        if (onChangeOption) {
            onChangeOption(selectedOption);
        }
    };

    return (
        <Field
            className="cursor-pointer"
            type="asyncOptionsWithPagination"
            name={name}
            label={label}
            value={value}
            onChange={handleProjectNameChange}
            apiOptions={{
                url: APIS.ProjectName.getAllProjectNamesByProperty,
                keys: ["projectName"],
            }}
            defaultTheme="black"
            {...rest}
        />
    );
};
