/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import incomeExpenseStatement from './reportsForm/incomeExpenseStatement'
import { Button } from 'react-bootstrap-v5'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
// import { Link, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';


type Props = {
  className: string
}

const FeedsWidget2: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body pb-0'>
        {/* begin::Header */}
        <div className='d-flex align-items-center mb-5'>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}
            <div className='symbol symbol-45px me-5'>
              <img src={toAbsoluteUrl('/media/icons/custom/report-financials.png')} alt='' />
            </div>
            {/* end::Avatar */}

            {/* begin::Info */}
            <div className='d-flex flex-column'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
              Income Expense Statement
              </a>
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}

          {/* begin::Menu */}
          <div className='my-0'>
          </div>
          {/* end::Menu */}
        </div>
        {/* end::Header */}

        {/* begin::Post */}
        <div className='mb-5'>
          {/* begin::Text */}
          <p className='text-gray-800 fw-normal mb-5'>
            This is a profit and loss report that shows you all the income and expenses that have been assigned to a property.
          </p>
          {/* end::Text */}
          
        </div>
        {/* end::Post */}

        {/* begin::Separator */}
        <div className='separator mb-4'></div>
        {/* end::Separator */}

        {/* begin::Button */}
        <form className='position-relative mb-6'>
          
            <span className='form-control border-0 p-0 pe-10 resize-none min-h-35px'></span>
          <div className='position-absolute top-0 end-0 me-n5 mb-10'>
            <span className=''>
              <button
                type='button'
                className='btn btn-secondary mb-0 me-10'
              >
                <NavLink to ='/incomeExpenseStatement'>View</NavLink>
                {/* window.location.replace("https://www.tutorialrepublic.com/"); */}

                
              </button> 
            </span>
          </div>
        </form>
        {/* edit::Button */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {FeedsWidget2}
