import { useState, useEffect, ChangeEvent, useRef } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Modal from "app/components/Modal";
import Loader from "app/components/Loader";
import CustomInput from "app/components/Inputs";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

const validationSchema = yup.object({
  projectName: yup.string().required("Project name is required"),
});
interface Coordinates {
  latitude: number;
  longitude: number;
}
type EditProps = {
  open: any;
  setOpen: any;
  data?: any;
  update?: any;
  loading?: boolean;
  type: "ADD" | "EDIT";
};

const Edit = ({
  open,
  setOpen,
  data,
  update,
  loading,
  type = "EDIT",
}: EditProps) => {
  const formik = useFormik({
    initialValues: {
      projectName: data?.projectName || "",
      latitude: data?.latitude || "",
      longitude: data?.longitude || "",
      address: data?.address || "",
      street: data?.street || "",
      sector: data?.sector || "",
      area: data?.area || "",
      city: data?.city || "",
      state: data?.state || "",
      country: data?.country || "",
      placeId: data?.placeId || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      update(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (Object.keys(data).length > 0) formik.setValues({ ...data });
  }, [data, open]);
  const [address, setAddress] = useState<string>('');
  const [areaName, setAreaName] = useState<string>('');
  const [sector, setSector] = useState<string>('');
  const [district, setDistrict] = useState<string>('');
  const [streetName, setStreetName] = useState<string>('');
  const [latitude, setLatitude] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [formattedAddress, setFormattedAddress] = useState<string>('');
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
    handleGetCoordinates();
  };

  const handleGetCoordinates = async () => {
    try {
      // Replace 'YOUR_API_KEY' with your actual Google Maps API key
      const apiKey = process?.env?.REACT_APP_GOOGLE_MAPS_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${apiKey}`
      );

      if (response.ok) {
        const data = await response.json();
        if (data.results.length > 0) {
          const location = data.results[0].geometry.location;
          const addressComponents = data.results[0].address_components;
          const placeId = data.results[0].place_id;
          const formattedAddressValue = data.results[0].formatted_address;
          console.log("🚀 ~ handleGetCoordinates ~ data.results:formatted_address", data.results[0])
          // Extract city and country names from address components
          let streetName = '';
          let areaName = '';
          let sectorColonyArea = '';
          let district = '';
          let city = '';
          let state = '';
          let country = '';

          // Iterate through address components to extract details
          addressComponents.forEach((component: any) => {
            if (component.types.includes('route')) {
              streetName = component.long_name;
            } else if (component.types.includes('sublocality_level_1') || component.types.includes('sublocality') || component.types.includes('neighborhood')) {
              if (!sectorColonyArea) { // Only set if sectorColonyArea is not already set
                sectorColonyArea = component.long_name;
              }
            } else if (component.types.includes('political') && component.types.includes('sublocality') && component.types.includes('sublocality_level_1')) {
              areaName = component.long_name;
            } else if (component.types.includes('administrative_area_level_2')) {
              district = component.long_name;
            } else if (component.types.includes('locality')) {
              city = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name;
            } else if (component.types.includes('country')) {
              country = component.long_name;
            }
          });

          formik.setValues({
            ...formik.values,
            latitude: location.lat,
            longitude: location.lng,
            address: formattedAddressValue,
            street: streetName,
            sector: sectorColonyArea,
            area: areaName,
            city: city,
            state: state,
            country: country,
            placeId: placeId,
          });

          setCoordinates({
            latitude: location.lat,
            longitude: location.lng,
          });
          setCity(city);
          setState(state);
          setCountry(country);
          setLatitude(placeId);
          setSector(sectorColonyArea);
          setDistrict(district);
          setStreetName(streetName)
          setAreaName(areaName)
          setFormattedAddress(formattedAddressValue)
        } else {
          console.error('No results found for the provided address.');
        }
      } else {
        console.error('Error fetching data from the geocoding API.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <Modal
      setOpen={() => setOpen(!open)}
      open={open}
      title={` ${Object.keys(data).length
        ? "Edit Project Name # " + data?.projectName
        : "Add New Project Name"
        } `}
      cancelButtonTitle="Cancel"
      acceptButtonVarient="contained"
      acceptButtonTitle="Add"
      handleAccept={() => formik.handleSubmit()}
      handleClose={() => formik.resetForm()}
      loading={loading}
      isActionable={true}
    >
      {loading ? (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "30%",
          }}
        >
          <Loader top='"60%' left='"50%' />
        </Box>
      ) : null}
      <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
        <Typography variant="h5" fontWeight={700}>
          Project Name That you are currently operating
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <CustomInput
              id="projectName"
              label="Project Name"
              placeholder="Type here..."
              value={formik.values.projectName}
              onChangeHandler={formik.handleChange}
              isError={
                formik.touched?.projectName &&
                Boolean(formik.errors.projectName)
              }
              helperText={
                formik.touched.projectName ? formik.errors.projectName : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>

            {/* <div>
               <GooglePlacesAutocomplete apiKey="AIzaSyDerCbqfe0G16gZvfXwNhUT6xnuVl8qtQU" /> 
            </div> */}

            <CustomInput
              id="address"
              label="Enter Building name with city"
              placeholder="Type here..."
              value={address}
              onChangeHandler={handleAddressChange}
            />
            {/* <input type="text" value={address} onChange={handleAddressChange} /> */}
            <button className="btn btn-sm btn-primary" onClick={handleGetCoordinates}>Get Coordinates</button>

            {coordinates && (
              <>
                <div className="pt-3">
                  <p className="fw-bolder">Address: &nbsp;&nbsp;&nbsp; {formattedAddress}</p>
                  <p className="fw-bolder">Latitude: &nbsp;&nbsp;&nbsp; {coordinates.latitude}</p>
                  <p className="fw-bolder">Longitude: &nbsp;&nbsp;&nbsp; {coordinates.longitude}</p>
                  <p className="fw-bolder">street Name: &nbsp;&nbsp;&nbsp; {streetName}</p>
                  <p className="fw-bolder">Sector: &nbsp;&nbsp;&nbsp; {sector}</p>
                  <p className="fw-bolder">Area: &nbsp;&nbsp;&nbsp; {areaName}</p>
                  <p className="fw-bolder">District: &nbsp;&nbsp;&nbsp; {district}</p>
                  <p className="fw-bolder">City: &nbsp;&nbsp;&nbsp; {city}</p>
                  <p className="fw-bolder">State: &nbsp;&nbsp;&nbsp; {state}</p>
                  <p className="fw-bolder">Country: &nbsp;&nbsp;&nbsp; {country}</p>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Edit;
