import { useContext, useMemo } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { GeneralContext } from "app/Context/General";
import { FormControl, Typography, useFormControl } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { EyeIcon } from "@heroicons/react/solid";

interface AsyncSelectFieldType {
  loadOptions: any;
  label: string;
  errorMessage: string;
  value: any;
  name: string;
  cacheUniqs: any;
  onChange: (data: any) => {};
  isMulti: boolean;
  reduceOptions?: any;
  additional: any;
  inputValue?: any;

  apiOptions: {
    url: string;
    keys: string;
    filterBy?: () => {};
  };
  defaultTheme: String;
  showLabel: boolean;
  isError: boolean;
  defaultOptions: any;
}
const useStyles = makeStyles(() => ({
  headingStyles: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
    // margin: '0px 0px 10px 0px !important',
  },
}));

const AsyncSelectField = ({
  label,
  errorMessage,
  value,
  onChange,
  name = "",
  cacheUniqs,
  isMulti = false,
  // reduceOptions,
  additional,
  inputValue,
  apiOptions,
  defaultTheme = "white",
  isError,
}: AsyncSelectFieldType) => {
  const { getOptions, setSelectedItem }: any = useContext(GeneralContext);
  const customStyles = {
    option: (styles: any, { isFocused, isSelected }: any) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        maxHeight: 200,
        overflowY: "auto",
        backgroundColor: isFocused
          ? " #233a65"
          : isSelected
            ? " #233a65"
            : "white",
        color: isFocused ? "white" : isSelected ? "white" : "black",
        textTransform: "capitalize !important",
        cursor: "pointer",
      };
    },
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  };
  function HeadingText() {
    const classes = useStyles();
    const { focused } = useFormControl() || {};

    const headingColor = useMemo(() => {
      if (isError) {
        return " #233a65";
      }

      if (focused && !isError) {
        return " #233a65";
      }

      return "inherit";
    }, [focused]);

    return (
      <Typography
        // fontSize={18}
        className={classes.headingStyles}
        color={headingColor}
      >
        {label}
      </Typography>
    );
  }

  const onChangeOption = (data: any) => {
    setSelectedItem(data);
    onChange(data);
  };
  const filtering = apiOptions?.filterBy
    ? apiOptions?.filterBy
    : (items: any) => items;
  return (
    <div
      className={`form-group ${defaultTheme === "black" ? "black-theme" : ""}`}
    >
      <FormControl
        variant="standard"
        fullWidth
        sx={{
          "& > div > div": { minHeight: "51.69px", textTransform: "capitalize !important", cursor: "pointer" },
        }}
      >
        <HeadingText />
        <AsyncPaginate
          menuPortalTarget={document.body}
          name={name}
          value={value}
          placeholder={label}
          loadOptions={() =>
            getOptions(apiOptions.url, apiOptions.keys, filtering)
          }
          // reduceOptions={reduceOptions}
          cacheUniqs={cacheUniqs}
          isMulti={isMulti}
          onChange={onChangeOption}
          className={"select-wrapper"}
          additional={additional}
          inputValue={inputValue}
          styles={defaultTheme === "black" ? customStyles : {}}
        // defaultOptions
        //   onInputChange={this.handleInputChange}
        />
        {errorMessage && <p>{errorMessage}</p>}
      </FormControl>
    </div>
  );
};
export default AsyncSelectField;
