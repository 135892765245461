import * as Yup from 'yup'

interface IProperty {
  Urdu: boolean;
  English: boolean;
  Punjabi: boolean;
  Sindhi: boolean;
  Pashto: boolean;
  Kashmiri: boolean;
  Balochi: boolean;
  Saraiki: boolean;
  Hindko: boolean;
  Brahui: boolean;
  Shina: boolean;
  Wakhi: boolean;
  ResidentialSales: boolean;
  CommercialSales: boolean;
}


export interface IUpdateAccount {
  accountType?: string
  businessName?: string
  businessEmail?: string
  businessAddress?: string
  fname: string
  lname?: string
  email: string
  address?: string
  country?: string
  province?: string
  city?: string
  description: string
  accountPlan?: string
  // businessDescription: string
  // listing: any
  contact?: string
  consultant: string
  languages?: IProperty[];
  specialties?: IProperty[];
  userImage?: any;
  userAvatar?: [];


}

const createAccountSchemas = [
  Yup.object({
    fname: Yup.string().required().label('Name'),
    contact: Yup.string().required().label('Phone is required'),
    description: Yup.string().required().label('Description'),
    address: Yup.string().required().label('Address is required.'),
  }),
]


const inits = (auth: any) => {
  return {
    accountType: auth.user?.accountType || '',
    businessName: auth.user?.businessName || '',
    businessEmail: auth.user?.businessEmail || '',
    businessAddress: auth.user?.businessAddress || '',
    fname: auth.user?.fname || '',
    address: auth.user?.address || '',
    country: auth.user?.country || '',
    province: auth.user?.province || '',
    city: auth.user?.city || '',
    accountPlan: auth.user?.accountPlan || '',
    email: auth.user?.email || '',
    description: auth.user?.description || '',
    contact: auth.user?.contact || '',
    name: auth.user?.name || '',
    userImage: auth.user?.userImage || '',

    consultant: auth.user?.consultant || '',
    languages: auth.user?.languages || [{
      Urdu: false, English: false, Punjabi: false, Sindhi: false, Pashto: false, Kahmiri: false,
      Balochi: false, Saraiki: false, Hindko: false, Shina: false, Wakhi: false
    }],
    specialties: auth.user?.specialties || [{ ResidentialSales: false, CommercialSales: false }],

  }
}

export { createAccountSchemas, inits }
