import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
    PageLink,
    PageTitle,
} from "../../../_metronic/layout/core";
import Table, { capitalizeFirstLetter, IconRender } from "../Table";
import { deleteProperty, getLandlordProperties } from "./redux/PropertyCrud";
import * as AuthRedux from "../auth/redux/AuthRedux";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { updateProperty, createProperty } from "./redux/PropertyCrud";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "_metronic/helpers/AssetHelpers";
import EditLandlordProperty from "./components/EditLandlordProperty";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import { EnergySavingsLeafRounded } from "@mui/icons-material";
import { EyeIcon } from "@heroicons/react/solid";

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
    },
];
const columns = (iconClickHandler: any) => [
    {
        name: "Title",
        key: "title",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    },
    {
        name: "Sub Category",
        key: "subCategory",
        numaric: false,
        isSortable: true,
    },
    {
        name: "Sale Price",
        key: "price",
        numaric: true,
        isSortable: true,
        renderContent: (value: any) =>
            new Intl.NumberFormat().format(parseInt(value || 0)),
    },
    {
        name: "Purchase Price",
        key: "purchasePrice",
        numaric: true,
        isSortable: true,
        renderContent: (value: any) =>
            new Intl.NumberFormat().format(parseInt(value || 0)),
    },

    {
        name: "city",
        key: "cityName",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) =>
            capitalizeFirstLetter(value),
    }, {
        name: "Listed",
        key: "isListed",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => (value),
    }, {
        name: "View",
        key: "display",
        renderContent: (row: any) => (
            <>
                <div><EyeIcon style={{ width: "20px" }} /></div>
            </>
        ),
    }, {
        name: "Action",
        key: "action",
        component: (row: any) => (
            <>
                {/* {IconRender(InfoOutlinedIcon, iconClickHandler)} */}
                {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "edit")}
                {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, "delete")}
            </>
        ),
    },
];
const rowsData = [
    { title: "2 Bedroom Hotel Apartment - Gulberg Sixty6", category: 'Residential', subCategory: 'Hotel Apartment', price: "78570658", purchasePrice: "65055000", cityName: "Lahore", isListed: "Not Listed", display: '', action: '', },
    { title: "3 Bedroom Apartment - Downtown", category: 'Residential', subCategory: 'Apartment', price: "95470658", purchasePrice: "65655000", cityName: "Multan", isListed: "Listed", display: '', action: '', },
];
const DemoListingPage: React.FC = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState({});

    const [currentListing, setCurrentListing]: any = useState(0);
    const listing: any = useSelector<RootState>(
        ({ auth }) => auth.listing,
        shallowEqual
    );
    // Project LookUp
    const { getAllNames } = APIS.ProjectName;
    const { projectNameData } = useFetchData({ getAllNames });
    const [projectLookup, setProjectLookup] = useState<any>({});

    useEffect(() => {
        // Create a lookup object for projectName id's to project Names
        const lookup: any = {};
        projectNameData.forEach((row: any) => {
            lookup[row?._id] = row?.projectName;
        });
        setProjectLookup(lookup);
    }, [projectNameData]);
    const [myProperties, setMyProperties] = useState(listing);
    const [accessToken, setAccessToken]: any = useState("");
    const [modalMode, setModalMode]: any = useState("edit");

    const getToken = async () => {
        let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
        accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
        setAccessToken(accessToken);
    };

    const getProperties = (filters: any) => {
        getLandlordProperties(accessToken, filters)
            .then(({ data }) => {
                dispatch(AuthRedux.actions.setMyListings(data.property));
                setCount(data.count);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        getToken();
        setTimeout(() => {
            getProperties(filter);
        }, 1000);
    }, []);

    useEffect(() => {
        setMyProperties(listing);
    }, [listing]);

    const filtersChanged = (filters: any) => {
        setFilter(filters);
        setLoading(true);
        setTimeout(() => {
            getProperties(filters);
        }, 1000);
    };

    const onClickCreate = () => {
        setOpen(true);
        setCurrentListing([]);
        setModalMode("create");
    };

    useEffect(() => {
        document
            .getElementById("create-button")
            ?.addEventListener("click", onClickCreate);
    }, []);

    const iconClickHandler = (row: any, type: any) => {
        if (type === "edit") {
            setModalMode("edit");
            setCurrentListing(row);
            setOpen(true);
        } else if (type === "delete") {
            Swal.fire({
                title: "",
                text: "Are you sure you want to delete this Property",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes",
                cancelButtonColor: "#d33",
            }).then((status) => {
                if (status.isConfirmed) {
                    setLoading(true);
                    deleteProperty(row._id, accessToken)
                        .then(({ data, status }) => {
                            let text = "";
                            let icon: any = "error";
                            if (status === 200) {
                                text = "Successfully Deleted";
                                icon = "success";
                            } else {
                                text = data.message;
                                icon = "error";
                            }
                            Swal.fire({
                                title: "Property Deleted",
                                text: text,
                                icon: icon,
                                showCancelButton: false,
                                confirmButtonColor: "#3085d6",
                                // cancelButtonColor: "#d33",
                                confirmButtonText: "Ok",
                            });
                            dispatch(AuthRedux.actions.setMyListings(data.property));
                            setCount(data.count);
                        })
                        .catch(() => { })
                        .finally(() => setLoading(false));
                }
            });
        }
    };

    const createPropertyFunction = (values: any) => {
        setLoading(true);
        setOpen(false);
        Swal.fire({
            title: "Property Listing?",
            text: "Do you want to List this Property for sale?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No Don't List",
            confirmButtonText: "Yes List this",
        })
            .then((value: any) => {
                if (value.isConfirmed) {
                    console.log(values);
                    values = {
                        ...values,
                        isListed: true,
                        // cityName: values.city,
                        // propertyLocation: values.location,
                    };
                    console.log(values);
                }
                console.log(values);
                createProperty(values, accessToken)
                    .then(({ data }) => {
                        dispatch(AuthRedux.actions.setMyListings(data.property));
                        setCount(data.count);
                    })
                    .catch(() => { })
                    .finally(() => setLoading(false));
            })
            .finally(() => { });
    };

    const updatePropertyFunction = (values: any) => {
        setLoading(true);
        updateProperty(values, currentListing?._id, accessToken)
            .then(({ data }) => {
                dispatch(AuthRedux.actions.setMyListings(data.property));
                setCount(data.count);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    const dataCount = 2;
    return (
        <>
            <PageTitle>Property</PageTitle>
            {user?.status === "approved" ? (
                <div className="pb-5">
                    <Table
                        columns={columns(iconClickHandler)}
                        data={rowsData}
                        loading={loading}
                        onRowClick={(row: any, type: any) => iconClickHandler(row, type)}
                        isPaginationRequired
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        total={dataCount}
                    />
                    <EditLandlordProperty
                        setOpen={setOpen}
                        open={open}
                        loading={loading}
                        updateProperty={
                            modalMode === "edit"
                                ? updatePropertyFunction
                                : createPropertyFunction
                        }
                        modalMode={modalMode}
                        property={currentListing}
                    />
                </div>
            ) : (
                <div className="card-body p-0 ">
                    {/* begin::Chart */}
                    <div className="alert alert-warning p-5 mt-5 mx-10" role="alert">
                        This is a warning alert{" "}
                        <h3 className="text-danger">
                            Your account is not approved you cannot Post Property!!.
                        </h3>
                    </div>
                    {/* end::Chart */}
                    {/* begin::Stats */}
                    <div className="card-p mt-10 position-relative d-flex justify-content-center">
                        <img src={toAbsoluteUrl("/media/illustrations/sketchy-1/57.svg")} />

                        {/* end::Row */}
                    </div>
                    <span className="d-flex justify-content-center fw-bold fs-4 mb-1 ">
                        No Record Found
                    </span>
                    {/* <span className='d-flex justify-content-center fw-bold fs-4 text-muted mb-5 '>Submit your application to Imlaak support for further assistance</span>  */}
                    {/* end::Stats */}
                </div>
            )}
        </>
    );
};

export default DemoListingPage;
