import { useEffect } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Modal from "../../Modal";
import CustomInput from "../../Inputs";
import { useFormik } from "formik";
import * as yup from "yup";
import Loader from "../../Loader";
import { ImageUploader, ImageViewer } from "./imageSelector";
import { useImageMethod } from "app/hooks/useImageUpload";
import { API } from "app/utils/config";
import MultipleSelectCheckmarks from "app/components/Inputs/DropdownWithCheckBox";
import {
  propertylanguagesOptions,
  propertyspecialtiesOptions,
} from "app/components/DropDownOptions/DropDownOptions";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const validationSchema = yup.object({
  fname: yup.string().required("Manager's name is required"),
  email: yup
    .string()
    .required("Manager's email is required")
    .email("Invalid email address"),
  password: yup
    .string()
    .required("Manager's password is required")
    .min(8, "Password must be at least 8 characters long"),
  contact: yup.string().required("Manager's contact is required"),
  experience: yup
    .number()
    .typeError("Must be a number")
    .positive("Must be greater than 0")
    .required("Experience is required"),
  // description: yup
  //   .string()
  //   .required("Description is required")
  //   .max(1000, "Description must be at most 500 characters long"),
  // propertiesfor: yup
  //   .array()
  //   .required("Properties field is required")
  //   .min(1, "At least one field must be selected"),
});

type EditProps = {
  open: any;
  setOpen: any;
  data?: any;
  update?: any;
  loading?: boolean;
  type: "ADD" | "EDIT";
};

const Edit = ({
  open,
  setOpen,
  data,
  update,
  loading,
  type = "EDIT",
}: EditProps) => {
  const formik = useFormik({
    initialValues: {
      fname: data?.fname || "",
      email: data?.email || "",
      password: data?.password || "",
      contact: data?.contact || "",
      userImage: data?.userImage || "",
      description: data?.description || "",
      languages: data?.languages || [],
      experience: data?.experience || "",
      role: 'employee',
      isVerified: true,
      status: 'approved',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      update(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (Object.keys(data).length > 0) formik.setValues({ ...data });
  }, [data, open]);

  //start Image upload files
  const { uploadImage, images, setImages, files, setFiles } = useImageMethod();
  const onSubmitImage = async () => {
    let formData = new FormData(); //formdata object
    formData.append("image", files[0]);
    // formData.append('images', files)
    const res = await uploadImage({ params: formData });
    if (res?.data?.succes) {
      formik.setFieldValue("userImage", res?.data?.image[0]);
      setImages([]);
    }
  };

  //End Image upload files

  return (
    <Modal
      setOpen={() => setOpen(!open)}
      open={open}
      title={` ${Object.keys(data).length
        ? "Edit Manager # " + data?._id
        : "Add New Manager"
        } `}
      cancelButtonTitle="Cancel"
      acceptButtonVarient="contained"
      acceptButtonTitle="Add New Manager"
      handleAccept={() => formik.handleSubmit()}
      handleClose={() => formik.resetForm()}
      loading={loading}
      isActionable={true}
    >
      {loading ? (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "30%",
          }}
        >
          <Loader top='"60%' left='"50%' />
        </Box>
      ) : null}
      <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <CustomInput
              id="fname"
              label="Managers Name"
              placeholder="Type here..."
              value={formik.values.fname}
              onChangeHandler={formik.handleChange}
              isError={formik.touched?.fname && Boolean(formik.errors.fname)}
              helperText={formik.touched.fname ? formik.errors.fname : ""}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="email"
              label="Email"
              placeholder="Type here..."
              value={formik.values.email}
              onChangeHandler={formik.handleChange}
              isError={formik.touched?.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="password"
              label="Password"
              placeholder="Type here..."
              value={formik.values.password}
              onChangeHandler={formik.handleChange}
              isError={
                formik.touched?.password && Boolean(formik.errors.password)
              }
              helperText={formik.touched.password ? formik.errors.password : ""}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>Phone number</Typography>
            <div
              style={{
                fontSize: "30.2rem",
              }}
            >
              <PhoneInput
                country={"pk"}
                value={formik.values.contact}
                onChange={(phone: any) =>
                  formik.setFieldValue("contact", phone)
                }
                inputProps={{
                  name: "contact",
                  id: "contact",
                  required: true,
                  autoFocus: true,
                  placeholder: "Enter phone number",
                  style: {
                    fontSize: "1.2rem", // change the font size as per your preference
                    marginTop: "1rem", // change the margin as per your preference
                    height: "50px",
                    width: "328px",
                  },
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <CustomInput
              id="experience"
              label="Experience in Years"
              type="number"
              placeholder="Type here..."
              value={formik.values.experience}
              onChangeHandler={formik.handleChange}
              isError={
                formik.touched?.experience && Boolean(formik.errors.experience)
              }
              helperText={
                formik.touched.experience ? formik.errors.experience : ""
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <h5
            className="text-light p-3 rounded"
            style={{
              background:
                "linear-gradient(45deg, #233A65 0%, #f5f8fa 62%, #fff 100%)",
            }}
          >
            Add Manager Photo
          </h5>
          <Grid item display="flex" sx={{ columnGap: "16px" }}>
            {formik.values.userImage ? (
              <ImageViewer
                index={formik.values.userImage}
                image={`${API}/uploads/${formik.values.userImage}`}
                onImageRemove={() => {
                  formik.setFieldValue("image", "");
                }}
              />
            ) : null}

            <ImageUploader
              multiple={false}
              images={images}
              setImages={setImages}
              files={files}
              setFiles={setFiles}
            />

            {images.length > 0 ? (
              <Button variant="contained" size="large" onClick={onSubmitImage}>
                Save
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Edit;
