import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageTitle } from '_metronic/layout/core';
import { APIS } from 'Constants';
import useFetchData from 'app/hooks/useFetchData ';
import Dummy from '../../../_metronic/assets/media/propertyDummy.png'
import { Box } from '@mui/material';
import Loader from '../Loader';

export interface PropertyDetailPageProps {
    className?: string;
}

interface InventoryItem {
    _id: string;
    title: string;
    category: string;
    subCategory: string;
    Area: string;
    // include other properties as needed
}

interface RouteParams {
    id: string;
}

const PropertyDetailPage: FC<PropertyDetailPageProps> = () => {
    let { id } = useParams<RouteParams>();

    const { getAllInventory } = APIS.inventory;
    const { inventoryData, loading } = useFetchData({ getAllInventory });

    // Assuming inventoryData is an array of objects
    const propertyDetail: any | undefined = inventoryData?.find((row: InventoryItem) => row._id === id);

    return (
        <>
            <PageTitle>Property Details</PageTitle>
            <div className="card p-5">
                {loading ? (
                    <Box
                        sx={{
                            height: "200px",
                            background: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            left: "50%",
                            top: "30%",
                        }}
                    >
                        <Loader top='"60%' left='"50%' />
                    </Box>
                ) : (
                    <>
                        {propertyDetail && (
                            <>
                                <div className="p-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-center">
                                                <img src={Dummy} alt="" className="" style={{ width: "1000px", height: "500px", borderRadius: "10px", border: "1px solid #bfbfbf" }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 w-lg-[96] w-80 px-lg-48 px-md-48 px-20 mt-5" >
                                            <h3 className="fw-bolder">{propertyDetail.title}</h3>
                                            <div className="d-flex justify-content-between">
                                                <h4 className="fw-bolder">Category:</h4>
                                                <p className="text-capitalize">{propertyDetail.category}</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <h4 className="fw-bolder">Sub-Category:</h4>
                                                <p className="text-capitalize">{propertyDetail.subCategory}</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <h4 className="fw-bolder">Area:</h4>
                                                <p className="text-capitalize">{propertyDetail.area}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default PropertyDetailPage;
