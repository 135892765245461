import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import DialogTitle from '@mui/material/DialogTitle'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import './styles.css'
export const useStyles = makeStyles(() => ({
  titleContainer: {
    padding: '0px !important',
  },
  titleContentContainer: {
    width: '100%',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  profile: {
    marginRight: 10,
  },
  dialogTitleRoot: {
    width: '100% !important',
  },
  dialogTitle: {
    fontSize: '24px !important',
    color: '#001533 !important',
    fontWeight: '700 !important',
  },
  dialogSubTitle: {
    fontSize: '16px',
    color: '#AEAEAE',
    fontWeight: '400',
  },
  iconsContainer: {
    width: '10% !important',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    display: 'flex',
  },
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    backgroundColor: 'white',
    transform: 'translate(-50%, -50%)',
    borderRadius: 10,
    margin: '24px 0px',
    maxHeight: '100vh',
    overflowY: 'auto'
  },
  body: {
    padding: '0px 40px',
  },
  titleInnerContainer: {
    padding: '0px 40px',
    minWidth: '80%'
  },
  buttonContainer: {
    width: '100%',
    padding: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

type Props = {
  open: boolean
  setOpen: Function
  title: string
  subtitle?: string
  children: any
  showProfileIcon?: boolean
  cancelButtonVarient?: any
  acceptButtonVarient?: any
  cancelButtonTitle: string
  acceptButtonTitle: string
  handleAccept?: Function
  handleClose?: Function
  loading?: boolean
  isActionable?: boolean
  conentLoading?: boolean
}
export default function ModalBase({
  open,
  setOpen,
  title,
  subtitle,
  children,
  cancelButtonVarient,
  acceptButtonVarient,
  cancelButtonTitle,
  acceptButtonTitle,
  handleAccept,
  handleClose,
  loading,
  isActionable = false,
}: Props) {
  const handleCloseInt = () => {
    if (handleClose) {
      handleClose()
    }
    setOpen(false)
  }

  const classes = useStyles()

  return (
    <Modal
      sx={{ minHeight: '80vh', overflowY: 'scroll', minWidth: '50%' }}
      open={open}
      onClose={handleCloseInt}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.root}>
        <DialogTitle className={classes.titleContainer + ' title-gradient'}>

          <Box className={classes.titleContentContainer}>
            <Box className={classes.titleInnerContainer}>
              <Box className={classes.dialogTitleRoot}>
                <Typography className={classes.dialogTitle}>{title}</Typography>
                {subtitle && (
                  <Typography className={classes.dialogSubTitle}>
                    {subtitle}
                  </Typography>
                )}
              </Box>
            </Box>
            <IconButton onClick={handleCloseInt}>
              <Close />
            </IconButton>

          </Box>

        </DialogTitle>
        <Box className={classes.body}>{children}</Box>
        <Box className={classes.buttonContainer}>
          {isActionable && (
            <>
              <Button
                sx={{ width: '48%' }}
                variant={cancelButtonVarient ? cancelButtonVarient : 'outlined'}
                onClick={() => handleCloseInt()}
              >
                {cancelButtonTitle}
              </Button>
              <LoadingButton
                sx={{ width: '48%' }}
                variant={acceptButtonVarient ? acceptButtonVarient : 'outlined'}
                loading={loading}
                onClick={() => (handleAccept ? handleAccept() : {})}
              >
                {acceptButtonTitle}
              </LoadingButton>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
