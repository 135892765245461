import { useEffect, useState, useMemo, useRef } from "react";
import {
    Box,
    Grid,
    FormControl,
    MenuItem,
    Select,
    Typography,
    useFormControl,
    Card,
    IconButton,
} from "@mui/material";
import Modal from "../../Modal";
import CustomInput from "../../Inputs";
import { useFormik } from "formik";
import * as yup from "yup";
import JoditEditor from "jodit-react";
import { makeStyles } from "@mui/styles";
import * as AuthRedux from "../../auth/redux/AuthRedux";
import Loader from "../../Loader";
import { useImageMethod } from "app/hooks/useImageUpload";
import { API } from "app/utils/config";
import MultipleSelectCheckmarks from "app/components/Inputs/DropdownWithCheckBox";
import useFetchData from "app/hooks/useFetchData ";
import { APIS } from "Constants";
import axios from "axios";
import { AppErrorColor, AppTextColor } from "app/components/theme/Colors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { getLandlordProperties } from "app/components/property/redux/PropertyCrud";
import { CloudImage } from "app/shared/Input/CloudImage";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Delete } from "@mui/icons-material";
import { v2 as cloudinary } from "cloudinary";


interface InventoryItem {
    projectName: string;
    appartmentNo: string;
    floorNo: string;
    category: string;
    subCategory: string;
    title: string;
    _id: string;
    consultantId: string;
    isListed: boolean;
    projectSelecting: string;
    description: string;
    landlordId: string;
    ProjectSelecting: string;
    propertyImages: [];
}
interface Landlord {
    _id: string;
    approvalStatus: string;
}

const validationSchema = yup.object({
    // projectName: yup.string().required("Floor No is required"),
    // floorNo: yup.string().required("Floor No is required"),
    // apartmentNo: yup.number().required("Apartment No is required"),
    salePrice: yup.number().required("Sale price is required").min(1, "Sale price must be greater than 0"),
});

const useStyles = makeStyles(() => ({
    heading: {
        background: "#263c67",
        color: "#fff",
        fontWeight: "700 !important",
    },
    headingStyles: {
        fontSize: "14px !important",
        fontWeight: "700 !important",
    },
    description: {
        padding: "8.5px 14px",
        width: "100%",
        pointerEvents: "none",
        border: "1px solid",
        borderColor: "#093d5c",
        borderRadius: "5px",
        height: "45px",
    },
}));

type LandlordListingForm = {
    open: any;
    setOpen: any;
    property?: any;
    AddProperty?: any;
    loading?: boolean;
    updateProperty?: any;
    modalMode?: any;
    error?: any;
    label?: string;
    value?: string;
};

const LandlordListingForm = ({
    open,
    setOpen,
    property,
    AddProperty,
    loading,
    modalMode,
    error,
    label,
    updateProperty,
    value,
}: LandlordListingForm) => {
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    const appStatus = user?.approvalStatus
    const { getAllNames } = APIS.ProjectName;
    const { projectNameData } = useFetchData({ getAllNames });
    const [projectLookup, setProjectLookup] = useState<any>({});
    const [error2, setError2] = useState('');

    useEffect(() => {
        const lookup: any = {};
        projectNameData.forEach((row: any) => {
            lookup[row?._id] = row?.projectName;
        });
        setProjectLookup(lookup);
    }, [projectNameData]);

    const editor = useRef(null);
    //start Image upload files
    const { uploadImage, images, setImages, files, setFiles } = useImageMethod();
    const onSubmitImage = async (event: any) => {
        let formData = new FormData(); //formdata object
        for (var i = 0; i < files.length; ++i) {
            formData.append(files[i].name, files[i]);
        }
        // formData.append('images', files)
        const res: any = await uploadImage({ params: formData });
        if (res?.data?.succes) {
            formik.setFieldValue("images", res?.data?.image);
            setImages([]);
            setFiles([]);
        }
    };

    useEffect(() => {
        // console.log(images, "images");
    }, [images]);
    // End images upload files

    const HeadingTextValue: any = {};
    const classes = useStyles();
    function HeadingText() {
        const { focused } = useFormControl() || {};
        const headingColor = useMemo(() => {
            if (error) {
                return AppErrorColor;
            }

            if (focused && !error) {
                return "#007FFF";
            }

            return AppTextColor;
        }, [focused]);
        return (
            <Typography
                className={classes.headingStyles}
                fontSize={18}
                color={headingColor}
            >
                Select Building Name
            </Typography>
        );
    }
    function HeadingText2() {
        const { focused } = useFormControl() || {};
        const headingColor = useMemo(() => {
            if (error) {
                return AppErrorColor;
            }

            if (focused && !error) {
                return "#007FFF";
            }

            return AppTextColor;
        }, [focused]);
        return (
            <Typography
                className={classes.headingStyles}
                fontSize={18}
                color={headingColor}
            >
                Apartment No
            </Typography>
        );
    }
    function HeadingText3() {
        const { focused } = useFormControl() || {};
        const headingColor = useMemo(() => {
            if (error) {
                return AppErrorColor;
            }

            if (focused && !error) {
                return "#007FFF";
            }

            return AppTextColor;
        }, [focused]);
        return (
            <Typography
                className={classes.headingStyles}
                fontSize={18}
                color={headingColor}
            >
                Select Floor No
            </Typography>
        );
    }

    // Getting Consultant Properties
    const dispatch = useDispatch();
    const listing: any = useSelector<RootState>(
        ({ auth }) => auth.listing,
        shallowEqual
    );
    const [landlordProperties, setLandlordProperties] = useState(listing);
    const [accessToken, setAccessToken]: any = useState("");
    const [countState, setCountState] = useState(0);
    const [filter, setFilter] = useState({});

    const getToken = async () => {
        let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
        accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
        setAccessToken(accessToken);
    };

    const getProperties = (filters: any) => {
        getLandlordProperties(accessToken, filters)
            .then(({ data }) => {
                const filteredData = data?.property?.filter((row: any) => !row.isListed);
                setLandlordProperties(filteredData);
                setCountState(filteredData.length);
            })
            .catch(() => { })
    };

    useEffect(() => {
        getToken();
        setTimeout(() => {
            getProperties(filter);
        }, 1000);
    }, []);
    // Filtering Properties
    const selectedBuildingData: InventoryItem[] = landlordProperties?.filter((row: InventoryItem) => row?.isListed === false);
    const uniqueProjectNames = new Set(selectedBuildingData?.map(item => item.projectName));

    const selectedBuildingProjectName = Array.from(uniqueProjectNames).sort().map(name => ({
        projectName: name
    }));
    const [projectNameValue, setProjectNameValue] = useState({});
    const selectedBuilding: InventoryItem[] = selectedBuildingData?.filter((row: InventoryItem) => row?.projectName === projectNameValue);
    const uniqueFloorNumbers: string[] = Array.from(new Set(selectedBuilding?.map((row: InventoryItem) => row?.floorNo)));

    const selectedFloorNo: string[] = uniqueFloorNumbers.slice().sort((a, b) => {
        const numA = parseInt(a, 10); // Convert string to number
        const numB = parseInt(b, 10); // Convert string to number

        const isANumber = !isNaN(numA);
        const isBNumber = !isNaN(numB);

        if (!isANumber && !isBNumber) {
            // If both are strings, sort them alphabetically
            return a.localeCompare(b);
        } else if (!isANumber && isBNumber) {
            // Place strings before numbers
            return -1;
        } else if (isANumber && !isBNumber) {
            // Place numbers after strings
            return 1;
        } else {
            // Both are numbers, sort numerically
            return numA - numB;
        }
    });

    const [selectedFloorNoValue, setSelectedFloorNoValue] = useState({});

    const selectedApartments: InventoryItem[] = selectedBuilding?.filter((row: InventoryItem) => row?.floorNo === selectedFloorNoValue);

    const tagApartmentsWithLandlordVerification = (properties: InventoryItem[]) => {
        const isLandlordVerified = appStatus === 'approved';

        const taggedApartments = properties?.map((property: InventoryItem) => ({
            appartmentNo: property.appartmentNo,
            isLandlordVerified: isLandlordVerified,
        }));

        return taggedApartments;
    }

    const apartmentsWithVerificationStatus = tagApartmentsWithLandlordVerification(selectedApartments);

    const FinalApartmentNo = apartmentsWithVerificationStatus
        .sort((a, b) => a.appartmentNo.localeCompare(b.appartmentNo))
        .map(apartment => ({
            ...apartment,
            appartmentNo: apartment.appartmentNo.charAt(0).toUpperCase() + apartment.appartmentNo.slice(1)
        }));

    const [imagePreviews, setImagePreviews] = useState<(string | ArrayBuffer)[]>([]);
    const [apartmentNumberValue, setApartmentNumberValue] = useState({});
    const [apartmentId, setApartmentId] = useState('');
    const [localImages, setLocalImages] = useState([]);
    const [projectSelectingValue, setProjectSelectingValue] = useState('');
    const selectedPropertyDetails: InventoryItem[] = selectedApartments?.filter((row: InventoryItem) => row?.appartmentNo === apartmentNumberValue);

    useEffect(() => {
        if (selectedPropertyDetails && selectedPropertyDetails?.length > 0) {
            setApartmentId(selectedPropertyDetails?.[0]?._id);
            setProjectSelectingValue(selectedPropertyDetails?.[0]?.ProjectSelecting);
        }
    }, [selectedPropertyDetails]);
    const formik = useFormik({
        initialValues: {
            projectName: property?.projectName || "",
            category: property?.category || "",
            subCategory: property?.subCategory || "",
            description: property?.description || "",
            salePrice: property?.salePrice || 0,
            floorNo: property?.floorNo || 0,
            appartmentNo: property?.appartmentNo || 0,
            images: property?.images || [],
            propertyImages: [],
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            const { propertyImages } = values
            let imagesData = [];
            const existingImages = localImages;
            const currentImages = values.propertyImages || [];

            // Identify deleted images
            const imagesToDelete = existingImages?.filter((img: any) => !currentImages?.some((curImg: any) => curImg.public_id === img.public_id));
            const previousImagesToSave = existingImages?.filter((img: any) => currentImages?.some((curImg: any) => curImg.public_id === img.public_id));

            // Delete removed images from Cloudinary
            const deletePromises = imagesToDelete?.map((img: any) => cloudinary.uploader.destroy(img.public_id));
            await Promise.all(deletePromises);

            // Identify new images that need to be uploaded
            const imagesToUpload = currentImages?.filter((img: { public_id?: string, file?: File }) => !img.public_id)
                .map((img: { file: File }) => img.file);
            // Upload new images
            if (imagesToUpload.length > 0) {
                const uploadPromises = imagesToUpload?.map(async (imageFile: any) => {
                    return CloudImage(imageFile);
                });
                const uploadedImages = await Promise.all(uploadPromises);
                imagesData = [...previousImagesToSave?.filter((img: any) => img.url), ...uploadedImages];
            } else {
                imagesData = currentImages;
            }

            const { salePrice, description } = values;
            const newValues = { id: apartmentId, isListed: true, salePrice, description, propertyImages: imagesData };
            const newValues2 = { id: apartmentId, isListed: true, salePrice, description, propertyImages: imagesData };

            if (projectSelectingValue === "Registered_project") {
                updateProperty(newValues2)
            }
            else {
                updateProperty(newValues);
            }
            setImagePreviews([])
        },
    });

    useEffect(() => {
        formik.setFieldValue("images", property?.images);
        formik.setFieldValue("projectName", property?.projectName);
        formik.setFieldValue("category", property?.category);
        formik.setFieldValue("subCategory", property?.subCategory);
        formik.setFieldValue("description", property?.description);
        formik.setFieldValue("floorNo", property?.floorNo);
        formik.setFieldValue("appartmentNo", property?.appartmentNo);
        formik.setFieldValue("salePrice", property?.salePrice);
        formik.setFieldValue("propertyImages", []);
    }, [property]);

    useEffect(() => {
        if (selectedPropertyDetails && selectedPropertyDetails?.length > 0) {
            const details = selectedPropertyDetails?.[0];
            const { propertyImages } = selectedPropertyDetails?.[0];
            if (propertyImages && JSON.stringify(propertyImages) !== JSON.stringify(localImages)) {
                setLocalImages(propertyImages);
                formik.setFieldValue('propertyImages', propertyImages);
            }
        }
    }, [selectedPropertyDetails]);


    // Images Function
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };
    useEffect(() => {
        if (formik.values.propertyImages?.length > 0) {
            const initialPreviews = formik.values.propertyImages?.map((image: any) => image.url);
            setImagePreviews(initialPreviews);
        }
    }, [formik.values.propertyImages]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            files.forEach((file) => {
                if (file.size > 1024 * 1024) {
                    setError2('File size should not exceed 1024 KB.');
                } else {
                    const fileObjects = files.map(file => ({
                        file,
                        url: URL.createObjectURL(file)
                    }));

                    // Update Formik state and local preview state
                    const allImages = [...formik.values.propertyImages, ...fileObjects];
                    formik.setFieldValue("propertyImages", allImages);
                    setImagePreviews(allImages.map(img => img.url));
                }
            });
        }
    };

    const handleDeleteImage = (index: number) => {
        const updatedImages = formik.values.propertyImages.filter((_: any, i: any) => i !== index);
        formik.setFieldValue("propertyImages", updatedImages);
        setImagePreviews(updatedImages.map((img: any) => img.url));
    };
    return (
        <Modal
            setOpen={() => setOpen(!open)}
            open={open}
            title={
                modalMode === "edit"
                    ? ` Update Listed Property`
                    : "List Property"
            }
            cancelButtonTitle="Cancel"
            acceptButtonVarient="contained"
            acceptButtonTitle={
                modalMode === "edit"
                    ? ` Update Listed Property`
                    : "List Property"
            }
            handleAccept={() => formik.handleSubmit()}
            handleClose={() => {
                formik.resetForm()
                setImagePreviews([])
            }}
            loading={loading}
            isActionable={true}
        >
            {loading ? (
                <Box
                    sx={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        left: "50%",
                        top: "30%",
                    }}
                >
                    <Loader top='"60%' left='"50%' />
                </Box>
            ) : null}
            <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
                <Grid container spacing={2}>
                    {modalMode !== 'edit' ? (
                        <>
                            <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>
                                <FormControl style={{ width: "100%" }}>
                                    <HeadingText />
                                    <Select
                                        style={{ width: "100%" }}
                                        sx={{ backgroundColor: "#fcfcfb", textTransform: 'capitalize' }}
                                        id="projectName"
                                        value={formik.values.projectName}
                                        label={<HeadingText />}
                                        onChange={(event: any) => {
                                            formik.setFieldValue("projectName", event.target.value);
                                            setProjectNameValue(event.target.value);
                                            formik.setFieldValue("floorNo", '');
                                            formik.setFieldValue("appartmentNo", '');
                                            formik.setFieldValue("category", '');
                                            formik.setFieldValue("subCategory", '');
                                        }}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        {selectedBuildingProjectName.map((property, index) => (
                                            <MenuItem
                                                sx={{ textTransform: 'capitalize' }}
                                                key={index}
                                                value={property.projectName}
                                            >
                                                {projectLookup[property.projectName] || property.projectName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>
                                <FormControl style={{ width: "100%" }}>
                                    <HeadingText3 />
                                    <Select
                                        style={{ width: "100%" }}
                                        sx={{ backgroundColor: "#fcfcfb" }}
                                        id="floorNo"
                                        value={formik.values.floorNo}
                                        label={<HeadingText3 />}
                                        onChange={(event: any) => {
                                            formik.setFieldValue("floorNo", event.target.value);
                                            setSelectedFloorNoValue(event.target.value.toString() || formik.values.floorNo.tostring());
                                        }}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        {selectedFloorNo.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>
                                <FormControl style={{ width: "100%" }}>
                                    <HeadingText2 />
                                    <Select
                                        style={{ width: "100%" }}
                                        sx={{ backgroundColor: "#fcfcfb" }}
                                        id="appartmentNo"
                                        value={formik.values.appartmentNo}
                                        label={<HeadingText2 />}
                                        onChange={(event: any) => {
                                            formik.setFieldValue("appartmentNo", event.target.value);
                                            setApartmentNumberValue(event.target.value.toString() || formik.values.appartmentNo.toString())
                                        }}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        {FinalApartmentNo.map((property, index) => (
                                            <MenuItem
                                                key={index}
                                                value={property.appartmentNo}
                                                disabled={!property.isLandlordVerified}
                                                style={{ display: 'flex', justifyContent: 'space-between', color: property.isLandlordVerified ? 'inherit' : '#ff1616', fontWeight: property.isLandlordVerified ? 'normal' : '600' }}
                                            >
                                                {property.appartmentNo}
                                                {!property.isLandlordVerified && "(Consultant Approval Pending)"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="projectName"
                                    label="Building Name *"
                                    value={projectLookup[property.projectName] || property.projectName}
                                    type="text"
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="floorNo"
                                    label="Floor No *"
                                    value={formik.values.floorNo}
                                    type="text"
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="appartmentNo"
                                    label="Apartment No *"
                                    value={formik.values.appartmentNo}
                                    type="text"
                                    disabled
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} sm={12} md={6}>
                        <CustomInput
                            id="category"
                            label="Category *"
                            value={selectedPropertyDetails[0]?.category || formik.values.category}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <CustomInput
                            id="subCategory"
                            label="Sub-Category *"
                            value={selectedPropertyDetails[0]?.subCategory || formik.values.subCategory}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <CustomInput
                            id="salePrice"
                            label="Sale Price (PKR):"
                            placeholder="Type here..."
                            value={formik.values.salePrice}
                            type="number"
                            onChangeHandler={(event: any) => {
                                formik.setFieldValue("salePrice", event.target.value);
                            }}
                            isError={formik.touched.salePrice && Boolean(formik.errors.salePrice)}
                            helperText={formik.touched.salePrice ? formik.errors.salePrice : ""}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px" }}>Description</label>
                        <JoditEditor
                            ref={editor}
                            value={selectedPropertyDetails[0]?.description || formik.values.description}
                            onChange={(newContent) =>
                                formik.setFieldValue("description", newContent)
                            }
                        />
                    </Grid>

                    {/* start images upload section */}
                    <Grid item xs={12} sm={12} md={12}>
                        <h5
                            className='text-light p-3 rounded'
                            style={{
                                background:
                                    "linear-gradient(45deg,  #233a65 0%, #f5f8fa 62%, #fff 100%)",
                            }}
                        >
                            Images
                        </h5>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className="bg-white p-3 shadow rounded mt-3">
                                <Box
                                    className='alert alert-warning d-flex align-items-center'
                                    role='alert'
                                >
                                    <Box>
                                        <i className='fonticon-house'></i>
                                        You can select multiple images at once
                                    </Box>
                                </Box>
                                <input
                                    ref={fileInputRef}
                                    id="propertyImages"
                                    name="propertyImages"
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                    multiple
                                />
                                {error2 && <p style={{ color: 'red' }}>{error2}</p>}
                                <Grid container m={2}>
                                    {imagePreviews?.length > 0 && (
                                        imagePreviews?.map((src, index) => (
                                            <Grid item key={index}>
                                                <Card elevation={4} sx={{ position: "relative", mb: 2, mx: 2 }}>
                                                    <div className='image-item'>
                                                        <img src={src.toString()} alt={`Preview ${index}`} style={{ maxWidth: '170px', maxHeight: '170px', borderRadius: '5px' }} />
                                                        <div className='image-item__btn-wrapper'>
                                                            <IconButton
                                                                onClick={() => handleDeleteImage(index)}
                                                                sx={{
                                                                    border: "1px dashed red",
                                                                    borderRadius: "50%",
                                                                    position: "absolute",
                                                                    top: "0",
                                                                    right: "0",
                                                                    m: 1,
                                                                }}
                                                            >
                                                                <Delete sx={{ fontSize: 28, fill: "red" }} />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Grid>
                                        ))
                                    )}

                                    <Grid item>
                                        <div className="py-1 px-5 bg-white shadow rounded" style={{ textAlign: 'center' }}>
                                            <IconButton
                                                sx={{
                                                    border: "1px dashed aquamarine",
                                                    borderRadius: "50%",
                                                    width: "110px",
                                                    height: "110px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    margin: "6px",
                                                }}
                                                onClick={handleUploadClick}
                                            >
                                                <AddAPhotoIcon sx={{ fontSize: 100 }} />
                                            </IconButton>
                                            <br />
                                            <b>Select Images</b>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                    </Grid>
                    {/* End images upload section */}
                </Grid>
            </Box>
        </Modal >
    );
};

export default LandlordListingForm;
