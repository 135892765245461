import CustomTable, {
  capitalizeFirstLetter,
  IconRender,
} from "app/components/Table";
// import React, { useEffect, useState } from "react";

// import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
// import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { RootState } from "setup";
import Swal from "sweetalert2";
import { ProjectHeader } from "app/components/Project/ProjectHeader";
import { getAllUsers, updateAgency } from "./Crud";
import { Chip, Typography } from "@mui/material";
import { PageLink, PageTitle } from "_metronic/layout/core";

import { Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { blue, green, red } from "@mui/material/colors";
import { Link } from "react-router-dom";
import { StatisticsWidget8 } from "_metronic/partials/widgets/statistics/StatisticsWidget8";
let i = 1;
const colors = {
  approve: "#47BE7D",
  reject: " #bda588",
  suspend: red[300],
  pending: "#F1BC00",
};

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

const UsersTable = ({ setAgenciesList }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({});

  const [currentListing, setCurrentListing] = useState(0);
  const listing = useSelector(
    ({ auth }) => auth.listing,
    shallowEqual
  );
  const [myProperties, setMyProperties] = useState(listing);
  const [accessToken, setAccessToken] = useState("");
  const [agencies, setAllAgencies] = useState([]);
  const [modalMode, setModalMode] = useState("edit");

  const getToken = async () => {
    let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
    accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
    setAccessToken(accessToken);
  };

  const filtersChanged = (filters) => {
    setFilter(filters);
    setLoading(true);
    setTimeout(() => {
      getAgencies(filters);
    }, 1000);
  };

  const getAgencies = (filters) => {
    getAllUsers(accessToken, filters)
      .then(({ data }) => {
        setAllAgencies(data.data);
        setAgenciesList(data);

        setCount(data?.count);
      })
      .catch(() => { })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    getToken();
    setTimeout(() => {
      getAgencies(filter);
    }, 1000);
  }, []);

  useEffect(() => {
    setMyProperties(listing);
  }, [listing]);

  const onClickCreate = () => {
    setOpen(true);
    setCurrentListing([]);
    setModalMode("create");
  };

  useEffect(() => {
    document
      .getElementById("create-button")
      ?.addEventListener("click", onClickCreate);
  }, []);
  const iconClickHandler = (data, type) => {
    // if (type === "edit") {
    //   // setModalMode("edit");
    //   // setCurrentListing(row);
    //   // setOpen(true);
    // } else
    if (type) {
      Swal.fire({
        title: `Attempting to ${type} `,
        text: `Are you sure you want to ${type} `,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((status) => {
        if (status.isConfirmed) {
          setLoading(true);
          console.log(data[6], "data._id")
          updateAgency(data[6], type)
            .then(({ data, status }) => {
              let text = "";
              let icon = "error";
              if (status === 200) {
                text = `Successfully changed status`;
                icon = "success";
              } else {
                text = data.message;
                icon = "error";
              }
              Swal.fire({
                title: "Status Changed",
                text: text,
                icon: icon,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
              });
              setAllAgencies(data);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
        }
      });
    }
  };

  const createProjectFunction = (values) => {
    setLoading(true);
    setOpen(false);
    Swal.fire({
      title: "Project Listing?",
      text: "Do you want to List this Project for sale?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No Don't List, Add as an asset",
      confirmButtonText: "Yes List this",
    })
      .then((value) => {
        if (value.isConfirmed) {
          values = { ...values, isListed: true };
        }
        // createProject(values, accessToken)
        //   .then(({ data }) => {
        //     dispatch(AuthRedux.actions.setMyListings(data.project));
        //     setCount(data.count);
        //   })
        //   .catch(() => {})
        //   .finally(() => setLoading(false));
      })
      .finally(() => { });
  };

  const updateProjectFunction = (values) => {
    setLoading(true);
    // updateProject(values, currentListing?._id, accessToken)
    //   .then(({ data }) => {
    //     dispatch(AuthRedux.actions.setMyListings(data.project));
    //     setCount(data.count);
    //   })
    //   .catch(() => {})
    //   .finally(() => setLoading(false));
  };
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const columns = [
    { name: "fname", label: "Name", options: { filterOptions: { fullWidth: true } } },
    { name: "email", label: "Email", options: { filterOptions: { fullWidth: true } } },
    { name: "contact", label: "Contact", options: { filterOptions: { fullWidth: true } } },
    { name: "role", label: "Role", options: { filterOptions: { fullWidth: true } } },
    {
      label: "Status",
      name: "status",
      numaric: false,
      isSortable: true,
      renderContent: (value) => (
        <Chip
          sx={{ background: colors[value], color: "#233A65", fontWeight: "bold" }}
          label={capitalizeFirstLetter(value)}
        />
      ),
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          const data = tableMeta.rowData;
          return (
            <Select onChange={(event) => iconClickHandler(data, event.target.value)}>
              <MenuItem value="approved">Approve</MenuItem>
              <MenuItem value="reject">Reject</MenuItem>
              <MenuItem value="suspended">Suspend</MenuItem>
            </Select>
          );
        },
      },
    },
    {
      name: "_id",
      options: {
        filter: false,
        display: false
      }
    },
    // {
    //   name: "Actions",
    //   options: {
    //     filter: false,
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <Button
    //           color="primary"
    //           onClick={() => iconClickHandler(data, event.target.value)}
    //         >
    //           Action
    //         </Button>
    //       );
    //     }
    //   }
    // },
    // {
    //   label: "Action",
    //   name: "action",
    //   component: (row) => (
    //     <>
    //       <select
    //         className="form-select form-select-solid"
    //         aria-label="Select example"
    //         onChange={(event) => iconClickHandler(row, event.target.value)}
    //       >
    //         <option>Edit </option>
    //         <option value="approved">Approved</option>
    //         <option value="reject">Reject</option>
    //         <option value="suspended">Suspended</option>
    //       </select>
    //     </>
    //   ),
    // },

  ];

  const handleSelectChange = (event, id) => {
    console.log(event.target.value, id);
  };

  const handleButtonClick = (id) => {
    console.log(id);
  };

  // const data = [
  //   ["John Doe", 1, "id1"],
  //   ["Jane Doe", 2, "id2"],
  //   ["Jim Doe", 3, "id3"]
  // ];


  const options = {
    filterType: "dropdown",
    responsive: "scroll",
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };


  return (
    <>
      <PageTitle>Users</PageTitle>
      <div className="row">
        <div className="col-md-3">
          <Link to="/approveUsers">
            <StatisticsWidget8
              className="mb-5 text-white"
              svgIcon="/media/icons/duotune/communication/com005.svg"
              color="success"
              iconColor="white"
              title=''
              bgcolor=''
              description="approved"
            />
          </Link>
        </div>
        <div className="col-md-3">
          <Link to="/totalUsers">
            <StatisticsWidget8
              className="mb-5 text-white"
              svgIcon="/media/icons/duotune/graphs/gra007.svg"
              color=""
              iconColor="white"
              title=''
              bgcolor='#bf3939'
              description="Suspended"
            />
          </Link>
        </div>
        <div className="col-md-3">
          <Link to="/totalUsers">
            <StatisticsWidget8
              className="mb-5 text-white"
              svgIcon="/media/icons/duotune/general/gen008.svg"
              color="warning"
              iconColor="white"
              title=''
              bgcolor=''
              description="Landlords"
            />
          </Link>
        </div>
        <div className="col-md-3">
          <Link to="/totalUsers">
            <StatisticsWidget8
              className="mb-5 text-white"
              svgIcon="/media/icons/duotune/layouts/lay002.svg"
              color=""
              iconColor="white"
              title=''
              bgcolor='#333'
              description="Consultants"
            />
          </Link>
        </div>
      </div>

      <ProjectHeader filter={filter} setFilter={filtersChanged} />

      <CacheProvider value={muiCache}>
        <MUIDataTable
          title={""}
          data={agencies}
          loading={loading}
          columns={columns}
          options={options}
          onRowClick={(data, type) => iconClickHandler(data, type)}
        />
      </CacheProvider>

    </>
  );
}


export default UsersTable;