import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { Details } from './components/Details'
import { OrganizationHeader } from './SectorLocationHeader'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OrganizationPage: React.FC = () => {
  return (
    <>
      <OrganizationHeader />
      <Switch>
        <Route path='/sector'>
          <PageTitle>sector Location</PageTitle>
          <Details />
        </Route>

      </Switch>
    </>
  )
}

export default OrganizationPage
