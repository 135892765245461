import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/components/auth'
import * as profile from '../../app/components/ProfilePage'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  profile: profile.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
  yield all([profile.saga()])
}
