import React, { useEffect, useState } from "react";
import { PageLink, PageTitle, } from "../../../_metronic/layout/core";
import Table, { capitalizeFirstLetter, IconRender } from "../Table";
import * as AuthRedux from "../auth/redux/AuthRedux";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "_metronic/helpers/AssetHelpers";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import { numberToNotation } from "app/utils/numberToNotation";
import { Button } from "@mui/material";
import { CreateUpdateEvaluationByConsultant, UpdateEvaluationValue, getAllPropertiesWithEvaluations } from "../property/redux/PropertyCrud";
import { RootState } from "setup";
import NumberFormatter from "app/shared/Input/NumberFormatter";
import UpdateEvaluation from "./components/UpdateEvaluation";
import CreateEvaluation from "./components/CreateEvaluation";
import { components } from "react-select";

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
    },
];

const columns = (iconClickHandler: any, projectLookup: any, truncateToTenWords: any) => [
    {
        name: "Building",
        key: "projectName",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(
            <div className="overflow-ellipsis" title={value}>
                <div className="overflow-ellipsis text-capitalize" title={value}>
                    {projectLookup[value] || value}
                </div>
            </div>
        ),
    }, {
        name: "Sub Category",
        key: "subCategory",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    }, {
        name: "Floor",
        key: "floorNo",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    }, {
        name: "Apartment No",
        key: "appartmentNo",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    }, {
        name: "Purchase Price",
        key: "price",
        numaric: false,
        isSortable: true,
        renderContent: (row: any) => (
            <NumberFormatter value={row} />
        ),

    }, {
        name: "Current Evaluation",
        key: "evaluations",
        numaric: false,
        isSortable: true,
        renderContent: (row: any) => (
            <NumberFormatter value={row?.[0]?.currentEvaluation || 0} />
        ),

    }, {
        name: "Profit/loss %",
        key: "evaluations",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => `${value?.[0]?.profitLossValue || 0}`,
        // renderContent: (row: any) => {
        //     const netGainValue = row[0]?.netGain;

        //     if (netGainValue && netGainValue !== null) {
        //         return netGainValue < 0 ? `${netGainValue} loss` : `${netGainValue}  Profit`;
        //     }

        //     return "Not Evaluated";
        // }
        // renderContent: (value: string) => value ? numberToNotation(parseFloat(value), 10) : 0 + " PKR", Ye shayad million bilion mein aesy hee kr deta ha will check
    }, {
        name: "Action",
        key: "action",
        component: (row: any) => (
            <>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => iconClickHandler(row, 'edit')}
                >
                    {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, 'edit')}
                    Evaluate
                </Button>
                {/* {IconRender(InfoOutlinedIcon, iconClickHandler)} */}
                {/* {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, 'delete')} */}
            </>
        ),
    },
];

const ConsultantEvaluationPage: React.FC = () => {
    const dispatch = useDispatch();
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [modalMode, setModalMode]: any = useState("edit");

    const { getAll } = APIS.ProjectName;
    const { data } = useFetchData({ getAll });
    const [projectLookup, setProjectLookup] = useState<any>({});

    useEffect(() => {
        const lookup: any = {};
        data.forEach((row: any) => {
            lookup[row?._id] = row?.projectName;
        });
        setProjectLookup(lookup);
    }, [data]);
    const truncateToTenWords = (text: string, n: number = 15): string => {
        if (text?.length > n) {
            return text?.substring(0, n) + '...';
        }
        return text;
    };

    const [currentListing, setCurrentListing]: any = useState(0);
    const listing: any = useSelector<RootState>(
        ({ auth }) => auth.listing,
        shallowEqual
    );
    const [myProperties, setMyProperties] = useState(listing);
    const [accessToken, setAccessToken]: any = useState("");

    const getToken = async () => {
        let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
        accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
        setAccessToken(accessToken);
    };

    const getProperties = () => {
        getAllPropertiesWithEvaluations(accessToken)
            .then(response => {
                const filteredData = response.data?.properties?.filter((row: any) => row?.isDeleted === false);
                const sortedData = filteredData.sort((a: any, b: any) => {
                    const dateA = new Date(a.updatedAt);
                    const dateB = new Date(b.updatedAt);
                    return dateB.getTime() - dateA.getTime();
                });
                setMyProperties(sortedData);
                setCount(filteredData.length);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        setLoading(true);
        getToken();
        setTimeout(() => {
            getProperties();
        }, 1000);
    }, []);

    useEffect(() => {
        setMyProperties(listing);
    }, [listing]);

    const iconClickHandler = (row: any, type: any) => {
        if (type === "edit") {
            setModalMode("edit");
            setCurrentListing(row);
            setOpen(true);
        }
    };

    const updateEvaluationFunction = (values: any) => {
        setLoading(true)
        setOpen(false)
        CreateUpdateEvaluationByConsultant(values, currentListing?._id, accessToken)
            .then(({ data }) => {
                Swal.fire({
                    title: "Property Evaluated Successfully.",
                    text: "",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "OK",
                })
                getProperties();
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <PageTitle>Property Evaluation</PageTitle>
            {user?.role === "consultant" && user?.isVerified ? (
                <div className="pb-5">
                    <Table
                        columns={columns(iconClickHandler, projectLookup, truncateToTenWords)}
                        data={myProperties}
                        loading={loading}
                        onRowClick={(row: any, type: any) => iconClickHandler(row, type)}
                        isPaginationRequired
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        total={count}
                    />
                    <CreateEvaluation
                        setOpen={setOpen}
                        open={open}
                        loading={loading}
                        createEvaluation={
                            modalMode === "edit"
                                ? updateEvaluationFunction
                                : ''
                        }
                        modalMode={modalMode}
                        evaluation={currentListing}
                    />
                </div>
            ) : (
                <div className="card-body p-0 ">
                    {/* begin::Chart */}
                    <div className="alert alert-warning p-5 mt-5 mx-10" role="alert">
                        This is a warning alert{" "}
                        <h3 className="text-danger">
                            Your account is not approved you cannot Post Property!!.
                        </h3>
                    </div>
                    {/* end::Chart */}
                    {/* begin::Stats */}
                    <div className="card-p mt-10 position-relative d-flex justify-content-center">
                        <img src={toAbsoluteUrl("/media/illustrations/sketchy-1/57.svg")} />

                        {/* end::Row */}
                    </div>
                    <span className="d-flex justify-content-center fw-bold fs-4 mb-1 ">
                        No Record Found
                    </span>
                    {/* <span className='d-flex justify-content-center fw-bold fs-4 text-muted mb-5 '>Submit your application to Imlaak support for further assistance</span>  */}
                    {/* end::Stats */}
                </div>
            )}
        </>
    );
};

export default ConsultantEvaluationPage;
