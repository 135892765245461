import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { UserLandlordsDetails } from "./components/UserLandlordsDetails";

const UsersLandlordsPage: React.FC = () => {
    return (
        <>
            <PageTitle>Property Landlords</PageTitle>
            <UserLandlordsDetails />
        </>
    );
};


export default UsersLandlordsPage;
