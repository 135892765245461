import { Avatar, Button, Card, Grid, IconButton } from "@mui/material";
import React, { createRef, useEffect, useRef } from "react";
// import ImageUploading, { ImageListType } from "react-images-uploading";
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Box } from "@mui/system";
import { Delete, Remove } from "@mui/icons-material";
export function ImageUploader({
  multiple = false,
  files: oldFiles,
  setFiles,
  images,
  setImages,
  variant = 'normal',
}) {
  function handleFileSelect(evt) {
    var files = evt.target.files; // FileList object

    setFiles([...oldFiles, files[0]]);
    // files is a FileList of File objects. List some properties.
    var reader = new FileReader();
    var output = [];
    for (var file of files) {
      reader.onloadend = function () {
        output.push(reader.result);
        if (multiple) {
          setImages([...images, reader.result]);
        } else {
          setImages([reader.result]);
        }
      };
      reader.readAsDataURL(file);
    }
  }

  const onImageRemove = (index) => {
    let newImages = [];
    let newFiles = [];
    images.map((_, i) => (index === i ? null : newImages.push(_)));
    oldFiles.map((_, i) => (index === i ? null : newFiles.push(_)));
    setImages(newImages);
    setFiles(newFiles);
  };
  const ref = createRef();
  const clickedImage = () => {
    ref?.current?.click();
  };

  return (
    <Box
      className="ImageUploader"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container columnGap={1}>
        {images.map((image, index) => (
          <ImageViewer
            index={index}
            image={image}
            onImageRemove={onImageRemove}
          />
        ))}
        {images.length === 0 || multiple ? (
          <Grid item>
            <Box display={"flex"} alignItems="center" onClick={clickedImage}>
              <Card elevation={4} sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <input
                    multiple={multiple}
                    ref={ref}
                    type="file"
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                    onClick={() => { }}
                  />
                  {variant === "profile" ? (
                    <IconButton
                      sx={{
                        border: "1px dashed aquamarine",
                        borderRadius: "50%",
                        width: "100px",
                        height: "100px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        margin: "8px",
                      }}
                    >
                      <Avatar sx={{ fontSize: 40 }} />
                    </IconButton>
                  ) : (
                    <>
                      {" "}
                      <IconButton
                        sx={{
                          border: "1px dashed aquamarine",
                          borderRadius: "50%",
                          width: "150px",
                          height: "150px",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          margin: "8px",
                        }}
                      >
                        <AddAPhotoIcon sx={{ fontSize: 100 }} />
                      </IconButton>
                      <br />
                      Click or Drop here
                    </>
                  )}
                </Box>
              </Card>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}


export const ImageViewer = ({ index, image, onImageRemove }) => {
  return (
    <Grid item key={index}>
      <Card elevation={4} sx={{ p: 2, position: "relative" }}>
        <div key={index} className="image-item">
          <img src={image} alt="no preview" width={150} height={150} />
          <div className="image-item__btn-wrapper">
            {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
            <IconButton
              onClick={() => onImageRemove(index)}
              sx={{
                border: "1px dashed red",
                borderRadius: "50%",
                margin: "8px",
                position: "absolute",
                top: "0",
                right: "0",
              }}
            >
              <Delete sx={{ fontSize: 28, fill: "red" }} />
            </IconButton>
          </div>
        </div>
      </Card>
    </Grid>
  );
};