import axios from 'axios'
import { CREATE_INVENTORY, UPDATE_OWNER, DELETE_INVENTORY, GET_ALL_INVENTORY, REQUEST_EVALUATION, UPDATE_INVENTORY, GET_MY_EVALUATION, GET_MY_INVENTORY, DELETE_INVENTORYPROPERLY, GET_ALL_INVENTORIES } from '../../../constants/api'

export function getMyProperties(accessToken: string | null, filter: any) {
  return axios.get(`${GET_ALL_INVENTORIES}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      // params: { filters: filter }
    },
  )
}

export function getOwnProperties(accessToken: string | null, filter: any) {
  return axios.get(`${GET_MY_INVENTORY}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { filters: filter }
    },
  )
}

export function getMyEvaluations(accessToken: string | null) {
  return axios.get(`${GET_MY_EVALUATION}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    },
  )
}

export function updateProperty(values: any, propertyId: any, accessToken: string | null) {
  // const { city, state, location, sellingPrice, availabilityDate, contactName, contactPhone, contactEmail } = values
  // const alteredValues = {
  //   location: {
  //     ...location,
  //     state,
  //     city,
  //   },
  //   sellingDetails: {
  //     availabilityDate, contactName, contactPhone, contactEmail, sellingPrice
  //   }
  // }
  // delete values?.city
  // delete values?.state
  // delete values?.location
  // delete values?.availabilityDate
  // delete values?.contactName
  // delete values?.contactPhone
  // delete values?.contactEmail
  // delete values?.sellingPrice

  return axios.post(UPDATE_INVENTORY, {
    property: [{
      ...values,
      // ...alteredValues,
      id: propertyId
    }]

  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}
export function updateOwner(values: any, propertyId: any, accessToken: string | null) {
  // const { city, state, location, sellingPrice, availabilityDate, contactName, contactPhone, contactEmail } = values
  // const alteredValues = {
  //   location: {
  //     ...location,
  //     state,
  //     city,
  //   },
  //   sellingDetails: {
  //     availabilityDate, contactName, contactPhone, contactEmail, sellingPrice
  //   }
  // }
  // delete values?.city
  // delete values?.state
  // delete values?.location
  // delete values?.availabilityDate
  // delete values?.contactName
  // delete values?.contactPhone
  // delete values?.contactEmail
  // delete values?.sellingPrice

  return axios.post(UPDATE_OWNER, {
    property: [{
      ...values[0],
      // ...alteredValues,
      id: propertyId
    }]

  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function createProperty(values: any, accessToken: string | null) {
  // const { city, state, location } = values
  // const alteredValues = {
  //   // location: {
  //   //   ...location,
  //   //   state,
  //   //   city,
  //   // }
  // }
  // delete values.city
  // delete values.state
  // delete values.location

  return axios.post(CREATE_INVENTORY, {
    property: [{
      ...values,
      // ...alteredValues,
    }]

  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function deleteProperty(id: any, accessToken: string | null) {
  return axios.post(DELETE_INVENTORY, {
    id
  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function deletePropertyProperly(id: any, accessToken: string | null) {
  return axios.post(DELETE_INVENTORYPROPERLY, {
    id
  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function requestEvaluation(accessToken: string | null, propertyId: any) {
  return axios.post(`${REQUEST_EVALUATION}`,
    {
      property: propertyId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    },
  )
}