/* eslint-disable jsx-a11y/anchor-is-valid */
import { Stack } from "@mui/material";
import { url } from "inspector";
import React from "react";
import { KTSVG } from "../../../helpers";

type Props = {
  className: string;
  color: string;
  svgIcon: string;
  iconColor: string;
  title: string;
  titleColor?: string;
  description: string;
  descriptionColor?: string;
  img: string
  bgcolor: string
};

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  img,
  bgcolor,
}) => {
  return (
    <div className={`card bg-${color} shadow hoverable ${className}`} style={{ backgroundImage: `url('${img}')`, backgroundColor: bgcolor, backgroundSize: '100%' }}>
      <div className="card-body">
        <div className={`fw-semibold pt-7 pb-4 fs-3 text-${descriptionColor}`}>
          {description}
        </div>
        <Stack direction={"row"} alignItems="center" spacing={2}>
          <KTSVG
            path={svgIcon}
            className={`svg-icon-${iconColor} svg-icon-3x ms-n1`}
          />

          <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>
            {title}
          </div>
        </Stack>

      </div>
    </div>
  );
};

export { StatisticsWidget5 };
