import React from 'react'
import { PageTitle } from '_metronic/layout/core'
import { Details } from "./components/Details";

const DataTables = () => {
    return (
        <>
            <PageTitle>Property Table</PageTitle>
            <Details />
        </>
    )
}

export default DataTables
