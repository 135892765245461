/* eslint-disable jsx-a11y/anchor-is-valid */
import { capitalizeFirstLetter, IconRender } from "app/components/Table";
import Table from "app/components/Table";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import EditPropertyCategory from "./CreateUpdate";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Button } from "@mui/material";
import { useLayout } from "_metronic/layout/core";
import { useEffect } from "react"
const columns = (iconClickHandler: any) => [
  {
    name: "Property Name",
    key: "propertyCategory",
    numaric: false,
    isSortable: true,
    renderContent: (value: any) => capitalizeFirstLetter(value),
  },
  {
    name: "Action",
    key: "action",
    component: (row: any) => (
      <>
        {/* {IconRender(InfoOutlinedIcon, iconClickHandler)} */}
        {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "EDIT")}
        {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, "delete")}
      </>
    ),
  },
];

export function Details() {
  const { getAll, create, update, delete: deleteUrl } = APIS.propertyCategories;
  const {
    data,
    loading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    open,
    setOpen,
    modalMode,
    updateFunction,
    createFunction,
    current,
    iconClickHandler,
    setCurrent,
  } = useFetchData({ getAll, create, update, deleteUrl });
  const { setClickButtonHandler } = useLayout();

  useEffect(() => {
    setClickButtonHandler(() => () => {
      setOpen(true);
      setCurrent({});
    });
  }, []);
  
  return (
    <>
      {/* <Button
        sx={{ float: "right" }}
        variant="contained"
        color="info"
        onClick={() => {
          setOpen(true);
          setCurrent({});
        }}
      >
        Add Location
      </Button> */}
      <Table
        columns={columns(iconClickHandler('/PropertyCategory/edit'))}
        data={data}
        loading={loading}
        onRowClick={(row: any, type: any) => iconClickHandler('/PropertyCategory/edit')(row, type )}
        isPaginationRequired
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        total={count}
      />
      <EditPropertyCategory
        setOpen={setOpen}
        open={open}
        loading={loading}
        update={modalMode === "EDIT" ? updateFunction : createFunction}
        type={modalMode}
        data={current}
      />
    </>
  );
}
