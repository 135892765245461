import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { VerifyEmail } from '../redux/AuthCRUD';

interface RouteParams {
    token: string;
}

const VerificationComponent: React.FC = () => {
    const history = useHistory();
    const { token } = useParams<RouteParams>();

    useEffect(() => {
        if (token) {
            VerifyEmail(token)
                .then((response: any) => {
                    // Log the response for debugging
                    // console.log('Verification response:', response);

                    // Check for success status
                    if (response.status === 200) {
                        Swal.fire({
                            title: 'Success!',
                            text: 'Email verified successfully.',
                            icon: 'success',
                            confirmButtonText: 'LOGIN'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                history.push('/profile'); // Redirect after confirmation
                            }
                        });
                    } else {
                        // If success is not true but there's no error thrown
                        Swal.fire('Error!', 'Verification failed.', 'error');
                    }
                })
                .catch((error: any) => {
                    // Handle HTTP errors or other exceptions
                    const errorMessage = error.response?.data?.message || "Verification failed due to an unexpected error.";
                    console.error("Verification error:", errorMessage);
                    Swal.fire('Error!', errorMessage, 'error');
                });
        }

    }, [history, token]);

    return (
        <>
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <div>Verifying...</div>
            </div>
        </>
    );
};

export default VerificationComponent;
