import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
    PageLink,
    PageTitle,
    usePageData,
} from "../../../_metronic/layout/core";
import { Overview } from "./components/Overview";
import { PropertyHeader } from "./PropertyHeader";
import { Settings } from "./components/settings/Settings";
import Table, { capitalizeFirstLetter, IconRender } from "../Table";
import { deleteProperty, getAllProperties } from "./redux/PropertyCrud";
import * as AuthRedux from "../auth/redux/AuthRedux";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { updateProperty, createProperty } from "./redux/PropertyCrud";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "_metronic/helpers/AssetHelpers";
import CreateConsultantProperty from "./components/CreateConsultantProperty";

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
    },
];
const columns = (iconClickHandler: any) => [
    {
        name: "Name",
        key: "title",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => (
            <div className="overflow-ellipsis" title={value}>
                {value.length > 15 ? value.substring(0, 20) + "..." : value}
            </div>
        ),
    },
    // {
    //   name: "Location",
    //   key: "community",
    //   numaric: false,
    //   isSortable: true,
    // },
    // {
    //   name: "Lat & Lng",
    //   key: "location",
    //   numaric: false,
    //   isSortable: true,
    //   renderContent: (value: any) => `${value.lat} , ${value.lng}`,
    // },
    {
        name: "Category",
        key: "category",
        numaric: false,
        isSortable: true,
    },
    {
        name: "Sub Category",
        key: "subCategory",
        numaric: false,
        isSortable: true,
        // renderContent: (value: any) => capitalizeFirstLetter(`For ${value || "-"}`),
    },
    {
        name: "Price",
        key: "price",
        numaric: true,
        isSortable: true,
        renderContent: (value: any) =>
            new Intl.NumberFormat().format(parseInt(value || 0)),
    },

    // {
    //   name: "State",
    //   key: "location",
    //   numaric: false,
    //   isSortable: true,
    //   renderContent: (value: any) =>
    //     capitalizeFirstLetter(`${value?.state || "-"}`),
    // },
    // {
    //   name: "Zip code",
    //   key: "location",
    //   numaric: false,
    //   isSortable: true,
    //   renderContent: (value: any) =>
    //     capitalizeFirstLetter(`${value?.zipCode || "-"}`),
    // },
    // {
    //   name: "Category",
    //   key: "propertyCategory",
    //   numaric: false,
    //   isSortable: true,
    //   renderContent: (value: any) => capitalizeFirstLetter(`For ${value || "-"}`),
    // },

    // {
    //   name: "Purpose",
    //   key: "purpose",
    //   numaric: false,
    //   isSortable: true,
    //   // renderContent: (value: any) => capitalizeFirstLetter(`For ${value || "-"}`),
    // },
    // { name: "Type", key: "propertyType", numaric: false, isSortable: true },
    // { name: "Area Type", key: "areaType", numaric: false, isSortable: true },
    // { name: "Area", key: "area", numaric: false, isSortable: true },

    // { name: "Beds", key: "bedrooms", numaric: false, isSortable: true },
    // { name: "Baths", key: "bathrooms", numaric: false, isSortable: true },

    {
        name: "Listed",
        key: "isListed",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => (value ? "Listed" : "Not Listed"),
    },
    {
        name: "Action",
        key: "action",
        component: (row: any) => (
            <>
                {/* {IconRender(InfoOutlinedIcon, iconClickHandler)} */}
                {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "edit")}
                {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row, "delete")}
            </>
        ),
    },
];

const AllPropertiesPage: React.FC = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState({});

    const [currentListing, setCurrentListing]: any = useState(0);
    const listing: any = useSelector<RootState>(
        ({ auth }) => auth.listing,
        shallowEqual
    );
    const [myProperties, setMyProperties] = useState(listing);
    const [accessToken, setAccessToken]: any = useState("");
    const [modalMode, setModalMode]: any = useState("edit");

    const getToken = async () => {
        let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
        accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
        setAccessToken(accessToken);
    };

    const filtersChanged = (filters: any) => {
        setFilter(filters);
        setLoading(true);
        setTimeout(() => {
            getProperties(filters);
        }, 1000);
    };

    const getProperties = (filters: any) => {
        getAllProperties(accessToken, filters)
            .then(({ data }) => {
                dispatch(AuthRedux.actions.setMyListings(data.property));
                setCount(data.count);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        getToken();
        setTimeout(() => {
            getProperties(filter);
        }, 1000);
    }, []);

    useEffect(() => {
        setMyProperties(listing);
    }, [listing]);

    const onClickCreate = () => {
        setOpen(true);
        setCurrentListing([]);
        setModalMode("create");
    };

    useEffect(() => {
        document
            .getElementById("create-button")
            ?.addEventListener("click", onClickCreate);
    }, []);
    const iconClickHandler = (row: any, type: any) => {
        if (type === "edit") {
            setModalMode("edit");
            setCurrentListing(row);
            setOpen(true);
        } else if (type === "delete") {
            Swal.fire({
                title: "",
                text: "Are you sure you want to delete this Property",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((status) => {
                if (status.isConfirmed) {
                    setLoading(true);
                    deleteProperty(row._id, accessToken)
                        .then(({ data, status }) => {
                            let text = "";
                            let icon: any = "error";
                            if (status === 200) {
                                text = "Successfully Deleted";
                                icon = "success";
                            } else {
                                text = data.message;
                                icon = "error";
                            }
                            Swal.fire({
                                title: "Deleting Property",
                                text: text,
                                icon: icon,
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes",
                            });
                            dispatch(AuthRedux.actions.setMyListings(data.property));
                            setCount(data.count);
                        })
                        .catch(() => { })
                        .finally(() => setLoading(false));
                }
            });
        }
    };

    const createPropertyFunction = (values: any) => {
        console.log(values, "values in property page");
        setLoading(true);
        setOpen(false);
        Swal.fire({
            title: "Property Listing?",
            text: "Do you want to List this Property for sale?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No Don't List",
            confirmButtonText: "Yes List this",
        })
            .then((value: any) => {
                if (value.isConfirmed) {
                    console.log(values);
                    values = {
                        ...values,
                        isListed: true,
                        cityName: values.city,
                        propertyLocation: values.location,
                    };
                    console.log(values);
                }
                console.log(values);
                createProperty(values, accessToken)
                    .then(({ data }) => {
                        dispatch(AuthRedux.actions.setMyListings(data.property));
                        setCount(data.count);
                    })
                    .catch(() => { })
                    .finally(() => setLoading(false));
            })
            .finally(() => { });
    };

    const updatePropertyFunction = (values: any) => {
        setLoading(true);
        updateProperty(values, currentListing?._id, accessToken)
            .then(({ data }) => {
                dispatch(AuthRedux.actions.setMyListings(data.property));
                setCount(data.count);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );

    const filteredData = myProperties.filter((row: any) => row?.isDeleted === false && row?.ownerType !== "Imlaak");

    return (
        <>
            <PageTitle>Other Owner Properties</PageTitle>
            {user?.role === "employee" || user?.role === "admin" ? (
                <div className="pb-5">
                    {/* <PropertyHeader filter={filter} setFilter={filtersChanged} /> */}
                    <Table
                        columns={columns(iconClickHandler)}
                        data={filteredData}
                        loading={loading}
                        onRowClick={(row: any, type: any) => iconClickHandler(row, type)}
                        isPaginationRequired
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        total={count}
                    />
                    <CreateConsultantProperty
                        setOpen={setOpen}
                        open={open}
                        loading={loading}
                        updateProperty={
                            modalMode === "edit"
                                ? updatePropertyFunction
                                : createPropertyFunction
                        }
                        modalMode={modalMode}
                        property={currentListing}
                    />
                </div>
            ) : (
                <div className="card-body p-0 ">
                    {/* begin::Chart */}
                    <div className="alert alert-warning p-5 mt-5 mx-10" role="alert">
                        This is a warning alert{" "}
                        <h3 className="text-danger">
                            Your account is not approved you cannot Post Property!!.
                        </h3>
                    </div>
                    {/* end::Chart */}
                    {/* begin::Stats */}
                    <div className="card-p mt-10 position-relative d-flex justify-content-center">
                        <img src={toAbsoluteUrl("/media/illustrations/sketchy-1/57.svg")} />

                        {/* end::Row */}
                    </div>
                    <span className="d-flex justify-content-center fw-bold fs-4 mb-1 ">
                        No Record Found
                    </span>
                    {/* <span className='d-flex justify-content-center fw-bold fs-4 text-muted mb-5 '>Submit your application to Imlaak support for further assistance</span>  */}
                    {/* end::Stats */}
                </div>
            )}
        </>
    );
};

export default AllPropertiesPage;
