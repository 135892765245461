/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {
  StatisticsWidget1,
  StatisticsWidget2,
  StatisticsWidget3,
  StatisticsWidget4,
  StatisticsWidget5,
  StatisticsWidget6,
} from '../../../../_metronic/partials/widgets'
type Props = {
  className: string
  items?: number
}

const ListsWidget4: React.FC<Props> = ({className, items = 6}) => {
  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder text-dark'>Rent Collected</span>
          
        </h3>
       
      </div>
      {/* end::Header */}
      {/* begin::Body */}
    
      
      
          <span className=' mx-7 mb-xl-4  mt-10 fw-bold fs-25'>Outstandingin March 2022 <b>PRK 0.00 </b></span>
          <span className=' mx-7 mb-xl-4  mt-2 fw-bold fs-25'>Total Due <b>PRK 0.00 </b> </span>
   
          <span className='text-muted mx-7 mb-xl-4  mt-1 fw-bold fs-25'> <b>PRK 0.00 </b> Collected</span>
          <div className="separator my-2"></div>


          <span className=' mx-7 mb-xl-4  mt-10 fw-bold fs-25'>Outstandingin March 2022 <b>PRK 0.00 </b></span>
          <span className=' mx-7 mb-xl-4  mt-2 fw-bold fs-25'>Total Due <b>PRK 0.00 </b> </span>
   
          <span className='text-muted mx-7 mb-xl-4  mt-1 fw-bold fs-25'> <b>PRK 0.00 </b> Collected</span>
          

          
     
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget4}
