import { useEffect } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Modal from "../../Modal";
import CustomInput from "../../Inputs";
import { useFormik } from "formik";
import * as yup from "yup";
import Loader from "../../Loader";
import { useImageMethod } from "app/hooks/useImageUpload";
import { API } from "app/utils/config";
import MultipleSelectCheckmarks from "app/components/Inputs/DropdownWithCheckBox";
import {
  propertylanguagesOptions,
  propertyspecialtiesOptions,
} from "app/components/DropDownOptions/DropDownOptions";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const validationSchema = yup.object({
  tableName: yup.string().required("Consultant's name is required"),
  email: yup
    .string()
    .required("Consultant's email is required")
    .email("Invalid email address"),
  // password: yup
  //   .string()
  //   .required("Consultant's password is required")
  //   .min(8, "Password must be at least 8 characters long"),
  contact: yup.string().required("Consultant's contact is required"),
  // address: yup.string().required("Consultant's address is required"),
  languages: yup
    .array()
    .required("Languages are required")
    .min(1, "At least one language must be selected"),
  experience: yup
    .number()
    .typeError("Must be a number")
    .positive("Must be greater than 0")
    .required("Experience is required"),
  specialties: yup
    .array()
    .required("specialties are required")
    .min(1, "At least one speciality must be selected"),
  description: yup
    .string()
    .required("Description is required")
    .max(1000, "Description must be at most 500 characters long"),

});

type EditProps = {
  open: any;
  setOpen: any;
  data?: any;
  update?: any;
  loading?: boolean;
  type: "ADD" | "EDIT";
};

const Edit = ({
  open,
  setOpen,
  data,
  update,
  loading,
  type = "EDIT",
}: EditProps) => {
  const formik = useFormik({
    initialValues: {
      tableName: data?.tableName || "",
      email: data?.email || "",
      password: data?.password || "",
      contact: data?.contact || "",
      address: data?.address || "",
      userImage: data?.userImage || "",
      description: data?.description || "",
      specialities: data?.specialities || "",
      specialties: data?.specialties || [],
      languages: data?.languages || [],
      experience: data?.experience || "",
      propertiesfor: data?.propertiesfor || "",
      role: 'consultant',
      status: 'approved',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      update(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (Object.keys(data).length > 0) formik.setValues({ ...data });
  }, [data, open]);
  //start Image upload files
  const { uploadImage, images, setImages, files, setFiles } = useImageMethod();
  const onSubmitImage = async () => {
    let formData = new FormData(); //formdata object
    formData.append("image", files[0]);
    // formData.append('images', files)
    const res = await uploadImage({ params: formData });
    if (res?.data?.succes) {
      formik.setFieldValue("userImage", res?.data?.image[0]);
      setImages([]);
    }
  };

  //End Image upload files

  return (
    <Modal
      setOpen={() => setOpen(!open)}
      open={open}
      title={` ${Object.keys(data).length
        ? "Update Table"
        : "Add New Table"
        } `}
      cancelButtonTitle="Cancel"
      acceptButtonVarient="contained"
      acceptButtonTitle={` ${Object.keys(data).length
        ? "Update Table"
        : "Add New Table"
        } `}
      handleAccept={() => formik.handleSubmit()}
      handleClose={() => formik.resetForm()}
      loading={loading}
      isActionable={true}
    >
      {loading ? (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "30%",
          }}
        >
          <Loader top='"60%' left='"50%' />
        </Box>
      ) : null}
      <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <CustomInput
              id="tableName"
              label="Table Title"
              placeholder="Type here..."
              value={formik.values.tableName}
              onChangeHandler={formik.handleChange}
              isError={formik.touched?.tableName && Boolean(formik.errors.tableName)}
              helperText={formik.touched.tableName ? formik.errors.tableName : ""}
            />
          </Grid>
        </Grid>

      </Box>
    </Modal>
  );
};

export default Edit;
