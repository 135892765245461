import React, { FC } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Link } from "react-router-dom";
import { ErrorMessage, Field, FieldArray, useField } from "formik";

const Step5: FC = () => {
  return (
    <>
      {/* {field.value === 'I m Property Consultant' ? ( */}

      <div className="w-100">
        {/* <div className="pb-10 pb-lg-15 mt-5">
          <h5 className="fw-bolder text-dark">
            {" "}
            {field.value.toUpperCase()} , To become a successful real estate
            consultant, it is important to complete your profile by selecting
            and filling in the basic information. Don't miss this opportunity to
            showcase your expertise and attract more clients..
          </h5>
        </div> */}

        <div className="w-100">
          <div className="pb-8 pb-lg-10">
            <h2 className="fw-bolder text-dark">Your Are Done!</h2>

            <div className="text-gray-400 fw-bold fs-6">
              If you need more info, please go to the
              <Link to="/auth/login" className="link-primary fw-bolder">
                {" "}
                Dashboard
              </Link>
              .
            </div>
          </div>

          <div className="mb-0">
            <div className="fs-6 text-gray-600 mb-5">
              Your account setup is almost complete. If you are an authorized Landlord, <strong>Imlaak</strong>  will approve your account within 24 hours. Thank you
            </div>

            <div className="notice d-flexfsd bg-light-warning rounded border-warning border border-dashed p-6 d-none">
              <KTSVG
                path="/media/icons/duotune/general/gen044.svg"
                className="svg-icon-2tx svg-icon-warning me-4"
              />
              <div className="d-flex flex-stack flex-grow-1">
                <div className="fw-bold">
                  <h4 className="text-gray-800 fw-bolder">
                    We need your attention!
                  </h4>
                  <div className="fs-6 text-gray-600">
                    To start using great tools, please, please
                    <a href="/dashboard" className="fw-bolder">
                      {" "}
                      Create Team Platform
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Step5 };
