import { useEffect, useState } from "react";
import { PageTitle, useLayout } from "_metronic/layout/core";
import { RootState } from "setup";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { API as apiHost } from "app/utils/config";
import { getConsultantDetailsById } from "../auth/redux/AuthCRUD";
import Loader from "../Loader";
import { Box, Grid } from "@mui/material";

interface Consultant {
    userImage: string;
    fname: string;
    email: string;
    contact: string;
    address: string;
    city: string;
    province: string;
    country: string;
    specialties: string[];
    languages: string[];
    experience: number;
    description: string;
}
interface ConsultantDetails {
    consultant?: Consultant | null;
    propertiesCount: number;
    landlordCount: number;
    properties?: [] | null;
}


interface RouteParams {
    id: string;
}
export function ConsultantDetails() {
    const [loading, setLoading] = useState(true);

    let { id } = useParams<RouteParams>();
    const [consultantDetails, setConsultantDetails] = useState<ConsultantDetails>({
        consultant: null,
        propertiesCount: 0,
        landlordCount: 0,
        properties: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getConsultantDetailsById(id);
                setConsultantDetails(response);
                setLoading(false)
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [id]);

    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );

    return (
        <>
            <PageTitle>Consultant Details</PageTitle>
            <div className="pb-5">
                {loading ? (
                    <Box
                        sx={{
                            height: "200px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Loader top='"60%' left='"50%' />
                    </Box>
                ) : (
                    < div className="card">
                        <div className="py-5">
                            <div className="d-flex justify-content-center"                            >
                                <div className="overflow-hidden rounded-circle" style={{ width: "15vw", height: '15vw', border: '2px solid #263c67' }}>
                                    <img src={`${apiHost}/uploads/${consultantDetails?.consultant?.userImage}`} style={{ width: "15vw", height: '15vw', objectFit: 'fill' }} />
                                </div>
                            </div>
                            <div className="text-center">
                                <div className="text-center py-3">
                                    <h2 className="fw-bolder text-capitalize pt-2" style={{ color: '#263c67' }}>{consultantDetails?.consultant?.fname}</h2>
                                </div>
                                <div className="d-flex justify-content-center pb-2">
                                    <h2 className="fw-bold text-capitalize py-2 px-5 mx-3" style={{ color: '#fff', background: '#263c67', borderRadius: '5px', fontSize: '16px', letterSpacing: '1px' }}>{consultantDetails?.propertiesCount} Properties</h2>
                                    <h2 className="fw-bold text-capitalize py-2 px-5 mx-3" style={{ color: '#fff', background: '#263c67', borderRadius: '5px', fontSize: '16px', letterSpacing: '1px' }}>{consultantDetails?.landlordCount} Landlords</h2>
                                </div>
                                <div className="">
                                    <p style={{ fontWeight: '400', borderRadius: '5px', fontSize: '14px', letterSpacing: '1px' }}>{consultantDetails?.consultant?.description}</p>
                                </div>
                                <div className="d-flex flex-column">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-end pr-5 py-1" style={{ paddingRight: '6rem' }}><span className="fw-bolder">Email :</span></div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-start py-1">{consultantDetails?.consultant?.email}</div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-end pr-5 py-1" style={{ paddingRight: '6rem' }}><span className="fw-bolder">Contact : </span></div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-start py-1 fw-normal">+{consultantDetails?.consultant?.contact}</div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-end pr-5 py-1" style={{ paddingRight: '6rem' }}><span className="fw-bolder">Address : </span></div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-start py-1">{consultantDetails?.consultant?.address}</div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-end pr-5 py-1" style={{ paddingRight: '6rem' }}><span className="fw-bolder">Languages : </span>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-start py-1">{consultantDetails?.consultant?.languages.join(', ')}</div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-end pr-5 py-1" style={{ paddingRight: '6rem' }}><span className="fw-bolder">Experience : </span></div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-start py-1">{consultantDetails?.consultant?.experience} Years</div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-end pr-5 py-1" style={{ paddingRight: '6rem' }}><span className="fw-bolder">Specialties : </span></div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex justify-content-start py-1">{consultantDetails?.consultant?.specialties.join(', ')}</div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>

                        </div>
                    </div >
                )
                }
            </div >
        </>
    );
}
