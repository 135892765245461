/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PageTitle } from "../../../_metronic/layout/core";
import {
    StatisticsWidget5,
    StatisticsWidgetAdmin,
    AssetsCarts,
    MixedWidget11,
    ChartsWidget1,
} from "../../../_metronic/partials/widgets";
import {
    getMyEvaluations,
    getMyProperties,
    requestEvaluation as requestEvaluationApi,
} from "../../components/property/redux/PropertyCrud";
import * as AuthRedux from "../../components/auth/redux/AuthRedux";
import { RootState } from "../../../setup";
import { Link, useHistory } from "react-router-dom";
import DashboardTable from "./DashboardTable";
import { EngageWidget10 } from "_metronic/partials/widgets/_new/EngageWidget10";
import { KTCardBody } from "_metronic/helpers/components/KTCardBody";
// import UsersTable from "app/components/Admin/UsersTable";
import { toAbsoluteUrl } from "_metronic/helpers";
import { StatisticsWidget8 } from "_metronic/partials/widgets/statistics/StatisticsWidget8";
import { Charts } from "app/components/widgets/components/Charts";
import { StatisticsWidget9 } from "_metronic/partials/widgets/statistics/StatisticsWidget9";
import { getAllUsers } from "app/components/Admin/AllUsers/Crud";

const TotalListingsPage = ({ data }: any) => (
    <>
        <div className="row g-5 g-xl-8">
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <Link to="/totalUsers">
                    <StatisticsWidgetAdmin
                        className="card-xl-stretch mb-5 mb-xl-8 text-dark "
                        svgIcon="/media/icons/duotune/communication/com006.svg"
                        color="white"
                        iconColor="dark"
                        title={data?.data?.length ?? 0}
                        description="Total Listings"
                    />
                </Link>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <Link to="/AllAgencies">
                    <StatisticsWidgetAdmin
                        className="h-md-40 mb-5 mb-xl-8 text-white"
                        svgIcon="/media/icons/duotune/graphs/gra007.svg"
                        color="success"
                        iconColor="white"
                        title={
                            data?.data?.filter(
                                (item: { role: string }) => item.role === "landlord"
                            ).length ?? 0
                        }
                        description="Verified Listings"
                    />
                </Link>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <Link to="/pendingUsers">
                    <StatisticsWidgetAdmin
                        className="card-xl-stretch mb-5 fw-bold mb-xl-8 text-white"
                        svgIcon="/media/icons/duotune/general/gen009.svg"
                        color="warning"
                        iconColor="white"
                        title={
                            data?.data?.filter(
                                (item: { status: string }) => item.status === "pending"
                            ).length ?? 0
                        }
                        description="Unverified Listings"
                    />
                </Link>
            </div>
        </div>
        {/* begin::Row */}
        <div className="row g-2 g-xl-4 ">
            {/* begin::Col */}
            <div className="col-xxl-8">
                {/* <Charts /> */}
                <ChartsWidget1 className="" />
                {/* <EngageWidget10 className='h-md-39' /> */}
            </div>
            {/* end::Col */}

            {/* {console.log(data, "data")} */}
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-2 mb-md-5 mb-xl-10">
                <Link to="/AllAgencies">
                    <StatisticsWidgetAdmin
                        className="h-md-40 mb-5 mb-xl-8 text-white"
                        svgIcon="/media/icons/duotune/graphs/gra009.svg"
                        color="info"
                        iconColor="white"
                        title={
                            data?.data?.filter(
                                (item: { role: string }) => item.role === "landlord"
                            ).length ?? 0
                        }
                        description="Residential"
                    />
                </Link>
                <Link to="/AllAgents">
                    <StatisticsWidgetAdmin
                        className="h-md-40  mb-xl-8 text-white"
                        svgIcon="/media/icons/duotune/graphs/gra010.svg"
                        color="dark"
                        iconColor="white"
                        title={
                            data?.data?.filter(
                                (item: { role: string }) => item.role === "agent"
                            ).length ?? 0
                        }
                        description="Commercial"
                    />
                </Link>
            </div>
            {/* end::Col */}
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-2 mb-md-5 mb-xl-10">
                <Link to="/AllInvestors">
                    <StatisticsWidgetAdmin
                        className="h-md-40 mb-5 mb-xl-8 text-dark"
                        svgIcon="/media/icons/duotune/graphs/gra007.svg"
                        color="white"
                        iconColor="dark"
                        title={
                            data?.data?.filter(
                                (item: { role: string }) => item.role === "agency"
                            ).length ?? 0
                        }
                        description="Rented"
                    />
                </Link>
                <Link to="/AllBrokers">
                    <StatisticsWidgetAdmin
                        className="h-md-40  mb-xl-8 text-white"
                        svgIcon="/media/icons/duotune/graphs/gra007.svg"
                        color="danger"
                        iconColor="white"
                        title={
                            data?.data?.filter(
                                (item: { role: string }) => item.role === "admin"
                            ).length ?? 0
                        }
                        description="Sale"
                    />
                </Link>
            </div>
            {/* end::Col */}
        </div>
        {/* end::Row */}

        {/* <div className='row g-5 g-xl-8'>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5">
        <Link to="/crafted/pages/area/documents">
          <StatisticsWidget5
            className="h-md-40 mb-5 mb-xl-10 text-white"
            svgIcon="/media/icons/duotune/communication/com006.svg"
            color="info"
            iconColor="white"
            title={data?.count}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            bgcolor=''
            description="Consultants"
          />
        </Link>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5">
        <Link to="/crafted/pages/area/documents">
          <StatisticsWidget5
            className="h-md-40 mb-5 mb-xl-10 text-white"
            svgIcon="/media/icons/duotune/graphs/gra007.svg"
            color="warning"
            iconColor="white"
            title={
              data?.data?.filter((item: any) => item.status === "pending")
                ?.length ?? 0
            }
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            bgcolor=''
            description="Agents"
          />
        </Link>
      </div>

      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5">
        <Link to="/apps/consumer-information/users">
          <StatisticsWidget5
            className="h-md-40 mb-5 mb-xl-10 text-white"
            svgIcon="/media/icons/duotune/communication/com005.svg"
            color="success"
            iconColor="white"
            title={
              data?.data?.filter((item: any) => item.status === "approved")
                ?.length ?? 0
            }
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            bgcolor=''
            description="Investors"
          />
        </Link>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5">
        <Link to="/crafted/pages/area/connections">
          <StatisticsWidget5
            className="h-md-40 mb-5 mb-xl-8 text-white"
            svgIcon="/media/icons/duotune/general/gen008.svg"
            color=""
            iconColor="white"
            title={
              data?.data?.filter(
                (item: any) =>
                  item.status === "reject" || item.status === "suspended"
              )?.length ?? 0
            }
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            bgcolor='#bf3939'
            description="Brokers"
          />
        </Link>
      </div>
      
      <div className="col-xxl-6">
        <EngageWidget10 className="h-md-39" />
      </div>
    </div> */}

        {/* <DashboardTable /> */}
    </>
);

const TotalListings: FC = () => {
    const intl = useIntl();
    const [accessToken, setAccessToken]: any = useState("");
    const dispatch = useDispatch();
    const user: any =
        useSelector<RootState>(({ auth }) => auth.user, shallowEqual) ?? {};
    const [loading, setLoading] = useState(false);
    const getToken = async () => {
        let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
        accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");

        setAccessToken(accessToken);
    };

    const [filter, setFilter] = useState({});
    const [agencies, setAllAgencies] = useState([]);
    const [count, setCount] = useState(0);

    const filtersChanged = (filters: any) => {
        setFilter(filters);
        setLoading(true);
        setTimeout(() => {
            getAgencies(filters);
        }, 1000);
    };

    const getAgencies = (filters: any) => {
        getAllUsers(accessToken, filters)
            .then(({ data }) => {
                setAllAgencies(data.data);
                setAgenciesList(data);

                setCount(data?.count);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        setLoading(true);
        getToken();
        setTimeout(() => {
            getAgencies(filter);
        }, 1000);
    }, []);

    useEffect(() => {
        setLoading(true);
        getToken();
    }, []);
    const history = useHistory();

    const properties: any = useSelector<RootState>(
        ({ auth }) => auth.listing,
        shallowEqual
    );
    useEffect(() => {
        if (user?.role === "admin") {
            history.push("/totalListings");
        } else if (user?.role === "landloard") {
            history.push("/landloard/dashboard");
        } else {
            history.push("/dashboard");
        }
    }, [user]);

    const [agenciesList, setAgenciesList] = useState({});

    return (
        <>
            <PageTitle breadcrumbs={[]}> Total Listings </PageTitle>
            <TotalListingsPage data={agenciesList} total={count} />
            {/* <KTCardBody className="py-4 bg-white rounded">
        <UsersTable setAgenciesList={setAgenciesList} />
      </KTCardBody> */}
        </>
    );
};

export { TotalListings };
