import React, { useEffect, useRef, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { IProfileDetails, profileDetailsInitValues as initialValues } from '../SettingsModel'
import * as Yup from 'yup'
import { RootState } from '../../../../../../setup'
import { UserModel } from '../../../../../../app/components/auth/models/UserModel'
import { ProfileUpdate } from 'app/components/ProfilePage/components/ProfileUpdateStepper'
import { Formik, Form, FormikValues, useFormik } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import Swal from "sweetalert2";
import { CircularProgress } from '@mui/material'
import { accessToken } from 'mapbox-gl'


export interface IUpdateAccount {
  accountType: string
  manage: string
  fname: string
  lname: string
  organisation: string
  accountPlan: string
  // businessDescription: string
  // listing: any
  contact: string
}
const createAccountSchemas = Yup.object().shape({
  fName: Yup.string().required('First name is required'),
  lName: Yup.string().required('Last name is required'),
  company: Yup.string().required('Company name is required'),
  contactPhone: Yup.string().required('Contact phone is required'),
  companySite: Yup.string().required('Company site is required'),
  country: Yup.string().required('Country is required'),
  language: Yup.string().required('Language is required'),
  timeZone: Yup.string().required('Time zone is required'),
  currency: Yup.string().required('Currency is required'),
})

const inits = (auth: any) => {
  // console.log(auth.listing?.[0]?.listing, 'auth')
  return {
    accountType: auth.user?.accountType || '',
    manage: auth.user?.manage || '',
    fname: auth.user?.fname || '',
    accountPlan: auth.user?.accountPlan || '',
    lname: auth.user?.lname || '',
    organisation: auth.user?.organisation || '',
    // businessDescription: '',
    // listing: auth?.property || {},
    contact: auth.user?.contact || '',
  }
}



const ProfileDetails: React.FC = () => {
  const auth: any = useSelector<RootState>(({ auth }) => auth, shallowEqual)
  // const dispatch = useDispatch();

  const stepperRef = useRef<HTMLDivElement | null>(null)
  // const [fieldChanger, setFieldChanger]: any = useState(() => { })
  let fieldChanger: any = () => { }
  // const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<IUpdateAccount>(inits(auth))
  // const [loading, setLoading] = useState(false)

  const modal = (title: string, text: string, icon: any) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Okay",
    })
  }
  const accessToken = localStorage.getItem('accessToken')

  const [data, setData] = useState<IProfileDetails>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: createAccountSchemas,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        values.communications.email = data.communications.email
        values.communications.phone = data.communications.phone
        values.allowMarketing = data.allowMarketing
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Update</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <ProfileUpdate />
      </div>
    </div>
  )
}

export { ProfileDetails }
function fieldChanger(arg0: string, listing: any) {
  throw new Error('Function not implemented.')
}

