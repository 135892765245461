// hocs/RoleBasedRoute.tsx
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'setup';

interface RoleBasedRouteProps {
    component: React.ComponentType<any>;
    path: string;
    exact?: boolean;
    requiredRole: string;
}

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({ component: Component, requiredRole, ...rest }) => {
    const user = useSelector<RootState, RootState['auth']['user']>((state) => state.auth.user);

    const hasRequiredRole = user && user.role === requiredRole;

    return (
        <Route
            {...rest}
            render={(props) =>
                hasRequiredRole ? <Component {...props} /> : <Redirect to="/dashboard" /> // Redirect to a default page if role doesn't match
            }
        />
    );
};

export default RoleBasedRoute;
