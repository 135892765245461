import { useEffect } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import Modal from '../../Modal'
import CustomInput from '../../Inputs'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Loader from '../../Loader'
import { useImageMethod } from "app/hooks/useImageUpload";
import { API as apiHost } from "app/utils/config";
const validationSchema = yup.object({
  propertyCategory: yup.string().required('Property Category is required'),
})



type EditProps = {
  open: any
  setOpen: any
  data?: any
  update?: any
  loading?: boolean
  type: 'ADD' | 'EDIT'
}


const Edit = ({
  open,
  setOpen,
  data,
  update,
  loading,
  type = 'EDIT'
}: EditProps) => {

  const formik = useFormik({
    initialValues: {
      propertyCategory: data?.propertyCategory || '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      update(values)
      formik.resetForm()
    },
  })



  useEffect(() => {
    if (Object.keys(data).length > 0) formik.setValues({ ...data })
  }, [data, open])



  return (
    <Modal
      setOpen={() => setOpen(!open)}
      open={open}
      title={` ${Object.keys(data).length ? 'Edit Property Category # ' + data?._id : 'Add Property Category'} `}
      cancelButtonTitle="Cancel"
      acceptButtonVarient="contained"
      acceptButtonTitle="Add"
      handleAccept={() => formik.handleSubmit()}
      handleClose={() => formik.resetForm()}
      loading={loading}
      isActionable={true}
    >
      {loading ?
        <Box
          sx={{
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: '50%',
            top: '30%'
          }}
        >
          <Loader top='"60%' left='"50%' />
        </Box>
        : null}
      <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
        <Typography variant='h5' fontWeight={700} >Add The Property Type</Typography>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={12}>
            <CustomInput
              id="propertyCategory"
              label="property Category"
              placeholder="Type here..."
              value={formik.values.propertyCategory}
              onChangeHandler={
                formik.handleChange
              }
              isError={formik.touched?.propertyCategory && Boolean(formik.errors.propertyCategory)}
              helperText={formik.touched.propertyCategory ? formik.errors.propertyCategory : ''}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default Edit
