import axios from 'axios'
import { CREATE_PROPERTY, DELETE_PROPERTY, GET_ALL_PROPERTIES, REQUEST_EVALUATION, UPDATE_PROPERTY, GET_MY_EVALUATION, GET_MY_PROPERTIES, CREATE_INVENTORY, UPDATE_INVENTORY, GET_ALL_INVENTORY, UPDATE_CONSULTANTINVENTORY, DELETE_ConsultantProperty, GET_LANDLORD_INVENTORY, GET_CONSULTANT_INVENTORY, GET_CONSULTANT_PROPERTIES, MARK_AS_SOLD, UPDATE_CONSULTANT_PROPERTY, GET_REQUESTED_EVALUATION, UPDATE_EVALUATION, GET_CONSULTANT_INVENTORY_WITH_EVALUATION, CREATE_UPDATE_EVALUATION_BY_CONSULTANT } from '../../../constants/api'

export function getMyProperties(accessToken: string | null, filter: any) {
  return axios.get(`${GET_ALL_PROPERTIES}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { filters: filter }
    },
  )
}

export function getAllProperties(accessToken: string | null, filter: any) {
  return axios.get(`${GET_ALL_INVENTORY}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { filters: filter }
    },
  )
}

export function getAllPropertiesWithEvaluations(accessToken: string | null) {
  return axios.get(`${GET_CONSULTANT_INVENTORY_WITH_EVALUATION}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    },
  )
}

export function getOwnProperties(accessToken: string | null, filter: any) {
  return axios.get(`${GET_MY_PROPERTIES}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { filters: filter }
    },
  )
}

export function getMyEvaluations(accessToken: string | null) {
  return axios.get(`${GET_MY_EVALUATION}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    },
  )
}

// Get Evaluation Request by Consultant 
export function getRequestedEvaluations(accessToken: string | null) {
  return axios.get(`${GET_REQUESTED_EVALUATION}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    },
  )
}

// Update Evaluation
export function UpdateEvaluationValue(values: any, evaluation: any, accessToken: string | null) {
  return axios.post(UPDATE_EVALUATION, {
    ...values, _id: evaluation,
  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

// CREATE_UPDATE_EVALUATION_BY_CONSULTANT
export function CreateUpdateEvaluationByConsultant(values: any, inventory: any, accessToken: string | null) {
  return axios.post(CREATE_UPDATE_EVALUATION_BY_CONSULTANT, {
    ...values, inventory,
  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function updateProperty(values: any, propertyId: any, accessToken: string | null) {
  // const { city, state, location, sellingPrice, availabilityDate, contactName, contactPhone, contactEmail } = values
  // const alteredValues = {
  //   location: {
  //     ...location,
  //     state,
  //     city,
  //   },
  //   sellingDetails: {
  //     availabilityDate, contactName, contactPhone, contactEmail, sellingPrice
  //   }
  // }
  // delete values?.city
  // delete values?.state
  // delete values?.location
  // delete values?.availabilityDate
  // delete values?.contactName
  // delete values?.contactPhone
  // delete values?.contactEmail
  // delete values?.sellingPrice

  return axios.post(UPDATE_INVENTORY, {
    property: [{
      ...values,
      id: propertyId
    }]

  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function addConsultantPropertyInventory(values: any, accessToken: string | null) {
  return axios.post(UPDATE_CONSULTANTINVENTORY, {
    property: [{
      ...values,
    }]

  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function UpdateConsultantPropertyValues(values: any, propertyId: any, accessToken: string | null) {
  return axios.post(UPDATE_CONSULTANT_PROPERTY, {
    property: [{
      ...values,
      id: propertyId,
    }]

  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function createProperty(values: any, accessToken: string | null) {
  // const { city, state, location } = values
  // const alteredValues = {
  //   location: {
  //     ...location,
  //     state,
  //     city,
  //   }
  // }
  // delete values.city
  // delete values.state
  // delete values.location

  return axios.post(CREATE_INVENTORY, {
    property: [{
      ...values,
      // ...alteredValues,
    }]

  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function deleteProperty(id: any, accessToken: string | null) {
  return axios.post(DELETE_PROPERTY, {
    id
  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function deleteConsultantProperty(id: any, accessToken: string | null) {
  return axios.post(DELETE_ConsultantProperty, {
    id
  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function requestEvaluation(accessToken: string | null, propertyId: any) {
  return axios.post(`${REQUEST_EVALUATION}`,
    {
      property: propertyId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    },
  )
}

// Landlord Property
export function getLandlordProperties(accessToken: string | null, filter: any) {
  return axios.get(`${GET_LANDLORD_INVENTORY}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { filters: filter }
    },
  )
}

// Consultant
export function getConsultantProperties(accessToken: string | null, filter: any) {
  return axios.get(`${GET_CONSULTANT_INVENTORY}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { filters: filter }
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

export function getConsultantInventory(accessToken: string) {
  return axios.get(`${GET_CONSULTANT_PROPERTIES}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    })
    .then(response => response)
    .catch(error => {
      throw error;
    });
}

// Mark as Sold
export function markingPropertySold(values: any, propertyId: any, accessToken: string | null) {
  return axios.post(MARK_AS_SOLD, {
    ...values,
    propertyId: propertyId,
  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}