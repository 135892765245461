import { useEffect, useState, useMemo, useRef } from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    FormControl,
    FormHelperText,
    Chip,
    Paper,
    Skeleton,
    MenuItem,
    Select,
    Typography,
    FormControlLabel,
    useFormControl,
    Switch,
} from "@mui/material";
import Modal from "../../Modal";
import { City, State, Country } from "country-state-city"
import CustomInput from "../../Inputs";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import DropDown, {
    propertyCategoryOptions,
    propertyTypeOptions,
    propertyFloorOptions,
    furnishingStatus,
    ProjectSelecting,
    CompletedStatus,
} from "../../DropDownOptions/DropDownOptions";
import Loader from "../../Loader";
import {
    ImageUploader,
    ImageViewer,
} from "app/components/Inputs/imageSelector";
import { useImageMethod } from "app/hooks/useImageUpload";
import { API } from "app/utils/config";
import MultipleSelectCheckmarks from "app/components/Inputs/DropdownWithCheckBox";
import useFetchData from "app/hooks/useFetchData ";
import { uniqBy } from "lodash";
import { APIS } from "Constants";
import JoditEditor from "jodit-react";
import currency from "currency.js";
import axios from "axios";
import { ProjectNameDropdown } from "app/ServerSideDropdowns/ProjectNameDropdown";
import { AppErrorColor, AppTextColor } from "app/components/theme/Colors";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "setup";
import Selector from "app/components/ProfilePage/components/steps/Selector";
import { amenitiesCategories } from "app/components/amenities/components/DropDownOptions";
import { ProjectNamePropertyDropdown } from "app/ServerSideDropdowns/ProjectNamePropertyDropdown";

interface InventoryItem {
    projectName: string;
    appartmentNo: string;
    floorNo: string;
    category: string;
    subCategory: string;
    title: string;
    _id: string;
    consultantId: string;
    landlordId: string;
}

const validationSchema = yup.object({
    owner: yup.array()
        .of(
            yup.object({
                purchasePrice: yup.string().required("Purchase price is required"),
                purchasingDate: yup.string().required("Purchasing date is required"),
            })
        )
        .required("Fields are required"),
    // category: yup.string().required("Category is required"),
    // subCategory: yup.string().required("SubCategory is required"),
    // floorNo: yup.string().required("Floor No is required"),
    // apartmentNo: yup.number().required("Apartment No is required"),
    // country: yup.string().required("Country is required"),
    // province: yup.string().required("Province is required"),
    // city: yup.string().required("City is required"),
    // zipCode: yup.string().required("Property zip-code is required"),
    // state: yup.string().required("Property state is required"),
    // community: yup.string().required("community is required"),
    // provinceLocation: yup.string().required("ProvinceLocation is required"),
    // cityLocation: yup.string().required("CityLocation is required"),
    // communityLocation: yup.string().required("CommunityLocation is required"),
    // title: yup.string().required("title is required"),
    // description: yup.string().required("description is required"),
    // price: yup.number().required("Purchase price is required"),
    // areaType: yup.string().required("Property Area Type is required"),
    // area: yup.number().required("Property Area is required"),
    // projectType: yup.array().required("Project Type is required"),
    // projectNamemanual: yup.array().required("Project Name Type is required"),
    // images: yup.array().notRequired(),
    // location: yup.object({
    //   lat: yup.number().required().label("listing is required"),
    //   lng: yup.number().required().label("listing is required"),
    // }),
    // amenties: yup.object(),
});

const useStyles = makeStyles(() => ({
    heading: {
        background: "#263c67",
        color: "#fff",
        fontWeight: "700 !important",
    },
    headingStyles: {
        fontSize: "14px !important",
        fontWeight: "700 !important",
    },
    description: {
        padding: "8.5px 14px",
        width: "100%",
        pointerEvents: "none",
        border: "1px solid",
        borderColor: "#093d5c",
        borderRadius: "5px",
        height: "45px",
    },
}));

type EditLandlordPropertyProps = {
    open: any;
    setOpen: any;
    property?: any;
    AddProperty?: any;
    loading?: boolean;
    updateProperty?: any;
    modalMode?: any;
    error?: any;
    label?: string;
    value?: string;
};

const EditLandlordProperty = ({
    open,
    setOpen,
    property,
    AddProperty,
    loading,
    modalMode,
    error,
    label,
    updateProperty,
    value,
}: EditLandlordPropertyProps) => {
    const onClick = (data: any) => {
        formik.setFieldValue("location", data?.lngLat);
    };

    const formik = useFormik({
        initialValues: {
            projectName: property?.projectName || "",
            category: property?.category || "",
            subCategory: property?.subCategory || "",
            furnishingStatus: property?.furnishingStatus || "",
            owner: [
                {
                    landlordName: "",
                    purchasePrice: "",
                    purchasingDate: "",
                    landlord: "",
                },
            ],
            purpose: "for_sale",
            isVerified: false,
            placeId: property?.placeId || "",
            areaName: property?.areaName || "",
            sector: property?.sector || "",
            street: property?.street || "",
            address: property?.address || "",
            country: property?.country || "Pakistan",
            province: property?.province || "Punjab",
            state: property?.state || "Punjab",
            cityName: property?.cityName || "Lahore",
            rentType: property?.rentType || "",
            rentSystem: property?.rentSystem || "",
            projectname: property?.projectname || "",
            title: property?.title || "",
            rentalIncome: property?.rentalIncome || "",
            description: property?.description || "",
            price: property?.price || 0,
            salePrice: property?.salePrice || 0,
            advanceAmount: property?.advanceAmount || 0,
            noOfInstallments: property?.noOfInstallments || 0,
            monthlyInstallments: property?.monthlyInstallments || 0,
            possessionAmount: property?.possessionAmount || 0,
            rentFrequency: property?.rentFrequency || "",
            bedrooms: property?.bedrooms || 0,
            bathrooms: property?.bathrooms || 0,
            floorNo: property?.floorNo || 0,
            appartmentNo: property?.appartmentNo || 0,
            completionStatus: property?.completionStatus || "",
            projectNamemanual: property?.projectNamemanual || "",
            ProjectSelecting: property?.ProjectSelecting || "",
            areaType: property?.areaType || "",
            area: property?.area || 0,
            location: property?.location || {},
            images: property?.images || [],
            amenities: property?.amenties || {},
            consultantId: property?.consultantId || "",
            landlordId: property?.landlordId || "",
            projectType: property?.projectType || [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const { ProjectSelecting, owner, purpose, appartmentNo, floorNo, price, projectName, landlordId, country, province, state, cityName, street, sector, address, placeId, areaName } = values;
            const newValues = { landlordId, ProjectSelecting, id: apartmentId, price, sold: false, purpose, owner, isVerified: false, country, province, state, cityName, street, sector, address, placeId, areaName };

            if (ProjectSelecting === "Registered_project") {
                updateProperty(newValues)
            }
            else {
                updateProperty(values);
            }
        },
    });

    useEffect(() => {
        formik.setFieldValue("projectName", property?.projectName || "");
        formik.setFieldValue("category", property?.category || "");
        formik.setFieldValue("subCategory", property?.subCategory || "");
        formik.setFieldValue("purpose", "for_sale");
        formik.setFieldValue("rentType", property?.rentType || "")
        formik.setFieldValue("rentsystem", property?.rentSystem || "")
        formik.setFieldValue("projectType", property?.projectType || []);
        formik.setFieldValue("amenities", property?.amenities || []);

        formik.setFieldValue("address", property?.address || "");
        formik.setFieldValue("country", property?.country || "");
        formik.setFieldValue("state", property?.location?.state || "");
        formik.setFieldValue("province", property?.province || "");
        formik.setFieldValue("cityName", property?.cityName || "");
        // formik.setFieldValue("community", property?.community || "");
        formik.setFieldValue("title", property?.title || "");
        formik.setFieldValue("description", property?.description || "");
        formik.setFieldValue("location", property?.location);
        formik.setFieldValue("name", property?.name || "" || "");
        formik.setFieldValue("price", property?.price || "");
        formik.setFieldValue("salePrice", property?.salePrice || "");
        formik.setFieldValue("advanceAmount", property?.advanceAmount || "");
        formik.setFieldValue("possessionAmount", property?.possessionAmount || "");
        formik.setFieldValue("noOfInstallments", property?.noOfInstallments || "");
        formik.setFieldValue("area", property?.area || "");
        formik.setFieldValue("areaType", property?.areaType);
        formik.setFieldValue("bedrooms", property?.bedrooms || "");
        formik.setFieldValue("bathrooms", property?.bathrooms || "");
        formik.setFieldValue("floorNo", property?.floorNo || "");
        formik.setFieldValue("appartmentNo", property?.appartmentNo || "");
        formik.setFieldValue("description", property?.description);
        formik.setFieldValue("images", property?.images);
    }, [property]);

    //start Image upload files
    const { uploadImage, images, setImages, files, setFiles } = useImageMethod();
    const onSubmitImage = async (event: any) => {
        let formData = new FormData(); //formdata object
        for (var i = 0; i < files.length; ++i) {
            formData.append(files[i].name, files[i]);
        }
        // formData.append('images', files)
        const res: any = await uploadImage({ params: formData });
        if (res?.data?.succes) {
            formik.setFieldValue("images", res?.data?.image);
            setImages([]);
            setFiles([]);
        }
    };

    useEffect(() => {
        // console.log(images, "images");
    }, [images]);
    // End images upload files

    // Amenities List
    const { getAllAmenities } = APIS.amenities;
    const { amenitiesData } = useFetchData({ getAllAmenities });
    const AmenitiesList: any = useMemo(() => {
        let oneToOneData: any = [];
        if (amenitiesData.length > 0 && formik?.values?.projectType?.length > 0) {
            amenitiesData.forEach((amenityRow: any) => {
                amenityRow.categories.forEach((category: any) => {
                    if (
                        Boolean(
                            formik.values.projectType.find((type: any) => type === category)
                        )
                    ) {
                        amenityRow.list.forEach((amenity: any) => {
                            oneToOneData.push({
                                label: amenity.title,
                                value: amenity.title,
                                description: amenity.description,
                                key: amenity.title,
                            });
                        });
                    }
                });
            });
            return uniqBy(oneToOneData, "label");
        }
        return [];
    }, [amenitiesData, formik.values.projectType]);

    const [amenties, setAmenities]: any = useState({});
    useEffect(() => {
        if (Array.isArray(formik.values.amenities)) {
            const initialAmenities = formik.values.amenities.reduce<Record<string, boolean>>((acc, amenity) => {
                const { label } = amenity;
                acc[label] = true;
                return acc;
            }, {});

            setAmenities(initialAmenities); // Update the state with the initial amenities
        }
    }, [formik.values.amenities]);
    const changedAmenity = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, checked } = event.target;
        setAmenities((currentAmenities: any) => {

            const updatedAmenities = {
                ...currentAmenities,
                [name]: checked,
            };

            const toSetAmenities = AmenitiesList.filter((amenity: any) => updatedAmenities[amenity.label]);
            formik.setFieldValue("amenities", toSetAmenities);

            return updatedAmenities;
        });
    };
    // End of AMenities List

    const submitHandler = (event: any) => {
        event.preventDefault();
    };

    // For Location
    const countryData = Country.getAllCountries();
    const [stateData, setStateData] = useState<any[] | null>();
    const [cityData, setCityData] = useState<any[] | null>();

    const [country, setCountry] = useState<any | null>(countryData[166]);
    const [state, setState] = useState<any | null>();
    const [city, setCity] = useState<any | null>();

    useEffect(() => {
        if (country) {
            const statesOfCountry = State.getStatesOfCountry(country.isoCode);
            setStateData(statesOfCountry);
        }
    }, [country]);

    useEffect(() => {
        if (country && state) {
            const citiesOfState = City.getCitiesOfState(country.isoCode, state.isoCode);
            setCityData(citiesOfState);
        }
    }, [country, state]);

    useEffect(() => {
        if (stateData && stateData.length >= 2) {
            setState(stateData[1]);
        }
    }, [stateData]);

    useEffect(() => {
        if (cityData && cityData.length >= 1) {
            setCity(cityData[0]);
        }
    }, [cityData]);

    // END PROPERTY AMENTIES

    const editor = useRef(null);

    const [installmentAvailable, setInstallmentAvailable] = useState(false);
    const [possession, setPossession] = useState(false);

    const handleSwitchChange = (event: {
        target: { checked: boolean | ((prevState: boolean) => boolean) };
    }) => {
        setInstallmentAvailable(event.target.checked);
    };

    const handleSwitchChange2 = (event: {
        target: { checked: boolean | ((prevState: boolean) => boolean) };
    }) => {
        setPossession(event.target.checked);
    };

    // Set Title
    useEffect(() => {
        let title = "";
        if (formik.values.furnishingStatus === "furnished") {
            title += ` Furnished - `;
        } else if (formik.values.furnishingStatus === "semi-furnished") {
            title += `Semi Furnished `;
        } else {
            title += ` `;
        }

        if (formik.values.subCategory) {
            title += ` ${formik.values.subCategory}`;
        }
        if (formik.values.ProjectSelecting === "Registered_project") {
            title += ` `;
        } else {
            title += ` in ${formik.values.projectName}`;
        }
        if (formik.values.bedrooms) {
            title += ` - ${formik.values.bedrooms} bedroom(s)`;
        }

        formik.setFieldValue("title", title);
    }, [
        property,
        modalMode,
        formik.values.furnishingStatus,
        formik.values.subCategory,
        formik.values.bedrooms,
    ]);

    const classes = useStyles();
    function HeadingText() {
        const { focused } = useFormControl() || {};
        const headingColor = useMemo(() => {
            if (error) {
                return AppErrorColor;
            }

            if (focused && !error) {
                return "#007FFF";
            }

            return AppTextColor;
        }, [focused]);
        return (
            <Typography
                className={classes.headingStyles}
                fontSize={18}
                color={headingColor}
            >
                Floor No
            </Typography>
        );
    }
    function HeadingText2() {
        const { focused } = useFormControl() || {};
        const headingColor = useMemo(() => {
            if (error) {
                return AppErrorColor;
            }

            if (focused && !error) {
                return "#007FFF";
            }

            return AppTextColor;
        }, [focused]);
        return (
            <Typography
                className={classes.headingStyles}
                fontSize={18}
                color={headingColor}
            >
                Apartment No
            </Typography>
        );
    }
    function HeadingText3() {
        const { focused } = useFormControl() || {};
        const headingColor = useMemo(() => {
            if (error) {
                return AppErrorColor;
            }

            if (focused && !error) {
                return "#007FFF";
            }

            return AppTextColor;
        }, [focused]);
        return (
            <Typography
                className={classes.headingStyles}
                fontSize={18}
                color={headingColor}
            >
                Select Landlord
            </Typography>
        );
    }
    const { getAllProject } = APIS.projects;
    const { projectData } = useFetchData({ getAllProject });
    const [projects, setProjects] = useState<any[]>([]);

    useEffect(() => {
        // Filter and map the data within the useEffect
        const mappedProjects = projectData.map((item: any) => ({
            value: item?._id,
            label: item?.projectName,
        }));
        // Update the state with the mapped data
        setProjects(mappedProjects);
    }, [projectData]);

    // For Fetching Values for Selected Project
    const [projectNameValue, setProjectNameValue] = useState({});

    const { getAllInventory } = APIS.inventory;
    const { inventoryData } = useFetchData({ getAllInventory });

    const selectedProjectData: InventoryItem[] = inventoryData?.filter((row: InventoryItem) => row?.projectName === projectNameValue);

    const uniqueFloorNumbers: string[] = Array.from(new Set(selectedProjectData.map((row: InventoryItem) => row?.floorNo)));

    const selectedFloorNo: string[] = uniqueFloorNumbers.slice().sort((a, b) => {
        const numA = parseInt(a, 10); // Convert string to number
        const numB = parseInt(b, 10); // Convert string to number

        const isANumber = !isNaN(numA);
        const isBNumber = !isNaN(numB);

        if (!isANumber && !isBNumber) {
            // If both are strings, sort them alphabetically
            return a.localeCompare(b);
        } else if (!isANumber && isBNumber) {
            // Place strings before numbers
            return -1;
        } else if (isANumber && !isBNumber) {
            // Place numbers after strings
            return 1;
        } else {
            // Both are numbers, sort numerically
            return numA - numB;
        }
    });

    const [selectedFloorNoValue, setSelectedFloorNoValue] = useState({});

    const selectedApartments: InventoryItem[] = selectedProjectData.filter((row: InventoryItem) => row?.floorNo === selectedFloorNoValue);

    const selectedDataApartments = Array.from(new Set(selectedApartments.map((row: InventoryItem) => {
        return {
            appartmentNo: row?.appartmentNo,
            isLandlordIdEmpty: !row?.landlordId, // true if consultantId is empty
        };
    })));
    const FinalApartmentNo = selectedDataApartments
        .sort((a, b) => a.appartmentNo.localeCompare(b.appartmentNo))
        .map(apartment => ({
            ...apartment,
            appartmentNo: apartment.appartmentNo.charAt(0).toUpperCase() + apartment.appartmentNo.slice(1)
        }));

    const [apartmentNumberValue, setApartmentNumberValue] = useState({});
    const selectedPropertyDetails: InventoryItem[] = selectedApartments.filter((row: InventoryItem) => row?.appartmentNo === apartmentNumberValue);

    const [apartmentId, setApartmentId] = useState({});
    useEffect(() => {
        if (selectedPropertyDetails.length > 0) {
            setApartmentId(selectedPropertyDetails?.[0]?._id);
        }
    }, [selectedPropertyDetails]);
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );

    // Get Location
    const [address, setAddress] = useState<string>('');
    const [formattedAddress, setFormattedAddress] = useState<string>('');
    const [coordinates, setCoordinates] = useState<string>('');
    const [placeIdValue, setPlaceIdValue] = useState<string>('');
    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
        handleGetCoordinates();
    };

    const handleGetCoordinates = async () => {
        try {
            const apiKey = process?.env?.REACT_APP_GOOGLE_MAPS_KEY;
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    address
                )}&key=${apiKey}`
            );

            if (response.ok) {
                const data = await response.json();
                if (data.results.length > 0) {
                    const location = data.results[0].geometry.location;
                    const addressComponents = data.results[0].address_components;
                    const placeId = data.results[0].place_id;
                    const formattedAddressValue = data.results[0].formatted_address;
                    // Extract city and country names from address components
                    let streetName = '';
                    let areaName = '';
                    let sectorColonyArea = '';
                    let district = '';
                    let city = '';
                    let state = '';
                    let country = '';

                    // Iterate through address components to extract details
                    addressComponents.forEach((component: any) => {
                        if (component.types.includes('route')) {
                            streetName = component.long_name;
                        } else if (component.types.includes('sublocality_level_1') || component.types.includes('sublocality') || component.types.includes('neighborhood')) {
                            if (!sectorColonyArea) { // Only set if sectorColonyArea is not already set
                                sectorColonyArea = component.long_name;
                            }
                        } else if (component.types.includes('political') && component.types.includes('sublocality') && component.types.includes('sublocality_level_1')) {
                            areaName = component.long_name;
                        } else if (component.types.includes('administrative_area_level_2')) {
                            district = component.long_name;
                        } else if (component.types.includes('locality')) {
                            city = component.long_name;
                        } else if (component.types.includes('administrative_area_level_1')) {
                            state = component.long_name;
                        } else if (component.types.includes('country')) {
                            country = component.long_name;
                        }
                    });

                    formik.setValues({
                        ...formik.values,
                        address: formattedAddressValue,
                        street: streetName,
                        sector: sectorColonyArea,
                        areaName: areaName,
                        cityName: city,
                        province: state,
                        state: state,
                        country: country,
                        placeId: placeId,
                    });
                    setCoordinates(location);
                    setCity(city);
                    setState(state);
                    setCountry(country);
                    setPlaceIdValue(placeId);
                    setFormattedAddress(formattedAddressValue)
                } else {
                    console.error('No results found for the provided address.');
                }
            } else {
                console.error('Error fetching data from the geocoding API.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    function getErrorMessageByFieldName(fieldName: string, index: number): string | undefined {
        const error = formik.errors.owner?.[index] as any;
        return typeof error === 'object' && error !== null ? error[fieldName] : undefined;
    }

    return (
        <Modal
            setOpen={() => setOpen(!open)}
            open={open}
            title={
                modalMode === "edit"
                    ? ` Update Property`
                    : "Add Property"
            }
            cancelButtonTitle="Cancel"
            acceptButtonVarient="contained"
            acceptButtonTitle={
                modalMode === "edit"
                    ? ` Update Property`
                    : "Add Property"
            }
            handleAccept={() => formik.handleSubmit()}
            handleClose={() => formik.resetForm()}
            loading={loading}
            isActionable={true}
        >
            {loading ? (
                <Box
                    sx={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        left: "50%",
                        top: "30%",
                    }}
                >
                    <Loader top='"60%' left='"50%' />
                </Box>
            ) : null}
            <Box rowGap="10px" display="flex" flexDirection="column" mt="10px">
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} md={6}>
                        <DropDown
                            id="ProjectSelecting"
                            label="Please choose one of them*"
                            value={formik.values.ProjectSelecting}
                            onChangeHandler={(event: any) => {
                                formik.setFieldValue("ProjectSelecting", event.target.value);
                            }}
                            isError={
                                formik.touched.ProjectSelecting &&
                                Boolean(formik.errors.ProjectSelecting)
                            }
                            helperText={
                                formik.touched.ProjectSelecting
                                    ? formik.errors.ProjectSelecting
                                    : ""
                            }
                            options={ProjectSelecting}
                        />
                    </Grid>

                    {formik.values.ProjectSelecting === "" ||
                        formik.values.ProjectSelecting === "add_building" ? (
                        <Grid item xs={12} sm={12} md={6}>
                            <CustomInput
                                id="projectName"
                                label="Building Name *"
                                placeholder="Type here..."
                                value={formik.values.projectName}
                                onChangeHandler={formik.handleChange}
                                isError={
                                    formik.touched.projectName &&
                                    Boolean(formik.errors.projectName)
                                }
                                helperText={
                                    formik.touched.projectName ? formik.errors.projectName : ""
                                }
                            />
                        </Grid>
                    ) : null}

                    {formik.values.ProjectSelecting === "Registered_project" && (
                        <>
                            <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>
                                <ProjectNamePropertyDropdown
                                    onChangeOption={(value: any) => {
                                        formik.setFieldValue("projectName", value._id);
                                        formik.setFieldValue("projectName", value.projectName);
                                        formik.setFieldValue("floorNo", '');
                                        formik.setFieldValue("appartmentNo", '');
                                        formik.setFieldValue("category", '');
                                        formik.setFieldValue("subCategory", '');
                                        formik.setFieldValue("country", value.country);
                                        formik.setFieldValue("province", value.state);
                                        formik.setFieldValue("state", value.state);
                                        formik.setFieldValue("cityName", value.city);
                                        formik.setFieldValue("placeId", value.placeId);
                                        formik.setFieldValue("areaName", value.areaName);
                                        formik.setFieldValue("sector", value.sector);
                                        formik.setFieldValue("street", value.street);
                                        formik.setFieldValue("address", value.address);
                                        setProjectNameValue(value._id);
                                        setApartmentNumberValue('')
                                    }}
                                    isError={
                                        formik.touched?.projectName &&
                                        Boolean(formik.errors.projectName)
                                    }
                                    helperText={
                                        formik.touched.projectName ? formik.errors.projectName : ""
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>
                                <FormControl style={{ width: "100%" }}>
                                    <HeadingText />
                                    <Select
                                        style={{ width: "100%" }}
                                        sx={{ backgroundColor: "#fcfcfb" }}
                                        id="floorNo"
                                        value={formik.values.floorNo}
                                        label={<HeadingText />}
                                        onChange={(event: any) => {
                                            formik.setFieldValue("floorNo", event.target.value);
                                            setSelectedFloorNoValue(event.target.value.toString());
                                        }}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        {selectedFloorNo.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} sx={{ zIndex: "tooltip" }}>
                                <FormControl style={{ width: "100%" }}>
                                    <HeadingText2 />
                                    <Select
                                        style={{ width: "100%" }}
                                        sx={{ backgroundColor: "#fcfcfb" }}
                                        id="appartmentNo"
                                        value={formik.values.appartmentNo}
                                        label={<HeadingText2 />}
                                        onChange={(event: any) => {
                                            formik.setFieldValue("appartmentNo", event.target.value.toUpperCase());
                                            setApartmentNumberValue(event.target.value.toString())
                                        }}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        {FinalApartmentNo.map((property, index) => (
                                            <MenuItem
                                                key={index}
                                                value={property.appartmentNo}
                                                disabled={!property.isLandlordIdEmpty}
                                                style={{ color: property.isLandlordIdEmpty ? 'inherit' : '#ff1616', fontWeight: property.isLandlordIdEmpty ? 'normal' : '600' }}
                                            >
                                                {/* {property.appartmentNo} */}
                                                {property.appartmentNo} {!property.isLandlordIdEmpty && "(taken)"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="category"
                                    label="Category *"
                                    value={selectedPropertyDetails[0]?.category || formik.values.category}
                                    type="text"
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="subCategory"
                                    label="Sub-Category *"
                                    value={selectedPropertyDetails[0]?.subCategory || formik.values.subCategory}
                                    type="text"
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="completionStatus"
                                    label="Completion Status *"
                                    value={formik.values.completionStatus}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("completionStatus", event.target.value);
                                    }}
                                    isError={
                                        formik.touched.completionStatus &&
                                        Boolean(formik.errors.completionStatus)
                                    }
                                    helperText={
                                        formik.touched.completionStatus
                                            ? formik.errors.completionStatus
                                            : ""
                                    }
                                    options={CompletedStatus}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="furnishingStatus"
                                    label="Furnishing Status *"
                                    value={formik.values.furnishingStatus}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue(
                                            "furnishingStatus",
                                            event.target.value
                                        );
                                    }}
                                    isError={
                                        formik.touched.category && Boolean(formik.errors.category)
                                    }
                                    helperText={
                                        formik.touched.category ? formik.errors.category : ""
                                    }
                                    options={furnishingStatus}
                                />
                            </Grid>

                            {formik.values.owner.map((owner, index) => (
                                <>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id={`owner.purchasePrice${index}`}
                                            label="Purchase Price *"
                                            placeholder="Purchase Price"
                                            value={owner?.purchasePrice}
                                            type="number"
                                            onChangeHandler={(event: any) => {
                                                const newOwners = [...formik.values.owner];
                                                newOwners[index].purchasePrice = event.target.value;
                                                formik.setFieldValue("owner", newOwners);
                                                formik.setFieldValue("price", event.target.value);
                                                formik.setFieldValue(`owner.${[index]}.landlord`, user?._id);
                                                formik.setFieldValue(`owner.${[index]}.landlordName`, user?.fname);
                                                formik.setFieldValue(`landlordId`, user?._id);
                                            }}
                                            isError={!!getErrorMessageByFieldName('purchasePrice', index)}
                                            helperText={getErrorMessageByFieldName('purchasePrice', index) || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id={`owner.purchasingDate${index}`}
                                            label="Purchasing Date*"
                                            placeholder="Purchasing Date"
                                            value={owner?.purchasingDate}
                                            type="date"
                                            onChangeHandler={(event: any) => {
                                                const newOwners = [...formik.values.owner];
                                                newOwners[index].purchasingDate = event.target.value;
                                                formik.setFieldValue("owner", newOwners);
                                            }}
                                            isError={!!getErrorMessageByFieldName('purchasingDate', index)}
                                            helperText={getErrorMessageByFieldName('purchasingDate', index) || ''}
                                        />
                                    </Grid>
                                </>
                            ))}

                            <Grid item xs={12} sm={12} md={12}>
                                <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px", padding: "10px 0" }}>Select Property Type for Features/Amenities</label>
                                <MultipleSelectCheckmarks
                                    name="projectType"
                                    tagName="Property Type*"
                                    value={formik.values.projectType ?? ""}
                                    onChange={(event: any) => {
                                        formik.setFieldValue("projectType", event.target.value);
                                    }}
                                    keyToSelect={"label"}
                                    options={amenitiesCategories}
                                    isError={
                                        formik.touched.projectType && Boolean(formik.errors.projectType)
                                    }
                                    helperText={
                                        formik.touched.projectType ? formik.errors.projectType : ""
                                    }
                                />
                            </Grid>

                            <Box className="dropdown">
                                <label className="required form-label m-4">Property Features</label>
                                <button
                                    type="button"
                                    className="btn text-light mt-2 btn-sm dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-bs-auto-close="outside"
                                    style={{ backgroundColor: "#181c32" }}
                                >
                                    + Add Features
                                </button>
                                <form className="dropdown-menu p-4 " onSubmit={submitHandler}>
                                    <Box
                                        className="row"
                                        sx={{
                                            width: 350,
                                        }}
                                    >
                                        {AmenitiesList?.map(
                                            (
                                                amenity: {
                                                    label: any;
                                                    key: string | number | undefined;
                                                },
                                                index: number
                                            ) => {
                                                return (
                                                    <Box className="col-md-6 mt-3">
                                                        <Box className="form-check">
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`dropdownCheck${index}`}
                                                            >
                                                                {amenity.label}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id={`dropdownCheck${index}`}
                                                                value={formik.values.amenities || amenties[amenity.key as string]}
                                                                checked={amenties[amenity.key as string]}
                                                                name={amenity.key as string}
                                                                onChange={(event) => changedAmenity(event, index)}
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            }
                                        )}
                                    </Box>
                                </form>
                            </Box>

                            <Grid item xs={12} sm={12} md={12}>
                                <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px" }}>Description</label>
                                <JoditEditor
                                    ref={editor}
                                    value={formik.values.description}
                                    onChange={(newContent) =>
                                        formik.setFieldValue("description", newContent)
                                    }
                                />
                            </Grid>

                        </>
                    )}

                    {formik.values.ProjectSelecting === "add_building" && (
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="floorNo"
                                    label="Floor No *"
                                    value={formik.values.floorNo}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("floorNo", event.target.value);
                                    }}
                                    isError={formik.touched.floorNo && Boolean(formik.errors.floorNo)}
                                    helperText={formik.touched.floorNo ? formik.errors.floorNo : ""}
                                    options={propertyFloorOptions}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="appartmentNo"
                                    label="Apartment No *"
                                    placeholder="Type here..."
                                    value={formik.values.appartmentNo}
                                    type="text"
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("appartmentNo", event.target.value.toUpperCase());
                                    }}
                                    isError={
                                        formik.touched.appartmentNo &&
                                        Boolean(formik.errors.appartmentNo)
                                    }
                                    helperText={
                                        formik.touched.appartmentNo ? formik.errors.appartmentNo : ""
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="category"
                                    label="Property Category *"
                                    value={formik.values.category}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("category", event.target.value);
                                    }}
                                    isError={
                                        formik.touched.category && Boolean(formik.errors.category)
                                    }
                                    helperText={formik.touched.category ? formik.errors.category : ""}
                                    options={propertyTypeOptions}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="subCategory"
                                    label="Property SubCategory *"
                                    value={formik.values.subCategory}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("subCategory", event.target.value);
                                    }}
                                    isError={
                                        formik.touched.subCategory && Boolean(formik.errors.subCategory)
                                    }
                                    helperText={
                                        formik.touched.subCategory ? formik.errors.subCategory : ""
                                    }
                                    options={propertyCategoryOptions.filter(
                                        (item: any) => item.category === formik.values.category
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="completionStatus"
                                    label="Completion Status *"
                                    value={formik.values.completionStatus}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("completionStatus", event.target.value);
                                    }}
                                    isError={
                                        formik.touched.completionStatus &&
                                        Boolean(formik.errors.completionStatus)
                                    }
                                    helperText={
                                        formik.touched.completionStatus
                                            ? formik.errors.completionStatus
                                            : ""
                                    }
                                    options={CompletedStatus}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <DropDown
                                    id="furnishingStatus"
                                    label="Furnishing Status *"
                                    value={formik.values.furnishingStatus}
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue(
                                            "furnishingStatus",
                                            event.target.value
                                        );
                                    }}
                                    isError={
                                        formik.touched.category && Boolean(formik.errors.category)
                                    }
                                    helperText={
                                        formik.touched.category ? formik.errors.category : ""
                                    }
                                    options={furnishingStatus}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="area"
                                    label="Area * (Sqm)"
                                    placeholder="Type here..."
                                    value={formik.values.area}
                                    type="number"
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("area", event.target.value);
                                    }}
                                    isError={formik.touched.area && Boolean(formik.errors.area)}
                                    helperText={formik.touched.area ? formik.errors.area : ""}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="rentalIncome"
                                    label="Rental Income (Optional)"
                                    placeholder="Type here..."
                                    value={formik.values.rentalIncome} // set default value to "N/A"
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("rentalIncome", event.target.value);
                                    }}
                                    isError={
                                        formik.touched.rentalIncome &&
                                        Boolean(formik.errors.rentalIncome)
                                    }
                                    helperText={
                                        formik.touched.rentalIncome ? formik.errors.rentalIncome : ""
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="salePrice"
                                    label="Sale Price (PKR) (optional):"
                                    placeholder="Type here..."
                                    value={formik.values.salePrice}
                                    type="number"
                                    onChangeHandler={(event: any) => {
                                        formik.setFieldValue("salePrice", event.target.value);
                                    }}
                                    isError={formik.touched.salePrice && Boolean(formik.errors.salePrice)}
                                    helperText={formik.touched.salePrice ? formik.errors.salePrice : ""}
                                />
                            </Grid>

                            {formik.values.category === "Residential" && (
                                <>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id="bedrooms"
                                            label="Bedrooms *"
                                            placeholder="Type here..."
                                            value={formik.values.bedrooms}
                                            type="number"
                                            onChangeHandler={formik.handleChange}
                                            isError={
                                                formik.touched.bedrooms && Boolean(formik.errors.bedrooms)
                                            }
                                            helperText={
                                                formik.touched.bedrooms ? formik.errors.bedrooms : ""
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id="bathrooms"
                                            label="Bathrooms *"
                                            placeholder="Type here..."
                                            value={formik.values.bathrooms}
                                            type="number"
                                            onChangeHandler={formik.handleChange}
                                            isError={
                                                formik.touched.bathrooms && Boolean(formik.errors.bathrooms)
                                            }
                                            helperText={
                                                formik.touched.bathrooms ? formik.errors.bathrooms : ""
                                            }
                                        />
                                    </Grid>
                                </>
                            )}

                            {formik.values.owner.map((owner, index) => (
                                <>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id={`owner.purchasePrice${index}`}
                                            label="Purchase Price *"
                                            placeholder=""
                                            value={owner?.purchasePrice}
                                            type="number"
                                            onChangeHandler={(event: any) => {
                                                // formik.setFieldValue(`owner.purchasePrice${index}`, event.target.value);
                                                const newOwners = [...formik.values.owner];
                                                newOwners[index].purchasePrice = event.target.value;
                                                formik.setFieldValue("owner", newOwners);
                                                formik.setFieldValue("price", event.target.value);
                                                formik.setFieldValue(`owner.${[index]}.landlord`, user?._id);
                                                formik.setFieldValue(`owner.${[index]}.landlordName`, user?.fname);
                                                formik.setFieldValue(`landlordId`, user?._id);
                                            }}
                                            isError={!!getErrorMessageByFieldName('purchasePrice', index)}
                                            helperText={getErrorMessageByFieldName('purchasePrice', index) || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CustomInput
                                            id={`owner.purchasingDate${index}`}
                                            label="Purchasing Date*"
                                            placeholder="Purchasing Date"
                                            value={owner?.purchasingDate}
                                            type="date"
                                            onChangeHandler={(event: any) => {
                                                const newOwners = [...formik.values.owner];
                                                newOwners[index].purchasingDate = event.target.value;
                                                formik.setFieldValue("owner", newOwners);
                                            }}
                                            isError={!!getErrorMessageByFieldName('purchasingDate', index)}
                                            helperText={getErrorMessageByFieldName('purchasingDate', index) || ''}
                                        />
                                    </Grid>
                                </>
                            ))}

                            <Grid item xs={12} sm={12} md={12}>
                                <CustomInput
                                    id="address"
                                    label="Enter Address/Building Name with City"
                                    placeholder="Type here..."
                                    value={address}
                                    onChangeHandler={handleAddressChange}
                                />
                                {coordinates && (
                                    <>
                                        <div className="pt-3">
                                            <p className="fw-bolder">(Address: &nbsp;&nbsp;&nbsp; {formattedAddress})</p>
                                            {/* <p className="fw-bolder">City: &nbsp;&nbsp;&nbsp; {city}</p>
                      <p className="fw-bolder">State: &nbsp;&nbsp;&nbsp; {state}</p>
                      <p className="fw-bolder">Country: &nbsp;&nbsp;&nbsp; {country}</p> */}
                                        </div>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="country"
                                    label="Country *"
                                    value={country}
                                    type="text"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="province"
                                    label="Province*"
                                    value={state}
                                    type="text"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <CustomInput
                                    id="cityName"
                                    label="City*"
                                    value={city}
                                    type="text"
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <CustomInput
                                        id="title"
                                        label="Property Title *"
                                        placeholder="Type here..."
                                        value={formik.values.title}
                                        onChangeHandler={formik.handleChange}
                                        isError={formik.touched.title && Boolean(formik.errors.title)}
                                        helperText={formik.touched.title ? formik.errors.title : ""}
                                        disabled
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px" }}>Description</label>
                                <JoditEditor
                                    ref={editor}
                                    value={formik.values.description}
                                    onChange={(newContent) =>
                                        formik.setFieldValue("description", newContent)
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <label htmlFor="" style={{ color: "#27344E", fontWeight: "700", fontSize: "14px", padding: "10px 0" }}>Select Property Type for Features/Amenities</label>
                                <MultipleSelectCheckmarks
                                    name="projectType"
                                    tagName="Property Type*"
                                    value={formik.values.projectType ?? ""}
                                    onChange={(event: any) => {
                                        formik.setFieldValue("projectType", event.target.value);
                                    }}
                                    keyToSelect={"label"}
                                    options={amenitiesCategories}
                                    isError={
                                        formik.touched.projectType && Boolean(formik.errors.projectType)
                                    }
                                    helperText={
                                        formik.touched.projectType ? formik.errors.projectType : ""
                                    }
                                />
                            </Grid>

                            <Box className="dropdown">
                                <label className="required form-label m-4">Property Features</label>
                                <button
                                    type="button"
                                    className="btn text-light mt-2 btn-sm dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    data-bs-auto-close="outside"
                                    style={{ backgroundColor: "#181c32" }}
                                >
                                    + Add Features
                                </button>
                                <form className="dropdown-menu p-4 " onSubmit={submitHandler}>
                                    <Box
                                        className="row"
                                        sx={{
                                            width: 350,
                                        }}
                                    >
                                        {AmenitiesList?.map(
                                            (
                                                amenity: {
                                                    label: any;
                                                    key: string | number | undefined;
                                                },
                                                index: number
                                            ) => {
                                                return (
                                                    <Box className="col-md-6 mt-3">
                                                        <Box className="form-check">
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`dropdownCheck${index}`}
                                                            >
                                                                {amenity.label}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id={`dropdownCheck${index}`}
                                                                value={amenties[amenity.key as string]}
                                                                checked={amenties[amenity.key as string]}
                                                                name={amenity.key as string}
                                                                onChange={(event) => changedAmenity(event, index)}
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            }
                                        )}
                                    </Box>
                                </form>
                            </Box>
                            {/* End add more features */}

                            {/* start images upload section */}
                            <Box className="col-xl-12">
                                <h5
                                    className="text-light p-3 mt-2 rounded"
                                    style={{
                                        background:
                                            "linear-gradient(45deg,  #233a65 0%, #f5f8fa 62%, #fff 100%)",
                                    }}
                                >
                                    ADD IMAGES
                                </h5>
                                {/* START ALERT */}
                                <Paper elevation={6} m-2>
                                    <Box
                                        className="alert alert-warning d-flex align-items-center"
                                        role="alert"
                                    >
                                        <Box>
                                            <i className="fonticon-house"></i>
                                            Press CTRL key while selecting images to upload multiple images
                                            at once
                                        </Box>
                                    </Box>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Grid container m={2}>
                                            {formik?.values?.images?.map((image: any, index: number) => (
                                                <ImageViewer
                                                    key={image}
                                                    index={image}
                                                    image={`${API}/uploads/${image}`}
                                                    onImageRemove={() => {
                                                        let prevImages: any = [];
                                                        formik?.values?.images?.map((_: any, i: number) =>
                                                            index === i ? null : _
                                                        );
                                                        setFiles(prevImages);
                                                    }}
                                                />
                                            ))}
                                            <Grid item>
                                                <ImageUploader
                                                    multiple={true}
                                                    onSubmit={onSubmitImage}
                                                    images={images}
                                                    setImages={setImages}
                                                    files={files}
                                                    setFiles={setFiles}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        alignItems="center"
                                        sx={{
                                            position: "sticky",
                                            bottom: "0",
                                            alignItems: "center",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexDirection: "row-reverse",
                                            m: 1,
                                        }}
                                    >
                                        {files.length > 0 && (
                                            <Button
                                                sx={{ backgroundColor: " #233a65", margin: "30px" }}
                                                variant="contained"
                                                // color="success"
                                                size="large"
                                                onClick={onSubmitImage}
                                            >
                                                Upload Images
                                            </Button>
                                        )}
                                    </Grid>
                                </Paper>
                            </Box>
                            {/* End images upload section */}
                        </>
                    )}

                </Grid>
            </Box>
        </Modal>
    );
};

export default EditLandlordProperty;
