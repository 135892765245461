import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
    PageLink,
    PageTitle,
    usePageData,
} from "../../../_metronic/layout/core";
import { Overview } from "./components/Overview";
import { PropertyHeader } from "./PropertyHeader";
import { Settings } from "./components/settings/Settings";
import Table, { capitalizeFirstLetter, IconRender } from "../Table";
import { deleteProperty, getMyProperties, updateOwner } from "./redux/PropertyCrud";
import * as AuthRedux from "../auth/redux/AuthRedux";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { updateProperty, createProperty } from "./redux/PropertyCrud";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "_metronic/helpers/AssetHelpers";
// import UpdatePropertyOwner from "./components/UpdatePropertyOwner";
import UpdatePropertyOwner from "./components/UpdatePropertyOwner";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";


const accountBreadCrumbs: Array<PageLink> = [
    {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
    },
];
const columns = (iconClickHandler: any, projectLookup: any) => [
    // {
    //     name: "Name",
    //     key: "title",
    //     numaric: false,
    //     isSortable: true,
    //     renderContent: (value: any) => (
    //         <div className="overflow-ellipsis" title={value}>
    //             {value.length > 15 ? value.substring(0, 20) + "..." : value}
    //         </div>
    //     ),
    // },
    // {
    //   name: "Location",
    //   key: "community",
    //   numaric: false,
    //   isSortable: true,
    // },
    // {
    //   name: "Lat & Lng",
    //   key: "location",
    //   numaric: false,
    //   isSortable: true,
    //   renderContent: (value: any) => `${value.lat} , ${value.lng}`,
    // },
    {
        name: "Building Name",
        key: "projectName",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) =>
            capitalizeFirstLetter(
                <div className="overflow-ellipsis" title={value}>
                    <div className="overflow-ellipsis" title={value}>
                        {projectLookup[value] || value}
                        {/* {projectLookup[value].length > 15 ? projectLookup[value].substring(0, 20) + "..." : value} */}
                    </div>
                </div>
            ),
    },
    {
        name: "Floor No",
        key: "floorNo",
        numaric: false,
        isSortable: true,
        // renderContent: (value: any) => capitalizeFirstLetter(`For ${value || "-"}`),
    },
    {
        name: "Appartment No",
        key: "appartmentNo",
        numaric: true,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(`${value || " "}`),
    },
    {
        name: "Beds",
        key: "bedrooms",
        numaric: false,
        isSortable: true,
    },
    {
        name: "Area/Sq.m",
        key: "area",
        numaric: false,
        isSortable: true,
    },

    {
        name: "Change Owner",
        key: "action",
        component: (row: any) => (
            <>
                {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "edit")}
            </>
        ),
    },
];

const ChangeOwnerPage: React.FC = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [filter, setFilter] = useState({});


    const { getAll } = APIS.ProjectName;
    const { data } = useFetchData({ getAll });
    const [projectLookup, setProjectLookup] = useState<any>({});

    useEffect(() => {
        // Create a lookup object for projectName id's to project Names
        const lookup: any = {};
        data.forEach((row: any) => {
            lookup[row?._id] = row?.projectName;
        });
        setProjectLookup(lookup);
    }, [data]);

    const [currentListing, setCurrentListing]: any = useState(0);
    const listing: any = useSelector<RootState>(
        ({ auth }) => auth.listing,
        shallowEqual
    );
    const [myProperties, setMyProperties] = useState(listing);
    const [accessToken, setAccessToken]: any = useState("");
    const [modalMode2, setModalMode2]: any = useState("edit");

    const getToken = async () => {
        let accessToken = (await localStorage.getItem("persist:accessToken")) || "";
        accessToken = JSON.parse(accessToken)?.accessToken?.replaceAll('"', "");
        setAccessToken(accessToken);
    };

    const filtersChanged = (filters: any) => {
        setFilter(filters);
        setLoading(true);
        setTimeout(() => {
            getProperties(filters);
        }, 1000);
    };

    const getProperties = (filters: any) => {
        getMyProperties(accessToken, filters)
            .then(({ data }) => {
                dispatch(AuthRedux.actions.setMyListings(data.property));
                setCount(data.count);
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        setLoading(true);
        getToken();
        setTimeout(() => {
            getProperties(filter);
        }, 1000);
    }, []);

    useEffect(() => {
        setMyProperties(listing);
    }, [listing]);

    const iconClickHandler = (row: any, type: any) => {
        if (type === "edit") {
            setModalMode2("edit");
            setCurrentListing(row);
            setOpen2(true);
            console.log(setOpen2)
        } else if (type === "delete") {
            Swal.fire({
                title: "",
                text: "Are you sure you want to delete this Property",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((status) => {
                if (status.isConfirmed) {
                    setLoading(true);
                    deleteProperty(row._id, accessToken)
                        .then(({ data, status }) => {
                            let text = "";
                            let icon: any = "error";
                            if (status === 200) {
                                text = "Successfully Deleted";
                                icon = "success";
                            } else {
                                text = data.message;
                                icon = "error";
                            }
                            dispatch(AuthRedux.actions.setMyListings(data.property));
                            setCount(data.count);
                        })
                        .catch(() => { })
                        .finally(() => setLoading(false));
                }
            });
        }
    };

    const updatePropertyFunction = (values: any) => {
        setLoading(true);
        updateOwner(values, currentListing?._id, accessToken)
            .then(({ data }) => {
                dispatch(AuthRedux.actions.setMyListings(data.property));
                setCount(data.count);
                setOpen(false);
                Swal.fire({
                    title: "Property updated Successfully.",
                    text: "",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "",
                    confirmButtonText: "OK",
                })
            })
            .catch(() => { })
            .finally(() => setLoading(false));
    };
    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    const filteredData = myProperties.filter((row: any) => row?.isDeleted === false);

    return (
        <>
            <PageTitle>Change Property Ownership</PageTitle>
            {user?.role === "employee" || user?.role === "admin" ? (
                <div className="pb-5">
                    <Table
                        columns={columns(iconClickHandler, projectLookup)}
                        data={filteredData}
                        loading={loading}
                        onRowClick={(row: any, type: any) => iconClickHandler(row, type)}
                        isPaginationRequired
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        total={count}
                    />
                    <UpdatePropertyOwner
                        setOpenOne={setOpen2}
                        openOne={open2}
                        loading={loading}
                        updateProperty={
                            modalMode2 === "edit"
                                ? updatePropertyFunction
                                : updatePropertyFunction
                        }
                        modalMode={modalMode2}
                        property={currentListing}
                    />
                </div>
            ) : (
                <div className="card-body p-0 ">
                    {/* begin::Chart */}
                    <div className="alert alert-warning p-5 mt-5 mx-10" role="alert">
                        This is a warning alert{" "}
                        <h3 className="text-danger">
                            Your account is not approved you cannot Post Property!!.
                        </h3>
                    </div>
                    {/* end::Chart */}
                    {/* begin::Stats */}
                    <div className="card-p mt-10 position-relative d-flex justify-content-center">
                        <img src={toAbsoluteUrl("/media/illustrations/sketchy-1/57.svg")} />

                        {/* end::Row */}
                    </div>
                    <span className="d-flex justify-content-center fw-bold fs-4 mb-1 ">
                        No Record Found
                    </span>
                    {/* <span className='d-flex justify-content-center fw-bold fs-4 text-muted mb-5 '>Submit your application to Imlaak support for further assistance</span>  */}
                    {/* end::Stats */}
                </div>
            )}
        </>
    );
};

export default ChangeOwnerPage;
