import axios from 'axios'
import { CREATE_PROPERTY, GET_MY_PROPERTIES, UPDATE_LANDLORD_PROFILE, UPDATE_PROPERTY } from '../../../constants/api'
import { IUpdateAccount } from '../components/CreateAccountWizardHelper'
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'setup';

// export function updateProfile(values: IUpdateAccount, accessToken: string | null) {

export function updateProfile(values: IUpdateAccount, accessToken: string | null, userID: string | null) {
  return axios.post(UPDATE_LANDLORD_PROFILE, {
    _id: userID, ...values
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}
export function updateImage(values: any, accessToken: string | null) {
  const img = values.userImage || {}
  const id = values.id || {}
  return axios.post(UPDATE_LANDLORD_PROFILE, {
    _id: id,
    userImage: img
  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}

export function createProperty(values: any, accessToken: string | null) {
  const { listing } = values || {}
  return axios.post(CREATE_PROPERTY, {
    property: listing
  },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}


export function getMyProperties(accessToken: string | null) {
  return axios.get(GET_MY_PROPERTIES,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
}


